import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from 'axios';

const { Option } = Select 


/**
 * @const SelectServicio
 * @description Select de servicio
 */
const SelectServicio = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el servicio", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},

        proyecto_id,

    } = props


    const [ servicios, setServicio ] = useState({
        data: [],
        page: 1,
        limit: 100,
        total: 0,
        search: null,
    })


    /**
     * @const getServicios
     * @description Obitiene las servicios
     */
    const getServicios = ({
    	page = servicios.page,  
    	limit = servicios.limit, 
    	search
    } = servicios) => {

        axios.get('/servicios', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setServicio(data)
        }).catch(error => {
            message.error(error?.response?.data?.message + " (servicios)" ?? 'Error al obtener los servicios')
        })
    }


    //componentDidMount
    useEffect(() => { 
        getServicios()
    }, [])

    //componentDidUpdate
    useEffect(() => { 
        if(proyecto_id)
            getServicios()
    }, [proyecto_id])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getServicios({search})}
            onSelect={(servicios)=> {
                onChange(servicios.value)
                onSelect(servicios.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {servicios?.data?.map(({ _id, nombre }) => <Option value={_id} >
                {nombre}
            </Option>)}
        </Select>
    );
}



export default SelectServicio;