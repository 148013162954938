import { Avatar, Button, Form, Input, Space, Typography, Upload, Badge, Tooltip, Spin, Popconfirm, message} from "antd";
import React , {useContext} from "react"
import { FileOutlined, SendOutlined, SmileOutlined, UploadOutlined, LoadingOutlined, EditFilled , DeleteFilled} from "@ant-design/icons"

import "../../../Styles/Modules/Tickets/Chat.scss"
import axios from "axios";
import moment from "moment"
import { User } from '../../../Hooks/Logged';

const { Text, Title, Paragraph } = Typography
const {Ribbon} = Badge;

//Estatus para mostrar en el tooltip de la bitacora
var estatus = {
    '1': {
      title: "En Espera",
      color: "#2c59ff",
    },
    '2':{
      title: "En Proceso",
      color: "#faad14",
    },
    '3':{
      title: "En Confirmación",
      color: "#7b868e",
    },
    '4':{
      title: "Pausado",
      color: "#4e2e8f",
    },
    '5':{
      title: "Completado",
      color: "#52c41a",
    },
}


//Wrapper para los hooks
export default function (props) {
    let user = useContext(User)
	return <BitacoraHistorial {...props} userLogged={user} />
}

class BitacoraHistorial extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            bitacora: [{ _id: '1', descripcion: 'Se han subido cambios al servidor', realizado_por: 'John Doe',email:'johny.doe@gmail.com', estatus: 1 , 
            fecha:  "2023-01-11T00:59:56.401Z"},] ,
            descripcion: '',
            evidencias:[],
            filesUploaded: {},
            shiftKey: false,
            loading:true,
        };
    }

    

    componentDidMount() {

        document.addEventListener('keyup', this.keyUp);
        document.addEventListener('keydown', this.keyDown);
        this.getActualizaciones();

        console.log('Que usuario esta aqui? ', this.props.userLogged)
    }

    keyUp = (e) => {
        // console.log("keyup", e)
        if (e.shiftKey == false && this.state.shiftKey == true) {
            this.setState({ shiftKey: false })
        }
    }
    keyDown = (e) => {
        if (e.shiftKey == true && this.state.shiftKey == false) {
            this.setState({ shiftKey: true })
        }
    }



    getActualizaciones = () =>{
        console.log('Getting updates....')
        this.setState({loading:true})
        axios.get('/tickets/actualizaciones/list',{
            params:{
                ticket_id:this.props.ticket_id,
            }
        })
        .then(response =>{
                console.log('Response updates ', response.data);
                this.setState({
                    bitacora: response.data ,
                    loading:false
                });

        })  
        .catch(error =>{
            console.log('Error en listado de entradas', error);
        })

    }

    uploadFile = (file) => {
        let [_id] = this.uploader.upload([file.file]);
        this.setState(state => {
            state.filesUploaded[_id] = {
                ...file,
                progress: 0,
                uploadId: _id,
                isImage: Boolean(/\.(png|jpe?g|gif|webp|svg)(\?.*)?$/.test(file.file.name))
            }
            return state
        })
    }

    deleteFile = ({ filename, uploadId }) => {
        this.context.emit(this.props.type + "/chat/delete-file", { ...this.getRequester(), filename })

        this.setState(state => {
            delete state.filesUploaded[uploadId]
            return state
        })
    }


    handleSubmit = ({ descripcion }) => {
        console.log("descripcion ", descripcion)
        this.setState({loading:true})
        if (!descripcion.length) {
            return;
        }


        axios.post( "/tickets/actualizaciones/add", {
            ticket_id: this.props.ticket_id,
            descripcion: descripcion,
            fecha: moment(),
            evidencias: this.state.filesUploaded.fileList,
        }).then( response=> {

            console.log('Result', response)
            this.getActualizaciones();
          
        })
        .catch(error=>{

            console.log('Error', error)

        })

        //this.setState(state => ({ messages: state.messages.concat(newItem), }));
        this.formRef.setFieldsValue({ descripcion: "" })
    }

    deleteActualizacion = (_id) =>{
        this.setState({loading:true})
        axios.delete('/tickets/actualizaciones/delete',{
            params:{
                id:_id,
            }
        })
        .then(response =>{
                console.log('Delete response ', response.data);
                this.setState({
                    loading:false
                });
                message.success('Registro eliminado.')
                this.getActualizaciones()

        })  
        .catch(error =>{
            console.log('Error en listado de entradas', error);
        })


    }


    render() {
        const { style, userLogged } = this.props//{ height, maxHeight }
        const {deleteActualizacion,getActualizaciones} = this
        return (
            <div class="chat" style={style}>
                <div class="messages">
                <Spin spinning={this.state.loading} indicator={<LoadingOutlined />}/>
                    <div class="messages-history">
                        <MessagesHistory items={this.state.bitacora}  user={userLogged._id} onDelete={deleteActualizacion} onList={getActualizaciones} />
                    </div>
                    <Form
                        className="chat-form"
                        ref={e => this.formRef = e}
                        onFinish={this.handleSubmit}>
                        <Button type="link" icon={<SmileOutlined style={{ color: "currentcolor" }} />} ></Button>
                        <Form.Item
                            name="descripcion"
                            noStyle>
                            <Input.TextArea
                                style={{ flex: "0 0 1", }}
                                bordered={false}
                                autoSize={{ minRows: 1 }}
                                placeholder={"Envia un mensaje..."}
                                onPressEnter={e => {
                                    if (this.state.shiftKey == false) {
                                        e.preventDefault()
                                        this.formRef.submit()
                                    }
                                }}

                            />
                        </Form.Item>
                           <Upload
                            multiple
                            showUploadList={false}
                            customRequest={this.uploadFile}>
                            <Button type="link" icon={<FileOutlined style={{ color: "currentcolor" }} />} ></Button>
                        </Upload>
                        <Button htmlType="submit" type="link" icon={<SendOutlined style={{ color: "currentcolor" }} />} ></Button>
                    </Form>
                </div>
            </div>
        );
    }
}


class MessagesHistory extends React.Component {
    state = {
        editing:false,
        id_editing: '',
        shiftKey: false,
    }

    componentDidMount() {

        document.addEventListener('keyup', this.keyUp);
        document.addEventListener('keydown', this.keyDown);
    }

    keyUp = (e) => {
        // console.log("keyup", e)
        if (e.shiftKey == false && this.state.shiftKey == true) {
            this.setState({ shiftKey: false })
        }
    }
    keyDown = (e) => {
        if (e.shiftKey == true && this.state.shiftKey == false) {
            this.setState({ shiftKey: true })
        }
    }



    editActualizacion = (new_texto) =>{
        //console.log('New texto => ',new_texto )
        //console.log('state =>', this.state)

        this.setState({loading:true})
        if (!new_texto.length) {
            return;
        }
        axios.put( "/tickets/actualizaciones/update", {
            id: this.state.id_editing,
            descripcion: new_texto,
        }).then( response=> {
            console.log('Result', response.data)
            message.success('Entrada actualizada')
            this.setState({editing:false,
                            id_editing: '',
                            shiftKey: false,
                       })
                this.props.onList()
          
        })
        .catch(error=>{
            this.setState({editing:false,
                id_editing: '',
                shiftKey: false,
           })
            console.log('Error', error)
            message.error('Error al actualizar')
        })
        
    }

    render() {
        return [].concat(this.props.items).map(item => (
    
            <div  className="message" key={item.id}>
               
                <Tooltip title={item.realizado_por.email ? item.realizado_por.email: ''}>
                        <Avatar style={{minWidth:'32px'}}>{(item.realizado_por.nombre && item.realizado_por.apellidos)? item.realizado_por.nombre[0] + item.realizado_por.apellidos[0]: 'UNK' }</Avatar>
                </Tooltip>
                <Ribbon placement="end" color={estatus[item.estatus].color} text={estatus[item.estatus].title} >
                    <div class="message-body" style={{maxWidth: '110%'}}>
                    <Title level={5} style={{ color: "currentcolor", display: "inline-block" , marginRight:'10%', padding:'5px'}}>{(item.realizado_por.nombre && item.realizado_por.apellidos)?item.realizado_por.nombre +' '+ item.realizado_por.apellidos: 'Usuario'} </Title> 
                        {(! this.state.editing)? <Paragraph style={{ color: "currentcolor", marginBottom: 0 }}>{item.descripcion}</Paragraph> :
                            
                                <Input.TextArea
                                style={{ flex: "0 0 1", }}
                                bordered={false}
                                autoSize={{ minRows: 1 }}
                                defaultValue={item.descripcion}
                                onPressEnter={e => {
                                    console.log('que paso aqui', e)
                                    if (this.state.shiftKey == false) {
                                        e.preventDefault()
                                        console.log('sending', e)
                                        this.editActualizacion(e.target.value)
                                    }
                                }}
                             />


                        
                        }
                        
                        {(item.realizado_por._id == this.props.user)? 
                        <div style={{textAlign:'end'}}>
                            <Button style={{
                                opacity: 0.7,
                                width: "10%",
                                color: "currentcolor", display: "inline-block", 
                                fontSize: 11,
                                //marginRight: '5px'
                                //float:"right"

                            }}
                            type="ghost"
                            onClick={()=>{ this.setState({editing:true,id_editing: item._id})}}
                            ><EditFilled/></Button>

                            <Popconfirm 
                            title="¿Eliminar registro?"
                            description="Esta acción no se puede deshacer"
                            okText="Eliminar"
                            cancelText="Cancelar"
                            okType="danger"
                            onConfirm={()=> this.props.onDelete(item._id)}
                            >
                            <Button style={{
                                    opacity: 0.7,
                                    width: "10%",
                                    color: "currentcolor", display: "inline-block", //textAlign: "center",
                                    fontSize: 11,
                                    float:"right"
                                }}
                                type="ghost"
                                ><DeleteFilled/></Button>

                            </Popconfirm>
                       </div>
                        :  null }

                        <Tooltip title={moment(item.fecha).format("dddd DD, MMMM YYYY, h:mm a")}>
                            <Text style={{
                            opacity: 0.7,
                            width: '100%',
                            color: "currentcolor", display: "inline-block", textAlign: "end",
                            fontSize: 11,
                        }}>{moment(item.fecha).fromNow()}</Text>
                    </Tooltip>
                    
                    </div>
                </Ribbon>
            </div>
        ));
    }
}
