import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, InputNumber } from 'antd';
import { ControlFilled } from "@ant-design/icons";

import axios from "axios"

const { Title } = Typography;



class ModalActividades extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalActividades = React.createRef();

    componentDidMount() {
        if (this.props.actividad_id !== undefined) {
            this.getActividad()
        }
    }


    /**
     * @memberof ModalActividades
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.actividad_id) {
            this.update(values)
        } else {
            this.addActividad(values)
        }
    }


    /**
     * @memberof ModalActividades
     * @method addActividad
     * @description Añade una actividad a la BD
     */
    addActividad = (values) => {
        this.setState({loading: true})
        axios.post('/actividad', {
            modulo_id: this.props.modulo_id,
            ...values
        }).then(response => {
            message.success('Actividad creada')
            this.props.onClose(true)
           
        }).catch(error => {
            console.log(error)
            message.error(error?.response?.data?.message ?? 'Error al crear la actividad.')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof ModalActividades
     * @method getActividad
     * @description Obtiene un rubro de la DB
     */
    getActividad = () => {

        this.setState({ loading: true })

        axios.get('/actividad/' + this.props.actividad_id)
        .then(({ data }) => {
            this.ModalActividades.current.setFieldsValue(data)

        }).catch(error => {
            message.error('Error al traer rubro')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalActividades
     * @method update
     * @description Actualiza la información de un rubro
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/actividad', {
            ...values,
            id: this.props.actividad_id,
        }).then(response => {
            message.success('Actividad actualizada')
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al actualizar la actividad')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                name="form-rubro"
                id="form-rubro"
                layout="vertical"
                ref={this.ModalActividades}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción" />

                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Horas"
                                name="horas_aproximadas"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese las horas aproximadas"
                                }]}
                            >
                                <InputNumber  className="width-100"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={props.actividad_id ? "Editar Actividad" : "Crear Actividad"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-rubro', key: 'submit', htmlType: 'submit' }}
    >
        <ModalActividades {...props} />
    </Modal>

}