import React, { Component } from "react";
import { Row, Col, Button, Modal, Form, Input, message, Upload, Spin } from 'antd';
import { PlusOutlined,  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import PhoneInput from "../../Widgets/Inputs/PhoneInput";

  import axios from "axios"


class ModalProveedores extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    refModalProveedores = React.createRef();

    componentDidMount() {
         ;

        if (this.props.proveedor_id !== undefined) {
            this.getProveedor()
        }
    }

    /**
     * @memberof ModalProveedores
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.proveedor_id) {
            this.updateProveedor(values)
        } else {
            this.addProveedor(values)
        }

    }

    /**
     * @memberof ModalProveedores
     * @method addProveedor
     * @description Añade un proveedor a la BD
     *
     */
    addProveedor = (values) => {
        axios.post('/proveedor', {
            ...values,
            logo: this.state.image?.name,
        }).then(response => {
            message.success('Proveedor creado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al crear la cuenta')
            console.log(error)
        })
    }

    /**
     * @memberof ModalProveedores
     * @method getProveedor
     * @description Obtiene un proveedor de la DB
     *
     */
    getProveedor = () => {
        this.setState({ loading: true })
        axios.get('/proveedor/'+ this.props.proveedor_id).then(response => {
            let proveedor = response.data;
            this.setState({
                proveedor: response.data,
                tipo: proveedor.tipo,
                image: proveedor.logo ? {
                    url: axios.defaults.baseURL + '/upload/' + proveedor.logo,
                    name: proveedor.logo
                } : undefined,
            })

            this.refModalProveedores.current.setFieldsValue({
                alias: proveedor.alias,
                razon_social: proveedor.razon_social,
                email: proveedor.email,
                rfc: proveedor.rfc,
                contacto: proveedor.contacto,
                telefono: proveedor.telefono,
                direccion: proveedor.direccion,
            })

        }).catch(error => {
            message.error('Error al obtener el proveedor')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalProveedores
     * @method updateProveedor
     * @description Actualiza la información de un proveedor
     * 
     */
    updateProveedor = (values) => {
        this.setState({ loading: true })
        axios.put('/proveedor', {
            ...values,
            historial: true,
            logo: this.state.image?.name,
            id: this.props.proveedor_id,
        }).then(response => {
            message.success('Proveedor Actualizado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar la cuenta')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof ModalProveedores
     *
     * @method deleteImage
     * @description Elimina un archivo del WebService.
     *
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        if (this.props.proveedor_id !== undefined)
            axios.put('/proveedor/update', { logo: null, id: this.props.proveedor_id })
                .catch(error => console.log(error)).finally(() => {
                    this.setState({ loadingImage: false, image: null })
                })
        else {
            this.setState({ loadingImage: false })
        }

    };

    /**
     *
     * @memberof ModalProveedores
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })

            if (this.props.proveedor_id !== undefined)
                axios.post('/proveedor/update', {
                    logo: e.file.response.filename,
                    id: this.props.proveedor_id
                })

        }


        return e && e.fileList;
    };

    /**
     *
     * @memberof ModalProveedores
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito");
                this.setState({ loadingImage: false, image: null })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    };




    render() {

        const uploadButton = (
            <div>
                {this.state.loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Subir Imagen</div>
            </div>
        );


        return (
            <Form
                layout="vertical"
                name="form-proveedor"
                id="form-proveedor"
                ref={this.refModalProveedores}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row align="center">
                        <Form.Item
                            name="logo"
                            align="center"
                            getValueFromEvent={this.normFile}
                            valuePropName="fileList"
                            help={this.state.image ? <Button
                                className="btn-upload-delete"
                                shape="circle"
                                danger
                                onClick={this.deleteImage}
                            ><DeleteOutlined /></Button> : null}
                        >
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={axios.defaults.baseURL + "/upload/add"}
                                accept="image/*"
                            >

                                {this.state.image ? <img src={this.state.image?.url} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Row>
                    <Row justify="center">
                        <Col xs={24} lg={22}>
                            <Form.Item
                                label="Alias"
                                name="alias"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el alias"
                                }]}
                            >
                                <Input placeholder="Alias" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={22}>
                            <Form.Item
                                label="Razón Social"
                                name="razon_social"
                                
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={22} >
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[{ pattern: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, message: "El RFC es inválido" }]}
                            >
                                <Input placeholder="RFC" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={22}>
                            <Form.Item
                                label="Nombre del Contacto"
                                name="contacto"

                            >

                                <Input placeholder="Contacto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={22}>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                ]}
                            >
                                <Input placeholder="E-mail"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={22}>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                            >
                                <PhoneInput placeholder="Teléfono"/>
                            </Form.Item>
                        </Col>
                         <Col xs={24} lg={22} >
                            <Form.Item
                                label="Dirección"
                                name="direccion"
                            >
                                <Input.TextArea placeholder="Dirección" ></Input.TextArea>
                            </Form.Item>
                        </Col>
                        
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, proveedor_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={proveedor_id ? "Editar Proveedor" : "Crear Proveedor"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-proveedor', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProveedores {...props} />
    </Modal>

}