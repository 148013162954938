import React, { Component } from 'react';
import { Row, Col, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Card, Typography } from 'antd'
import axios from 'axios';  
import Carousel from 'react-multi-carousel';
import { PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined } from "@ant-design/icons";
//Componentes
import { color_tipos_facturas } from "../../../Utils"
import { IconEye, IconEdit, IconDelete, IconLink } from '../../../Widgets/Iconos';
//Modales
import ModalXML from '../../Finanzas/Facturas/ModalXML'
import ModalFacturas from "../../Finanzas/Facturas/ModalFacturas";
import ModalAsociacionFacturasTransacciones from '../ModalAsociacionFacturasTransacciones'


const { Content } = Layout;
const { Text, Title } = Typography
const moment = require('moment')
const tipo = { "0": 'Sin Cambios', "1": ' Ingreso', "-1": 'Egreso' }
const estatus_facturas = ['Factura Cancelada', 'Pendiente', 'Liquidad']

/**
 * @export
 * @class FacturasList
 * @extends {Component}
 * @description Vista de FacturasList
 */
export default class FacturasList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            facturas: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
                search: '',
                loading: false,
            },
            modal_link: false,
            transaccion_id: undefined,
            factura_id: undefined
        }
    }

    componentDidMount() {
        this.getFacturas()
    }

    /**
      *
      *
      * @memberof FacturasList
      * @description Obtiene las facturas sin transacciones
      * @param page pagina
      * @param filters Filtrado de registros mediante el uso del form de filtros
      */
    getFacturas = (
        {
            page = this.state.facturas.page,
            limit = this.state.facturas.limit,
            search = this.state.facturas.search,

        } = this.state.facturas
    ) => {
       
        this.setState({loading: true})
        axios.get('/facturas', {
            params: {
                page,
                limit,
                search,
                sin_transacciones: true
            }
        })
        .then(res => {
            this.setState({
                facturas: res.data
            })
        })
        .catch(res => {
            console.log("error", res);
            message.error('No se pudieron cargar las Facturas')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     *
     *
     * @param {*} tipo
     * @memberof TransaccionesList
     * @description Renderiza el icono según el estatus
     */
    renderIconTransaccion = (tipo) => {
        switch (tipo) {
            case 1:
                return <PlusOutlined style={{ color: "currentColor" }} />
            case -1:
                return <MinusOutlined style={{ color: "currentColor" }} />
            case 3:
                return <SwapOutlined style={{ color: "currentColor" }} />
            case 0:
                return <StopOutlined style={{ color: "currentColor" }} />
        }
    }

    render() {

        return (
            <>
                <Row>
                    <Col span={24}><Title className="subtitle-dashboard mt-1" level={4}>Facturas sin Transacción</Title></Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <List
                            loading={this.state.loading}
                            className="component-list "
                            itemLayout="horizontal"
                            dataSource={this.state.facturas.data}
                            pagination={{
                                onChange: (page, limit) => {
                                    this.getFacturas({ page, limit })
                                },
                                hideOnSinglePage: true,
                                total: this.state.facturas.total,
                                page:  this.state.facturas.page,
                            }}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list" bordered={false}>
                                        <Row className="width-100" justify="space-around">
                                            <Col xs={24} md={6} lg={2} xl={3} className="center item-info">
                                                <Text ellipsis strong>{moment(item?.fecha).format('DD/MM/YYYY')}</Text>
                                            </Col>
                                            <Col xs={24} md={18} lg={4} xl={6} className="center item-info">
                                                <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                            </Col>
                                            <Col xs={24} md={4} lg={3} xl={4} className="center item-info">
                                                <span className="spn-estatus">
                                                    <Text ellipsis className="text-gray-dark">{estatus_facturas[item?.estatus]}</Text>
                                                </span>
                                            </Col>
                                            <Col xs={24} md={4} lg={3} xl={4} className="center item-info">
                                                <span className="spn-tipo">
                                                    <Text ellipsis className="text-gray-dark">{tipo[item?.tipo]}</Text>
                                                </span>
                                            </Col>
                                            <Col xs={24} md={4} lg={3} xl={3} className="center item-info">
                                                <Text style={{ color: color_tipos_facturas(item.tipo), fontWeight: "bold" }} >
                                                {this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney(true)} MXN</Text>
                                            </Col>
                                            <Col xs={24} md={12} lg={3} xl={4} className="center item-info">
                                                <Space direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        icon={<IconEye/>}
                                                        onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                                                        title="Ver XML"
                                                        disabled={!!item.factura_xml}
                                                    ></Button>

                                                    <Button
                                                        className="btn-link"
                                                        icon={<IconLink />}
                                                        type="primary"
                                                        title="Link"
                                                        onClick={() => this.setState({ modal_link: true, factura_id: item._id })}

                                                    >
                                                    </Button>

                                                    <Button
                                                        className="btn-editar"
                                                        icon={<IconEdit />}
                                                        type="primary"
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modal_factura_editar: true, factura_id: item._id }) }}

                                                    >
                                                    </Button>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta factura?"
                                                        onConfirm={() => axios.delete(`/factura/${item._id}`)
                                                            .then((response) => {
                                                                message.success('Factura Eliminada')
                                                                this.props.updateInfo()
                                                            }).catch((error) => {
                                                                message.error('Factura NO Eliminada')
                                                                
                                                            })
                                                        }
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            icon={<IconDelete />} title="Eliminar" />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                           
                        />
                    </Col>
                </Row>
                <ModalAsociacionFacturasTransacciones
                    visible={this.state.modal_link}
                    onClose={() => {
                        this.setState({ modal_link: false, transaccion_id: undefined, factura_id: undefined})
                        this.props.updateInfo()
                    }}
                    factura_id={this.state.factura_id}
                    transaccion_id={this.state.transaccion_id}
                    //razon_social_id={this.props.match.params.razon_social_id}
                />

                <ModalFacturas
                    visible={this.state.modal_factura_editar}
                    onClose={() => {
                        this.setState({ modal_factura_editar: false, factura_id: undefined })
                        this.getFacturas()
                    }}
                    factura_id={this.state.factura_id}
                />

                <ModalXML
                    visible={this.state.ModalXMLVisible}
                    onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml_storage: undefined, xml: undefined })}
                    factura_xml_storage={this.state.factura_xml_storage}
                />
            </>
        )
    }
}