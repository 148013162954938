import React from 'react'
import { Layout, Col, Row, PageHeader, Typography } from "antd";
// import '../../Styles/Modules/Dashboard/dashboard.css';

import { CardSaldosGraph, CardMontoVentaMensual, CardMontoVentaAnual, CardDashboardGraph } from '../Widgets/Cards';

const { Content } = Layout;

export default class Dashboard extends React.Component {
	
	render() {
		return (
			<>
				<PageHeader
					className="site-page-header custom-page-header"
					title={
							<Row className="width-100" justify="space-between" align="middle">
								<Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
									<span className="ant-page-header-heading-title">
											Dashboard
									</span>
								</Col>
							</Row>
					}
				/>
				<Content className="isy-layout-content dashboard-content-bg">
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Row gutter={[16, 16]} className='mb-1'>
								<Col lg={8} md={24} sm={24} xs={24}>
									<CardSaldosGraph />
								</Col>
								<Col lg={8} md={24} sm={24} xs={24}>
									<CardMontoVentaMensual />
								</Col>
								<Col lg={8} md={24} sm={24} xs={24}>
									<CardMontoVentaAnual />
								</Col>
							</Row>
							<Row justify='center' className="pt-1">
								<Col span={16}>
									<CardDashboardGraph />
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</>
		)
	}
}