import React, { Component } from "react";
import { Layout, Row, Col, Button, List, Space, Typography, Card, message, Popconfirm } from "antd";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined, FileSearchOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined } from "@ant-design/icons";

//componentes
import Sort from '../../../Widgets/Sort'
import { color_transaccion_tipo } from "../../../Utils"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//modales
import ModalTransaccion from "../../Finanzas/Transacciones/ModalTransaccion";
import ModalTransaccionDetalle from "../../Finanzas/Transacciones/ModalTransaccionDetalle"

const { Content } = Layout;
const { Text } = Typography;

const moment = require('moment')

/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de Transacciones
 */
class Transacciones extends Component {

	constructor(props) {
		super(props)
		this.state = {


			transacciones: {

				data: [],

				limit: 10,
				page: 1,

				total: 0,
				pages: 0,

				filters: [],
				sort: {},

			}
		}
	}

	componentDidMount() {

		this.getTransacciones();
	}

	/**
	*
	*
	* @memberof Transacciones
	* @description Obtiene todas todas las transacciones
	* @param page pagina actual
	* @param limit limite de objetos de una pagina
	*/
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		sort = this.state.transacciones.sort,

	} = this.state.transacciones) => {
		axios.post('/transacciones', {
			page,
			limit,
			sort,
			proyecto_id: this.props.proyecto_id,
			tipo: 1 //Ingresos
		})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({ transacciones: { ...this.state.transacciones, ...data, loading: false } });
			})
			.catch(res => {
				message.error('No se pudieron cargar las transacciones')
			})
			.finally(() => this.setState({ loading: false }))
	}


	/**
	 *
	 *
	 * @param {*} key
	 * @memberof Transacciones
	 */
	setSort = (key) => {
		let value;
		switch (this.state.transacciones.sort[key]) {
			case 1:
				value = -1
				break;
			case -1:
				value = undefined
				break;
			default:
				value = 1
				break;
		}
		this.getTransacciones({
			sort: {
				...this.state.transacciones.sort,
				[key]: value
			}
		})
	}

	/**
 *
 *
 * @param {*} tipo
 * @memberof Transacciones
 * @description Renderiza el icono según el estatus
 */
	renderIconTransaccion = (tipo) => {
		switch (tipo) {
			case 1:
				return <PlusOutlined style={{ color: "currentColor" }} />
			case 2:
				return <MinusOutlined style={{ color: "currentColor" }} />
			case 3:
				return <SwapOutlined style={{ color: "currentColor" }} />
			case 4:
				return <StopOutlined style={{ color: "currentColor" }} />
		}
	}


	render() {

		// console.log('state', this.state)
		return (
			<>
				<Content className="admin-content content-bg pd-1">
					<List
						loading={this.state.loading}
						className="component-list"
						itemLayout="horizontal"
						locale={{ emptyText: "Sin Transacciones" }}
						dataSource={this.state.transacciones?.data}
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.getTransacciones({ page, limit })
						}}
						header={<Row className="header-list width-100 pl-1 pr-1">
							<Col span={2} className=" center">
								<Sort
									sort={this.state.transacciones?.sort["fecha"]} onClick={() => this.setSort("fecha")} >
									<Text strong >FECHA</Text>
								</Sort>
							</Col>
							<Col xs={4} className="center">
								<Sort
									sort={this.state.transacciones.sort["concepto"]} onClick={() => this.setSort("concepto")} >
									<Text strong >CONCEPTO</Text>
								</Sort>
							</Col>
							<Col xs={3} className="center">
								<Sort
									sort={this.state.transacciones.sort["area_id.nombre"]} onClick={() => this.setSort("area_id.nombre")} >
									<Text strong >ÁREA</Text>
								</Sort>
							</Col>
							<Col xs={3} className="center">
								<Sort
									sort={this.state.transacciones.sort["proyecto_id.folio"]}
									onClick={() => this.setSort("proyecto_id.folio")} >
									<Text strong >RUBRO</Text>
								</Sort>
							</Col>
							<Col xs={3} className="center text-center">
								<Text strong >CLIENTE / PROVEEDOR</Text>
							</Col>
							<Col xs={3} className=" center">
								<Sort
									sort={this.state.transacciones.sort["cuenta_id.nombre"]}
									onClick={() => this.setSort("cuenta_id.nombre")} >
									<Text strong >CUENTA</Text>
								</Sort>
							</Col>
							<Col xs={4} className="center">
								<Sort
									sort={this.state.transacciones.sort["monto"]}
									onClick={() => this.setSort("monto")} >
									<Text strong >MONTO</Text>
								</Sort>
							</Col>
						</Row>
						}

						renderItem={item => (
							<List.Item className="component-list-item">
								<Card className="card-list">
									<div className={`badge-card tipo-${item.tipo}`}>
									</div>
									<Row className="width-100" gutter={[4, 4]}>
										<Col span={2} className="center">
											<Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
										</Col>
										<Col xs={4} className="center">
											<Text ellipsis className="text-gray-dark">{item.concepto}</Text>
										</Col>
										<Col xs={3} className="center">
											<Text ellipsis className="text-gray-dark">{item.area_id?.nombre}</Text>
										</Col>
										<Col xs={3} className="center">
											<Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre}</Text>
										</Col>
										<Col xs={3} className="center">
											{item.proveedor_id?.nombre ? <>
												{item.proveedor_id?.nombre}

											</> : item.cliente_id?.nombre ? <>
												<CustomAvatar
													style={{ minWidth: '25px' }}
													size="small"
													name={item.cliente_id?.alias}
												/>
												<Text ellipsis>{item.cliente_id?.alias}</Text>
											</> : 'N/A'
											}
										</Col>
										<Col xs={3} className=" center">
											<Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
										</Col>
										<Col xs={4} className="center">
											<Text style={{ color: color_transaccion_tipo(item.tipo), fontWeight: "bold" }} >
												{this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney(true)} MXN</Text>
										</Col>
										<Col xs={2} className="center">
											<Space>
												<Button
													icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
													type="primary"
													title="Ver Detalle"
													onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}>
												</Button>
												<Button
													icon={<EditOutlined style={{ color: "currentcolor" }} />}
													type="primary"
													title="Editar"
													onClick={() => this.setState({ modal_visible: true, transaccion_id: item._id })}>
												</Button>
												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta Transacción?"
													onConfirm={() => axios.delete('/transaccion/' + item._id).then((response) => {
														message.success('Transaccion Eliminada')
														this.getTransacciones()
														this.props.getProyecto()
													}).catch((error) => {
														message.success('Transacción NO Eliminada')
														this.getTransacciones();
													})
													}
													okText="Si"
													cancelText="No"
												>
													<Button type="primary" danger icon={<DeleteOutlined style={{ color: "currentcolor" }} />} title="Eliminar" />
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Content>

				{/* <Tooltip title="Agregar una Nueva Transacción" > */}
				<FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

				<ModalTransaccion
					visible={this.state.modal_visible}
					transaccion_id={this.state.transaccion_id}
					onClose={(flag) => {
						this.setState({ modal_visible: false, transaccion_id: undefined })
						if(flag === true){
							this.getTransacciones()
							this.props.getProyecto()
						}
					}}
					initialValues={{
						tipo: 1
					}}
				/>

				<ModalTransaccionDetalle
					visible={this.state.modalTransacDetalleVisible}
					onClose={() => this.setState({
						modalTransacDetalleVisible: false,
						transaccion_id: null
					})}
					id={this.state.transaccion_id}
				/>
				
			</>
		)
	}
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

	return (<Transacciones {...props} navigate={useNavigate()}/>)
}