import React, { Component, useContext } from "react";
import {
    Row,
    Col,
    Button,
    Popconfirm,
    Spin,
    PageHeader,
    Layout,
    message,
    Space,
    List,
    Typography,
    Collapse,
    Divider,
} from "antd";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";

//Componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit } from "../../Widgets/Iconos";
import { tipo_servicio } from "../../Utils";
//modelos
import ModalProveedores from "./ModalProveedores";
import ModalServicios from "../Servicios/ModalServicios";

const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;

/**
 * @export
 * @class Proveedores
 * @extends {Component}
 * @description Vista de Proveedores
 */
class Proveedores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalVisible: false,

            proveedor_id: undefined,

            proveedores: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },
        };
    }

    componentDidMount() {
        this.getProveedores();
    }

    /**
     *
     *
     * @memberof Proveedores
     *
     * @method getProveedores
     * @description Obtiene los Proveedores
     */
    getProveedores = (
        {
            page = this.state.proveedores.page,
            limit = this.state.proveedores.limit,
            search = this.props.search,
        } = this.state.proveedores
    ) => {
        this.setState({ loading: true });
        axios
            .get("/proveedores", {
                params: {
                    page: page,
                    limit,
                    search: search,
                    servicios: true,
                },
            })
            .then((response) => {
                console.log("response", response.data);
                this.setState({ proveedores: response.data });
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al traer la información");
            })
            .finally(() => this.setState({ loading: false }));
    };

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Proveedores"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Proveedores" }}
                            dataSource={this.state.proveedores.data}
                            pagination={{
                                current: this.state.proveedores.page,
                                pageSize: this.state.proveedores.limit,
                                total: this.state.proveedores.total,
                                hideOnSinglePage: false,
                                showSizeChanger: true,
                                position: "bottom",
                                className: "flex-left",
                                onChange: (page, limit) =>
                                    this.getProveedores({ page, limit }),
                            }}
                            header={
                                <Row className="width-100 pr-1 pl-1 component-list-header">
                                    <Col span={4} className="center">
                                        <Text strong>Alias</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong>RFC</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong>Contacto</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong>Telefono</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong>Email</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong>Acciones</Text>
                                    </Col>
                                </Row>
                            }
                            renderItem={(item) => {
                                return (
                                    <Collapse
                                        bordered={false}
                                        className="collapse-item"
                                    >
                                        <Panel
                                            header={
                                                <Row className="width-100" gutter={[6,6]}>
                                                    <Col xs={24} md={12} lg={4} className="center" >
                                                        <Text ellipsis strong> {item.alias}</Text>
                                                    </Col>
                                                    <Col xs={24} md={12} lg={4} className="center" >
                                                        <Text>{item.rfc}</Text>
                                                    </Col>
                                                    <Col xs={24} md={12} lg={4} className="center" >
                                                        <Text ellipsis> {item.contacto} </Text>
                                                    </Col>
                                                    <Col xs={24} md={12} lg={4} className="center" >
                                                        <Text ellipsis> {item.telefono}</Text>
                                                    </Col>
                                                    <Col xs={24} md={12} lg={4} className="center" >
                                                        <Text ellipsis> {item.email} </Text>
                                                    </Col>

                                                    <Col xs={24} md={12} lg={4} className="center" >
                                                        <Space>
                                                            <Button
                                                                type="primary"
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                                onClick={() =>
                                                                    this.setState(
                                                                        {
                                                                            modal_visible: true,
                                                                            proveedor_id:
                                                                                item._id,
                                                                        }
                                                                    )
                                                                }
                                                                title="Añadir Servicio"
                                                            />
                                                            <Button
                                                                style={{
                                                                    paddingTop:
                                                                        "3px",
                                                                }}
                                                                type="primary"
                                                                icon={
                                                                    <IconEdit />
                                                                }
                                                                title="Editar"
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            modalVisible: true,
                                                                            proveedor_id:
                                                                                item._id,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar esta Proveedor?"
                                                                onConfirm={() =>
                                                                    axios
                                                                        .delete(
                                                                            `/proveedor/${item._id}`,
                                                                            {
                                                                                params: {
                                                                                    id: item._id,
                                                                                },
                                                                            }
                                                                        )
                                                                        .then(
                                                                            () => {
                                                                                this.getProveedores();
                                                                                message.success(
                                                                                    "Proveedor eliminado"
                                                                                );
                                                                            }
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                error
                                                                            ) =>
                                                                                console.log(
                                                                                    error
                                                                                )
                                                                        )
                                                                }
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    style={{
                                                                        paddingTop:
                                                                            "5px",
                                                                    }}
                                                                    type="primary"
                                                                    icon={
                                                                        <IconDelete />
                                                                    }
                                                                    title="Eliminar"
                                                                    danger
                                                                />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            }
                                        >
                                            <Divider />
                                            <Row className="mt-1 mb-1 component-list-header">
                                                <Col
                                                    span={5}
                                                    className="center"
                                                >
                                                    <Text className="text-gray">
                                                        Nombre
                                                    </Text>
                                                </Col>
                                                <Col
                                                    span={5}
                                                    className="center"
                                                >
                                                    <Text className="text-gray">
                                                        Descripción
                                                    </Text>
                                                </Col>
                                                <Col
                                                    span={5}
                                                    className="center"
                                                >
                                                    <Text className="text-gray">
                                                        Costo
                                                    </Text>
                                                </Col>
                                                <Col
                                                    span={5}
                                                    className="center"
                                                >
                                                    <Text className="text-gray">
                                                        Tipo
                                                    </Text>
                                                </Col>
                                                <Col span={4}></Col>
                                            </Row>
                                            {item.servicios?.length === 0 ? (
                                                <Row className="">
                                                    <Col
                                                        span={24}
                                                        className="center"
                                                    >
                                                        <Text strong>
                                                            Sin servicios
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                            {item.servicios?.map((servicio) => {
                                                return (
                                                    <Row className="mt-1" gutter={[6,8]}>
                                                        <Col xs={12} lg={5} className="center">
                                                            <Text strong ellipsis>
                                                                {servicio.nombre}
                                                            </Text>
                                                        </Col>
                                                        <Col xs={12} lg={5} className="center">
                                                            <Text ellipsis>
                                                                {servicio.descripcion}
                                                            </Text>
                                                        </Col>
                                                        <Col xs={8} lg={5} className="center">
                                                            <Text>
                                                                $ {servicio.costo.toMoney(true)} MXN
                                                            </Text>
                                                        </Col>
                                                        <Col xs={8} lg={5} className="center">
                                                            <Text>
                                                                {tipo_servicio(
                                                                    servicio.tipo
                                                                )}
                                                            </Text>
                                                        </Col>
                                                        <Col xs={8} lg={4} className="center" >
                                                            <Space>
                                                                <Button
                                                                    style={{
                                                                        paddingTop:
                                                                            "3px",
                                                                    }}
                                                                    type="primary"
                                                                    icon={
                                                                        <IconEdit />
                                                                    }
                                                                    title="Editar"
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            {
                                                                                modal_visible: true,
                                                                                servicio_id:
                                                                                    servicio._id,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    title="¿Deseas eliminar este Servicio?"
                                                                    onConfirm={() =>
                                                                        axios.delete( "/servicio/" +  servicio._id)
                                                                            .then(() => {
                                                                                    this.getProveedores();
                                                                                    message.success( "Servicio eliminado");
                                                                                }
                                                                            ).catch((error) => console.log(error))
                                                                    }
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        style={{ paddingTop: "5px"}}
                                                                        danger
                                                                        type="primary"
                                                                        icon={ <IconDelete /> }
                                                                        title="Eliminar"
                                                                    />
                                                                </Popconfirm>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </Panel>
                                    </Collapse>
                                );
                            }}
                        />
                    </Content>

                    <FloatingButton
                        title="Nuevo registro"
                        onClick={() => this.setState({ modalVisible: true })}
                    />

                    <ModalProveedores
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({
                                modalVisible: false,
                                proveedor_id: undefined,
                            });
                            this.getProveedores();
                        }}
                        proveedor_id={this.state.proveedor_id}
                    />
                    <ModalServicios
                        visible={this.state.modal_visible}
                        onCancel={() => {
                            this.setState({
                                modal_visible: false,
                                proveedor_id: undefined,
                                servicio_id: undefined,
                            });
                            this.getProveedores();
                        }}
                        proveedor_id={this.state.proveedor_id}
                        servicio_id={this.state.servicio_id}
                    />
                </Spin>
            </>
        );
    }
}

export default function Vista(props) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarProveedores: ["proveedores", "edit"],
        deleteProveedores: ["proveedores", "delete"],
        createProveedores: ["proveedores", "create"],
    });

    return <Proveedores {...props} {...permisos}/>

}
