import React, { Component } from 'react';
import { Row, Col, Button, PageHeader, Layout, message, Divider, Radio } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom"
import Carousel from 'react-multi-carousel';

//Componentes
import Feed from './Feed';
import CardConcluido from './Cards/CardConcluido';
import CardTaskClient from './Cards/CardTaskClient';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import CardProgressClient from './Cards/CardProgressClient';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//modales
import ModalProyecto from "./ModalProyecto"
import ModalCotizacion from "./ModalCotizacion"

const responsive = {
    desktop_fhq: {
        breakpoint: { max: 4000, min: 1200 },
        items: 2
    },
    desktop_hq: {
        breakpoint: { max: 1500, min: 1200 },
        items: 1
    },
    desktop_xxl: {
        breakpoint: { max: 1200, min: 992 },
        items: 1
    },
    desktop_xl: {
        breakpoint: { max: 992, min: 768 },
        items: 1
    },
    desktop_lg: {
        breakpoint: { max: 768, min: 576 },
        items: 1
    },
    desktop_md: {
        breakpoint: { max: 576, min: 464 },
        items: 1
    },
    desktop_sm: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    },

    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const { Content } = Layout;

/**
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista de Proyectos
 */
export default class Proyectos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            clientes:{
                data: [],
                limit: 10,
                page: 1,
                total: 0,
            },
            clientes_cotizacion:{
                data: [],
                limit: 10,
                page: 1,
                total: 0,
            },
            vista: "proyectos",


            modalVisible: false,
            feed: false,
            empresas: {
                data: [],
            },

            page: 1,
            total: 0,
            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
        this.getClientes()
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.vista !== this.state.vista){
            this.getClientes()
        }

    }


    /**
     * @memberof ModalCotizacion
     * @method getClientes
     * @description Obtiene los proyectos o cotizaciones agrupados por clientes
     * 
     *  /project-manager/cotizaciones 
     *  /project-manager/proyectos
     * 
     */
    getClientes = () => {
        this.setState({loading: true})
        axios.post('/project-manager/'+this.state.vista,{})
        .then(response => {
            console.clear()
            console.log("response", response.data.data);
            if(this.state.vista === "proyectos")
                this.setState({ clientes: response.data })
            else
                this.setState({ clientes_cotizacion: response.data })
        })
        .catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener los proyectos")
        }).finally(()=>this.setState({loading: false}))
    }

    toggleFeed = () => {
        this.setState({
            feed: !this.state.feed
        })
    }

    renderCardProyecto(proyecto, cliente) {


        if(proyecto.estatus === undefined || proyecto.estatus === 1){
            return <CardProgressClient
                className='mr-1'
                key={proyecto._id}
                porcentaje={0}
                modulos={proyecto.modulos}
                proyecto={proyecto}
                cliente={{
                    nombre: cliente.nombre,
                    apellido_paterno: cliente.apellido_paterno,
                    apellido_materno: cliente.apellido_materno,

                }}
            />
        }else if(proyecto.estatus === 2){
            return <CardConcluido
                className='mr-1'
                key={proyecto._id}
                proyecto={proyecto}
                cliente={{
                    nombre: cliente.nombre,
                    apellido_paterno: cliente.apellido_paterno,
                    apellido_materno: cliente.apellido_materno,

                }}
            />
        }
    }


    render() {

        const options = [
            { label: 'Cotizaciones', value: 'cotizaciones' },
            { label: 'Proyectos', value: 'proyectos' },
        ];

        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Project Manager"
                    extra={[<Button className='ph-extra' onClick={this.toggleFeed}>Feed</Button>]}
                />
                <Row className='parent' wrap={false}>
                    <Col className='custom-column' flex={"auto"}>
                        <Content className="admin-content content-bg pd-1">
                            <Row>
                                <Col span={24}>
                                    <Radio.Group 
                                        options={options} 
                                        defaultValue={"proyectos"} 
                                        optionType="button" 
                                        onChange={(event)=>this.setState({vista: event.target.value})}
                                    />
                                </Col>
                                { this.state.vista === "proyectos" ?  this.state.clientes.data.map((cliente) => {
                                    return (
                                        <Col className='mt-2' span={24}>
                                            <CustomAvatar
                                                name={cliente?.alias}
                                                className="mr-1" />
                                            {`${cliente.alias}`}
                                            <Divider />
                                            <Row>
                                                <Col span={24}>
                                                    <Carousel
                                                        responsive={responsive}
                                                        className='carousel-cards'
                                                    >
                                                        { cliente.proyectos.map(proyecto => {
                                                            return <Link  to={`/admin/proyectos/detalle/${proyecto._id}`}>
                                                                {this.renderCardProyecto(proyecto, cliente)}
                                                            </Link>
                                                        })}
                                                    </Carousel>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                }) : null }

                                {
                                    this.state.vista === "cotizaciones" ? this.state.clientes_cotizacion.data.map(({cliente, cotizaciones})=> {
                                        return (
                                            <Col className='mt-2' span={24}>
                                            <CustomAvatar
                                                name={cliente?.alias}
                                                className="mr-1" />
                                            {`${cliente?.alias ?? "Sin Cliente"}`}
                                            <Divider />
                                            <Row>
                                                <Col span={24}>
                                                    <Carousel
                                                        responsive={responsive}
                                                        className='carousel-cards'
                                                    >
                                                        { cotizaciones.map(cotizacion => {
                                                            console.log("cotizacion", cotizacion);
                                                            return <Link  to={`/admin/project-manager/cotizacion/${cotizacion._id}`}>
                                                                <CardTaskClient
                                                                    className='mr-1'
                                                                    key={cotizacion._id}
                                                                    cotizacion={cotizacion}
                                                                    proyecto={cotizacion.proyecto_id}
                                                                    cliente={{
                                                                        nombre: cliente?.nombre,
                                                                        apellido_paterno: cliente?.apellido_paterno,
                                                                        apellido_materno: cliente?.apellido_materno,

                                                                    }}
                                                                />
                                                            </Link>
                                                        })}
                                                    </Carousel>
                                                </Col>
                                            </Row>
                                        </Col>
                                        )
                                    }) : null
                                }


                            </Row>
                        </Content>
                    </Col>
                    <Col flex={"none"} style={{backgroundColor: "#FFF"}}>
                        { this.state.feed && <Feed/> }
                    </Col>
                </Row>

                <FloatingButton title="Nuevo registro" onClick={() => {
                    if(this.state.vista === "cotizaciones"){
                        this.setState({ modal_visible: true })
                    }else{
                        this.setState({ modal_visible_proyectos: true })
                    }
                }} />

                <ModalCotizacion
                    visible={this.state.modal_visible}
                    onClose={()=>{
                        this.setState({modal_visible: false})
                        this.getClientes()
                    }}
                />

                <ModalProyecto
                    visible={this.state.modal_visible_proyectos}
                    onClose={()=>{
                        this.setState({modal_visible_proyectos: false})
                    }}
                />
            </>
        )
    }
}