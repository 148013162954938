import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Typography, Form, DatePicker, Input, InputNumber, Checkbox, Select, message, Spin, Cascader, Tag, AutoComplete, Divider } from 'antd';
import axios from "axios"


import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { SelectProgramadas } from "../../../Widgets/Inputs/Selects"

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const moment = require('moment');

const Decimal = require('decimal.js');

class ModalTransacciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            areas: {
                data: [],

                page: 1,
                limit: 0,

                pages: 0,
                limit: 15
            },
            rubros: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            clientes: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            proyectos: {
                data: [],

                page: 1,
                limit: 10,

                pages: 0,
                total: 0,
                search: null
            },
            proveedores: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            cuentas: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            empresas: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            facturas: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            filters: {},
            tipo_cambio: 0,
            cuentaSeleccionada: '',
            disabled: false,
            transaccion: {},
            cliente_id: undefined,
            seleccionadas: {},
            concepto_unico: false,

        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        
        console.log(this.props)

        this.getCuentas();
        this.getRazones();
        this.getAreas();
        this.getClientes();
        this.getProyectos();

        if (this.props.transaccion_id !== undefined || this.props.transaccion_id != null) {
            this.getTransaccion();

        } else {
            this.getTipoCambio();
            
        }

        if(this.props.seleccionadas){
            this.setState({seleccionadas: this.props.seleccionadas})
        }
    }

    /**
     * @memberof ModalTransacciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        console.log("values", values.empresa_id);

        values.area_id = (values?.area_id?.value) ? values.area_id.value : null
        values.rubro_id = (values?.rubro_id?.value) ? values.rubro_id.value : null
        values.cliente_id = (values?.cliente_id?.value) ? values.cliente_id.value : null
        values.proyecto_id = (values?.proyecto_id?.value) ? values.proyecto_id.value : null
        values.empresa_id = values?.empresa_id?.value ?? values.empresa_id

        if (this.props.clasificacion)
            this.clasificarTransacciones(values)
        else
            if (this.props.transaccion_id)
                this.updateTransaccion(values)
            else
                this.addTransaccion(values)
    }



    /**
     * @memberof ModalTransacciones
     * @method getTransaccion
     * @description 
     * 
     */
    getTransaccion = () => {
        this.setState({ loading: true })

        axios.get('/transaccion/' + this.props.transaccion_id)
            .then(({ data }) => {
                console.log("data", data);
                this.modalRef.current?.setFieldsValue({
                    ...data,
                    fecha: moment(data.fecha),
                    cuenta_id: data?.cuenta_id?._id,
                    empresa_id: {
                        value: data?.empresa_id?._id,
                        label: data?.empresa_id?.nombre
                    },
                    area_id: {
                        value: data?.area_id?._id,
                        label: data?.area_id?.nombre
                    },
                    rubro_id: {
                        value: data?.rubro_id?._id,
                        label: data?.rubro_id?.nombre
                    },
                    cliente_id: data?.cliente_id?._id ? {
                        value: data?.cliente_id?._id,
                        label: data?.cliente_id?.alias
                    } : null,
                    proyecto_id: data?.proyecto_id?._id ? {
                        value: data?.proyecto_id?._id,
                        label: data?.proyecto_id?.nombre
                    } : null,
                })
                this.setState({ loading: false, disabled: true })
            })
            .catch(error => {
                console.log("error", error);
                this.setState({ loading: false })
            })
    }

    /**
     * @memberof ModalTransacciones
     * @method getProyectos
     * @description Trae los proyectos del cliente 
     * 
     */
    getProyectos = (search) => {

        if (this.props.cliente_id){
            axios.get('/proyectos', {
                params: {
                    cliente_id: this.props.cliente_id,
                    search
                }
            }).then(response => {
                console.log(response.data)
                this.setState({
                    proyectos: response.data
                })
               
            }).catch(error => {
                message.error('Error al obetner los proyectos')
            })
        } else {
            axios.get('/proyectos', {
                params: {
                    search
                }
            }).then(response => {
                console.log(response.data)
                this.setState({
                    proyectos: response.data
                })
               
            }).catch(error => {
                message.error('Error al obetner los proyectos')
            })
        }
    }

    /**
    * @memberof ModalTransacciones
    * @method getCuentas
    * @description Trae las facturas 
    * 
    */
    getFacturas = ({

        page = this.state.facturas.page,
        limit = this.state.facturas.limit,
        search = this.state.facturas.search,

    } = this.state.facturas) => {
        this.setState({ facturas: { ...this.state.facturas, loading: true, page, limit, search } })
        axios.get('/facturas/list', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.facturas.data = data.itemsList;
                    state.facturas.total = data.paginator.itemCount;
                    state.facturas.pages = data.paginator.pageCount;
                    return state;
                })
            })
            .catch(error => {
                console.log(error)
                message.error('No es posible traer las facturas')
            })
    }

    /**
    * @memberof ModalTransacciones
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    getCuentas = ({

        page = this.state.cuentas.page,
        limit = this.state.cuentas.limit,
        search = this.state.cuentas.search,

    } = this.state.cuentas) => {
        this.setState({ cuentas: { ...this.state.cuentas, loading: true, page, limit, search } })
        axios.get('/cuentas', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {
                this.setState({
                    cuentas: { ...this.state.cuentas, ...data, loading: false }
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer las cuentas')
            })
    }

    /**
    * @memberof ModalTransacciones
    * @method getTipoCambio
    * @description Trae el ulitmo tipo de cambio utilizado 
    * 
    */
    getTipoCambio = () => {
        this.modalRef.current?.setFieldsValue({
            tipo_cambio: 19.50
        })
        this.setState({
            tipo_cambio: 19.50
        })
    }

    /**
    * @memberof ModalTransacciones
    * @method getAreas
    * @description Trae las areas 
    * 
    */
    getAreas = ({

        page = this.state.areas.page,
        limit = this.state.areas.limit,
        search = this.state.areas.search

    } = this.state.areas) => {

        this.setState({ areas: { ...this.state.areas, loading: true, page, limit, search } })
        axios.get('/areas', {
            params: {
                page,
                limit,
                search

            }
        }).then(({ data }) => {
            this.setState({ areas: { ...this.state.areas, ...data, loading: false } })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer las areas')
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method loadRubros
     * @description Trae los rubros asiganos a una area y los formatea para el cascader
     * 
     */
    loadRubros = ({
        page = this.state.rubros.page,
        limit = this.state.rubros.limit,
        search = this.state.rubros.search,
        area_id = this.state.filters.area_id,

    } = this.state.rubros) => {
        this.setState({
            rubros: { ...this.state.rubros, loading: true, page, limit, search },
            filters: { ...this.state.filters, area_id },
        })
        axios.get('/rubros', {
            params: {
                search,
                page,
                limit,
                area_id
            }
        }).then(({ data }) => {
            this.setState({ rubros: { ...this.state.rubros, ...data, loading: true } })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los rubros')
        })

    }

    /**
    * @memberof ModalTransacciones
    * @method getRazones
    * @description 
    * 
    */
    getRazones = ({

        page = this.state.empresas.page,
        limit = this.state.empresas.limit,
        search = this.state.empresas.search

    } = this.state.empresas) => {

        this.setState({ empresas: { ...this.state.empresas, loading: true, page, limit, search } })
        return axios.get('/empresas', {
            params: {
                page,
                limit,
                search

            }
        }).then(({ data }) => {
            this.setState({ empresas: { ...this.state.empresas, ...data, loading: true } })
        }).catch(error => {
            message.error('Error al traer las Empresas')
            console.log(error)
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method getClientes
     * @description Trae los negocios
     * 
     */
    getClientes = (
        {

            page = this.state.clientes.page,
            limit = this.state.clientes.limit,
            search = this.state.clientes.search,
        } = this.state.clientes
    ) => {

        this.setState(state => {
            state.clientes.page = page;
            state.clientes.limit = limit;
            state.clientes.search = search;
        })

        return axios.get('/clientes', {
            params: {
                page,
                search,
                limit
            }
        })
            .then(({ data }) => {
                this.setState({ clientes: { ...this.state.clientes, ...data, loading: false } })
            }).catch(error => {
                console.log(error)
                message.error('Error al traer los clientes')
            })
    }


    /**
     * @memberof ModalTransacciones
     * @method addTransaccion
     * @description Trae las cuentas 
     * 
     */
    addTransaccion = (values) => {
        this.setState({ loading: true })
        axios.post('/transaccion', {
            ...values,
            monto_pesos: values.monto_pesos.toMoney(),
            monto_dolar: values.monto_dolar.toMoney(),
            tipo_cambio: values.tipo_cambio.toMoney(),
        }).then(response => {
            message.success('Transacción creada')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransacciones
    * @method updateTransaccion
    * @description Trae las cuentas 
    * 
    */
    updateTransaccion = (values) => {
        this.setState({ loading: true })
        axios.put('/transaccion', {
            ...values,
            monto_pesos: values.monto_pesos.toMoney(),
            monto_dolar: values.monto_dolar.toMoney(),
            tipo_cambio: values.tipo_cambio.toMoney(),
            id: this.props.transaccion_id
        }).then(response => {
            message.success('Transacción Actualizada')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error)
            message.error('Error al guardar la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangePesos
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares 
     * 
     */
    onChangePesos = (value) => {
        let tipo_cambio = this.modalRef.current?.getFieldValue('tipo_cambio')
        this.modalRef.current?.setFieldsValue({
            monto_dolar: parseFloat(value / tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangeDolar
     * @description Hace la conversion de dolares a pesos para actualizar el campo de pesos 
     * 
     */
    onChangeDolar = (value) => {
        let tipo_cambio = this.modalRef.current?.getFieldValue('tipo_cambio')
        this.modalRef.current?.setFieldsValue({
            monto_pesos: parseFloat(value * tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        let monto_pesos = this.modalRef.current?.getFieldValue('monto_pesos')
        this.modalRef.current?.setFieldsValue({
            monto_dolar: parseFloat(monto_pesos / value).toMoney()
        })
        this.setState({ tipo_cambio: value })
    }

    /**
     * @memberof ModalTransacciones
     * @method clasificarTransacciones
     * @description Envia las transacciones a clasificar. Las clasifica. 
     * 
     */
    clasificarTransacciones = (values) => {

        this.setState({ loading: true })
        axios.post('/syncfy/transacciones/add', {
            ...values,
            cuenta_id: this.props.cuenta_id,
            transacciones: Object.values(this.state.seleccionadas),
            tipo_cambio: this.state.tipo_cambio,
            concepto_unico_transacciones: this.state.concepto_unico_transacciones,
        }).then(response => {
            message.success('Transacciones clasificadas')
            this.props.onClose()
            this.props.clearSeleccionadas()
        }).catch(error => {
            console.log(error)
            message.error('Error al clasificar transacciones')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransacciones
     * @method changeConcepto
     * @description Actualiza los conceptos de las transacciones seleccionadas en Syncfy
     * 
     */
    changeConcepto = (value, id_transaction) => {
        this.setState({
            seleccionadas:{
                ...this.state.seleccionadas,
                [id_transaction]:{
                    ...this.state.seleccionadas[id_transaction],
                    description: value
                }
            }
        })
    }

    /**
         * @memberof ModalTransacciones
         * @method tagRender
         * @description renderiza los tags del select con su respectivo color
         * 
         */
    tagRender = (props, other) => {

        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };

        let obj = JSON.parse(value)

        return (
            <Tag
                color={obj.color}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );

    }

    render() {

        const { disabled } = this.state;
        const { clasificacion } = this.props;
        let { seleccionadas } = this.state

        return (
            <Form
                layout="vertical"

                name="form-transaccion"
                id="form-transaccion"
                ref={this.modalRef}
                onFinish={this.onFinish}
                initialValues={{
                    ...this.props.initialValues,
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio
                }}

                onValuesChange={value => {
                    if (value.tipo_cambio) this.onChangeTipo(value.tipo_cambio)
                    if (value.monto_pesos) this.onChangePesos(value.monto_pesos)
                    if (value.monto_dolar) this.onChangeDolar(value.monto_dolar)
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[16, 0]}>
                        {!clasificacion ? <>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Concepto"
                                    name="concepto"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el concepto"
                                    }]}
                                >
                                    <Input placeholder="Concepto"></Input>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Fecha"
                                    name="fecha"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la fecha"
                                    }]}
                                >
                                    <DatePicker className="width-100" disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                >
                                    <Input.TextArea placeholder="Descripción"></Input.TextArea>
                                </Form.Item>
                            </Col>
                        </> : null}
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Area"
                                name="area_id"
                                required
                            >
                                <Select
                                    className="width-100"
                                    disabled={disabled}
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getAreas({ search })}
                                    onSelect={area_id => this.loadRubros({ area_id: area_id?.value ?? area_id })}
                                    labelInValue
                                >
                                    {this.state.areas.data.map((area) => {
                                        return <Option value={area._id} >{area.nombre}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Rubro"
                                name="rubro_id"
                                required
                            >
                                <Select
                                    className="width-100"
                                    disabled={disabled}
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.loadRubros({ search })}
                                    labelInValue
                                >
                                    {this.state.rubros.data.map((rubro) => {
                                        return <Option value={rubro._id} >{rubro.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                            >
                                <Select
                                    className="width-100"
                                    disabled={disabled}
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getClientes({ search })}
                                    onSelect={cliente_id => {
                                        this.setState({cliente_id: cliente_id.value},()=>this.getProyectos())
                                    }}
                                    labelInValue
                                >
                                    {this.state.clientes?.data?.map((cliente) => {
                                        return <Option value={cliente._id} >
                                            <Space size={3}>
                                                <CustomAvatar
                                                    size="small"
                                                    placeholder={true}
                                                    image={cliente.logo}
                                                    name={cliente.alias}
                                                />
                                                {cliente.alias}
                                            </Space>
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id"
                            >
                                <Select
                                    className="width-100"
                                    disabled={disabled}
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getProyectos(search)}
                                    labelInValue
                                >
                                    {this.state.proyectos?.data?.map((item) => {
                                        return <Option value={item._id} >{item.nombre}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>

                        {this.props.transacciones_programadas ? <Col xs={24} lg={24}>
                            <Form.Item
                                label="Transaccion Programada"
                                name="transaccion_programada_id"
                            >   
                                <SelectProgramadas
                                    cliente_id={this.props.cliente_id ?? this.state.cliente_id}
                                />
                            </Form.Item>
                        </Col> : null }

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Facturas (UUID)"
                                name="uuid"
                            >
                                <AutoComplete
                                    className="width-100"
                                    options={this.state.facturas.data.map(item => {
                                        const { uuid, serie, folio, cliente, proveedor, monto, monto_restante } = item
                                        return (
                                            {
                                                option: item,
                                                value: uuid,
                                                label: <List.Item className="width-100" style={{ borderBottom: "1px solid lightgray" }}>
                                                    <List.Item.Meta
                                                        title={<>
                                                            {serie}-{folio}
                                                            <small>{uuid}</small>
                                                            <small>{(cliente || proveedor) ? (cliente ? cliente.razon_social : proveedor.nombre_comercial) : null}</small>
                                                        </>}
                                                        description={<>
                                                            {monto.toLocaleString('en-US', { style: 'currency', currency: "USD" })}<br />
                                                            <small><strong>{monto_restante.toLocaleString('en-US', { style: 'currency', currency: "USD" })}</strong></small>
                                                        </>}
                                                    />
                                                </List.Item>,
                                                title: uuid
                                            }
                                        )
                                    })}
                                    onSearch={search => this.getFacturas({ search })}
                                    onSelect={(value, option) => {

                                        if (this.ModalTransacciones.current.getFieldValue("monto_pesos") > option.option.monto_restante) {
                                            this.ModalTransacciones.current.setFieldsValue({
                                                uuid: null
                                            })
                                            return message.error("El monto es mayor al monto restante")
                                        }



                                        this.ModalTransacciones.current.setFieldsValue({
                                            uuid: value
                                        })

                                    }}
                                >
                                    <Input placeholder="UUID" />
                                </AutoComplete>
                            </Form.Item>
                        </Col>

                        {!clasificacion ? [
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Cuenta"
                                    name="cuenta_id"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione la cuenta"
                                    }]}
                                >
                                    <Select
                                        onSelect={value => {
                                            this.setState({ cuentaSeleccionada: value })
                                            let cuenta_detino = this.modalRef.current?.getFieldValue('cuenta_destino_id')
                                            if (cuenta_detino === value)
                                                this.modalRef.current?.setFieldsValue({
                                                    cuenta_destino_id: null
                                                })
                                        }}
                                        placeholder="Seleccione cuenta"
                                    >
                                        {this.state.cuentas.data.map(function ({ _id, nombre, logo, color }, index) {
                                            return <Option style={{ margin: '2px 0 2px 0' }} value={_id}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>,
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Tipo"
                                    name="tipo"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el tipo"
                                    }]}
                                >
                                    <Select
                                        onSelect={value => {
                                            this.setState({ tipoTransaccion: value })
                                        }}
                                        disabled={this.state.disabled}
                                        placeholder="Seleccione tipo">
                                        <Option value={1}>Ingreso</Option>
                                        <Option value={2}>Egreso</Option>
                                        <Option value={3}>Traspaso</Option>
                                        <Option value={4}>Nulo <Text type="secondary" size="small">(No afecta saldos)</Text></Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        ] : null}
                        <Col xs={24} lg={clasificacion ? 24 : 6} >
                            <Form.Item
                                label="Tipo Cambio"
                                name="tipo_cambio"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={1} defaultValue={0} className="width-100" disabled={disabled}
                                    // disabled={this.props.transaccion_id}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                        {!clasificacion ? [
                            <Col xs={24} lg={9} >
                                <Form.Item
                                    label="Monto (Pesos)"
                                    name="monto_pesos"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese monto"
                                    }]}
                                >
                                    <InputNumber min={0.01} defaultValue={0} className="width-100"
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                        // disabled={this.props.transaccion_id}
                                    />
                                </Form.Item>
                            </Col>,
                            <Col xs={24} lg={9} >
                                <Form.Item
                                    label="Monto (Dolar)"
                                    name="monto_dolar"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese monto"
                                    }]}
                                >
                                    <InputNumber

                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                        // disabled={this.props.transaccion_id}

                                        min={0.01} defaultValue={0} className="width-100" />
                                </Form.Item>
                            </Col>
                        ] : null}

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Empresa"
                                name="empresa_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la empresa"
                                }]}

                            >


                                <Select
                                    placeholder="Seleccione la empresa"
                                    onSearch={(value) => this.getRazones({ search: value })}
                                    showSearch
                                    disabled={disabled}
                                >
                                    {
                                        this.state.empresas.data.map(empresa => {
                                            return <Option value={empresa._id}>
                                                <Space>
                                                    <CustomAvatar size="small" image={empresa.logo} name={empresa.alias} color={empresa.color} /> {empresa.alias}
                                                </Space>
                                            </Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Col>

                        
                    </Row>

                    {this.state.tipoTransaccion === 3 ? <Row>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cuenta"
                                name="cuenta_destino_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <Select placeholder="Seleccione cuenta">
                                    {this.state.cuentas.data.map(({ _id, nombre, logo, color }, index) => {

                                        if (this.state.cuentaSeleccionada.toString() === _id.toString())
                                            return null
                                        else
                                            return <Option style={{ margin: '2px 0 2px 0' }} value={_id}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row> : null}

                    {clasificacion ? <Row>

                        <List
                            header={<>
                                <Title level={5}>Transacciones a clasificar</Title>
                                {Object.keys(seleccionadas)?.length > 1 ? <div span={24}>
                                    <Checkbox onChange={(event) => {
                                        this.setState({
                                            concepto_unico: event.target.checked,
                                            concepto_unico_transacciones: undefined,
                                        })
                                    }}/> Concepto Unico (repetir en todas)
                                    {this.state.concepto_unico ? <Input
                                        className="mt-1" 
                                        placeholder="Concpeto para todas las transacciones"
                                        onChange={(e)=>{
                                            this.setState({
                                                concepto_unico_transacciones: e.target.value,
                                            })
                                        }}
                                        /> 
                                    : null}
                                </div> : null}
                            </>}
                            className="width-100"
                            itemLayout="horizontal"
                            dataSource={Object.values(seleccionadas)}
                            renderItem={item => (
                                <List.Item className="width-100">
                                    <List.Item.Meta
                                        title={<Input
                                            disabled={this.state.concepto_unico}
                                            defaultValue={item.description} 
                                            onChange={(event)=>{
                                                this.changeConcepto(event.target.value, item.id_transaction)
                                            }}/>
                                        }
                                        description={<>
                                            {item.reference ? <> <p>{item.reference} </p> <br /> </> : null} 
                                            <strong>{moment.unix(item.dt_transaction).format("LLLL")}</strong>
                                        </>}
                                    />
                                    <div style={{ textAlign: "right", marginLeft: "14px" }} className="border">{(
                                        item.currency == "MXN"
                                    ) ? <>
                                        <div>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> MXN</strong></small></div>
                                        <div>{Decimal(item.amount).div(this.state.tipo_cambio).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> USD</strong></small></div>
                                    </> : <>
                                        <div>{Decimal(item.amount).mul(this.state.tipo_cambio).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> MXN</strong></small></div>
                                        <div>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> USD</strong></small></div>
                                    </>
                                    }</div>
                                </List.Item>
                            )}
                        />
                    </Row> : null}
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id, clasificacion } = props

    return <Modal
        visible={visible}
        onCancel={onClose}

        title={transaccion_id ? "Editar Transacción" : "Nueva Transacción"}
        // footer={null}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        zIndex={1000}
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalTransacciones {...props} />
    </Modal>

}