import React, { Component } from 'react';
import { Row, Col, Form, Input, Typography, DatePicker, Button, InputNumber } from 'antd'

//componentes
import ColorPicker from '../../../Widgets/ColorPicker';
import { ImagesUploader } from "../../../Widgets/Uploaders";
import { SelectCliente, SelectUsuario, SelectPlantilla } from "../../../Widgets/Inputs/Selects";

const { Title } = Typography;
const moment = require("moment");

/**
 * @export
 * @class Informacion
 * @extends {Component}
 * @description Vista de Informacion
 */
class Informacion extends Component {


	constructor(props){
		super(props);
		this.state = {

		}
	}

	refInformacion = React.createRef()

	componentDidMount(){
		if(this.props.values){
			this.refInformacion.current.setFieldsValue({
				...this.props.values
			})
		}
	}


	 /**
     * @memberof Informacion
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario, pasa al sig step
     */
	onFinish = (values) => {

		if(this.props.values){
			values.modulos = Array.isArray(this.props.values.modulos) ? this.props.values.modulos : []
		}

		this.props.onSubmit(values)
	}

	render(){
		return(
			<>
				<Title level={5}>Informacion general</Title>
				<Form
					layout="vertical"
					onFinish={this.onFinish}
					ref={this.refInformacion}
					initialValues={{
						fecha: moment()
					}}
				>
					<Row gutter={[16,0]}>
						<Col xs={24} md={8}>
							<Form.Item
								label="Nombre"
								name="nombre"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
							>
								<Input
									placeholder="Nombre del proyecto"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Asesor"
								name="asesor_id"
								rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el asesor"
                                }]}
							>
								<SelectUsuario/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8} >
                            <Form.Item
                                label="Color"
                                name="color"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un color"
                                }]}
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Cliente"
								name="cliente_id"
								rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el cliente"
                                }]}
							>
								<SelectCliente/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Dominio"
								name="dominio"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el dominio"
                                }]}
							>
								<Input placeholder="https://ejmplo.com"/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Fecha Venta"
								name="fecha_venta"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
							>
								<DatePicker
									className="width-100"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Fecha Final"
								name="fecha_final"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
							>
								<DatePicker
									className="width-100"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Costo Hora"
								name="costo_hora"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el costo por hora"
                                }]}
							>
								<InputNumber className="width-100"/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Plantilla"
								name="plantilla_id"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el dominio"
                                }]}
							>
								<SelectPlantilla/>
							</Form.Item>
						</Col>
						<Col xs={24} >
							<Form.Item
								label="Descripción"
								name="descripcion"
							>
								<Input.TextArea/>
							</Form.Item>
						</Col>
						<Col xs={24} >
							<Form.Item
								label="Documentos"
								name="documentos"
							>
								<ImagesUploader/>
							</Form.Item>
						</Col>
						<Col xs={24} className="flex-right">
							<Form.Item>
      							<Button type="primary" htmlType="submit">
        							Continuar
      							</Button>
    						</Form.Item>
						</Col>
					</Row>
				</Form>
			</>
		)
	}

}


export default Informacion