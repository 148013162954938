import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin } from 'antd';
import axios from "axios"

const { Title } = Typography;



class ModalAreas  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalAreas = React.createRef();

    componentDidMount() {
         ;
        if (this.props.area_id !== undefined) {
            this.getArea()
        }
    }


    /**
     * @memberof ModalAreas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.area_id) {
            this.updateArea(values)
        } else {
            this.addArea(values)
        }
    }


    /**
     * @memberof ModalAreas
     * @method addArea
     * @description Añade una area a la BD
     */
    addArea = (values) => {
        axios.post('/area', {
            ...values
        }).then(response => {
    
            message.success('Area creada')
            this.props.onClose()

        }).catch(error => {
            console.log(error)
            message.error('Error al crear area.')
        })
    }


    /**
     * @memberof ModalAreas
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getArea = () => {

        this.setState({ loading: true })

        axios.get('/area/' + this.props.area_id)
        .then(({ data }) => {
            this.setState({ area: data })
            this.ModalAreas.current.setFieldsValue(data)

        }).catch(error => {
            message.error('Error al traer area')
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalAreas
     * @method updateArea
     * @description Actualiza la información de una area
     */
    updateArea = (values) => {
        this.setState({ loading: true })
        axios.put('/area', {
            ...values,
            id: this.props.area_id,
        }).then(response => {
            message.success('Area Actualizada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar area')
            console.log(error.response)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                id="form-area"
                name="form-area"
                layout="vertical"

                ref={this.ModalAreas}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción"  />

                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={props.area_id ? "Editar Area" : "Crear Area"}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-area', key: 'submit', htmlType: 'submit' }}

    >
        <ModalAreas {...props} />
    </Modal>

}