import React, { useContext, useEffect, useState, useRef } from 'react';
import { Dropdown, Menu, Badge, notification, Row, Col, Typography } from 'antd';
import { BsBellFill } from "react-icons/bs";

import axios from 'axios';
import Socket from "../../Context/Socket";

const moment = require('moment')
const { Text } = Typography;

/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} {  showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */
const Navbar = (props) => {

    let socket = useContext(Socket)
    let menuRef = useRef(null);

    let [notViews, setNotViews] = useState(0)

    let [notifications, setNotifications] = useState({
        data: [],
        limit: 50,
        page: 1,
        total: 0,
        unread: 0
    })

    useEffect(()=>{
        getNotifications()

        return () => {
            socket.disconnect();
        }

    }, [])


    const clearNotificacion = (notification_id, index) => {
        axios.post("/notification/clear",{
            notification_id
        })

        if(notification_id === "all"){
            setNotifications((prevNotifications) => {
                
                const newNotifications = { ...prevNotifications };
                newNotifications.data = prevNotifications.data.map((notification) => ({
                    ...notification,
                    leido: true
                }));

                newNotifications.unread = 0
                return newNotifications;
            });
        }

        if(index >= 0){
            setNotifications((prevNotifications) => {
                
                const newNotifications = { ...prevNotifications };

                if(newNotifications.data[index].leido === false){
                    newNotifications.data[index].leido = true;
                    newNotifications.unread--
                }

                return newNotifications;
            });
        }
    }


    const newNotification = (new_notification) => {
        getNotifications()
    }

    const openNotification = (noti, index) => {

        if(noti._id !== "all")
            notification.info({
                placement:"topLeft",
                message: moment(noti.createdAt).format("DD/MM/YYYY HH:mm"),
                description: noti.bitacora_id?.message,
                duration: 10,
            })

        clearNotificacion(noti._id, index)
    }


    const getNotifications = () => {
        axios.get('/notifications',{
            params:{
                limit: notifications.limit
            }
        })
        .then(response => {
            setNotifications(response.data)
        })
    };


    useEffect(() => {
        socket.on("notification/send", newNotification)
        console.log("menuRef.current", menuRef.current);

        return () => {
            socket.off('notification/send')
        }

    }, [socket])


    return (
        <Dropdown
            arrow={false}
            trigger = { ['click']}
            placement="bottomRight"
            onClick={()=>{openNotification({_id: "all"})}}
            overlay={
                <Menu 
                    style={{maxHeight: "300px", overflow: "hidden", overflowY: "auto"}}
                    ref={menuRef}
                >
                    {/*<Menu.Item key={"unique"} onClick={()=>{openNotification({_id: "all"})}}>
                        <Row justify="center">
                            <Col span={22} className="flex-right">
                                <Text ellipsis underline className="font-10">Marcar todos como leído</Text>
                            </Col>
                        </Row>
                        
                    </Menu.Item>*/}
                    {notifications.data.map((e, index) => <Menu.Item key={index} className={e.leido === false ? "unread" : ""} onClick={()=>{openNotification(e, index)}}>
                        <Row justify="center" style={{maxWidth: "400px", minWidth: "300px"}}>
                            <Col span={11}>
                                <Text className="font-10">{moment(e.createdAt).format("DD/MM/YYYY HH:mm")}</Text>
                            </Col>
                            <Col span={11} className="flex-right">
                                {/*e.leido === false ? <Badge dot>
                                </Badge> : null*/}
                            </Col>
                            <Col span={22}>
                                <Text ellipsis className="font-12">{e.bitacora_id?.message}</Text>
                            </Col>
                        </Row>
                        
                    </Menu.Item>) }
                </Menu>
            }
        >
                <div style={{ position: "relative", top: 5 }}>
                    <Badge count={notifications.unread} size='small'>
                        <BsBellFill fontSize={24} />
                    </Badge>
                </div>
        </Dropdown >

    );

}

export default Navbar;