import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin } from 'antd';
import axios from "axios"

const { Title } = Typography;



class ModalModulo  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    ModalModulo = React.createRef();

    componentDidMount() {
        if (this.props.modulo_id !== undefined) {
            this.getModulo()
        }
    }


    /**
     * @memberof ModalModulo
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.modulo_id) {
            this.updateModulo(values)
        } else {
            this.addModulo(values)
        }
    }


    /**
     * @memberof ModalModulo
     * @method addModulo
     * @description Añade un modulo a la BD
     */
    addModulo = (values) => {
        this.setState({loading: true})
        axios.post('/modulo', {
            ...values
        }).then(response => {
            message.success('Modulo creado')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error)
            message.error('Error al crear modulo.')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof ModalModulo
     * @method getModulo
     * @description Obtiene un modulo de la DB
     */
    getModulo = () => {

        this.setState({ loading: true })

        axios.get('/modulo/' + this.props.modulo_id)
        .then(({ data }) => {
            console.log("data", data);
            this.ModalModulo.current.setFieldsValue(data)

        }).catch(error => {
            message.error('Error al traer el modulo')
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalModulo
     * @method updateModulo
     * @description Actualiza la información de un modulo
     */
    updateModulo = (values) => {
        this.setState({ loading: true })
        axios.put('/modulo', {
            ...values,
            id: this.props.modulo_id,
        }).then(response => {
            message.success('Modulo Actualizado')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error.response)
            message.error('Error al actualizar el modulo')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                id="form-area"
                name="form-area"
                layout="vertical"

                ref={this.ModalModulo}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción"  />

                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={props.modulo_id ? "Editar Módulo" : "Crear Módulo"}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-area', key: 'submit', htmlType: 'submit' }}

    >
        <ModalModulo {...props} />
    </Modal>

}