import React, { Component } from "react";
import { Layout, Row, Col, Button, List, Space, Typography, Card, message, Popconfirm, PageHeader, Tooltip } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";

//componentes
import axios from "axios";
import { CardModulos } from "../../../Widgets/Cards";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//modales
import ModalProyectos from '../Modales/ModalModulosProyecto'
import DrawerActividades from "../Modales/DrawerActividades";
import DrawerActualizacion from "../Modales/DrawerActualizacion";

const { Content } = Layout;
const { Text } = Typography;

const moment = require('moment')

/**
 * @class Modulos
 * @extends {Component}
 * @description Vista del listado de Modulos
 */
class Modulos extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			modulos: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
			}
		}
	}

	componentDidMount() {
		this.getModulos()
	}



    /**
     * @memberof ModalCuentas
     * @method getModulos
     * @description Obtiene los modulos del proyecto
     */
    getModulos = ({
    	page = this.state.modulos.page,
    	limit = this.state.modulos.limit,
    } = this.state.modulos) => {
    	this.setState({loading: true})
    	axios.get('/modulos-proyectos',{
    		params:{
    			page,
    			limit,
    			proyecto_id: this.props.proyecto_id
    		}
    	}).then(response => {
    		console.log("response", response.data);
    		this.setState({
    			modulos: response.data
    		})

    	}).catch(error => {
    		console.log("error", error);
    		message.error(error?.response?.data?.message ?? "Error al obtener los modulos")
    	})
    }


    /**
     * @memberof ModalCuentas
     * @method deleteModulos
     * @description Obtiene los modulos del proyecto
     */
    deleteModulo = (modulo_proyecto_id) => {
    	this.setState({loading: true})
    	axios.delete('/modulo-proyecto/'+modulo_proyecto_id,{
    		
    	}).then(response => {
    		message.success("Eliminado correctamente")
    		this.getModulos()
    		this.props.getProyecto()
    	}).catch(error => {
    		console.log("error", error);
    		message.error(error?.response?.data?.message ?? "Error al obtener los modulos")
    	})
    }

	render() {

		return (
			<>
				<Content className="admin-content content-bg pd-1">
					<Row gutter={[16,16]}>
					{
						this.state.modulos.data.map(e => <Col md={8} xxl={6} >
							<CardModulos 
								{...e}
								onView={()=>this.setState({drawer_visible_actualizacion: true, modulo_proyecto_id: e._id})}
								onEdit={()=>this.setState({modal_visible: true, modulo_proyecto_id: e._id})}
								onDelete={()=>this.deleteModulo(e._id)}
								onClick={()=>this.setState({drawer_visible: true, modulo_proyecto_id: e._id})}
							/>
						</Col>)
					}
					</Row>
				</Content>

				<FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true, modulo_proyecto_id: undefined })} />

				<ModalProyectos
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false, modulo_proyecto_id: undefined})
                        if(flag === true){
                        	this.getModulos()
                        	this.props.getProyecto()
                        }
                    }}
                    proyecto_id={this.props.proyecto_id}
                    modulo_id={this.state.modulo_proyecto_id}
                />

                <DrawerActividades
                	visible={this.state.drawer_visible}
                	onClose={()=>{
                		this.setState({drawer_visible: false, modulo_proyecto_id: undefined})
                		this.getModulos()
                	}}
                	modulo_proyecto_id={this.state.modulo_proyecto_id}
                />

                <DrawerActualizacion
                	visible={this.state.drawer_visible_actualizacion}
                	onClose={()=>{
                		this.setState({drawer_visible_actualizacion: false, modulo_proyecto_id: undefined})
                		//this.getModulos()
                	}}
                	modulo_proyecto_id={this.state.modulo_proyecto_id}
                />
			</>
		)
	}
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

	return (<Modulos {...props} navigate={useNavigate()}/>)
}