import React from 'react'
import { Route, Routes } from "react-router-dom";

import Plantillas from '../components/Admin/Plantillas/Plantillas';

/**
 * 
 * @export
 * @function RouterPlantillas
 * @description Router for Plantillas routes 
 */
function RouterPlantillas(props) {
  return (
    <Routes>
      <Route path="" element={<Plantillas {...props} />} />
    </Routes>
  )
}

export default RouterPlantillas