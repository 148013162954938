import React, { Component } from "react";
import { Row, Col, Space, Steps, Modal, Button, Spin, message } from 'antd';
import axios from "axios"

//Compoenentes
import Modulos from "./Secciones/Modulos";
import Informacion from "./Secciones/Informacion";
import RevisionProyecto from "./Secciones/RevisionProyecto";

const { Step } = Steps

class ModalCotizacion extends Component {


    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            loading: false,
            values: {},
            proyecto_id: undefined,
            cotizacion_id: undefined,
        }
    }

    refModalCotizacion = React.createRef();

    componentDidMount() {
    }

    /**
     * @memberof ModalCotizacion
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        
    }


    /**
     * @memberof ModalCotizacion
     * @method onSubmit
     * @description Se ejecuta al terminar la primera parte del formualrio, guarda la informacion del proyecto
     * 
     */
    onSubmit = (values) => {

    	this.setState({
    		values,
    		current: this.state.current + 1
    	})
    }

    /**
     * @memberof ModalCotizacion
     * @method onSubmitModulos
     * @description Guarda los servicios en el state, junto los valores de la cotizacion
     * 
     */
    onSubmitModulos = (values) => {

        this.setState({
            values:{
                ...this.state.values,
                modulos: values
            },
            current: this.state.current + 1
        })
    }

    /**
     * @memberof ModalCotizacion
     * @method currentBack
     * @description Regresa al step anterior
     */
    currentBack = (modulos) => {
        console.log("modulos currentBack", modulos);
        this.setState((prevState) => ({
            current: prevState.current > 0 ? prevState.current - 1 : prevState.current,
            values: {
                ...prevState.values,
                modulos: Array.isArray(modulos) ? modulos : prevState.values.modulos
            }
        }),()=>{
        	console.log(this.state.values)
        });
    }

     /**
     * @memberof ModalCotizacion
     * @method renderContent
     * @description Renderiza los steps de la cotizacion
     * 
     */
    renderContent = (current) => {

        let props = {
            onSubmit: (values) => this.onSubmit(values),
            onSubmitModulos: (servicios) => this.onSubmitModulos(servicios),
            back:(servicios)=>this.currentBack(servicios),
            onClose: this.props.onClose,
        }


        const steps = [
            {
                title: 'First',
                content: <Informacion
                    {...props}
                    values={this.state.values}
                />
            },
            {
                title: 'Second',
                content: <Modulos
                    {...props}
                    values={this.state.values}
                />,
            },
            {
                title: 'Last',
                content: <RevisionProyecto
                    {...props}
                    values={this.state.values}
                />,
            },
        ]

        return steps[current].content
    }




    render() {

        let { current } = this.state

        return (
            <>
                <Steps current={current}>
                    <Step title="Información"/>
                    <Step title="Módulos"/>
                    <Step title="Resumen"/>
                </Steps>
                <Spin spinning={this.state.loading}>
                    <div className="mt-1">{this.renderContent(current)}</div>
                </Spin>
            </>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={cuenta_id ? "Editar Proyecto" : "Crear Proyecto"}
        destroyOnClose={true}
        footer={null}
        width={800}
        style={{top: "50px"}}
    >
        <ModalCotizacion {...props} />
    </Modal>

}