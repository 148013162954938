import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, InputNumber, message, Spin } from 'antd';
import axios from "axios"

const { Title } = Typography;



class ModalAreas  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalAreas = React.createRef();

    componentDidMount() {
       
    }


    /**
     * @memberof ModalAreas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
       this.updateCosto(values)
    }


    /**
     * @memberof ModalAreas
     * @method updateCosto
     * @description Se ejecuta al dar enter al formulario
     */
    updateCosto = (values) => {
        this.setState({loading: true})
        axios.put('/project-manager/costo',{
            ...values,
            proyecto_id: this.props.proyecto_id
        }).then(()=>{
            message.success("Costo Actualizado")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al actualizar el costo del proyecto")
        }).finally(() =>{
            this.setState({loading: false})
        })
    }

    render() {
        return (
            <Form
                id="form-area"
                name="form-area"
                layout="vertical"
                ref={this.ModalAreas}
                onFinish={this.onFinish}
                initialValues={{
                    ...this.props.values
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Costo por hora"
                                name="costo_hora"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el costo"
                                }]}
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder="Saldo"
                                    min={1} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Editar Costo"}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-area', key: 'submit', htmlType: 'submit' }}

    >
        <ModalAreas {...props} />
    </Modal>

}