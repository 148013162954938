import React, { Component } from "react";

import { Layout, Row, Col, Button, List, Space, Typography, Card, message, Popconfirm, PageHeader, Tooltip } from "antd";

import { Link, useNavigate } from "react-router-dom";

import { FilterOutlined, EditOutlined, DeleteOutlined, FileSearchOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, CopyOutlined } from "@ant-design/icons";

import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

import ModalTransaccion from "./ModalTransaccion";
import ModalTransaccionDetalle from "./ModalTransaccionDetalle"

import ModalAddMultiple from "./ModalAddMultiple";

import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import DrawerFiltros from "../../../Widgets/DrawerFiltros/DrawerFiltros";

import { color_transaccion_tipo } from "../../../Utils"

import Sort from '../../../Widgets/Sort'

import axios from "axios"

const { Content } = Layout;
const { Text } = Typography;

const moment = require('moment')

/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de Transacciones
 */
class Transacciones extends Component {

	constructor(props) {
		super(props)
		this.state = {


			transacciones: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
				filters: [],
				sort: {},
				search: undefined
			}
		}
	}

	componentDidMount() {
		this.getTransacciones();
	}

	componentDidUpdate(prevProps){
		if(this.props.search !== prevProps.search){
		console.log(this.props)
			this.getTransacciones({page: 1, search: this.props.search})
		}
	}

	/**
	*
	*
	* @memberof Transacciones
	* @description Obtiene todas todas las transacciones
	* @param page pagina actual
	* @param limit limite de objetos de una pagina
	*/
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		filters = this.state.transacciones.filters,
		sort = this.state.transacciones.sort,
		search = this.state.transacciones.search

	} = this.state.transacciones) => {
		axios.post('/transacciones', {
			page,
			limit,
			filters, 
			sort,
			search,
		})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({ 
					transacciones: { 
						...this.state.transacciones, 
						...data, 
						sort,
						search, 
					} 
				});
			})
			.catch(res => {
				message.error('No se pudieron cargar las transacciones')
			})
			.finally(() => this.setState({ loading: false }))
	}


	/**
	 *
	 *
	 * @param {*} key
	 * @memberof Transacciones
	 */
	setSort = (key) => {
		let value;
		console.log("key", key);
		console.log("this.state.transacciones.sort[key]", this.state.transacciones.sort[key]);
		switch (this.state.transacciones.sort[key]) {
			case 1:
				value = -1
				break;
			case -1:
				value = undefined
				break;
			default:
				value = 1
				break;
		}
		this.getTransacciones({
			sort: {
				
				[key]: value
			}
		})
	}

	/**
 *
 *
 * @param {*} tipo
 * @memberof Transacciones
 * @description Renderiza el icono según el estatus
 */
	renderIconTransaccion = (tipo) => {
		switch (tipo) {
			case 1:
				return <PlusOutlined style={{ color: "currentColor" }} />
			case 2:
				return <MinusOutlined style={{ color: "currentColor" }} />
			case 3:
				return <SwapOutlined style={{ color: "currentColor" }} />
			case 4:
				return <StopOutlined style={{ color: "currentColor" }} />
		}
	}


	render() {

		// console.log('state', this.state)
		return (
			<>
				<PageHeader
					className='custom-page-header'
					title="Lista de Transacciones"

					breadcrumb={{
						className: "custom-page-header-breadcrumb",
						itemRender: (route, params, routes, paths) => (routes.indexOf(route) === routes.length - 1) ? route.breadcrumbName : <Link to={route.path}>{route.breadcrumbName}</Link>,
						routes: [
							{
								path: "/admin/finanzas",
								breadcrumbName: 'Finanzas',
							},
							{
								path: "/admin/finanzas/transacciones",
								breadcrumbName: 'Transacciones',
							}
						]
					}}

					extra={
						<Button
							type="dashed"
							onClick={() => this.setState({ visibleFilters: true })}
							className="button-plus"
							icon={<FilterOutlined style={{ color: "currentcolor" }} />} />
					}
				/>
				<Content className="admin-content content-bg pd-1">
					<List
						loading={this.state.loading}
						className="component-list"
						itemLayout="horizontal"
						locale={{ emptyText: "Sin Transacciones" }}
						dataSource={this.state.transacciones?.data}
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.getTransacciones({ page, limit })
						}}
						header={<Row className="header-list width-100 pl-1 pr-1">
							<Col span={2} className=" center">
								<Sort
									sort={this.state.transacciones?.sort["fecha"]} onClick={() => this.setSort("fecha")} >
									<Text strong >FECHA</Text>
								</Sort>
							</Col>
							<Col xs={4} className="center">
								<Sort
									sort={this.state.transacciones.sort["concepto"]} onClick={() => this.setSort("concepto")} >
									<Text strong >CONCEPTO</Text>
								</Sort>
							</Col>
							<Col xs={2} className="center">
								<Sort
									sort={this.state.transacciones.sort["area_id.nombre"]} onClick={() => this.setSort("area_id.nombre")} >
									<Text strong >ÁREA</Text>
								</Sort>
							</Col>
							<Col xs={2} className="center">
								<Sort
									sort={this.state.transacciones.sort["proyecto_id.folio"]}
									onClick={() => this.setSort("proyecto_id.folio")} >
									<Text strong >RUBRO</Text>
								</Sort>
							</Col>
							<Col xs={3} className="center">
								<Sort
									sort={this.state.transacciones.sort["proyecto"]}
									onClick={() => this.setSort("proyecto")} >
									<Text strong >PROYECTO</Text>
								</Sort>
							</Col>
							<Col xs={3} className="center text-center">
								<Text strong >CLIENTE / PROVEEDOR</Text>
							</Col>
							<Col xs={3} className=" center">
								<Sort
									sort={this.state.transacciones.sort["cuenta_id.nombre"]}
									onClick={() => this.setSort("cuenta_id.nombre")} >
									<Text strong >CUENTA</Text>
								</Sort>
							</Col>
							<Col xs={3} className="center">
								<Sort
									sort={this.state.transacciones.sort["monto"]}
									onClick={() => this.setSort("monto")} >
									<Text strong >MONTO</Text>
								</Sort>
							</Col>
						</Row>
						}

						renderItem={item => (
							<List.Item className="component-list-item">
								<Card className="card-list">
									<div className={`badge-card tipo-${item.tipo}`}>
									</div>
									<Row className="width-100" gutter={[4, 4]}>
										<Col span={2} className="center">
											<Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
										</Col>
										<Col xs={4} className="center">
											<Text ellipsis className="text-gray-dark">{item.concepto}</Text>
										</Col>
										<Col xs={2} className="center">
											<Text ellipsis className="text-gray-dark">{item.area_id?.nombre}</Text>
										</Col>
										<Col xs={2} className="center">
											<Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre}</Text>
										</Col>
										<Col xs={3} className="center">
											<Text className="text-gray-dark">{(item.proyecto_id) ? item.proyecto_id?.nombre : 'Sin Proyecto'}</Text>
										</Col>
										<Col xs={3} className="center">
											{item.proveedor_id?.nombre ? <>
												{item.proveedor_id?.nombre}

											</> : item.cliente_id?.nombre ? <>
												<CustomAvatar
													style={{ minWidth: '25px' }}
													size="small"
													name={item.cliente_id?.alias}
												/>
												<Text ellipsis>{item.cliente_id?.alias}</Text>
											</> : 'N/A'
											}
										</Col>
										<Col xs={3} className=" center">
											<Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
										</Col>
										<Col xs={3} className="center">
											<Text style={{ color: color_transaccion_tipo(item.tipo), fontWeight: "bold" }} >
												{this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney(true)} MXN</Text>
										</Col>
										<Col xs={2} className="center">
											<Space>
												<Button
													icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
													type="primary"
													title="Ver Detalle"
													onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}>
												</Button>
												<Button
													icon={<EditOutlined style={{ color: "currentcolor" }} />}
													type="primary"
													title="Editar"
													onClick={() => this.setState({ modalTransacVisible: true, transaccion_id: item._id })}>
												</Button>
												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta Transacción?"
													onConfirm={() => axios.delete('/transaccion/' + item._id).then((response) => {
														message.success('Transaccion Eliminada')
														this.getTransacciones()
													}).catch((error) => {
														message.success('Transacción NO Eliminada')
														this.getTransacciones();
													})
													}
													okText="Si"
													cancelText="No"
												>
													<Button type="primary" danger icon={<DeleteOutlined style={{ color: "currentcolor" }} />} title="Eliminar" />
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Content>

				{/* <Tooltip title="Agregar una Nueva Transacción" > */}
				<FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalTransacVisible: true })} />
				<FloatingButton
					buttonStyle={{
						width: "40px",
						height: "40px",
						bottom: "42px",
						right: "125px",
						color: "white"
					}}
					icon={<CopyOutlined style={{ color: "white", fontSize: "20px", left: -6, top: 2, position: "relative" }} />}
					title="Multiples Transacciones"
					onClick={() => this.setState({ modalMultiplesTransacciones: true })}

				/>

				<ModalTransaccion
					visible={this.state.modalTransacVisible}
					transaccion_id={this.state.transaccion_id}
					onClose={() => {
						this.setState({ modalTransacVisible: false, transaccion_id: undefined })
						this.getTransacciones()
					}}
				/>
				<ModalTransaccionDetalle
					visible={this.state.modalTransacDetalleVisible}
					onClose={() => this.setState({
						modalTransacDetalleVisible: false,
						transaccion_id: null
					})}
					id={this.state.transaccion_id}
				/>
				<ModalAddMultiple
					visible={this.state.modalMultiplesTransacciones}
					onClose={() => {
						this.setState({
							modalMultiplesTransacciones: false,
						})
					}}
					
					onSuccess={(closeModal) => {
						this.getTransacciones();
	
						if (closeModal)
							this.setState({ modalMultiplesTransacciones: false, })
					}}
				/>
				<DrawerFiltros
					title="Filtrar Transacciones"
					ref={ref => this.drawerFiltros = ref}
					updateFilters={filters => this.getTransacciones({
						page: 1,
						filters
					})}
					csv="transacciones/list"
					onClose={() => this.setState({ visibleFilters: false })}
					visible={this.state.visibleFilters}
					negocios={false}
				/>
			</>
		)
	}
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

	return (<Transacciones {...props} navigate={useNavigate()} />)
}