import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Checkbox, Divider } from 'antd';
import axios from "axios"

//componentes
import { AvatarLoader } from "../../Widgets/Uploaders";
import { SelectProyecto } from "../../Widgets/Inputs/Selects";

const { Title, Text } = Typography;



class ModalPlantilla  extends Component {

    constructor(props) {
        super(props);
        this.state = {

            modulos:[],
            horas_aproximadas: 0,
            proyecto: true,
            edit: false,

            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalPlantilla = React.createRef();

    componentDidMount() {
        
        this.getModulos()

        if (this.props.plantilla_id !== undefined) {
            this.getPlantillas()
        }
    }


    /**
     * @memberof ModalPlantilla
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            avatar: undefined,
            modulos_id: undefined,
            id: this.props.plantilla_id
        })

        if(values.avatar && !values.avatar?.fromDB){
            formData.append("avatar", values.avatar, values.avatar.name)
        }

        if(values.modulos_id){
            for(let id of values.modulos_id)
                formData.append("modulos_id", id)
        }

        if (this.props.plantilla_id) {
            this.updatePlantilla(formData)
        } else {
            this.addPlantilla(formData)
        }
    }

    /**
     * @methodOf  ModalPlantilla
     * @method getModulos
     *
     * @description Obtiene las modulos
     * */
    getModulos = (search) => {
        axios.get('/modulos', {
            params: {
                page: 1,
                limit: 50,
                search
            }
        }).then(({ data }) => {
            this.setState({modulos: data.data})
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof ModalPlantilla
     * @method addPlantilla
     * @description Añade una plantilla a la BD
     */
    addPlantilla = (values) => {
        this.setState({loading: true})
        axios.post('/plantilla', values)
        .then(response => {
            message.success('Plantilla creada')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error)
            message.error('Error al crear area.')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof ModalPlantilla
     * @method getPlantillas
     * @description Obtiene una area de la DB
     */
    getPlantillas = () => {

        this.setState({ loading: true })

        axios.get('/plantilla/' + this.props.plantilla_id)
        .then(({ data }) => {
            console.log("data", data);

            this.ModalPlantilla.current.setFieldsValue({
                ...data,
                modulos_id: data.modulos_id.map(e => e._id),
                avatar:  data.avatar && typeof data.avatar !== 'string' ? {
                    uid: data.avatar.file,
                    name: data.avatar.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/plantilla/" + this.props.plantilla_id + "?avatar=" + data.avatar.file +"&Authorization=" + sessionStorage.getItem("token")
                } : null,
                proyecto_id: data.proyecto_id ? {
                    value: data.proyecto_id?._id,
                    label: data.proyecto_id?.nombre,
                } : null
            })
            this.setState({
                horas_totales: data.horas_aproximadas,
                proyecto: false,
                edit: true
            })

        }).catch(error => {
            message.error('Error al traer area')
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalPlantilla
     * @method updatePlantilla
     * @description Actualiza la información de una area
     */
    updatePlantilla = (values) => {
        this.setState({ loading: true })
        axios.put('/plantilla', values).then(response => {
            message.success('Plantilla Actualizada')
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al actualizar plantilla')
            console.log(error.response)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalPlantilla
     * @method checkModulo
     * @description Calcula las horas totales sumando las horas de los modulos
     */
    checkModulo = (values) => {
        let horas = 0

        for(let id of values){
            let index = this.state.modulos.findIndex(e => e._id.toString() === id.toString())
            if(index !== -1){
                horas += this.state.modulos[index].horas_aproximadas
            }
        }
        
        this.setState({horas_totales: horas})
    }


    render() {
        return (
            <Form
                id="form-plantilla"
                name="form-plantilla"
                layout="vertical"

                ref={this.ModalPlantilla}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">

                        <Col xs={24} lg={20} className="center">
                            <Form.Item
                                name="avatar"
                            >
                                <AvatarLoader
                                    imageCrop={true}
                                    onRemove={() => {
                                        axios.put('/plantilla', {
                                            id: this.props.plantilla_id,
                                            delete_avatar: true
                                        })
                                    }}

                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción"  />

                            </Form.Item>
                        </Col>
                        <Divider> Selecciona un proyecto o modulos </Divider>

                        <Col xs={24} lg={20} className="mb-1">
                            <Form.Item
                                label=""
                                name="proyecto_id"
                            >
                                <SelectProyecto
                                    disabled={this.state.edit}
                                    onSelect={()=>this.setState({proyecto: false})}
                                    onClear={()=>this.setState({proyecto: true})}
                                />

                            </Form.Item>
                        </Col>

                        {this.state.proyecto ? <> <Col xs={24} lg={20} className="mb-1">
                            <Text>Horas Totales: {this.state.horas_totales}</Text>
                        </Col>

                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Modulos"
                                name="modulos_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un modulo"
                                }]}
                            >
                                <Checkbox.Group 
                                    onChange={(e)=>this.checkModulo(e)}
                                >
                                    <Row>
                                        {
                                            this.state.modulos.map( e => <Col span={24} className="flex-between">
                                                <Checkbox value={e._id}>
                                                    {e.nombre}
                                                </Checkbox>
                                                <Text>hrs: {e.horas_aproximadas.toMoney(true)}</Text>
                                            </Col>)
                                        }
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col> </> : null}
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={props.plantilla_id ? "Editar Plantilla" : "Crear Plantilla"}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-plantilla', key: 'submit', htmlType: 'submit' }}

    >
        <ModalPlantilla {...props} />
    </Modal>

}