import React, { Component } from "react";
import { Route, Routes, Redirect } from "react-router-dom";
import { Layout } from "antd";

import '../Styles/Global/admin.css';

// Routers
import RouterCRM from "./RouterCRM"
import RouterAreas from "./RouterAreas"
import RouterCuentas from "./RouterCuentas"
import RouterFinance from "./RouterFinance"
import RouterUsuarios from "./RouterUsuarios"
import RouterEmpresas from "./RouterEmpresas";
import RouterDashboard from "./RouterDashboard";
import RouterProyectos from "./RouterProyectos";
import RouterProveedores from "./RouterProveedores";
import RouterContabilidad from "./RouterContabilidad";
import RouterEmpleados from "./RouterEmpleados";
import RouterRoles from "./RouterRoles";
import RouterModulos from "./RouterModulos";
import RouterPlantillas from "./RouterPlantillas";
import RouterServicios from "./RouterServicios";

// Components
import Navbar from "../components/Header/Header";
import AdminSidebar from "../components/Sidebar/Sidebar.js"
import RouterProjectManager from "./ProjectManagerRoutes";
import RouterTickets from "./RouterTickets";

import {PrivateRoutes} from "./Middlewares/PrivateRoutes"

const { Content } = Layout;

class AdminRoutes extends Component {


	constructor(props){
		super(props)
		this.state={
			search: undefined
		}
	}


	setFilterSearch = (search) => {
		this.setState({search})
	}

	render() {
		return (
			<PrivateRoutes>
				<Layout className="layout" >
					<Navbar setFilterSearch={this.setFilterSearch} />
					<Layout>
						<AdminSidebar />
						<Content className="admin-content">
							<Routes>
								<Route path="areas/*"  			element={<RouterAreas search={this.state.search}/>}/>} />
								<Route path="empresas" 			element={<RouterEmpresas search={this.state.search}/>}/>} />
								<Route path="cuentas/*"  		element={<RouterCuentas search={this.state.search}/>}/>} />
								<Route path="usuarios/*" 		element={<RouterUsuarios search={this.state.search}/>}/>} />
								<Route path="finanzas/*"  		element={<RouterFinance search={this.state.search}/>} />
								<Route path="proyectos/*" 		element={<RouterProyectos search={this.state.search}/>}/>} />
								<Route path="dashboard/*" 		element={<RouterDashboard search={this.state.search}/>}/>} />
								<Route path="proveedores/*" 	element={<RouterProveedores search={this.state.search}/>}/>} />
								<Route path="contabilidad/*" 	element={<RouterContabilidad search={this.state.search}/>}/>} />
								<Route path="empleados/*" 		element={<RouterEmpleados search={this.state.search}/>}/>} />

								<Route path="customer-relationship-management/*" element={<RouterCRM  search={this.state.search}/>}/>} />
								<Route path="project-manager/*" element={<RouterProjectManager search={this.state.search}/>}/>} />
								<Route path="tickets/*" 		element={<RouterTickets search={this.state.search}/>}/>} />
								<Route path="roles/*" 			element={<RouterRoles search={this.state.search}/>}/>} />
								<Route path="modulos/*" 		element={<RouterModulos search={this.state.search}/>}/>} />
								<Route path="plantillas/*" 		element={<RouterPlantillas search={this.state.search}/>}/>} />
								<Route path="servicios/*" 		element={<RouterServicios search={this.state.search}/>}/>} />
							</Routes>
						</Content>
					</Layout>
				</Layout>
			</PrivateRoutes>
		)
	}
}

export default (props) => <AdminRoutes {...props} />