import React, { Component, useContext } from 'react';
import {Row, Col, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Card, Typography } from 'antd'
import axios from 'axios';

//Componentes
import User from "../../../Hooks/Logged";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import usePermissions from "../../../Hooks/usePermissions";
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//modelos
import ModalProyectos from './ModalProyectos'

const { Content } = Layout;
const { Text, Paragraph } = Typography 
/**
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista de Proyectos
 */
class Proyectos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            proyecto_id: undefined,
            proyectos: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },

            page: 1,
            total: 0,
            filtroSearch:'',
            searching: true,
        }
    }

    componentDidMount() {
         ;
        this.getProyectos();
    }


    /**
     *
     *
     * @memberof Proyectos
     * 
     * @method getProyectos
     * @description Obitiene las Proyectos
     */
    getProyectos = ({
        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
    } = this.state.proyectos) => {
        let search = this.props.search;
        this.setState({loading: true})
        axios.get('/proyectos', {
            params: {
                page: page,
                limit: limit,
                search:search
            }
        }).then(response => {
            this.setState({proyectos: response.data})
            

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Proyectos"
                    />

                    <Content className="admin-content content-bg pd-1">
                    	<List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Proyectos" }}
                            dataSource={this.state.proyectos.data}
                            pagination={{
                                current: this.state.proyectos.page,
                                pageSize: this.state.proyectos.limit,
                                total: this.state.proyectos.total,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className:"flex-left",
                                onChange: (page) => this.getProyectos({page})
                               
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={2}  className="center">
                                    <Text strong>Color</Text>
                                </Col>
                                <Col span={7}  className="center">
                                    <Text strong>Nombre</Text>
                                </Col>

                                <Col span={9}  className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col span={6}  className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            <Col span={2}  className="center">
                                                <CustomAvatar color={item.color} />
                                            </Col>

                                            <Col span={7}  className="center">
                                                <Text strong>{item.nombre}</Text>
                                            </Col>

                                            <Col span={9}  className="center">
                                                <Paragraph
                                                    style={{margin: '0'}}
                                                    ellipsis={{
                                                        rows: 2,
                                                        expandable: true,
                                                        symbol: 'Ver más'
                                                    }}
                                                >
                                                    {item.descripcion}
                                                </Paragraph>
                                                
                                            </Col>
                                            <Col span={6} className="center">
                                                <Space>

		                                            <Button
		                                            	style={{paddingTop: '3px'}}
		                                                type="primary"
		                                                icon={<IconEdit />}
		                                                title="Editar"
		                                                onClick={() => { this.setState({ modalVisible: true, proyecto_id: item._id }) }}

		                                            />
		                                            <Popconfirm
		                                                placement="topRight"
		                                                title="¿Deseas eliminar esta proyecto?"
		                                                onConfirm={() => axios.delete('/proyectos/delete',
		                                                    { params: {id: item._id} }).then(() => {
		                                                        this.getProyectos()
		                                                        message.success('Proyecto eliminado')
		                                                    }).catch(error => console.log(error))}
		                                                okText="Si"
		                                                cancelText="No"
		                                            >
		                                                <Button
		                                                	style={{paddingTop: '5px'}}
		                                                    type="primary"
		                                                    icon={<IconDelete />}
		                                                    title="Eliminar"
		                                                    danger
		                                                />
		                                            </Popconfirm>
		                                        </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                        
                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />


                    <ModalProyectos
                        visible={this.state.modalVisible}
                        onClose={(flag) => {
                            this.setState({ modalVisible: false, proyecto_id: undefined })
                            if(flag === true)
                                this.getProyectos()
                        }}
                        proyecto_id={this.state.proyecto_id}
                    />
                </Spin>
            </>
        )
    }
}

export default function Vista(props) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarProyectos: ["proyectos", "edit"],
        deleteProyectos: ["proyectos", "delete"],
        createProyectos: ["proyectos", "create"],
    });

    return <Proyectos {...props} {...permisos}/>

}