import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Collapse } from 'antd'
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

//Componentes
import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconDetails, IconPlus, IconPlusCollapsile, IconPlusGroup } from '../../Widgets/Iconos';

//Modal
import ModalModulo from "./ModalModulo"
import ModalActividades from "./ModalActividades"

const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;

/**
 * @export
 * @class Modulos
 * @extends {Component}
 * @description Vista de Modulos
 */
export class Modulos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modulos: {
                data: [],
                limit: 20,
                page: 1,
                total: 0,
                pages: 0,
            },
            modulo_id: undefined
        }
    }

    componentDidMount() {
        this.getModulos()
    }

    /**
     * @methodOf  Modulos
     * @method getModulos
     *
     * @description Obtiene las modulos
     * */
    getModulos = ({
        page = this.state.modulos.page,
        limit = this.state.modulos.limit,
        search = this.props.search

    } = this.state.modulos) => {
        axios.get('/modulos', {
            params: {
                page,
                limit,
                search,
                actividades:  true
            }
        }).then(({ data }) => {
            console.log("modulos",data)
            this.setState({modulos: data})
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


    render() {

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Módulos"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Modulos " }}
                            dataSource={this.state.modulos.data}
                            pagination={{
                                current: this.state.modulos.page,
                                pageSize: this.state.modulos.limit,
                                total: this.state.modulos.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page,limit) => this.getModulos({ page, limit })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-2" >

                                <Col span={6}>
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={10} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Horas</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <>
                                    <Collapse>
                                        <Panel
                                            header={
                                                <Row className='width-100' align='middle'>
                                                    <Col span={6}>
                                                        <Text ellipsis>{item?.nombre}</Text>
                                                    </Col>
                                                    <Col span={10} className="center">
                                                        <Text ellipsis>{item?.descripcion}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <Text ellipsis>{item?.horas_aproximadas}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <Space size="small" direction="horizontal">

                                                            <Button
                                                                type="primary"
                                                                icon={<IconPlusGroup />}
                                                                title="Agregar Actividad"
                                                                onClick={(event) => { event.stopPropagation(); this.setState({ modal_visible_actividad: true, modulo_id: item._id }) }}
                                                            />


                                                            <Button
                                                                type="primary"
                                                                icon={<IconEdit />}
                                                                title="Editar"
                                                                onClick={(event) => { event.stopPropagation(); this.setState({ modal_visible: true, modulo_id: item._id }) }}
                                                            />
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar este módulo?"
                                                                onConfirm={() => axios.delete('/modulo/' + item._id,
                                                                    {}).then(() => {
                                                                        this.getModulos()
                                                                        message.success('Módulo eliminado')
                                                                    }).catch(error => {
                                                                        message.error('Error al eliminar el Módulo')
                                                                        console.log(error.response)
                                                                    })}
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    icon={<IconDelete />}
                                                                    title="Eliminar"
                                                                    onClick={event => { event.stopPropagation() }}
                                                                    danger
                                                                />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            }
                                            key={item._id}
                                            className="mb-1"
                                        >
                                            <List
                                                className='component-list'
                                                itemLayout="horizontal"
                                                locale={{ emptyText: "Sin Actividades" }}
                                                dataSource={item?.actividades}
                                                
                                                header={
                                                    <Row className="width-100 header-list">
                                                        <Col span={6}>
                                                            <Text strong>Nombre</Text>
                                                        </Col>
                                                        <Col span={10} className="center">
                                                            <Text strong>Descripción</Text>
                                                        </Col>
                                                        <Col span={4} className="center">
                                                            <Text strong> Horas </Text>
                                                        </Col>
                                                    </Row>
                                                }
                                                renderItem={actividad => (
                                                    <List.Item>
                                                        <Row className="width-100">
                                                            <Col span={6}>
                                                                <Text ellipsis>{actividad?.nombre}</Text>
                                                            </Col>
                                                            <Col span={10} className="center">
                                                                <Text ellipsis>{actividad?.descripcion}</Text>
                                                            </Col>
                                                            <Col span={4} className="center">
                                                                <Text ellipsis>{actividad?.horas_aproximadas}</Text>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Row align='end' className='mr-1'>
                                                                <Space size="small" align='end' direction="horizontal">
                                                                    <Button

                                                                        type="primary"
                                                                        //disabled={!this.props.editProductosProveedores}
                                                                        icon={<IconEdit />}
                                                                        title="Editar"
                                                                        onClick={(event) => { event.stopPropagation(); this.setState({ modal_visible_actividad: true, actividad_id: actividad._id }) }}
                                                                    />
                                                                    <Popconfirm
                                                                        placement="topRight"
                                                                        //disabled={!this.props.deleteProductosProveedores}
                                                                        title="¿Deseas eliminar esta actividad?"
                                                                        onConfirm={() => axios.delete('/actividad/' + actividad._id,
                                                                            {}).then(() => {
                                                                                this.getModulos()
                                                                                message.success('Actividad eliminada')
                                                                            }).catch(error => {
                                                                                message.error(error?.response?.data?.message ?? 'Error al eliminar la actividad')
                                                                                console.log(error)
                                                                            })}
                                                                        okText="Si"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button

                                                                            type="primary"
                                                                            //disabled={!this.props.deleteProductosProveedores}
                                                                            icon={<IconDelete />}
                                                                            title="Eliminar"
                                                                            danger
                                                                        />
                                                                    </Popconfirm>
                                                                </Space>
                                                                </Row>
                                                                
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                )}
                                            />


                                        </Panel>
                                    </Collapse>
                                </>
                            )}
                        />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

                    <ModalModulo
                        visible={this.state.modal_visible}
                        onClose={(flag)=>{
                            this.setState({modal_visible:  false, modulo_id: undefined})
                            if(flag === true) this.getModulos();
                        }}
                        modulo_id={this.state.modulo_id}
                    />

                    <ModalActividades
                        visible={this.state.modal_visible_actividad}
                        onClose={(flag)=>{
                            this.setState({modal_visible_actividad:  false, actividad_id: undefined, modulo_id: undefined})
                            if(flag === true) this.getModulos();
                        }}
                        modulo_id={this.state.modulo_id}
                        actividad_id={this.state.actividad_id}
                    />
                   
                </Spin>
            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(Logged)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editProductos: ["productos", "edit"],
        createProductos: ["productos", "create"],
        deleteProductos: ["productos", "delete"],


        editProductosProveedores: ["productos","proveedores", "edit"],
        createProductosProveedores: ["productos","proveedores", "create"],
        deleteProductosProveedores: ["productos","proveedores", "delete"],

    })

    return <Modulos {...props} {...permissions} navigate={useNavigate()} />

}