import React, { Component } from "react";
import { Layout, Row, Col, PageHeader, Tooltip, Button, List, Typography, Card, message, Spin, Popconfirm, Statistic, Checkbox, Modal, Space } from "antd";
import axios from "axios"

import { Link } from 'react-router-dom';

//Componentes
import { IconDelete, IconCheckGreen } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
//Modales
import ModalTransacciones from './ModalTransaccion'
import ModalTransaccionesDetalle from './ModalTransaccionDetalle'
import { useParams } from "react-router-dom";

import { ReloadOutlined } from "@ant-design/icons";

import SyncWidget from "@paybook/sync-widget";


window.SyncWidget = SyncWidget


const { Content } = Layout;
const { Text } = Typography;
const moment = require('moment')

/**
 *
 *
 * @export
 * @class TransaccionesSyncfy
 * @extends {Component}
 * @description Vista del listado de transacciones
 */
class TransaccionesSyncfy extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            transacciones: [],
            cuenta: undefined,
            seleccionadas: {},

            page: 1,
            limit: 20,
            total: 0,
        }
    }

    componentDidMount() {
        this.setState({ cuenta_id: this.props.params.cuenta_id });

        this.getCuenta();
    }

    /**
    *
    *
    * @memberof TransaccionesSyncfy
    * @description Obtiene todas todas las transacciones
    * @param values pagina
    */
    getCuenta = () => {
        this.setState({ loading: true })
        axios.get('/cuenta/' + this.props.params.cuenta_id).then(({ data }) => {
            this.setState({ cuenta: data }, () => {
                this.getToken()
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar la cuenta')
            this.setState({ loading: false })
        })

    }

    /**
    *
    *
    * @memberof TransaccionesSyncfy
    * @description Obtiene todas todas las transacciones
    * @param values pagina
    */
    getToken = () => {
        axios.get('/syncfy/session', {
            params: {
                id_user_syncfy: this.state.cuenta?.syncfy_usuario_id,
            }
        }).then(response => {
            sessionStorage.setItem('syncfy', response.data.token)
            axios.defaults.headers.common['syncfy'] = response.data.token
            this.setState({ token: response.data.token }, () => {
                this.getTransacciones();
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener el token')
            this.setState({ loading: false })
        })

    }

    /**
    *
    *
    * @memberof TransaccionesSyncfy
    * @description Obtiene todas todas las transacciones
    * @param values pagina
    */
    getTransacciones = () => {
        this.setState({ loading: true })
        axios.get('/syncfy/transacciones', {
            params: {
                cuenta_id: this.props.params.cuenta_id,
                token: this.state.token,
                page: this.state.page,
                limit: this.state.limit,
            }
        }).then(response => {
            console.log("response", response);
            this.setState({
                transacciones: response.data.data.data,
                total: response.data.data.total,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener las transacciones')
            this.setState({ loading: false })
        }).finally(() => this.setState({ loading: false }))

    }

    /**
    *
    * @memberof TransaccionesSyncfy
    * 
    * @method clasificarTransacciones
    * @description Abre el modal si se seleccionaron transacciones
    */
    clasificarTransacciones = () => {
        const selecionadasLenght = Object.keys(this.state.seleccionadas).length
        if (selecionadasLenght === 0)
            return Modal.error({
                title: "Debe seleccionar algunas transacciones",
                content: "Para poder agregar transacciones "
            })
        this.setState({ modalClasificarVisible: true })
    }

     /**
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method renderWidget
     * @description Renderizamos el widget para declara las solicitudes.
     */
    renderWidget = (credential = this.state.cuenta?.syncfy_credenciales_id) => {
        if (sessionStorage.getItem('syncfy') && sessionStorage.getItem('syncfy') !== null) {
            if (!window.syncWidget)
                window.syncWidget = new SyncWidget({
                    // Set up the token you created in the Quickstart:  
                    token: this.state.token,
                    config: {
                        // Set up the language to use:  
                        locale: 'es',
                        navigation: {
                            displayStatusInToast: true,
                            displaySiteOrganizationTypes: ["56cf4f5b784806cf028b4568"]
                        },
                        entrypoint: {
                            credential
                        }
                    }
                })
            else {
                window.syncWidget.setConfig({
                    navigation: {
                        displayStatusInToast: true,
                        displaySiteOrganizationTypes: ["56cf4f5b784806cf028b4568"]
                    },
                    entrypoint: {
                        credential
                    }
                })
                window.syncWidget.setToken(this.state.token)
            }

            window.syncWidget.$on("error", () => {
                message.error("Hubo un error al actualizar")
            });
            window.syncWidget.$on("success", () => this.getTransacciones());

            if (!credential)
                window.syncWidget.open()
            return
        }
        return Modal.error({
            title: "Debe seleccionar un Usuario de Syncfy para poder generar las credenciales."
        })
    }






    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Lista de Transacciones"

                        breadcrumb={{
                            className: "custom-page-header-breadcrumb",
                            itemRender: (route, params, routes, paths) => (route.path && !(routes.indexOf(route) === routes.length - 1)) ? <Link to={route.path}>{route.breadcrumbName}</Link> : route.breadcrumbName,
                            routes: [
                                {
                                    path: "/admin/finanzas",
                                    breadcrumbName: 'Finanzas',
                                },
                                {
                                    breadcrumbName: 'Transacciones Bancarias',
                                },
                                {
                                    breadcrumbName: this.state.cuenta?.nombre
                                }
                            ]
                        }}
                        extra={[
                            <Space>
                                <Button
                                    type="ghost"
                                    icon={<ReloadOutlined />}
                                    style={{ color: "white" }}
                                    onClick={() => this.renderWidget()}
                                >Sincronizar Credenciales</Button>
                            </Space>
                        ]}
                    />
                    {/* <PageHeader
                        className="site-page-header custom-page-header"
                        title="Transacciones no clasificadas"
                    /> */}
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.transacciones}
                            header={<Row className="header-list width-100 pl-1 pr-1">

                                <Col span={2} className=" center">
                                    <Text strong></Text>
                                </Col>
                                <Col xs={12} className="center">
                                    <Text strong>Concepto</Text>
                                </Col>
                                <Col xs={5} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Monto</Text>
                                </Col>
                                <Col xs={1} className="center">
                                    <Text strong></Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => {

                                const { amount, dt_transaction, description, transaccion, id_transaction, category, currency } = item;

                                return <List.Item className="component-list-item">
                                    <Tooltip trigger={(item.transaccion !== null) ? ["hover"] : []} title=" Esta Transacción se clasificó previamente" placement="topLeft">
                                        <Card
                                            onClick={(item.transaccion !== null) ? () => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item.transaccion?._id }) : null}
                                            className="card-list" style={{ ...(item.transaccion !== null) ? { background: "rgba(0,0,0,0.1)" } : {} }}>
                                            <Row className="width-100">
                                                <Col span={2} className="center">

                                                    <Checkbox className="radio-purple"
                                                        disabled={(item.transaccion !== null)}
                                                        checked={(item.transaccion == null) ? this.state.seleccionadas[id_transaction] : true}
                                                        onChange={value => {
                                                            this.setState(state => {
                                                                if (value.target.checked === true)
                                                                    state.seleccionadas[id_transaction] = item
                                                                else
                                                                    delete state.seleccionadas[id_transaction]
                                                                return state;
                                                            })
                                                        }}
                                                    ></Checkbox>

                                                </Col>
                                                <Col span={12} className="center">
                                                    <Text>{description}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text strong>{moment.unix(dt_transaction).format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Statistic value={amount} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: (amount < 0) ? '#FF0000' : '#00FF19' }} prefix={'$'} suffix={currency} />
                                                </Col>
                                                <Col span={1} className="center">
                                                    {this.state.vista_guardadas ? <Popconfirm placement="topRight"
                                                        title="¿Eliminar de Guardados?" okText="Si, seguro" cancelText="No"
                                                        onConfirm={() => {
                                                            this.deleteGuardados(item._id)
                                                        }}>
                                                        <Button type="primary"
                                                            className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                            title="Eliminar">
                                                            <IconDelete />
                                                        </Button>
                                                    </Popconfirm> : null
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Tooltip>
                                </List.Item>
                            }}

                            pagination={{
                                onChange: (page, limit) => this.setState(state => {
                                    state.page = page;
                                    state.limit = limit;
                                    return state;
                                }, () => this.getTransacciones()),

                                pageSizeOptions: [10, 20, 50, 100, 500],
                                showSizeChanger: true,

                                pageSize: this.state.limit,
                                total: this.state.total,
                                current: this.state.page,

                                hideOnSinglePage: false,
                                showQuickJumper: false,
                                className: "pagination-list",
                            }}

                        />

                        <FloatingButton title="Clasificar" icon={<IconCheckGreen />} onClick={() => this.clasificarTransacciones()} />
                    </Content>
                </Spin>
                <ModalTransaccionesDetalle
                    visible={this.state.modalTransacDetalleVisible}
                    onClose={() => this.setState({
                        modalTransacDetalleVisible: false,
                        transaccion_id: null
                    })}
                    id={this.state.transaccion_id}
                />

                <ModalTransacciones
                    clasificacion={true}
                    visible={this.state.modalClasificarVisible}
                    seleccionadas={this.state.seleccionadas}
                    onClose={() => {
                        this.setState({ modalClasificarVisible: false })
                        this.getTransacciones()
                    }}
                    clearSeleccionadas={() => this.setState({ seleccionadas: {} })}
                    cuenta_id={this.state.cuenta_id}
                />

            </>
        )
    }
}
export default function () {
    return <TransaccionesSyncfy params={useParams()} />
}