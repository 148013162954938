import React, { Component, useContext } from 'react';
import { Row, Col, Button, Popconfirm, Space, Spin, PageHeader, Layout, message, Typography, Tooltip, Card, List } from 'antd'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BankOutlined } from '@ant-design/icons';

//Componentes
import User from "../../../../Hooks/Logged";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import usePermissions from "../../../../Hooks/usePermissions";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconBankError, IconMoneyCircle } from '../../../Widgets/Iconos';

import { CardColors, CardTime } from "../../../Widgets/Cards"

const { Content } = Layout;
const { Text } = Typography;
const moment = require("moment");

/**
 * @export
 * @class Cuentas
 * @extends {Component}
 * @description Vista de Project Manager
 */
class Cuentas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            cuenta_id: undefined,
            cuentas: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,

            },
            proyecto:{
                horas_estimadas: 0,
                horas_consumidas: 0,
                personal: [],
                modulos: [],
            }
        }
    }

    componentDidMount() {
        this.getProyecto();
    }

    /**
    * @memberof Cuentas
    * @method getProyecto
    * @description Trae la informacion del proyecto
    */
    getProyecto = () => {
        this.setState({ loading: true })
        axios.get('/project-manager/proyecto/info', {
            params: { 
                proyecto_id: this.props.proyecto_id
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({
                proyecto: data
            })
            
        }).catch(error => {
            message.error(error?.response?.data?.message ?? "Error al obtener la información")
        }).finally(() => this.setState({ loading: false }))
    }

    render() {

        const { proyecto } = this.state

        return (
            <>
                <Spin spinning={false}>
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[16,16]}>
                            <Col xs={24} sm={12} md={6}>
                                <CardColors
                                    title="Fecha Limite"
                                    text={moment(proyecto?.fecha_final).format('D [de] MMMM')}
                                    color="#48A0FF"
                                />
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <CardColors
                                    title="Porcentaje de Avance"
                                    text={`${proyecto?.porcentaje_avance?.toMoney(true)}%`}
                                    color="#5FC576"
                                />
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <CardColors
                                    title="Tiempo Restante"
                                    text={`${proyecto.horas_estimadas - proyecto.horas_consumidas} horas`}
                                    color="#E46773"
                                />
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <CardColors
                                    title="Tiempo Transcurrido"
                                    text={`${proyecto.horas_consumidas ?? 0} horas`}
                                    color="#FFD147"
                                />
                            </Col>
                            <Col md={8}>
                                <CardTime
                                    title="Distribución de Tiempo por Persona"
                                    data={proyecto.personal?.map(e => ({ name: e.responsable_id?.nombre ?? "N/A", value: e.horas_estimadas }) )}
                                />
                            </Col>
                            <Col md={8}>
                                <CardTime
                                    title="Distribución de Tiempo por Módulo"
                                    data={proyecto.modulos?.map(e => ({ name: e.nombre, value: e.horas_estimadas }) )}
                                />
                            </Col>
                            <Col md={8}>
                                <CardTime
                                    title="Distribución de Tiempo por Tipo"
                                />
                            </Col>
                        </Row>
                    </Content>
                </Spin>
                
            </>
        )

    }
}

export default function Vista(props) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarCuentas: ["cuentas", "edit"],
        deleteCuentas: ["cuentas", "delete"],
        createCuentas: ["cuentas", "create"],
        enlaceCuenta: ["cuentas", "syncfy_cuenta"]

    });

    return <Cuentas {...props} navigate={useNavigate()} {...permisos}/>

}