import React, { Component } from 'react';
import { Row, Col, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Card, Typography } from 'antd'
import axios from 'axios';  
import Carousel from 'react-multi-carousel';
import { PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined } from "@ant-design/icons";

//Componentes
import { color_transaccion_tipo } from "../../../Utils"
import { IconEye, IconEdit, IconDelete, IconLink } from '../../../Widgets/Iconos';
//Modales
import ModalTransacciones from "../../Finanzas/Transacciones/ModalTransaccion";
import ModalAsociacionFacturasTransacciones from '../ModalAsociacionFacturasTransacciones'
import ModalTransaccionesDetalle from "../../Finanzas/Transacciones/ModalTransaccionDetalle";

const { Content } = Layout;
const { Text, Title } = Typography
const moment = require('moment')
const tipo = { "0": 'Sin Cambios', "1": ' Ingreso', "-1": 'Egreso' }
const estatus_facturas = ['Factura Cancelada', 'Pendiente', 'Liquidad']

/**
 * @export
 * @class TransaccionesList
 * @extends {Component}
 * @description Vista de TransaccionesList
 */
export default class TransaccionesList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            transacciones: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
                search: '',
            },
        }
    }

    componentDidMount() {
        this.getTransacciones()
    }

     /**
   *
   *
   * @memberof Transacciones
   * @description Obtiene todas todas las transacciones sin facturas
   * @param page pagina
   * @param filters Filtrado de registros mediante el uso del form de filtros
   */
    getTransacciones = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
            search = this.state.transacciones.search,

        } = this.state.transacciones,
    ) => {
        this.setState({loading: true})
        axios.post('/transacciones', {
            page,
            limit,
            search,
            sin_facturas: true, //transacciones que aun tienen saldo para abonar a facturas

        })
        .then(res => {
            this.setState({
                transacciones: res.data
            })
        })
        .catch(res => {
            console.log("error", res);
            message.error('No se pudieron cargar las transacciones')
        })
        .finally(()=>this.setState({loading: false}))
    }

    /**
     *
     *
     * @param {*} tipo
     * @memberof TransaccionesList
     * @description Renderiza el icono según el estatus
     */
    renderIconTransaccion = (tipo) => {
        switch (tipo) {
            case 1:
                return <PlusOutlined style={{ color: "currentColor" }} />
            case 2:
                return <MinusOutlined style={{ color: "currentColor" }} />
            case 3:
                return <SwapOutlined style={{ color: "currentColor" }} />
            case 4:
                return <StopOutlined style={{ color: "currentColor" }} />
        }
    }


    render() {

        const { empresas } = this.state;

        return (
            <>
                <Row>
                    <Col span={24}><Title className="subtitle-dashboard mt-1" level={4}>Transacciones sin Factura</Title></Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <List
                            loading={this.state.loading}
                            className="component-list "
                            itemLayout="horizontal"
                            dataSource={this.state.transacciones.data}
                            pagination={{
                                onChange: (page, limit) => {
                                    this.getTransacciones({ page, limit })
                                },
                                hideOnSinglePage: true,
                                total: this.state.transacciones.total,
                                page: this.state.transacciones.page
                            }}
                            renderItem={item => (

                                <List.Item className="component-list-item ">
                                    <Card className="card-list" bordered={false}>
                                        <Row className="width-100" justify="space-around">
                                            <Col xs={24} md={6} lg={2} xl={2} className="center item-info">
                                                <Text ellipsis strong>{moment(item?.fecha).format('DD/MM/YYYY')}</Text>
                                            </Col>
                                            <Col xs={24} md={18} lg={4} xl={4} className="center item-info">
                                                <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                            </Col>
                                            <Col xs={24} md={6} lg={3} xl={3} className="center item-info">
                                                <Text ellipsis className="text-gray-dark">{(item?.area_id !== undefined) ? item?.area_id.nombre : 'Sin Area'}</Text>
                                            </Col>
                                            <Col xs={24} md={6} lg={3} xl={3} className="center item-info">
                                                <Text ellipsis className="text-gray-dark">{(item?.rubro_id !== undefined) ? item?.rubro_id.nombre : 'Sin Rubro'}</Text>
                                            </Col>

                                            <Col xs={24} md={6} lg={4} xl={4} className="center item-info">
                                                <Text ellipsis className="text-gray-dark">{item?.cuenta_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={24} md={16} lg={4} xl={4} className="center item-info">
                                                <Text style={{ color: color_transaccion_tipo(item.tipo), fontWeight: "bold" }} >
                                                {this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney(true)} MXN</Text>
                                            </Col>
                                            <Col xs={24} md={8} lg={4} xl={4} className="center item-info">
                                                <Space direction="horizontal">
                                                    <Button
                                                        className="btn-eye"
                                                        icon={<IconEye />}
                                                        type="primary"
                                                        title="Ver Detalle"
                                                        onClick={() => this.setState({ modal_trans_detalle: true, transaccion_id: item._id })}

                                                    >
                                                    </Button>

                                                    <Button
                                                        className="btn-link"
                                                        icon={<IconLink />}
                                                        type="primary"
                                                        title="Link"
                                                        onClick={() => this.setState({ modal_link: true, transaccion_id: item._id })}

                                                    >
                                                    </Button>

                                                    <Button
                                                        className="btn-editar"

                                                        icon={<IconEdit />}
                                                        type="primary"
                                                        title="Editar"
                                                        onClick={() => this.setState({ modal_trans_editar: true, transaccion_id: item._id })}

                                                    >
                                                    </Button>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta Transacción?"
                                                        onConfirm={() => axios.delete(`/transaccion/${item._id}`)
                                                            .then((response) => {
                                                                message.success('Transaccion Eliminada')
                                                                this.props.updateInfo()
                                                            }).catch((error) => {
                                                                message.error('Transacción NO Eliminada')
                                                                
                                                            })
                                                        }
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            icon={<IconDelete />} title="Eliminar" />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                           
                        />
                    </Col>
                </Row>
                 <ModalAsociacionFacturasTransacciones
                    visible={this.state.modal_link}
                    onClose={() => {
                        this.setState({ modal_link: false, transaccion_id: undefined, factura_id: undefined})
                        this.props.updateInfo()
                    }}
                    factura_id={this.state.factura_id}
                    transaccion_id={this.state.transaccion_id}
                    //razon_social_id={this.props.match.params.razon_social_id}
                />
                <ModalTransaccionesDetalle
                    visible={this.state.modal_trans_detalle}
                    onClose={() => this.setState({
                        modal_trans_detalle: false,
                        transaccion_id: undefined
                    })}
                    id={this.state.transaccion_id}
                />
                <ModalTransacciones
                    visible={this.state.modal_trans_editar}
                    transaccion_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modal_trans_editar: false, transaccion_id: undefined })
                        this.props.updateInfo()
                    }}
                />
            </>
        )
    }
}