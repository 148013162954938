import React, { Component } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Layout, Row, Col, Button, List, Space, Typography, Card, message, Popconfirm, PageHeader, Tooltip } from "antd";
import { FilterOutlined, EditOutlined, DeleteOutlined, FileSearchOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, CopyOutlined } from "@ant-design/icons";


//componentes
import Sort from '../../../Widgets/Sort'
import { color_transaccion_tipo } from "../../../Utils"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//modal
import ModalServicios from "../Modales/ModalServicios";

import axios from "axios"

const { Content } = Layout;
const { Text } = Typography;

const moment = require('moment')

/**
 *
 *
 * @export
 * @class Servicios
 * @extends {Component}
 * @description Vista del listado de Servicios
 */
class Servicios extends Component {

	constructor(props) {
		super(props)
		this.state = {
			servicios: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
				filters: [],
				sort: {},
			}
		}
	}

	componentDidMount() {
		this.getServicios();
	}

	/**
	* @memberof Servicios
	* @description Obtiene todas todas las Servicios
	* @param page pagina actual
	* @param limit limite de objetos de una pagina
	*/
	getServicios = ({
		page = this.state.Servicios.page,
		limit = this.state.Servicios.limit,
	} = this.state.servicios) => {
		axios.get('/servicios-adquiridos', {
			params:{
				page,
				limit,
				proyecto_id: this.props.proyecto_id,
			}
		})
		.then(({ data }) => {
			console.log("data", data);
			this.setState({
				servicios: data
			})
		})
		.catch(res => {
			message.error('No se pudieron cargar las Servicios')
		})
		.finally(() => this.setState({ loading: false }))
	}


	/**
	 *
	 *
	 * @param {*} key
	 * @memberof Servicios
	 */
	setSort = (key) => {
		let value;
		switch (this.state.Servicios.sort[key]) {
			case 1:
				value = -1
				break;
			case -1:
				value = undefined
				break;
			default:
				value = 1
				break;
		}
		this.getServicios({
			sort: {
				...this.state.Servicios.sort,
				[key]: value
			}
		})
	}

	/**
 *
 *
 * @param {*} tipo
 * @memberof Servicios
 * @description Renderiza el icono según el estatus
 */
	renderIconTransaccion = (tipo) => {
		switch (tipo) {
			case 1:
				return <PlusOutlined style={{ color: "currentColor" }} />
			case 2:
				return <MinusOutlined style={{ color: "currentColor" }} />
			case 3:
				return <SwapOutlined style={{ color: "currentColor" }} />
			case 4:
				return <StopOutlined style={{ color: "currentColor" }} />
		}
	}


	render() {

		// console.log('state', this.state)
		return (
			<>
				<Content className="admin-content content-bg pd-1">
					<List
						loading={this.state.loading}
						className="component-list"
						itemLayout="horizontal"
						locale={{ emptyText: "Sin Servicios" }}
						dataSource={this.state.servicios?.data}
						pagination={{
							current: this.state.servicios.page,
							pageSize: this.state.servicios.limit,
							total: this.state.servicios.total,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.getServicios({ page, limit })
						}}
						header={<Row className="width-100 pr-1 pl-1" >
                            <Col span={9} className="center">
                                <Text strong>Nombre</Text>
                            </Col>
                            <Col span={9} className="center">
                                <Text strong>Costo</Text>
                            </Col>
                            <Col span={6} className="center">
                                <Text strong>Acciones</Text>
                            </Col>
                        </Row>
                        }

						renderItem={item => (
							<List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col span={9} className="center">
                                            <Text strong>{item.servicio_id?.nombre}</Text>
                                        </Col>
                                        <Col span={9} className="center">
                                            <Text className="text-gray">$ {item.monto.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col span={6} className="center">
                                            <Space>
                                                <Button
                                                    style={{ paddingTop: '3px' }}
                                                    type="primary"
                                                    icon={<EditOutlined />}
                                                    title="Editar"
                                                    onClick={() => { this.setState({ modal_visible: true, servicio_adquirido_id: item._id }) }}

                                                />
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar esta servicio?"
                                                    onConfirm={() => axios.delete('/servicio-adquirido/' + item._id)
                                                    	.then(() => {
                                                            this.getServicios()
                                                            message.success('Servicios eliminado')
                                                        }).catch(error => {
                                                        	console.log(error)
                                                        	message.error("Error al eliminar el servicio")
                                                    	})
                                                   	}
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        style={{ paddingTop: '5px' }}
                                                        type="primary"
                                                        icon={<DeleteOutlined />}
                                                        title="Eliminar"
                                                        danger
                                                    />
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
						)}
					/>
				</Content>

				<FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

				<ModalServicios
					visible={this.state.modal_visible}
					onClose={(flag)=>{
						this.setState({ modal_visible: false })
						if(flag === true){
							this.getServicios()
						}
					}}
					proyecto_id={this.props.proyecto_id}
					servicio_adquirido_id={this.state.servicio_adquirido_id}
				/>
				
			</>
		)
	}
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

	return (<Servicios {...props} navigate={useNavigate()}/>)
}