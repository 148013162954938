import React from 'react';
import { Row, Card, List, Tag, Checkbox, Typography, Col } from 'antd';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
const moment = require('moment')
const { Meta } = Card
const { Title } = Typography

const CardTaskClient = ({ 
    id,
    nombre,
    vencido = false, 
    sinAsesor = null,
    complete = false, 
    cliente = {}, 
    className="" ,
    date = new Date(), 
    description, 
    proyecto = {}, 
    onClick = () => { }, 
    onCancel = () => { } 
}) => {

    let dates = (now = moment(), date) => {

        let time_ago = 'min'
        let diff_ago = now.diff(moment(date), 'minutes')

        if (diff_ago > 120) {
            diff_ago = now.diff(moment(date), 'hours')
            time_ago = 'hrs';
            if (diff_ago > 23) {
                diff_ago = now.diff(moment(date), 'days');
                time_ago = 'days';
            }
        }


        return <>
            {diff_ago} {time_ago}
        </>
    }

    let tipo = 'Cotización'

    let color = "#0089ED"

    let task = [
        {
            title: 'Solicitud de Cotizacion',
            value: 1,
            checked: true,
        },
        {
            title: 'Cuantificación de horas',
            value: 2,
            extra: <small style={{color:'rgba(0, 0, 0, 0.4)'}}>{proyecto.horas_estimadas ? `${proyecto.horas_estimadas} horas` : null}</small>,
            checked: proyecto.horas_estimadas > 0
        },
        {
            title: 'Estimación de Costos',
            value: 4,
            //extra: <small style={{color:'#52C41A'}}>{proyecto.costo_hora ? `$ ${(proyecto.costo_hora * proyecto.horas_estimadas).toMoney(true)}` : null}</small>,
            checked: proyecto.costo_hora > 0
        },
        {
            title: 'Envío de Cotización',
            value: 5
        },
        {
            title: 'Firma de Contrato',
            value: 6,
            extra: <small style={{color:'#52C41A'}}>{proyecto.fecha_firma ? `${moment(proyecto.fecha_firma).format('ll')}` : null}</small>
        }
    ]

    return <Card
        hoverable
        bordered={false}
        
        className={"card-shadow card-task pm " + (complete ? " card-task-complete" : "") + (className)}
        style={{ position: "relative", }}
        loading={(proyecto.loading === true)}
    >
		<Meta title={
			<Row justify='space-between' align='middle'>
				<Title className='card-title'>
					{proyecto.nombre}
				</Title>
				<Tag onClick={onClick} className="card-task-tag" color={color} style={{ color: vencido ? "black" : undefined }} >{tipo}</Tag>
				</Row>} 
				description={dates(moment(), proyecto.createdAt)} />
        <div className='card-container-pm'>
            <div  onClick={onClick}>
                <List
                    itemLayout="horizontal"
                    dataSource={task}
                    bordered={false}
                    className="list-border-none mt-1"
                    renderItem={item => (
                        <List.Item >
                            <List.Item.Meta
                                className="card-task-list-meta"
                                avatar={<Checkbox
                                    className="custom-checkbox-circle"
                                    checked={item.checked}
                                />}
                                title={item.title}
                            />
                            <Row align='middle'>{item.extra ? item.extra : null}</Row>
                        </List.Item>
                    )}

                />
            </div>
        </div>
        <Row className='card-task-footer' justify={"space-between"}>
            <Col span={24} className='mt-1'>
				<CustomAvatar
					image={cliente?.nombre ?? ""}
            		name={`${cliente?.nombre ?? ""} ${cliente?.apellido_paterno ?? ""}`}
                    className="mr-1" /> 
				{`${cliente.nombre ?? "Sin Cliente"} ${cliente.apellido_paterno ?? ""}`}
            </Col>

        </Row>
    </Card>
}

export default CardTaskClient