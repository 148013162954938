import React, { Component } from 'react';
import { Row, Col, Form, Input, Typography, DatePicker, Button, InputNumber, Collapse, Space, Modal } from 'antd'

import { EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';

//Modal
import ModalModulo from "./ModalModulo";

const { Panel } = Collapse;
const { Title, Text } = Typography;
const moment = require("moment");

/**
 * @export
 * @class Servicios
 * @extends {Component}
 * @description Vista de Servicios, crea los servicios adquiridos de los proyectos
 */
class Servicios extends Component {


	constructor(props){
		super(props);
		this.state = {
			modulos: [],
			servicio: undefined,
			index: undefined,
		}
	}

	componentDidMount(){
			console.log("this.props.values", this.props.values);
		if(this.props.values?.modulos){
			this.setState({modulos: this.props.values.modulos})
		}
	}


	/**
     * @memberof Servicios
     * @method addModulo
     * @description Añade un nuevo modulo
     */
	addModulo = (values) => {
		let modulos = [...this.state.modulos]
		modulos.push(values)
		this.setState({
			modulos,
			modal_visible: false
		})	
	}


	/**
     * @memberof Servicios
     * @method deleteModulo
     * @description Elimina un modulo del array
     */
	deleteModulo = (index) => {
		if(typeof index === "number" && index >= 0){
			Modal.confirm({
				title: '¿Quieres eliminar este Módulo?',
    			icon: <ExclamationCircleFilled />,
			    onOk: () => {
			    	let modulos = [...this.state.modulos]
					modulos.splice(index, 1)
					this.setState({
						modulos,
					})
			    },
			    onCancel() {},
			    okText: "Si",
			    cancelText: "Cancelar"
			})
			
		}
	}

	render(){

		let { modulos } = this.state;

		return(
			<>
				<Row>
					<Col span={12} className="flex-left">
						<Title level={5} className="m-0">Módulos</Title>
					</Col>
					<Col span={12} className="flex-right">
						<Button type="primary" onClick={()=>this.setState({modal_visible: true})}>
							Agregar
						</Button>
					</Col>
				</Row>
				<div style={{minHeight: "500px"}} className="mt-1">
					{modulos?.length === 0 ? <Title level={4} className="text-center text-gray">Sin Módulos</Title> : null}
					<Collapse>
						{modulos?.map((modulo, index) => {
							return <Panel header={modulo.nombre} key={index} extra={<Space>
									<DeleteOutlined onClick={(e)=>{
											e.stopPropagation()
											this.deleteModulo(index)
										}}
									/>
								</Space>
							}>
								<Row className="text-right">
									<Col span={14}>
										<Text strong className="text-purple">Actividad</Text>
									</Col>
									<Col span={10}>
										<Text strong className="text-purple">Horas Aproximadas</Text>
									</Col>
								</Row>
								{
									modulo.actividades.map(actividad => {
										return <Row className="text-right">
											<Col span={14}>
												<Text>{actividad.nombre}</Text>
											</Col>
											<Col span={10}>
												<Text>{modulo?.horas_aproximadas?.toMoney(true)}</Text>
											</Col>
										</Row>
									}) 
								}
							</Panel>
						})}
					</Collapse>
				</div>
				<Row>
					<Col xs={12}>
						<Button type="secondary" onClick={()=>this.props.back(this.state.modulos)}>
							Anterior
						</Button>
					</Col>
					<Col xs={12} className="flex-right">
						
						<Button type="primary" onClick={()=>this.props.onSubmitModulos(this.state.modulos)}>
							Continuar
						</Button>
						
					</Col>
				</Row>
				<ModalModulo
					visible={this.state.modal_visible}
					onClose={()=>{
						this.setState({modal_visible: false, servicio: undefined, index: undefined})
					}}
					addModulo={(values)=>{
						this.addModulo(values)}
					}
				/>
			</>
		)
	}

}


export default Servicios