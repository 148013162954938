import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, InputNumber, Select, DatePicker } from 'antd';
import axios from "axios"

//Componentes
import { SelectUsuario } from "../../../Widgets/Inputs/Selects";

const { Option } = Select;
const { RangePicker } = DatePicker;
const moment = require("moment")

class ModalActividadModulo extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modulo_id: undefined,
            actividades: []
        }
    }

    ModalModulo = React.createRef();

    componentDidMount() {
        if(this.props.actividad_modulo_id){
            this.getActividad()
        }
    }

    onFinish = (values) => {

        if(values.fechas?.length === 2){
            values.fecha_inicio = values.fechas[0]
            values.fecha_final = values.fechas[1]
        }

        if(this.props.actividad_modulo_id){
            this.updateActividad(values)
        }else{
            this.addActividad(values)
        }
    }

    /**
     * @memberof ModalCuentas
     * @method getActividad
     * @description Obtiene informacion del modulo
     */
    getActividad = () => {
        this.setState({loading: true})
        axios.get('/actividad-modulo/'+this.props.actividad_modulo_id)
        .then(({ data }) => {
            console.log("data", data);
            this.ModalModulo.current.setFieldsValue({
                ...data,
                fechas: [moment(data.fecha_inicio),moment(data.fecha_final)],
                responsable_id: data.responsable_id ? {
                    value: data.responsable_id._id,
                    label: `${data.responsable_id.nombre} ${data.responsable_id.apellidos ?? ""}`
                } : null
            })
           
        }).catch(error => {
            console.log("error", error.response);
            message.error(error?.response?.data?.message ?? 'Error al obtener la actividad')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalCuentas
     * @method addActividad
     * @description Añade un nuevo modulo al sistema relacionado al proyecto
     */
    addActividad = (values) => {
        this.setState({loading: true})
        axios.post('/actividad-modulo',{
            ...values,
           modulo_proyecto_id: this.props.modulo_proyecto_id
        })
        .then(({ data }) => {
            message.success("Modulo registrado")
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al crear el modulo')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalCuentas
     * @method updateActividad
     * @description Actualiza el modulo
     */
    updateActividad = (values) => {
        this.setState({loading: true})
        axios.put('/actividad-modulo',{
            ...values,
            id: this.props.actividad_modulo_id
        })
        .then(({ data }) => {
            message.success("Actividad Actualizada")
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al actualizar la actividad')
        }).finally(() => this.setState({ loading: false }))
    }




    /**
     * @memberof ModalCuentas
     * @method calcularHorasEntreFechas
     * @description Se calculan las horas que hay entre dos fechas, quitando fines de semana y solo toma las horas entre 8 am y 6pm
     */
    calcularHorasEntreFechas = (fechaInicio, fechaFinal) => {

        fechaInicio = new Date(fechaInicio)
        fechaFinal = new Date(fechaFinal)

        let horasHabiles = 0;
        let fechaActual = moment(fechaInicio).clone();

        while (fechaActual.isBefore(fechaFinal)) {
            if (fechaActual.day() !== 0 && fechaActual.day() !== 6) { // Si no es fin de semana
                const horaActual = fechaActual.hour();
                if (horaActual >= 8 && horaActual < 18) { // Si está entre las 8am y las 6pm
                    horasHabiles++;
                }
            }

            fechaActual.add(1, 'hour');
        }

        return horasHabiles;
    }

    render() {

        return (
            <Form
                layout="vertical"
                name="form-proveedor"
                id="form-proveedor"
                ref={this.ModalModulo}
                onFinish={this.onFinish}
                initialValues={{
                    estatus: 1,
                    horas_consumidas: 0,
                    porcentaje_avance: 0,
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center">
                        <Col span={22}>
                            <Row gutter={[16,0]}>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Nombre"
                                        name="nombre"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese el nombre"
                                        }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Estatus"
                                        name="estatus"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el estatus"
                                        }]}
                                    >
                                        <Select>
                                            <Option value={1}>Planeado</Option>
                                            <Option value={2}>En proceso</Option>
                                            <Option value={3}>To commit</Option>
                                            <Option value={4}>En testeo</Option>
                                            <Option value={5}>En correción</Option>
                                            <Option value={6}>En pausa</Option>
                                            <Option value={7}>Completado</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        label="Fecha Inicio y Fin"
                                        name="fechas"
                                        // rules={[{
                                        //     required: true,
                                        //     message: "Por favor, ingrese las fechas"
                                        // }]}
                                    >
                                        <RangePicker 
                                            showTime
                                            format="YYYY-MM-DD HH:mm"
                                            className="width-100"
                                            onChange={(fechas)=>{
                                                let horas = this.calcularHorasEntreFechas(fechas[0], fechas[1])
                                                this.ModalModulo.current.setFieldsValue({
                                                    horas_estimadas: horas
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        label="Descripción"
                                        name="descripcion"
                                    >
                                        <Input.TextArea/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Horas Estimadas"
                                        name="horas_estimadas"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese las horas"
                                        }]}
                                    >
                                        <InputNumber
                                            className="width-100"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Horas Consumidas"
                                        name="horas_consumidas"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese las horas"
                                        }]}
                                    >
                                        <InputNumber
                                            className="width-100"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Porcentaje Avance"
                                        name="porcentaje_avance"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese los avances"
                                        }]}
                                    >
                                        <InputNumber
                                            className="width-100"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Responsable"
                                        name="responsable_id"
                                    >
                                        <SelectUsuario/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, actividad_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={actividad_id ? "Editar Actividad" : "Crear Actividad"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-proveedor', key: 'submit', htmlType: 'submit' }}
    >
        <ModalActividadModulo {...props} />
    </Modal>

}