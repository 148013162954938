import React, { Component } from 'react';
import { Row, Col, Form, Input, Typography, DatePicker, Button, InputNumber, Modal, message, Statistic, Space, Switch } from 'antd'
import axios from "axios";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

//componentes
import { ImagesUploader } from "../../../Widgets/Uploaders";
import { SelectPlantilla, SelectModulo } from "../../../Widgets/Inputs/Selects";

const { Text } = Typography;
const moment = require("moment");

/**
 * @export
 * @class ModalModal
 * @extends {Component}
 * @description Vista de Servicios, crea los servicios adquiridos de los proyectos
 */
class ModalModal extends Component {


	constructor(props){
		super(props);
		this.state = {
			loading: false,
			actividades: [],
			servicio: {},
			utilidad: 0,
			porcentaje: 0,
			usar_plantilla: false
		}
	}

	refModalServicio = React.createRef()

	componentDidMount(){

	}


	/**
     * @memberof ModalModal
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario, pasa al sig step
     */
	onFinish = (values) => {
		
		this.props.addModulo(this.state.modulo)
		
	}
	/**
     * @memberof ModalModal
     * @method onFinonSelectModuloish
     * @description Busca la informacion del modulo
     */
	onSelectModulo = (modulo_id) => {
		this.setState({loading: true})
		axios.get('/modulo/'+modulo_id,{
			params:{
				actividades: true
			}
		}).then(({data}) => {
			this.setState({
				modulo: data,
				actividades: Array.isArray(data.actividades) ? data.actividades : []
			})
		}).catch(error => {
			message.error(error?.response?.data?.message ?? "Error al obtener el modulo")
		}).finally(()=>this.setState({loading: true}))
	}


	render(){

		const { servicio, actividades } = this.state;

		return(
			<>
				<Form
					name="form-servicio"
					layout="vertical"
					onFinish={this.onFinish}
					ref={this.refModalServicio}
				>
					<Row gutter={[16,0]} justify="center">						
						<Col xs={24} md={20}>
							<Form.Item
								label="Módulo"
								name="modulo_id"
								rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el servicio"
                                }]}
							>
								<SelectModulo
									onSelect={(modulo_id)=>this.onSelectModulo(modulo_id)}
								/>
							</Form.Item>
						</Col>
						
							{
								actividades.map(({nombre, horas_aproximadas}) => {
									return <Col xs={24} md={20}>
										<Text>{nombre} <small>{horas_aproximadas} hrs.</small></Text>
										
									</Col>
								})
							}
						
					</Row>
				</Form>
			</>
		)
	}

}


export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={props.plantilla_id ? "Editar Módulo" : "Añadir Módulo"}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-servicio', key: 'submit', htmlType: 'submit' }}
    >
        <ModalModal {...props} />
    </Modal>

}