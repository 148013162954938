import React from 'react'
import { Route, Routes } from "react-router-dom";

import Areas from '../components/Admin/Areas/Areas';
import Rubros from '../components/Admin/Rubros/Rubros';

/**
 * 
 * @export
 * @function RouterAreas
 * @description Router for Areas routes 
 */
function RouterAreas(props) {
  return (
    <Routes>
      <Route path="" element={<Areas {...props} />} />
      <Route path=":area_id/rubros" element={<Rubros {...props} />} />
    </Routes>
  )
}

export default RouterAreas