import React from 'react'
import { Route, Routes } from "react-router-dom";

import Tickets from '../components/Public/Tickets/Tickets'
// import Ticket from '../components/Public/Tickets/Ticket'
// import NewTicket from '../components/Public/Tickets/NewTicket'


/**
 * 
 * @export
 * @function PublicRoutes
 * @description Router for handling all public routes
 */
function PublicRoutes() {
  return (
      <Routes>
        <Route path='/' element={<Tickets />} />
        <Route path='/:project/:name/:email' element={<Tickets />} />
      </Routes>
  )
}

export default PublicRoutes;
