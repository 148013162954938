import React, { Component, useContext, useState } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Image, Alert, Modal } from 'antd';
import { Redirect, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../Styles/Global/auth.css';

const { Title } = Typography

/**
 * 
 * @class Recovery
 * @extends {Component}
 * @description Componente Recovery para recuperar contraseñas
 */
export default function (props) {
	let navigate = useNavigate()

	let [loading, setLoading] = useState(false)

	/**
	* @memberof Login
	*
	* @method handleSubmit
	* @description  Envia los datos del formulario al Servidor
	*
	* @param values (array)
	* Contiene los campos del formulario para registrar al usuario
	*
	* @returns response (array)
	**/
	const handleSubmit = (values) => {
		setLoading(true)
		axios.put('/password/recovery', values)
			.then(({ data, headers }) => {
				Modal.success({ title: "Se ha enviado un correo electrónico de recuperación" })
				navigate("/login")

			})
			.catch((error) => {
				console.log("error", error)
				Modal.error({ title: "Credenciales incorrectas" })
			})
			.finally(() => setLoading(false))
	};

	return <Row className='layout-login'>
		<Col span={10} className="col-welcome">
			<Row>
				<img src="/img/ISY-Logo.png" alt="Logo I See You Technologies" />
			</Row>
			<Row>
				<div>
					<Title level={2} className='title-isyt-erp'>
						I See You Tech <br />
						Enterprise Resource <br />
						Planning <br /><br />
					</Title>
					<p className='subtitle-isyt-erp'>Welcome to the ISYT Digital Ecosystem</p>
				</div>
			</Row>
		</Col>
		<Col span={14}>
			<Spin spinning={loading}>
				<Form onFinish={handleSubmit} layout={"vertical"} requiredMark={false} className='password-recovery-form'>
					<Title level={3} className="h3-title">Password Reset</Title>
					<Title level={5} className="h5-title login-subtitle">Enter your email and we will send you a reset link</Title>
					<Form.Item className='input-name' name="email" label="Your name" rules={[{ required: true, message: 'Enter your name' }]}>
						<Input placeholder="Enter your name" size="large" />
					</Form.Item>
					<Button type="primary" htmlType="submit" block size="large">Send me the link</Button>
				</Form>
			</Spin>
		</Col>
		<div className='link-password-recovery'>
			¿Ya tienes tus credenciales? <Link style={{ color: '#5452F6' }} to='/login'>Inicia Sesión</Link>
		</div>
	</Row>
}