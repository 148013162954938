import React, { Component } from 'react';
import { Row, Col, Typography, Button, Collapse, message } from 'antd'
import axios from "axios"

//Modal
import ModalServicioAdquirido from "./ModalServicioAdquirido";

const { Panel } = Collapse;
const { Title, Text } = Typography;
const moment = require("moment");

/**
 * @export
 * @class Revision
 * @extends {Component}
 * @description Vista de Revision, Se observa todo el detalle de la cotizacion
 */
class Revision extends Component {


	constructor(props){
		super(props);
		this.state = {
			values: {},
			monto: 0,
			cliente: {}
		}
	}

	componentDidMount(){
		if(this.props.values && Object.keys(this.props.values).length > 0){
			let monto = 0

			for(let serv of this.props.values.servicios_adquiridos){
				monto += serv.monto
			}

			this.setState({monto})

			if(this.props.values.cliente_id)
				this.getCliente()

			if(this.props.values.asesor_id)
				this.getUsuario()

		}
	}


	/**
     * @memberof Revision
     * @method getCliente
     * @description Obtiene un Cliente de la DB
     */
    getCliente = () => {
        axios.get(`/cliente/${this.props.values.cliente_id}`)
            .then(({ data }) => {
                let cliente = data;
                this.setState({ cliente });
            })
            .catch((error) => {
                message.error("Error al traer la cliente");
                console.log(error);
            })
    }

    /**
    * @method getUsuario
    * @description Obtiene la informacion de un usuario
    */
    getUsuario = () => {
        axios.get('/usuario/' + this.props.values.asesor_id)
            .then(({data}) => {
               	let asesor = data
               	this.setState({ asesor });
            }).catch(error => {
                message.error('Error al obtener el usuario')
            })
    }

    /**
     * @memberof Revision
     * @method saveServicios
     * @description Crea en la BD los registros de los servicios_adquiridos seleccionados,(la informacion restante se guarda en el primer step)
     */
    saveServicios = () => {
    	this.setState({loading: true})
    	axios.post('/project-manager/servicios',{
    		...this.props.values,
    		proyecto_id: this.props.proyecto_id,
    		cotizacion_id: this.props.cotizacion_id,
    	}).then(response => {
    		message.success("Servicios creado con exito")
    		this.props.onClose(true)

    	}).catch(error => {
    		console.log("error", error);
    		message.error(error?.response?.data?.message ?? "Error al guardar la información")
    	})
    }


	render(){

		let { cliente, asesor } = this.state
		let { servicios_adquiridos } = this.props.values;
		let { values } = this.props;

		return(
			<>
				<Row>
					<Col span={12} className="flex-left">
						<Title level={5} className="m-0">Revisión</Title>
					</Col>
				</Row>
				<div style={{minHeight: "500px"}} className="mt-1">

					<Row className="mb-1">
						<Col xs={24} md={4} className="col-black">
							<Text>Nombre</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>{values.nombre}</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Asesor</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>{asesor?.nombre ?? "-"} {asesor?.apellidos ?? ""}</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Fecha</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>{moment(values.fecha).format("DD/MM/YYYY")}</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Monto Total</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>${this.state.monto?.toMoney(true)} mxn</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Documentos</Text>
						</Col>
						<Col xs={24} md={4} className="flex-column text-center">
							{this.props.values.documentos?.length > 0 ? null : "-"}
							{this.props.values.documentos?.map(e => {
								
								if(e instanceof File){
									return <a target="_blank" href={URL.createObjectURL(e)} className="link" rel="noreferrer">{e.name}</a>
								}
								return null

							})}
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Cliente</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>{cliente?.nombre ?? "-"} {cliente?.apellido_paterno ?? ""}</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Descripción</Text>
						</Col>
						<Col xs={24} md={20} className="flex-left p-1">
							<Text>{values.descripcion}</Text>
						</Col>
					</Row>

					<Collapse>
						{servicios_adquiridos?.map((servicio, index) => {
							return <Panel header={servicio.nombre} key={index} extra={`$ ${servicio?.monto?.toMoney(true)} mxn`}>
								{
									servicio.tipo === 1 ? <Row className="text-right">
										<Col span={5}>
											<Text strong className="text-purple">Costo Por Hora</Text>
										</Col>
										<Col span={4}>
											<Text strong className="text-purple">Horas Totales</Text>
										</Col>
										<Col span={5}>
											<Text strong className="text-purple">Costo</Text>
										</Col>
										<Col span={5}>
											<Text strong className="text-purple">Utilidad</Text>
										</Col>
										<Col span={5}>
											<Text strong className="text-purple">Total</Text>
										</Col>
										<Col span={5}>
											<Text>$ {servicio?.costo_hora?.toMoney(true)}</Text>
										</Col>
										<Col span={4}>
											<Text> {servicio?.horas_totales?.toMoney(true)}</Text>
										</Col>
										<Col span={5}>
											<Text>$ {servicio?.costo?.toMoney(true)}</Text>
										</Col>
										<Col span={5}>
											<Text>$ {servicio?.utilidad?.toMoney(true)}</Text>
										</Col>
										<Col span={5}>
											<Text>$ {servicio?.monto?.toMoney(true)}</Text>
										</Col>
									</Row> : <Row className="text-right">
										<Col span={12}>
											<Text strong className="text-purple">Costo</Text>
										</Col>
										<Col span={6}>
											<Text strong className="text-purple">Utilidad</Text>
										</Col>
										<Col span={6}>
											<Text strong className="text-purple">Total</Text>
										</Col>
										<Col span={12}>
											<Text>$ {servicio?.costo?.toMoney(true)}</Text>
										</Col>
										<Col span={6}>
											<Text>$ {servicio?.utilidad?.toMoney(true)}</Text>
										</Col>
										<Col span={6}>
											<Text>$ {servicio?.monto?.toMoney(true)}</Text>
										</Col>
									</Row>
								}
							</Panel>
						})}
					</Collapse>
				</div>
				<Row>
					<Col xs={12}>
						<Button type="secondary" onClick={()=>this.props.back(this.state.servicios_adquiridos)}>
							Anterior
						</Button>
					</Col>
					<Col xs={12} className="flex-right">
						
						<Button type="primary" onClick={()=>this.saveServicios()}>
							Continuar
						</Button>
						
					</Col>
				</Row>
				<ModalServicioAdquirido
					visible={this.state.modal_visible}
					onClose={()=>{
						this.setState({modal_visible: false, servicio: undefined, index: undefined})
					}}
					addServicio={(values)=>{
						this.addServicio(values)}
					}
					updateServicio={(values) => this.updateServicio(values)}
					servicio={this.state.servicio}
				/>
			</>
		)
	}

}


export default Revision