import React, { Component, useState } from "react";
import {
    Card,
    Row,
    Col,
    Button,
    Space,
    Typography,
    Divider,
    Form,
    Input,
    message,
    Upload,
    Avatar,
    Select,
    Image
} from "antd";
import {
    PlusOutlined,
    DeleteOutlined,
    LoadingOutlined,
    HighlightOutlined,
    CloseOutlined,
    SaveOutlined,
    UploadOutlined,
} from "@ant-design/icons";

import PhoneInput from "../../../Widgets/Inputs/PhoneInput";
import { AvatarLoader } from "../../../Widgets/Uploaders";
import axios from "axios"
import { Uploader } from "../../../Widgets/Uploaders";
const { Text, Paragraph, Title } = Typography;
const moment = require("moment");
const { Option } = Select;

export default class DetallesClientesCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: "Nombre",
            empresa: "Empresa",
            proyectos: [],
            cliente: {
                proyectos: [],
            },
        };
    }

    refCardClientes = React.createRef();

    resizeObserver = null;

    componentDidMount() {
        if (this.props.cliente_id !== undefined) {
            this.getCliente();
        }
    }


    componentDidUpdate() {
        if (this.props.cliente_id !== this.state.cliente_id && this.refCardClientes.current) {
            this.state.cliente_id = this.props.cliente_id;
            if (this.props.cliente_id == null || this.props.cliente_id == undefined)
                return this.refCardClientes.current.resetFields()

            this.getCliente();
        }
    }


    /**
     * @memberof DetallesClientesCard
     * @method getCliente
     * @description Obtiene un Cliente de la DB
     *
     */
    getCliente = () => {
        this.setState({ loading: true });
        axios
            .get(`/cliente/${this.props.cliente_id}`)
            .then(({ data }) => {
                let cliente = data;
                this.setState({
                    cliente,
                    tipo: cliente.tipo,
                    nombre: cliente.nombre,
                    empresa: cliente.empresa,
                    telefono: cliente.telefono,
                    
                });

                this.refCardClientes.current.setFieldsValue({
                    ...cliente,
                    logo:  cliente.logo && typeof cliente.logo !== 'string' ? {
                        uid: cliente.logo.file,
                        name: cliente.logo.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/cliente/" + this.props.cliente_id + "?logo=" + cliente.logo.file +"&Authorization=" + sessionStorage.getItem("token")
                    } : null,
                    documentos: cliente?.documentos?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/cliente/" + this.props.cliente_id + "?documento=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    }))
                    // {
                    //     nombre: cliente.nombre,
                    //     apellido_paterno: cliente.apellido_paterno,
                    //     apellido_materno: cliente.apellido_materno,
                    //     empresa: cliente.empresa,
                    //     telefono: cliente.telefono,
                    //     correo: cliente.correo,
                    //     rfc: cliente.rfc,
                    //     url: cliente.url,
                    //     direccion: cliente.direccion,
                    //     razon_social: cliente.razon_social,
                    // }
                });
            })
            .catch((error) => {
                message.error("Error al traer la cliente");
                console.log(error);
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @memberof DetallesClientesCard
     * @method updateCliente
     * @description Actualiza la información de una Cliente
     *
     */
    updateCliente = (values) => {
        this.setState({ loading: true });
        axios
            .put("/cliente", values)
            .then((response) => {
                message.success("Cliente actualizado");
                this.props.onClose();
            })
            .catch((error) => {
                message.error("Error al actualizar la cliente");
                console.log(error.response);
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @memberof DetallesClientesCard
     * @method addCliente
     * @description Añade una cliente a la BD
     *
     */
    addCliente = (values) => {
        this.setState({ loading: true });
        axios
            .post("/cliente", values)
            .then((response) => {
                message.success("Cliente creado");
                this.props.onClose();
                this.refCardClientes.current.resetFields();
            })
            .catch((error) => {
                message.error("Error al crear el cliente");
                console.log(error);
            })
            .finally(() => this.setState({ loading: false }));
    };


    /**
     *
     * @memberof DetallesClientesCard
     *
     * @method onFinish
     * @description Se ejecuta al hacer submit el formulario
     */
    onFinish = (values) => {

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            logo: undefined,
            documentos: undefined,
            id: this.props.cliente_id
        })

        if(values.logo && !values.logo?.fromDB){
            formData.append("logo", values.logo, values.logo.name)
        }
        values.documentos?.forEach(e => {
            if (!e.fromDB) formData.append("documentos", e, e.name)
        })

        if (this.props.cliente_id) {
            this.updateCliente(formData);
        } else {
            this.addCliente(formData);
        }
    };

    /**
     *
     * @memberof DetallesClientesCard
     *
     * @method submitForm
     * @description Se ejecuta al hacer submit el formulario
     */
    submitForm = () => {
        this.refCardClientes.current.submit();
    };

    render() {
        const { cliente } = this.state;

        return (
            <Card bordered={false}>
                <Form
                    id="form-ref"
                    layout="vertical"
                    size="small"
                    ref={this.refCardClientes}
                    onFinish={this.onFinish}
                    onKeyUp={(event) => {
                        // Enter
                        if (event.keyCode === 13) {
                            this.refCardClientes.current.submit();
                        }
                    }}
                    labelCol={{ span: 24 }}
                // className="w-100"
                >
                    <Space style={{ marginBottom: 16 }}>
                        {this.props.cliente_id ? null : (
                            <Button
                                icon={<SaveOutlined />}
                                type="primary"
                                onClick={() => {
                                    this.submitForm();
                                }}
                            >
                                Guardar y Nuevo
                            </Button>
                        )}
                        <Button
                            icon={<SaveOutlined />}
                            type="primary"
                            onClick={() => {
                                this.submitForm();
                            }}
                        >
                            Guardar
                        </Button>
                        <Button
                            icon={<CloseOutlined />}
                            danger
                            onClick={() => this.props.onClose()}
                            type="primary"
                        >
                            Cerrar
                        </Button>
                    </Space>
                    <Form.Item
                            name="logo"
                        >
                        <AvatarLoader/>
                    </Form.Item>
                    <Form.Item
                        label="Alías"
                        name="alias"
                        className="w-100"
                        extra={<Text style={{ fontSize: 12, opacity: 0.4 }}>De esta forma se identificará al cliente en todo el sistema</Text>}
                    >
                        <Input
                            placeholder="Indique la empresa"
                            bordered={false}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Nombre del Cliente"
                        name="nombre"
                        className="w-100"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input
                            placeholder="Indique el nombre del Cliente"
                            bordered={false}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Apellido Paterno"
                        name="apellido_paterno"
                        className="w-100"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el Apellido"
                        }]}
                    >
                        <Input
                            placeholder="Indique el primer apellido"
                            bordered={false}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Apellido Materno"
                        name="apellido_materno"
                        className="w-100"
                    >
                        <Input
                            placeholder="Indique el segundo apellido"
                            bordered={false}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Nombre o Razón Social"
                        name="empresa"
                        className="w-100"
                        // rules={[{
                        //     required: true,
                        //     message: "Por favor, ingrese el nombre de la empresa"
                        // }]}
                    >
                        <Input
                            placeholder="Indique la empresa"
                            bordered={false}
                        />
                    </Form.Item>
                    <Form.Item label="RFC" name="rfc" className="w-100">
                        <Input placeholder="VECJ880326XXX" bordered={false} />
                    </Form.Item>
                    <Form.Item name="url" label="Sitio:">
                        <Input placeholder="www.syncfy.com" bordered={false} />
                    </Form.Item>
                    <Form.Item name="telefono" label="Teléfono:">
                        <PhoneInput
                            name="telefono"
                            placeholder="Teléfono del cliente"
                            bordered={false}
                        />
                    </Form.Item>
                    <Form.Item name="correo" label="Correo:">
                        <Input placeholder="uno@algo.com" bordered={false} />
                    </Form.Item>
                    <Form.Item name="direccion" label="Dirección:">
                        <Input.TextArea
                            placeholder="Indique la dirección del cliente"
                            bordered={false}
                        />
                    </Form.Item>
                     <Form.Item
                                label="Documentos"
                                name="documentos"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.acreedor_id ? {
                                        method: "PUT",
                                        name: "documentos",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        //action: axios.defaults.baseURL + '/transacciones',
                                        onRemove: e => {
                                            axios.put(`/cliente`, {
                                                documento: e.uid,
                                                id: this.props.cliente_id
                                            })
                                        }
                                    } : {}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>
                </Form>
            </Card>
        );
    }
}
