import React, { Component } from "react";
import { Row, Col, Modal, Form, Checkbox, Input, message, Spin, Select, Space, List, DatePicker, Divider, InputNumber } from 'antd';
import axios from "axios";
import moment from "moment";

//componentes
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalVacaciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            empresas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
            loading: false,
            mostrarProyectos: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        
        if (this.props.vacaciones_id) {
            this.get()
        }
    }




    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/vacaciones/' + this.props.vacaciones_id)
            .then(({ data }) => {
                console.log("data", data);
                let datos = data
                console.log(datos)
                datos.fecha_inicio = datos.vacaciones.fecha_inicio ? moment(datos.vacaciones.fecha_inicio) : null
                datos.fecha_fin = datos.vacaciones.fecha_fin ? moment(datos.vacaciones.fecha_fin) : null
                
                this.formRef.current.setFieldsValue({
                    ...datos,
                })
            }).catch(error => {
                console.log(error)
                message.error('Error al obtener la solicitud')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            id: this.props.vacaciones_id,
            empleado_id: this.props.empleado_id,
        })



        if (this.props.vacaciones_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }

    };


    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/vacaciones', values)
            .then(response => {

                message.success('Vacacion Solicitada!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al crear la solicitud')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Actualiza la informacion de un usuario
    */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/vacaciones', values)
            .then(response => {
                message.success('Vacacion Actualizada!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al actualizar la solicitud')
            }).finally(() => this.setState({ loading: false }))
    }



    render() {

        window.a = this.formRef

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-empleado"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center" gutter={[16, 16]}>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="fecha_inicio"
                                label="Fecha de Inicio"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la fecha de inicio',
                                    },
                                ]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="fecha_fin"
                                label="Fecha de Fin"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la fecha de inicio',
                                    },
                                ]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Vista(props) {

    const { visible = false, onClose = () => { }, vacaciones_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={vacaciones_id ? "Editar Solicitud" : "Crear Solicitud"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-empleado', key: 'form-empleado', htmlType: 'submit' }}
    >
        <ModalVacaciones  {...props} />
    </Modal>

}