import React, { Component, useContext, useEffect, useState } from 'react'
import { Tabs, Typography, Layout, Space, Button, List, Alert, Col, Row, Form, Input, Select, Spin, message, Tag, Card, Badge } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import {
    AndroidOutlined, AppleOutlined, DesktopOutlined, CodeOutlined,
    CloseOutlined, PlayCircleOutlined, ReloadOutlined, ClockCircleOutlined, PauseOutlined, CheckOutlined
} from '@ant-design/icons';


import ChatCliente from '../../Admin/Tickets/ChatCliente'
import Uploader from '../../Widgets/Uploader'

import '../../../Styles/Modules/Public/Tickets.scss'
import { useParams } from 'react-router-dom';
import SocketContext from '../../../Context/Socket';
import Color from 'color'



const { Title, Text } = Typography
const { Content } = Layout
const { Option } = Select

let estatuses = [
    {
        title: "Cancelada",
        color: "#d80700",
        key: "cancelada",
    },
    {
        title: "Nueva",
        color: "#2c59ff",
        key: "en_espera",
    },
    {
        title: "Desarollo",
        color: "#faad14",
        key: "en_proceso",
    },
    {
        title: "Prueba",
        color: "#7b868e",
        key: "en_confirmacion",
    },
    {
        title: "Pausada",
        color: "#4e2e8f",
        key: "pausadas",
    },
    {
        title: "Completada",
        color: "#52c41a",
        key: "completadas",
    },
]

function ListaTickets(props) {
    const socket = useContext(SocketContext)
    const { params, move, onReloadPressed } = props

    const { name, email, project } = params

    const [pagination, setPagination] = useState({
        data: [],

        page: 1,
        limit: 20,

        total: 0,
        pages: 0
    })

    useEffect(() => {
        function onPagination(cPagination) {
            if (pagination.page == 1) {
                setPagination(cPagination)
            } else {
                setPagination({ ...cPagination, data: [...pagination.data, ...cPagination.data] })
            }
        }
 
    }, [])

    return <>
        <Space className='tickets-title' style={{ justifyContent: "space-between", width: "100%" }} >
            <Title level={5}>Lista de Tickets</Title>
            <Button onClick={onReloadPressed} type="link" icon={<ReloadOutlined style={{ color: "currentcolor" }} />}></Button>
        </Space>
        <List
            bordered={false}
            className="list-tickets"
            dataSource={pagination.data}
            renderItem={({ asunto, descripcion, estatus, _id }) => {
                return (
                    <List.Item style={{ border: 'none', padding: '6px 6px 6px 6px',  }} onClick={() => move(1, _id)}>
                        <Badge.Ribbon text={estatuses[estatus].title} color={estatuses[estatus].color}>
                            <Card size='small' style={{
                                background: Color(estatuses[estatus].color).alpha(0.1).hexa(),
                                border: '1px solid ' + estatuses[estatus].color,
                            }}>
                                <Space style={{ width: "100%", justifyContent: "space-between" }}>
                                    <Text strong>{asunto}</Text> 
                                </Space>
                                {/* <RightOutlined /> */}
                            </Card>
                        </Badge.Ribbon>
                    </List.Item>
                )
            }}
        />
        <div className='btn-new-ticket'>
            <Button onClick={() => move(2)} block style={{ marginTop: '1em' }} type='primary' danger size='large'>Nueva Incidencia</Button>
        </div>
    </>

}
function Ticket(props) {

    const { ticket_id, params, move } = props
    const { name, email, project } = params

    return <>
        <ChatCliente
            type="cliente"
            ticket_id={ticket_id}
            style={{ maxHeight: 560, height: 560 }}
            onBack={() => move(0)}
            name={name}
            email={email}
            project={project}
        />
    </>
}

// onSuccess
function TicketForm(props) {
    const { params, move } = props
    let [loading, setLoading] = useState(false)

    const socket = useContext(SocketContext)

    useEffect(() => {
        function success(ticket_id) {
            message.success("Guardado exitosamente")
            move(1, ticket_id)
        }
        function error(error) {
            setLoading(false)
            message.error("No fue posible guardar")
        }
        socket.on("cliente/tickets/add-success", success)
        socket.on("cliente/tickets/add-error", error)
        return () => {
            socket.removeEventListener("cliente/tickets/add-success", success)
            socket.removeEventListener("cliente/tickets/add-error", error)
        }
    }, [])

    const onFinish = (values) => {
        console.log("values", values)
        setLoading(true)
        const { project, name, email } = params
        socket.emit("cliente/tickets/add", {
            ...values,
            evidencias: values.evidencias?.map(e => ({ name: e.name, buffer: e })),
            name,
            email,
            project
        })
    }

    // loading, setLoading
    return <Spin spinning={loading}>
        <Space>
            <Button type="link" icon={<LeftOutlined style={{ color: "currentcolor" }} onClick={() => move(0)} />}></Button>
            <Typography>
                <Title level={5}>Nueva Incidencia</Title>
            </Typography>
        </Space>
        <Form
            onFinish={onFinish}
            style={{ padding: "0 6px" }}>
            <Row className='w-100'>
                <Col span={24}>
                    <Form.Item
                        name="asunto"
                        label="Ingrese el problema de "
                        rules={[
                            {
                                required: true,
                                message: "El Ticket debe de tener un asunto"
                            }
                        ]}
                    >
                        <Input placeholder='No puedo agregar una transacción' size='small' />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="descripcion"
                        label="Describa detalladamente el problema"
                        rules={[
                            {
                                required: true,
                                message: "Indique la descripción del problema"
                            }
                        ]}
                    >
                        <Input.TextArea placeholder='A la hora de intentar agregar una transacción me indica monto  incorrecto.' size='small' autoSize={{ minRows: 1, maxRows: 3 }} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="evidencias"
                        label="Ingrese imagenes, videos o cualquier fuente que ayude a resolver el problema."
                    >
                        <Uploader>
                            <Button type='primary' block size='small'>Subir evidencia</Button>
                        </Uploader>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Indique una serie de pasos para poder reproducir el problema"
                        name="pasos_reproduccion"
                    >
                        <Input.TextArea placeholder='Voy a transacciones, agregar transaccion e indico montos.' size='small' autoSize={{ minRows: 1, maxRows: 3 }} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="plataforma" label="Seleccione las plataformas donde aplique">
                        <Select style={{ width: '100%' }} size='small' placement='topRight'>
                            <Option value={1}>
                                <Space>
                                    <CodeOutlined />Web
                                </Space>
                            </Option>
                            <Option value={2}>
                                <Space>
                                    <AppleOutlined />Apple (iOS)
                                </Space>
                            </Option>
                            <Option value={3}>
                                <Space>
                                    <AndroidOutlined />Google (Android)
                                </Space>
                            </Option>
                            <Option value={4}>
                                <Space>
                                    <DesktopOutlined />Escritorio
                                </Space>
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Button htmlType='submit' block style={{ marginTop: '1em' }} type='primary' danger size='large'>Aceptar Incidencia</Button>
        </Form>
    </Spin>
}



/**
 * 
 * @class Login
 * @extends {Component}
 * @description Componente Login para inicio de sesion
 */
class TicketsWidget extends Component {

    state = {
        activeView: 0,
        ticket_id: null,
    }

    onReloadPressed = () => {
        // window.parent.reload()
    }

    move = (activeView, ticket_id = null) => {
        this.setState({ activeView, ticket_id })
    }

    render() {
        const views = [
            <ListaTickets
                move={this.move}
                onReloadPressed={this.onReloadPressed}
                {...this.props}
            />,
            <Ticket
                move={this.move}
                onReloadPressed={this.onReloadPressed}
                ticket_id={this.state.ticket_id}
                {...this.props}
            />,
            <TicketForm
                move={this.move}
                onReloadPressed={this.onReloadPressed}
                {...this.props}

            />
        ]

        return <div className='widget-tickets'>
            {views[this.state.activeView]}
        </div>

    }
}

export default function (props) {
    return <TicketsWidget {...props} params={useParams()} />
}
