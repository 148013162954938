import { Avatar, Button, Form, Input, Space, Typography, Dropdown, Card, Upload, message, Progress, Image } from "antd";
import React from "react"
import { DeleteOutlined, FileOutlined, LeftOutlined, ReloadOutlined, SendOutlined, SmileOutlined } from "@ant-design/icons"

import Socket from "../../../Context/Socket"
import Logged from "../../../Hooks/Logged"
import moment from "moment"

import EmojiPicker from 'emoji-picker-react';


import SocketIOFileClient from 'socket.io-file-client'

// import SocketIOFileUpload from 'socketio-file-upload/client';


import "../../../Styles/Modules/Tickets/Chat.scss"
import axios from "axios";


const { Text, Title, Paragraph } = Typography

class ChatApp extends React.Component {


    static contextType = Socket

    static defaultProps = {
        type: "admin"
    }


    constructor(props) {
        super(props);
        this.state = {
            messages: {

                data: [],
                page: 1,
                limit: 30,

                pages: 0,
                total: 0
            },

            message: '',
            filesUploaded: {},

            shiftKey: false,
        };
    }

    componentDidMount() {
        document.addEventListener('keyup', this.keyUp);
        document.addEventListener('keydown', this.keyDown);


        this.context.on("/admin/tickets/chat", this.getChat)
        this.context.on("/tickets/chat/add", this.addMessage)


        this.context.emit("/admin/tickets/chat", {
            ticket_id: this.props.ticket_id,
            page: this.state.messages.page,
            limit: this.state.messages.limit,
        })

        let uploader = new SocketIOFileClient(this.context);

        // uploader.on('start', function (fileInfo) {
        //     console.log('Start uploading', fileInfo);
        // })
        uploader.on('stream', (file) => {
            let { filesUploaded } = this.state
            filesUploaded[file.uploadId].progress = (file.size / file.sent) * 100
            this.setState({ filesUploaded })
        })
        uploader.on('complete', (file) => {
            let { filesUploaded } = this.state
            filesUploaded[file.uploadId].progress = 100
            filesUploaded[file.uploadId].filename = file.name
            this.setState({ filesUploaded })
        })
        uploader.on('error', function (err) {
            console.log('Error!', err);
            message.error("No fue posible subir el archivo")
        })
        uploader.on('abort', function (fileInfo) {
            console.log('Aborted: ', fileInfo);
            message.error("El proceso fue abortado")
        })
        this.uploader = uploader
    }

    componentWillUnmount() {
        this.context.emit("/admin/tickets/chat/off", this.props.ticket_id)
        this.context.removeListener('/admin/tickets/chat')
        this.context.removeListener('/tickets/chat/add')
    }

    keyUp = (e) => (e.shiftKey == false && this.state.shiftKey == true) ? this.setState({ shiftKey: false }) : null
    keyDown = (e) => (e.shiftKey == true && this.state.shiftKey == false) ? this.setState({ shiftKey: true }) : null

    getChat = (response) => {


        if (response.page > 1)
            response.messages = [...this.state.messages.data, ...response.messages]

        this.setState({ messages: response, ticket: response.ticket })
    }
    // formatMessage = ({ texto, _id }) => ({ text: texto, id: _id, me: true })

    addMessage = (message) => {
        let { messages } = this.state
        messages.data = [
            message,
            ...messages.data,
        ]
        this.setState({ messages: { ...messages } })

    }

    handleSubmit = ({ message }) => {
        let filesUploaded = Object.values(this.state.filesUploaded)

        if (filesUploaded.filter(file => file.progress < 100).length > 0)
            return message.error("Debe esperar a que se suban todos los archivos")

        if ((message && message.length > 0) || filesUploaded.length > 0) {

            this.formRef.setFieldsValue({ message: "" })
            this.setState({ filesUploaded: {} })
            this.context.emit("/admin/tickets/chat/add", {
                ticket_id: this.props.ticket_id,
                texto: message,
                archivos: filesUploaded.map(({ filename, file }) => ({ file: filename, name: file.name }))
            })
        }
    }

    uploadFile = (file) => {
        let [_id] = this.uploader.upload([file.file]);
        this.setState(state => {
            state.filesUploaded[_id] = {
                ...file,
                progress: 0,
                uploadId: _id,
                isImage: Boolean(/\.(png|jpe?g|gif|webp|svg)(\?.*)?$/.test(file.file.name))
            }
            return state
        })
    }

    deleteFile = ({ filename, uploadId }) => {
        this.context.emit("/admin/tickets/chat/delete/file", { filename })
        this.setState(state => {
            delete state.filesUploaded[uploadId]
            return state
        })
    }

    renderMessage = ({ texto, _id, autor, fecha, archivos }) => {

        let me = (this.props.user?._id == autor?._id) || (this.props.email && !autor)


        return <div className={"message " + (me ? "me" : "")} key={_id} style={me ? { justifyContent: "flex-end" } : {}}>
            {(!me ? <Avatar  >{this.props.nombre || this.props.user?.nombre}</Avatar> : null)}
            <div class="message-body">
                {me ? <Title level={5} style={{ color: "currentcolor", display: "inline-block" }}>{this.props.nombre || this.props.user?.nombre}</Title> : null}
                <Paragraph style={{ color: "currentcolor", marginBottom: 0 }}>{texto}</Paragraph>
                <Space style={{ width: "100%" }} direction="vertical">
                    {archivos?.map(e => Boolean(/\.(png|jpe?g|gif|webp|svg)(\?.*)?$/.test(e.file)) ? <Image style={{ maxWidth: 200 }} src={axios.defaults.baseURL + "/upload/customer-chat/" + e.file} className="mb-1" /> : <Button size="small" target="_blank" download href={axios.defaults.baseURL + "/upload/customer-chat/" + e.file} block >{e.name}</Button>)}
                </Space>
                <Text style={{
                    opacity: 0.5,
                    width: "100%",
                    color: "currentcolor", display: "inline-block", textAlign: "end",
                    fontSize: 11,
                }}>{moment(fecha).format("LL HH:MM")})</Text>
            </div>
        </div>
    }

    render() {
        const { style } = this.props

        let filesUploaded = Object.values(this.state.filesUploaded)

        return (<>
            <div class="chat" style={style}>
                <div class="messages">
                    <div class="messages-history">
                        {this.state.messages.data.map(this.renderMessage)}
                    </div>
                    {(filesUploaded.length > 0) ? <Space className="chat-form-file">
                        {filesUploaded.map(e => <Card className="chat-form-file-card" size="small">
                            <div className="hover-content">
                                {console.log("e", e)}
                                <Button onClick={() => this.deleteFile({ uploadId: e.uploadId, filename: e.filename })} type="primary" className="button-delete-file" danger icon={<DeleteOutlined style={{ color: "currentcolor" }} />} />
                            </div>
                            {(e.progress < 100) ? <Progress
                                style={{
                                    position: 'absolute',
                                    top: 30,
                                    width: 76
                                }}
                                type="line"
                                percent={e.progress}
                                showInfo={false}
                                size="small"
                                strokeLinecap="round"
                            /> : (
                                e.isImage ? <Avatar className="chat-form-file-image" shape="square" src={axios.defaults.baseURL + "/upload/customer-chat/" + e.filename} /> : <FileOutlined className={"chat-form-file-image-icon"} />
                            )}
                            <br />
                            <Text style={{ fontSize: 10 }}>{e?.file?.name?.substring(0, 10)}</Text>
                        </Card>)}
                    </Space> : null}

                    <Form
                        style={{ background: "white" }}
                        className="chat-form"
                        ref={e => this.formRef = e}
                        onFinish={this.handleSubmit}>
                        <Dropdown
                            forceRender={true}
                            overlay={<EmojiPicker
                                onEmojiClick={e => {
                                    console.log("e", e)
                                    this.formRef.setFieldsValue({ message: (this.formRef.getFieldValue("message") ?? "") + e.emoji })
                                }}
                            />} trigger={"click"}>
                            <Button type="link" icon={<SmileOutlined style={{ color: "currentcolor" }} />} ></Button>
                        </Dropdown>
                        <Form.Item name="message" noStyle>
                            <Input.TextArea
                                style={{ flex: "0 0 1", }}
                                bordered={false}
                                autoSize={{ minRows: 1 }}
                                placeholder={"Envia un mensaje..."}
                                onPressEnter={e => {
                                    if (this.state.shiftKey == false) {
                                        e.preventDefault()
                                        this.formRef.submit()
                                    }
                                }}
                            />
                        </Form.Item>
                        <Upload
                            multiple
                            showUploadList={false}
                            customRequest={this.uploadFile}>
                            <Button type="link" icon={<FileOutlined style={{ color: "currentcolor" }} />} ></Button>
                        </Upload>
                        <Button htmlType="submit" type="link" icon={<SendOutlined style={{ color: "currentcolor" }} />} ></Button>
                    </Form>
                </div>
            </div>
        </>
        );
    }
}


export default function (props) {

    return <ChatApp {...props} user={React.useContext(Logged)} />
}