import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from 'axios';

const { Option } = Select 


/**
 * @const SelectTransaccionProgramada
 * @description Select para las transacciones programadas, dependiendo del cliente, inversion o acreedor 
 */
const SelectTransaccionProgramada = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione la transacción programada a pagar", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},

        cliente_id,

    } = props


    const [ transacciones, setTransacciones ] = useState({
        data: [],
        page: 1,
        limit: 100,
        total: 0,
        search: null,
    })


    /**
     * @const getProgramadas
     * @description Obitiene las transacciones
     */
    const getProgramadas = ({
    	page = transacciones.page,  
    	limit = transacciones.limit, 
    	search
    } = transacciones) => {

        axios.get('/transacciones-programadas', {
            params: {
                page,
                limit,
                search,
                cliente_id,
                pendientes: true,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setTransacciones(data)
        }).catch(error => {
            message.error(error?.response?.data?.message + " (transacciones programadas)" ?? 'Error al obtener las transacciones programadas')
        })
    }


    //componentDidMount
    useEffect(() => { 
        getProgramadas()
    }, [])

    //componentDidUpdate
    useEffect(() => { 
        if(cliente_id)
            getProgramadas()
    }, [cliente_id])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getProgramadas({search})}
            onSelect={(transaccion)=> {
                onChange(transaccion.value)
                onSelect(transaccion.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {transacciones?.data?.map(({ _id, concepto, monto_pagado, monto_pendiente, monto, folio }) => <Option value={_id} >
                <div className="flex-between" style={{ justifyContent: "space-between"}}>
                    <span>{concepto ?? ""} <small>{folio}</small></span>
                    <i>{monto_pendiente?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}/{monto?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</i>
                    
                </div>
            </Option>)}
        </Select>
    );
}



export default SelectTransaccionProgramada;