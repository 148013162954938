import React from 'react'
import { Route, Routes } from "react-router-dom";

import Proveedores from '../components/Admin/Proveedores/Proveedores';

/**
 * 
 * @export
 * @function RouterRazones
 * @description Router for Razones routes 
 */
function RouterRazones(props) {
  return (
    <Routes>
      <Route exact path="" element={<Proveedores {...props} />} />
    </Routes>
  )
}

export default RouterRazones