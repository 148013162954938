import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from "axios"
import CustomAvatar from "../Avatar/Avatar";
const { Option } = Select 

/**
 * @const SelectPlantilla
 * @description Select para las plantillas registradas en el sistema
 */
const SelectPlantilla = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione la plantilla", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        proyecto= {},
        cliente_id
    } = props

    const [ plantillas, setPlantillas ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })

    /**
     * @const getPlantillas
     * @description Obitiene los plantillas
     */
    const getPlantillas = ({
        page = plantillas.page, 
        limit = plantillas.limit, 
        search
    } = plantillas) => {
        axios.get('/plantillas', {   
            params: {
                page,
                limit,
                search,
                cliente_id,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setPlantillas(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener las plantillas')
        })
    }

    //componentDidMount
    useEffect(() => {
        getPlantillas()
    }, [])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getPlantillas({search})}
            onSelect={(plantilla)=> {
                onChange(plantilla.value)
                onSelect(plantilla.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                plantillas?.data?.map(({ _id, nombre, horas_aproximadas }) => <Option value={_id} key={_id}>
                    {nombre ?? ""} <small>{horas_aproximadas ?? 0} hrs.</small>
                </Option>)
            }
        </Select>
    );
}



export default SelectPlantilla;