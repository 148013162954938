import React, { Component } from 'react';
import { Card, Row, Col, Typography, Progress, Button, InputNumber, Form, Popconfirm, message } from 'antd'
import axios from "axios";
import { FlagFilled } from "@ant-design/icons";
import { DeleteOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const moment = require("moment");

const ServicioForm = (props) => {

	let {
        _id,
		costo = 0,
    	monto = 0,
        utilidad = 0,
        servicio_id = {
            nombre: "-"
        },
        horas_estimadas = null,
        updateAll = () => {},
    } = props;

    return <Form
        initialValues={{
            costo: costo,
            monto: monto
        }}
        onValuesChange={(value, allValues) => {
            props.onChangeServicio(allValues)
        }}
    > 
        <Row gutter={[8,0]} className="row-servicio first">
            <Col xs={7} className="center">
                <Text>{servicio_id.nombre}</Text>
            </Col>
            <Col xs={3} className="center">
                <Text>{horas_estimadas ? `${horas_estimadas} hrs` : '-'}</Text>
            </Col>
            <Col xs={4} className="center">
                <Form.Item
                    name="costo"
                    className="m-0"
                >
                    <InputNumber
                        size="small"
                        className="width-100"
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                </Form.Item>
            </Col>
            <Col xs={4} className="center">
                <Form.Item
                    name="monto"
                    className="m-0"
                >
                    <InputNumber
                        size="small"
                        className="width-100"
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                </Form.Item>
            </Col>
            <Col xs={5} className="center">
                <Text>$ {utilidad.toMoney(true)} mxn</Text>
            </Col>
            <Col xs={1} className="center">
                <Popconfirm
                    placement="topRight"
                    title="¿Deseas eliminar este servicio?"
                    onConfirm={() => axios.delete('/project-manager/delete/servicios/'+_id)
                        .then((response) => {
                            message.success("Servicio eliminado")
                            updateAll()
                        }).catch(error => console.log(error))}
                    okText="Si"
                    cancelText="No"
                >
                    <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        title="Eliminar"
                        danger
                        size="small"
                    />
                </Popconfirm>
            </Col>
        </Row>
    </Form>
}

export default ServicioForm