import React from 'react';
import { Redirect, Route, useNavigate } from 'react-router-dom';
import axios from "axios"


import User from '../../Hooks/Logged';


/**
 *
 *
 * @const PrivateRoute
 * @description Restringe el acceso a las rutas privadas si no se ha iniciado sesion
 */
const PrivateRoutes = ({children }) => {

    let user = React.useContext(User)
    const navigate = useNavigate();

    axios.interceptors.response.use(function (response) {
    
        return response
    }, function (error) {
        console.clear()
        if(error?.response?.data?.name === "TokenExpiredError")
            window.location.href = '/login';
        return Promise.reject(error);
    })


    if(user || sessionStorage.getItem("token"))
        return (children )
    else{
        window.location.href = '/login'
        //navigate("/login")
        return null
        
    }

}
       

export {
    PrivateRoutes
}