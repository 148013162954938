import React, { Component, useContext } from "react";
import { Row, Col, Button, Modal, Form, Input, message, Upload, Spin, Image, Select, DatePicker, Typography, Checkbox, Drawer, Space, Popconfirm, Progress, Comment, Tooltip } from 'antd';
import axios from "axios"
import { PlusOutlined,  DeleteOutlined, LoadingOutlined, FileSearchOutlined, EditOutlined } from '@ant-design/icons';


//Componentes
import User from "../../../../Hooks/Logged";
import { estatus_modulo } from "../../../Utils"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { SelectModulo } from "../../../Widgets/Inputs/Selects";

//Modal
import ModalActualizacion from "./ModalActualizacion"

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text, Title } = Typography;
const moment = require("moment")

class DrawerActualizacion extends Component {


    constructor(props) {
        super(props);
        this.state = {
            avance: 0,
            actualizaciones:{
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },
            loading: false,
            modal_visible: false,
            flag: false,

        }
    }

    ModalModulo = React.createRef();

    componentDidMount() {
        if(this.props.modulo_proyecto_id){
            this.getActualizaciones()
        }
    }


    /**
     * @memberof ModalCuentas
     * @method getActualizaciones
     * @description Obtiene informacion del modulo
     */
    getActualizaciones = () => {
        this.setState({loading: true})
        axios.get("/actualizaciones",{
            params:{
                modulo_proyecto_id: this.props.modulo_proyecto_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                actualizaciones: response.data
            })
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las actividades")
        }).finally(()=>this.setState({loading: false}))
    }

    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Row justify="center">

                    <Col span={23}>

                        {this.state.actualizaciones.data.length === 0 ? <Title level={5} className="text-center mt-1 mb-1">Sin actualizaciones</Title> : null}

                        { this.state.actualizaciones.data.map( actualizacion => <Row className="pb-1 pt-1" gutter={[16,0]} style={{borderBottom: "1px #d5d5d5 solid"}}>
                            
                            <Col md={24}>
                                <Comment
                                    actions={this.props.user?._id?.toString() === actualizacion?.realizado_por?._id ? [
                                        <Tooltip key="comment-basic-like" title="Editar">
                                            <span onClick={()=>this.setState({modal_visible: true, actualizacion_id: actualizacion._id })}>
                                                <EditOutlined/>
                                            </span>
                                        </Tooltip>,
                                        <Popconfirm
                                            placement="topRight"
                                            title="¿Deseas eliminar este actualizacion?"
                                            onConfirm={() => axios.delete('/actualizacion/' + actualizacion._id).then((response) => {
                                                message.success('Actividad Eliminada')
                                                this.getActualizaciones()
                                            }).catch((error) => {
                                                message.success('Actividad NO Eliminada')
                                                this.getActualizaciones();
                                            })
                                            }
                                            okText="Si"
                                            cancelText="No"
                                        >
                                            <DeleteOutlined/>
                                        </Popconfirm>
                                    ] : []}
                                    author={<a>{actualizacion?.realizado_por?.nombre} {actualizacion?.realizado_por?.apellidos ?? ""}</a>}
                                    avatar={<CustomAvatar 
                                                name={`${actualizacion?.realizado_por?.nombre} ${actualizacion?.realizado_por?.apellidos ?? ""}`}  
                                            />}
                                    content={<><p className="mb-1"> {actualizacion.descripcion} </p>
                                        <div>
                                            {actualizacion?.archivos?.map(image => image.file?.match(/\.(jpeg|jpg|gif|png)$/i) ? <Image
                                                width={50}
                                                src={`${axios.defaults.baseURL + "/actualizacion/" + actualizacion._id + "?archivo=" + image.file +"&Authorization=" + sessionStorage.getItem("token")}`}
                                            /> :<><br/> <a target="_blank" href={`${axios.defaults.baseURL + "/actualizacion/" + actualizacion._id + "?archivo=" + image.file +"&Authorization=" + sessionStorage.getItem("token")}`}>{image.name}</a></>)}
                                        </div>
                                    </>}
                                    datetime={moment(actualizacion.createdAt).format("DD/MM/YYYY HH:mm")}
                                />
                            </Col>
                        </Row>)}
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={23} className="flex-right mt-1">
                        <Button
                            onClick={()=>this.setState({modal_visible: true})}
                        >
                            Agregar Actualización
                        </Button>
                    </Col>
                </Row>

                <ModalActualizacion
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false, actualizacion_id: undefined})
                        if(flag === true){
                            this.getActualizaciones()
                        }
                    }}
                    modulo_proyecto_id={this.props.modulo_proyecto_id}
                    actualizacion_id={this.state.actualizacion_id}
                />
            </Spin>
        )
    }
}



export default function Modals(props) {

    const user = useContext(User);


    const { visible = false, onClose = () => { }, modulo_id } = props

    return <Drawer
        visible={visible}
        onClose={onClose}
        title={"Actualizaciones del módulo"}
        destroyOnClose={true}
        //size="large"
    >
        <DrawerActualizacion {...props} user={user}/>
    </Drawer>

}