import React, { Component } from 'react';
import { Row, Col, Form, Input, Typography, DatePicker, Button } from 'antd'


//componentes
import { ImagesUploader } from "../../../Widgets/Uploaders";
import { SelectCliente, SelectUsuario, SelectProyecto } from "../../../Widgets/Inputs/Selects";

const { Title } = Typography;
const moment = require("moment");

/**
 * @export
 * @class Requerimientos
 * @extends {Component}
 * @description Vista de Requerimientos
 */
class Requerimientos extends Component {


	constructor(props){
		super(props);
		this.state = {

		}
	}

	refRequerimientos = React.createRef()

	componentDidMount(){
		if(this.props.requerimientos){
			this.refRequerimientos.current.setFieldsValue({
				...this.props.requerimientos
			})
		}
	}


	 /**
     * @memberof Requerimientos
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario, pasa al sig step
     */
	onFinish = (values) => {

		if(this.props.requerimientos){
			values.servicios_adquiridos = Array.isArray(this.props.requerimientos.servicios_adquiridos) ? this.props.requerimientos.servicios_adquiridos : []
		}

		this.props.onSubmit(values)
	}


	/**
     * @memberof Requerimientos
     * @method onSelectProyecto
     * @description Pone en el state el proyecto seleccionado
     */
	onSelectProyecto = (proyecto_id) => {
		this.setState({proyecto_id})

	}

	render(){
		return(
			<>
				<Title level={5}>Requerimientos</Title>
				<Form
					layout="vertical"
					onFinish={this.onFinish}
					ref={this.refRequerimientos}
					initialValues={{
						fecha: moment()
					}}
				>
					<Row gutter={[16,0]}>
						<Col xs={24} md={8}>
							<Form.Item
								label="Nombre"
								name="nombre"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
							>
								<Input
									placeholder="Nombre del proyecto"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Asesor"
								name="asesor_id"
								rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el asesor"
                                }]}
							>
								<SelectUsuario/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Fecha Venta"
								name="fecha"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
							>
								<DatePicker
									className="width-100"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={8}>
							<Form.Item
								label="Cliente"
								name="cliente_id"
								// rules={[{
                                //     required: true,
                                //     message: "Por favor, seleccione el cliente"
                                // }]}
							>
								<SelectCliente/>
							</Form.Item>
						</Col>
						{ !this.state.proyecto_id ?  <Col xs={24} md={8}>
							<Form.Item
								label="Dominio"
								name="dominio"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el dominio"
                                }]}
							>
								<Input placeholder="https://ejmplo.com"/>
							</Form.Item>
						</Col> : null }
						<Col xs={24} md={8}>
							<Form.Item
								label="Proyecto"
								name="proyecto_id"
								// rules={[{
                                //     required: true,
                                //     message: "Por favor, seleccione el cliente"
                                // }]}
							>
								<SelectProyecto
									onSelect={(proyecto_id)=>this.onSelectProyecto(proyecto_id)}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} >
							<Form.Item
								label="Descripción"
								name="descripcion"
							>
								<Input.TextArea/>
							</Form.Item>
						</Col>
						<Col xs={24} >
							<Form.Item
								label="Documentos"
								name="documentos"
							>
								<ImagesUploader/>
							</Form.Item>
						</Col>
						<Col xs={24} className="flex-right">
							<Form.Item>
      							<Button type="primary" htmlType="submit">
        							Continuar
      							</Button>
    						</Form.Item>
						</Col>
					</Row>
				</Form>
			</>
		)
	}

}


export default Requerimientos