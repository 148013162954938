import React from 'react';
import { Card, Row, Col, Typography, Progress, Button } from 'antd'
import { FlagFilled, EditOutlined } from "@ant-design/icons";

const { Text } = Typography;
const moment = require("moment");

const CardProyecto = (props) => {

	let {
		nombre = "-",
    	cliente_id = {},
    	createdAt = null,
	} = props;

    return <Card title={nombre} className="card-project card-shadow">
        <Card.Meta
            description={<p><FlagFilled /> <span className='mb-1'>{moment(createdAt).fromNow(true)}</span> <small>({moment(createdAt).format("LLL")})</small></p>}
        />
        <Row className="mb-1 mt-1">
            <Col span={24} className="card-project-info">{cliente_id?.nombre ?? "-"}</Col>
            <Col span={24} className="card-project-info">{cliente_id?.razon_social ?? "-"}</Col>
            <Col span={24} className="card-project-info">{cliente_id?.telefono ?? "-"} /<span>{cliente_id?.email ?? "-"}</span></Col>
        </Row>
    </Card>
}


const CardTiempos = (props) => {

	let {
		horas_estimadas = 0,
        fecha_venta
	} = props;


    function calcularFechaFinal(horas) {
        const horasPorDia = 10;

        const fechaActual = moment(fecha_venta);
        let fechaFinal = moment(fechaActual); // Copia de la fecha actual

        while (horas > 0) {
            if (fechaFinal.day() !== 6 && fechaFinal.day() !== 0) { // 6 es sábado, 0 es domingo
                horas -= horasPorDia;
            }
            fechaFinal.add(1, 'day'); // Avanza un día
        }

        return fechaFinal.format('DD-MMMM');
    }

    return <Card title={"Tiempos Estimados"} className="card-project">
        <Row className="">
            <Col span={24} className="flex-between">
            	<Text>
            		Estimación de Tiempo
            	</Text>
            	<small>{horas_estimadas.toMoney(true)} horas</small>
            </Col>
            <Col span={24} className="flex-between">
            	<Text>
            		Tiempo de Entrega <small className="text-gray">aprox</small>
            	</Text>
            	<small>{calcularFechaFinal(horas_estimadas)}</small>
            </Col>
        </Row>
    </Card>
}


const CardUtilidades = (props) => {

	let {
        costo_total = 0,
        monto_venta = 0
	} = props;

    let utilidad = 0, margen = 0
    if(monto_venta !== 0){
        utilidad = monto_venta - (costo_total)
        margen = (utilidad / monto_venta) * 100
    }

    return <Card title={"Utilidades"} className="card-project">
        <Row className="">
            <Col span={24} className="flex-between">
                <Text>
                    Monto Venta
                </Text>
                <small>$ {monto_venta?.toMoney(true)} mxn</small>
            </Col>
            <Col span={24} className="flex-between">
            	<Text>
            		Utilidad Bruta
            	</Text>
            	<small>$ {utilidad.toMoney(true)} mxn</small>
            </Col>
            <Col span={24} className="flex-between">
            	<Text>
            		Margen de Utilidad
            	</Text>
            	<small>{margen.toMoney(true)} %</small>
            </Col>
            <Col span={23} className="flex-between">
            	<Progress percent={margen.toMoney(true)} showInfo={false}/>
            </Col>
        </Row>
    </Card>
}

const CardEstimacion = (props) => {

	let {
        costo_hora = 0,
        costo_total = 0,
        onClick = () => {}, 
	} = props;

    return <Card title={"Estimación de Costos"} className="card-project">
        <Row className="">
            <Col span={24} className="flex-between">
            	<Text>
            		Costo Actual
            	</Text>
            	<small>$ {(costo_total).toMoney(true)} mxn</small>
            </Col>
            <Col span={24} className="flex-between">
            	<Text>
            		Impuestos
            	</Text>
            	<small>$ 0 mxn</small>
            </Col>
            <Col span={24} className="flex-between">
            	<Text>
            		Costo Total
            	</Text>
            	<small>$ {(costo_total).toMoney(true)} mxn</small>
            </Col>
            <Col span={24} className="flex-between">
            	<Text>
            		Costo por hora <EditOutlined 
                        className="hoverable"
                        onClick={()=>onClick()}
                    />
            	</Text>
            	<small>$ {costo_hora.toMoney(true)} mxn</small>
            </Col>
            
        </Row>
    </Card>
}

const CardEstatus = (props) => {

	let {
		estatus = 0,
	} = props;

    let estado = {
        0: "Cotizando",
        1: "Enviado",
        2: "Aprobada",
        3: "En proceso",
        4: "Completado",
    }

    return <Card title={"Estatus"} className="card-project">
        <Row className="">
            <Col span={24} className="flex-between">
            	<Text>
            		Enviado
            	</Text>
            	<small>-</small>
            </Col>
            <Col span={24} className="flex-between">
            	<Text>
            		Firmada
            	</Text>
            	<small>-</small>
            </Col>
            <Col span={24} className="flex-between">
            	<Text className="text-purple" strong>
            		{estado[estatus]}
            	</Text>
            </Col>
            <Col span={24} className="flex-right">
            	<Button>Enviar a firmar</Button>
            </Col>
            
        </Row>
    </Card>
}

export {	
	CardProyecto,
	CardTiempos,
	CardUtilidades,
	CardEstimacion,
	CardEstatus
}