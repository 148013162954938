import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin } from 'antd';
import { ControlFilled } from "@ant-design/icons";

import ColorPicker from '../../Widgets/ColorPicker';
import axios from "axios"

const { Title } = Typography;



class ModalRubros extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalRubros = React.createRef();

    componentDidMount() {
         ;
        if (this.props.rubro_id !== undefined) {
            this.getRubro()
        }
    }


    /**
     * @memberof ModalRubros
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        this.setState({loading: true})
        if (this.props.rubro_id) {
            this.updateRubro(values)
        } else {
            this.addRubro(values)
        }
    }


    /**
     * @memberof ModalRubros
     * @method addRubro
     * @description Añade un rubro a la BD
     */
    addRubro = (values) => {
        axios.post('/rubro', {
            area_id: this.props.area_id,
            ...values
        }).then(response => {
            message.success('Rubro creado')
            this.props.onClose()
           
        }).catch(error => {
            console.log(error)
            message.error(error?.response?.data?.message ?? 'Error al crear rubro.')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof ModalRubros
     * @method getRubro
     * @description Obtiene un rubro de la DB
     */
    getRubro = () => {

        this.setState({ loading: true })

        axios.get('/rubro/' + this.props.rubro_id)
        .then(({ data }) => {
            this.setState(data)
            this.ModalRubros.current.setFieldsValue(data)

        }).catch(error => {
            message.error('Error al traer rubro')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalRubros
     * @method updateRubro
     * @description Actualiza la información de un rubro
     */
    updateRubro = (values) => {
        this.setState({ loading: true })
        axios.put('/rubro', {
            ...values,
            id: this.props.rubro_id,
        }).then(response => {
            message.success('Rubro Actualizado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar rubro')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                name="form-rubro"
                id="form-rubro"
                layout="vertical"
                ref={this.ModalRubros}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción" />

                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Color"
                                name="color"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un color"
                                }]}
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}

        title={props.rubro_id ? "Editar Rubro" : "Crear Rubro"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-rubro', key: 'submit', htmlType: 'submit' }}
    >
        <ModalRubros {...props} />
    </Modal>

}