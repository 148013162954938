import React, { Component } from 'react';
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { PageHeader, Layout, Row, Col, message } from 'antd'

//componentes
import MatrizCostos from "./MatrizCostos";
import ProjectTracker from "./Cards/ProjectTracker";
import { IconArrowBack } from '../../Widgets/Iconos';
import { CardProyecto, CardTiempos, CardUtilidades, CardEstimacion, CardEstatus } from "./Cards/Cards";

//modales
import ModalCosto from "./ModalCosto";

//css
import '../../../Styles/Modules/ProjectManager/projectManager.css'

const { Content } = Layout;

/**
 * @export
 * @class ProjectManager
 * @extends {Component}
 * @description Vista de projectManager
 */
class ProjectManager extends Component {

	back = () => {
        this.props.navigate(-1);
    }


	constructor(props){
		super(props);
		this.state = {
			proyecto: {
				horas_estimadas: 0
			},
			cotizacion_id: this.props.params.cotizacion_id,
			cotizacion: {},
			servicios: [],
		}
	}

	refMatriz = React.createRef()

	componentDidMount(){
		if(this.props.params.cotizacion_id){
			this.getCotizacion()
		}
	}

	/**
     * @memberof ModalAreas
     * @method getCotizacion
     * @description Obtiene la cotizacion con toda la informacion general
     */
	getCotizacion = () => {
		this.setState({loading: true})
		axios.get('/project-manager/cotizacion/info',{
			params:{
				cotizacion_id: this.props.params.cotizacion_id
			}
		}).then(({data}) => {
			this.setState({
				proyecto: data.proyecto,
			})

		}).catch(error => {
			message.error(error?.response?.data?.message ?? "Error al obtener la información")
		}).finally(()=>this.setState({loading: false}))
	}

	render(){
		return(
			<>
				<PageHeader
					className="site-page-header custom-page-header"
                    title="Project Manager"
                    onBack={this.back}
                    backIcon={<IconArrowBack />}
				/>
				<Content className="admin-content content-bg pd-1">
					<Row gutter={[16,16]}>
						<Col xs={0} md={0} lg={0} xl={7} xxl={6} className="">
							<ProjectTracker {...this.state.proyecto} />
						</Col>
						<Col xs={24} md={24} lg={24} xl={17} xxl={18} className="">
							<Row gutter={[16,16]}>
								<Col xs={24} md={12} xxl={8}>
									<Row gutter={[0,16]}>
										<Col span={24}>
											<CardProyecto
												{
													...this.state.proyecto
												}
											/>
										</Col>
										<Col span={24}>
											<CardTiempos
												{...this.state.proyecto}
											/>
										</Col>
									</Row>
								</Col>
								<Col xs={24} md={12} xxl={8}>
									<Row gutter={[0,16]}>
										<Col span={24}>
											<CardUtilidades
												{...this.state.proyecto}
											/>
										</Col>
										<Col span={24}>
											<CardEstimacion 
												{...this.state.proyecto}
												onClick={()=>this.setState({modal_visible: true})}
											/>
										</Col>
									</Row>
								</Col>
								<Col xs={24} md={12} xxl={8}>
									<Row gutter={[0,16]}>
										<Col span={24}>
											<CardEstatus/>
										</Col>
									</Row>
								</Col>
								<Col xs={24}>
									<MatrizCostos
										ref={this.refMatriz}
										getCotizacion={this.getCotizacion}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
				<ModalCosto
					visible={this.state.modal_visible}
					onClose={(flag) => { 
						this.setState({modal_visible: false})
						if(flag){
							this.getCotizacion()
							if(this.refMatriz.current){
								this.refMatriz.current.getCotizacion()
							}
						}
					}}
					values={{
						costo_hora: this.state?.proyecto?.costo_hora
					}}
					proyecto_id={this.state.proyecto._id}
				/>
			</>
		)
	}

}


export default function Componente () {

    return <ProjectManager params={useParams()} navigate={useNavigate()}/>
}