import React, { Component } from "react";
import { Steps, Modal, Spin, message } from 'antd';
import axios from "axios"

//Compoenentes
import Revision from "./Secciones/Revision";
import Servicios from "./Secciones/Servicios";
import Requerimientos from "./Secciones/Requerimientos";

const { Step } = Steps

class ModalCotizacion extends Component {


    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            loading: false,
            values: {},
            proyecto_id: undefined,
            cotizacion_id: undefined,
        }
    }

    refModalCotizacion = React.createRef();

    componentDidMount() {
    }

    /**
     * @memberof ModalCotizacion
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        
    }


    /**
     * @memberof ModalCotizacion
     * @method onSubmit
     * @description Se ejecuta al terminar la primera parte del formualrio, Guarda o actualiza SOLO la informacion de los requisitos
     * 
     */
    onSubmit = (values) => {

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            documentos: undefined,
            proyecto_id: this.state.proyecto_id,
            cotizacion_id: this.state.cotizacion_id,
        })

        values.documentos?.forEach(e => {
            if (!e.fromDB) formData.append("documentos", e, e.name)
        })

        if(this.state.cotizacion_id){
            this.updateCotizacion(formData, values)
        }else{
            this.createCotizacion(formData, values)
        }
    }

    /**
     * @memberof ModalCotizacion
     * @method updateCotizacion
     * @description Actualiza los valores en el state, actualiza el registro en la BD,pasa al siguiente al siguiente step al obtener respuesta
     * 
     */
    updateCotizacion = (formData, values) => {

        this.setState({loading: true})

        axios.put('/project-manager/cotizacion',formData)
        .then(response => {

            this.setState({
                values: values,
                current: this.state.current + 1,
            })
                console.log("values", values);

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al crear la cotización")
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof ModalCotizacion
     * @method createCotizacion
     * @description Guarda los valores en el state, crea el registro en la BD,pasa al siguiente al siguiente step al obtener respuesta
     * 
     */
    createCotizacion = (formData, values) => {

        this.setState({loading: true})

        axios.post('/project-manager/cotizacion',formData)
        .then(response => {
            console.log("response", response.data);
            this.setState({
                values: values,
                //proyecto_id: response.data.proyecto_id,
                cotizacion_id: response.data.cotizacion_id,
                current: this.state.current + 1,
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al crear la cotización")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalCotizacion
     * @method onSubmitServicios
     * @description Guarda los servicios en el state, junto los valores de la cotizacion
     * 
     */
    onSubmitServicios = (values) => {

        this.setState({
            values:{
                ...this.state.values,
                servicios_adquiridos: values
            },
            current: this.state.current + 1
        })
    }

    /**
     * @memberof ModalCotizacion
     * @method currentBack
     * @description Regresa al step anterior
     */
    currentBack = (servicios) => {
        console.log("servicios", servicios);
        this.setState((prevState) => ({
            current: prevState.current > 0 ? prevState.current - 1 : prevState.current,
            values: {
                ...prevState.values,
                servicios_adquiridos: Array.isArray(servicios) ? servicios : prevState.values.servicios_adquiridos
            }
        }));
    }

     /**
     * @memberof ModalCotizacion
     * @method renderContent
     * @description Renderiza los steps de la cotizacion
     * 
     */
    renderContent = (current) => {

        let props = {
            onSubmit: (values) => this.onSubmit(values),
            onSubmitServicios: (servicios) => this.onSubmitServicios(servicios),
            back:(servicios)=>this.currentBack(servicios),
            onClose: this.props.onClose,
        }


        const steps = [
            {
                title: 'First',
                content: <Requerimientos
                    {...props}
                    requerimientos={this.state.values}
                />
            },
            {
                title: 'Second',
                content: <Servicios
                    {...props}
                    servicios_adquiridos={this.state.values}
                />,
            },
            {
                title: 'Last',
                content: <Revision
                    {...props}
                    values={this.state.values}
                    proyecto_id={this.state.proyecto_id}
                    cotizacion_id={this.state.cotizacion_id}
                />,
            },
        ]

        return steps[current].content
    }




    render() {

        let { current } = this.state

        return (
            <>
                <Steps current={current}>
                    <Step title="Requerimientos"/>
                    <Step title="Servicios"/>
                    <Step title="Resumen"/>
                </Steps>
                <Spin spinning={this.state.loading}>
                    <div className="mt-1">{this.renderContent(current)}</div>
                </Spin>
            </>
        )
    }
}



export default function Componente (props) {
    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={cuenta_id ? "Editar Cotización" : "Crear Cotización"}
        destroyOnClose={true}
        footer={null}
        width={800}
        style={{top: "50px"}}
    >
        <ModalCotizacion {...props} />
    </Modal>

}