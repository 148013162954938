import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, InputNumber, DatePicker } from 'antd';
import axios from "axios"

//Componentes
import { ImagesUploader } from "../../../Widgets/Uploaders";

const { RangePicker } = DatePicker;
const moment = require("moment")

class ModalActualizacion extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modulo_id: undefined,
            actividades: []
        }
    }

    ModalActualizacion = React.createRef();

    componentDidMount() {
        if(this.props.actualizacion_id){
            this.getAvance()
        }
    }

    /**
     * @memberof ModalCuentas
     * @method onFinish
     * @description Se ejecuta al hacer submit
     */
    onFinish = (values) => {

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            archivos: undefined,
            modulo_proyecto_id: this.props.modulo_proyecto_id,
            id: this.props.actualizacion_id
        })

        values.archivos?.forEach(e => {
            if (!e.fromDB) formData.append("archivos", e, e.name)
        })

        if(this.props.actualizacion_id){
            this.updateActualizacion(formData)
        }else{
            this.addActualizacion(formData)
        }
    }

    /**
     * @memberof ModalCuentas
     * @method getAvance
     * @description Se obtiene informacion del avance
     */
    getAvance = (values) => {
        this.setState({loading: true})
        axios.get('/actualizacion/'+this.props.actualizacion_id,{})
        .then(response => {
            console.log("response", response.data);

            let avance = response.data
            this.ModalActualizacion.current.setFieldsValue({
                ...response.data,
                fechas: avance.fecha_inicio ? [moment(avance.fecha_inicio), moment(avance.fecha_final)] : [] , 
                archivos: response.data.archivos?.map((file, index) => ({
                    uid: file.file,
                    name: file.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/actualizacion/" + this.props.actualizacion_id + "?archivo=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                }))
            })
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener el avance")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalCuentas
     * @method addActualizacion
     * @description Se añade un nuevo avance a la actividad
     */
    addActualizacion = (values) => {
        this.setState({loading: true})
        axios.post('/actualizacion',values)
        .then(response => {
            message.success("Avance creado")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al crear el avance")
        }).finally(()=>this.setState({loading: false}))
    }


     /**
     * @memberof ModalCuentas
     * @method updateActualizacion
     * @description Se añade un nuevo avance a la actividad
     */
    updateActualizacion = (values) => {
        this.setState({loading: true})
        axios.put('/actualizacion',values)
        .then(response => {
            message.success("Avance actualizado")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al editar el avance")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalCuentas
     * @method calcularHorasEntreFechas
     * @description Se calculan las horas que hay entre dos fechas, quitando fines de semana y solo toma las horas entre 8 am y 6pm
     */
    calcularHorasEntreFechas = (fechaInicio, fechaFinal) => {

        fechaInicio = new Date(fechaInicio)
        fechaFinal = new Date(fechaFinal)

        let horasHabiles = 0;
        let fechaActual = moment(fechaInicio).clone();

        while (fechaActual.isBefore(fechaFinal)) {
            if (fechaActual.day() !== 0 && fechaActual.day() !== 6) { // Si no es fin de semana
                const horaActual = fechaActual.hour();
                if (horaActual >= 8 && horaActual < 18) { // Si está entre las 8am y las 6pm
                    horasHabiles++;
                }
            }

            fechaActual.add(1, 'hour');
        }

        return horasHabiles;
    }

    render() {

        return (
            <Form
                layout="vertical"
                name="form-proveedor"
                id="form-proveedor"
                ref={this.ModalActualizacion}
                onFinish={this.onFinish}
                initialValues={{
                    estatus: 1,
                    horas_consumidas: 0,
                    porcentaje_avance: 0,
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center">
                        <Col span={22}>
                            <Row gutter={[16,0]}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        label="Descripción"
                                        name="descripcion"
                                    >
                                        <Input.TextArea/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item
                                        label="Archivos"
                                        name="archivos"
                                        valuePropName="fileList"
                                        getValueFromEvent={e => {
                                            if (Array.isArray(e)) {
                                                return e;
                                            }
                                            return e?.fileList;
                                        }}>
                                        <ImagesUploader
                                            {...this.props.acreedor_id ? {
                                                method: "PUT",
                                                name: "documentos",
                                                headers: {
                                                    authorization: sessionStorage.getItem("token")
                                                },
                                                onRemove: e => {
                                                    axios.put(`/cliente`, {
                                                        documento: e.uid,
                                                        id: this.props.cliente_id
                                                    })
                                                }
                                            } : {}}>
                                            
                                        </ImagesUploader>
                                    </Form.Item>
                                </Col>
                            
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, modulo_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={modulo_id ? "Editar Actualización" : "Crear Actualización"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-proveedor', key: 'submit', htmlType: 'submit' }}
    >
        <ModalActualizacion {...props} />
    </Modal>

}