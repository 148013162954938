import React, { Component, useContext } from "react";
import { Button, Col, Row, Typography, Card, List, Space, message, Popconfirm, Modal, Statistic, Tooltip } from "antd";
import axios from 'axios'
import { BiEnvelope } from "react-icons/bi";
import { PlusOutlined } from "@ant-design/icons";
import { DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined, LinkOutlined } from "@ant-design/icons"

//componentes
import User from "../../../../../Hooks/Logged";
import CustomAvatar from "../../../../Widgets/Avatar/Avatar";
import usePermissions from "../../../../../Hooks/usePermissions";

//modalees
import ModalTransaccionProgramada from "../../../Finanzas/Transacciones/ModalTransaccionProgramada"

const { Text } = Typography;
const moment = require('moment');

/**
 * @class TransaccionesProgramadasTab
 * @extends {Component}
 * @description Lista de transacciones programadas del cliente
 */
class TransaccionesProgramadasTab extends Component {

	constructor(props) {
		super(props)
		this.state = {
			proyecto: undefined,
			transacciones: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
			},
			cliente_id: this.props.cliente_id,
			transaccion_programada_id: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		this.getTransaccionesProgramadas()
	}

	/**
	 * @memberof TransaccionesProgramadasTab
	 * @method getTransaccionesProgramadas
	 * @description Obtiene los transacciones programadas del cliente
	 */
	getTransaccionesProgramadas = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
	} = this.state.transacciones) => {
		this.setState({ loading: true })
		axios.get('/transacciones-programadas', {
			params: {
				cliente_id: this.props.cliente_id,
				page,
				limit,
			}
		})
		.then(({ data }) => {
			console.log("data", data);
			this.setState({ transacciones: { ...this.state.transacciones, ...data } });
		})
		.catch(error => {
			message.error(error?.response?.data?.message ?? 'Error al traer los transacciones programadas')
			console.log(error.response)
		}).finally(() => this.setState({ loading: false }))
	}



	dias_atraso = (fecha_limite, pagado) => {

		const f_l = moment(fecha_limite)
		// Obtener la fecha actual
	  	const fechaActual = moment();

	  	// Calcular la diferencia en días
	  	const diasDiferencia = f_l.diff(fechaActual, "days");

		  // Verificar si la fecha actual es mayor o igual a la fecha límite
	  	if(diasDiferencia < 0 && pagado === false)
		    return <small style={{color: "#ff0000", marginLeft: "8px"}}>({diasDiferencia})</small>
		return ""
	}


	render() {

		const { data } = this.state.transacciones

		return (
			<>
				<Row gutter={[8, 8]}>
					<Col span={24} className="flex-right">
						<Button type="primary" icon={<PlusOutlined />} onClick={()=>this.setState({modal_visible: true})}>
							Agregar Transacción Programada
						</Button>
					</Col>
					<List
						itemLayout="horizontal"
						dataSource={data}
						loading={this.state.loading}
						key={(item => item._id)}
						className="component-list width-100"
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							showSizeChanger: true, 
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.get({ page, limit })
						}}
						renderItem={item => (
							<List.Item className="component-list-item transacciones">
								<Card className="ant-card-list width-100">
									<Row className="width-100" align={"middle"} gutter={[16, 16]}>
										<Col xs={12} md={3} className="center">
											{ item.tipo_recurrencia === undefined || item.tipo_recurrencia === 1 ? <Text className="text-date-format fecha">
												{item.periodo !== 1 ? moment(item.fecha_inicio).format('YYYY-MM-DD') + " - " : ""}{moment(item.fecha_limite).format('YYYY-MM-DD')} 
												{this.dias_atraso(item.fecha_limite,item.monto === item.monto_pagado )}
											</Text> : <Text> Suscripción </Text>}
										</Col>
										<Col xs={12} md={4} className="center">
											<Text ellipsis className="text-gray-dark">{item.concepto ?? "Sin Concepto"}</Text>
										</Col>
										<Col xs={12} md={4}>
											<Space direction='vertical' size='small' className="space-small-vertical">
												<Text ellipsis className="text-gray-dark">{item.area_id?.nombre ? item.area_id?.nombre : 'Area no asignada'}</Text>
												<Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre ? item.rubro_id?.nombre : 'Rubro No asignado'}</Text>
											</Space>
										</Col>
										<Col xs={12} md={3} className="center">
											<Space direction='horizontal' align='center' size='small'  >
												<CustomAvatar
													image={item.proyecto_id?.logo}
													color={item.proyecto_id?.color}
													name={item.proyecto_id?.nombre}
												/>
												<Text ellipsis>{item.proyecto_id?.nombre ? item.proyecto_id?.nombre : 'Sin Proyecto'} </Text>
											</Space>
										</Col>
										<Col xs={12} md={4} className=" center">
											<Space direction='horizontal' align='center' size={0} >
												<CustomAvatar
													image={item.cuenta_id?.logo}
													color={item.cuenta_id?.color}
													name={item.cuenta_id?.nombre}
												/>
												<Text ellipsis className="text-gray-dark">{item.cuenta_id?.nombre ? item.cuenta_id?.nombre : 'Sin Cuenta'}</Text>

											</Space>
										</Col>
										<Col xs={12} md={3} className="center">
											<Text className="text-gray-dark" strong>
												$ {item.monto?.toMoney(true)} <small>MXN</small></Text>
										</Col>
										<Col xs={12} md={1} className="center">
											<Button
                                                className={`btn-permission ${item.monto === item.monto_pagado ? 'active' : ''}`}
                                                type="ghost"
                                                
                                            />
										</Col>
										<Col xs={24} md={2} align="right">
											<Space direction="horizontal" size={5}>
												<Tooltip mouseEnterDelay={0} title="Editar la transacción programada" placement="rightTop">
													<Button
														size="small"
														type="primary"
														//disabled={!this.props.editarTransaccionesProgramadasPropiedad}
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.setState({modal_visible: true, transaccion_programada_id: item._id})}
														/>
												</Tooltip>

												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta transacción programda?"
													onConfirm={() => axios.delete(`/transaccion-programada/${item._id}`)
														.then(() => {
															message.success('Transacción programda eliminada')
															this.getTransaccionesProgramadas()
														}).catch(error => console.log(error))}
													okText="Si"
													cancelText="No"
												>												
													<Button
														size="small"
														type="primary"
														//disabled={!this.props.eliminarTransaccionesProgramadasPropiedad}
														icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
														title="Eliminar"
														danger
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>
				<ModalTransaccionProgramada
					visible={this.state.modal_visible}
					onClose={(flag)=>{
						this.setState({modal_visible: false, transaccion_programada_id: undefined})
						if(flag === true){
							this.getTransaccionesProgramadas()
						}
					}}
					cliente_id={this.props.cliente_id}
					transaccion_programada_id={this.state.transaccion_programada_id}
				/>

			</>
		)
	}
}

export default React.forwardRef((props, ref) => {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "edit"],
        eliminarTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "delete"]
    });

	return <TransaccionesProgramadasTab ref={ref} {...props} user={user} {...permisos}/>
})