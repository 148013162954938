import React, { useState, Component, useContext, useCallback, useEffect } from 'react';
import { Card, Typography, Row, Col, message, Modal } from "antd"
import { DragDropContext, Droppable, Draggable, } from 'react-beautiful-dnd';

import axios from 'axios';
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';


import CardTicket from "./CardTicket"
import ModalTicket from "./ModalTicket"

import '../../../Styles/Modules/Tickets/Tickets.scss'
import SocketContext from '../../../Context/Socket';

const { Text, Title, Paragraph } = Typography



/**
 * 
 * @param {props} children Contenido del card
 * @param {props} color Color del card en cuestión
 * @returns 
 */
function SectionTitleCard({ children, color }) {
  return <Card className='section-title-card' size='small' style={{ position: "relative" }}>
    <Text className='section-title-card-title' style={{ color }}>{children}</Text>
    <div className='section-title-card-footer' style={{ background: color }} />
  </Card>

}

/**
 * 
 * @class Kanban de elementos
 * @description Es el pizarron de tickets, permite moverlos 
 */
class Kanban extends Component {
  constructor(props) {
    super(props)
    this.state = {

      status: {
        1: {
          data: [],
          skip: 0,
          limit: 20,
          loading: false
        },
        2: {
          data: [],
          skip: 0,
          limit: 20,
          loading: false
        },
        3: {
          data: [],
          skip: 0,
          limit: 20,
          loading: false
        },
        4: {
          data: [],
          skip: 0,
          limit: 20,
          loading: false
        },
        5: {
          data: [],
          skip: 0,
          limit: 20,
          loading: false
        },
      },
      modalTicketVisible: false,
      loading: false
    }
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll)
    this.props.socket.on("/admin/tickets", this.setTickets)
    this.props.socket.on("/admin/tickets/add", this.addTicket)
    this.props.socket.on("/admin/tickets/message", this.addMessageTicket)
    this.props.socket.on("/admin/tickets/update", this.updateTicket)
    this.props.socket.on("/admin/tickets/delete", this.deleteTicket)
    this.props.socket.on("/admin/tickets/position", this.onPosition)
    this.props.socket.on("/admin/tickets/error", this.onError)
    this.getTickets()
  }

  componentWillUnmount() {
    this.props.socket.emit("/admin/tickets/off")

    document.removeEventListener("scroll", this.onScroll)
    this.props.socket.removeEventListener("/admin/tickets")
    this.props.socket.removeEventListener("/admin/tickets/add")
    this.props.socket.removeEventListener("/admin/tickets/update")
    this.props.socket.removeEventListener("/admin/tickets/delete")
    this.props.socket.removeEventListener("/admin/tickets/position")
    this.props.socket.removeEventListener("/admin/tickets/error")
  }

  onError = error => message.error(error)

  onPosition = ticket => {
    console.log("ticket", ticket)
    if (this.columnsDictionary[ticket._id]) {
      const { index, key } = this.columnsDictionary[ticket._id]
      this.onDragEnd({
        ticket,
        draggableId: ticket._id,
        source: {
          estatus: key,
          droppableId: key,
          index: index,
        },
        destination: {
          estatus: ticket.estatus,
          droppableId: ticket.estatus,
          index: ticket.order
        },
      })
    }
  }

  addTicket = (ticket) => {
    ticket.id = ticket._id
    let list = this.state.status[ticket.estatus]
    list.data = [ticket, ...list.data]//.push(ticket)
    list.skip++
    this.setState(state => {
      state.status[ticket.estatus] = list
      // state
      return state;
    })
    let audio = new Audio('/sounds/new_ticket.mp3');
    audio.play()
  }

  updateTicket = (ticket) => {
    ticket.id = ticket._id
    let { status } = this.state
    status[ticket.estatus].data[this.columnsDictionary[ticket._id].index] = ticket
    this.setState({ status })
  }

  addMessageTicket = (ticket_id) => {
    let ticketElement = this.columnsDictionary[ticket_id] //.index
    let { status } = this.state
    status[ticketElement.key].data[ticketElement.index].mensajes++;
    this.setState({ status })
  }

  deleteTicket = ({ estatus, order }) => {
    const { status } = this.state
    status[estatus].data.splice(order, 1)
    this.setState({ status })
  }

  getTickets = () => {
    for (let index = 1; index < 6; index++) {
      this.props.socket.emit("/admin/tickets", {
        estatus: index,
        ...this.state.status[index]
      })
    }
  }



  onScroll() {
    if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight)) {
      for (let index = 1; index < 6; index++) {
        if (this.state.status[index].total > this.state.status[index].skip + this.state.status[index].limit)
          this.props.socket.emit("admin/tickets", {
            estatus: index,
            ...this.state.status[index],
            data: null,
            skip: this.state.status[index].skip + this.state.status[index].limit + 1
            // 63e14e4c4d33efa9bcb7fb74
          })
      }
    }
  }

  setTickets = data => {
    let { status } = this.state
    // (data.skip == 0) ? data : [...status[data.estatus].data, ...data]

    console.log("d ", data.estatus, data.data.map(o => o.order))

    if (data.skip == 0) {
      status[data.estatus] = data
    } else {
      status[data.estatus] = {
        ...data,
        data: [...status[data.estatus].data, ...data.data]
      }
    }
    this.setState({ status })
  }



  /**
   * 
   * @description Cambia la posición de un elemento en una misma lista
   * @param {props} list Lista a reordenar
   * @param {props} color Color del card en cuestión
   * @returns 
   */
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  /**
   * 
   * @description Mueve un elemento de unma lista a otra
   * @param source Lista de origen del elemento a mover
   * @param destionation Lista destino del elemento a mover
   * @param droppableSource Elemento de origen
   * @param droppableDestination Elemento destino
   * @returns result 
   */
  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };



  /**
   * 
   * @description Mueve un elemento de unma lista a otra
   * @param {result} source Lista de elemento fuente 
   * @param {result} destination Lista de elementos
   * @param {result} draggableId Id del Elemento
   * @returns result 
   */
  onDragEnd = result => {
    console.log("result", result)
    console.log("result", result)
    const { source, destination, draggableId } = result;

    this.setState({ draggableId }, () => {

      if (!destination) return;
      if (source.droppableId === destination.droppableId && destination.index == source.index) return;

      if (result.mode || result.reason)
        this.props.socket.emit('/admin/tickets/position', {
          ticket_id: draggableId,
          estatus: destination.droppableId,
          position: destination.index
        })

      if (source.droppableId === destination.droppableId) {
        const items = this.reorder(
          this.getList(source.droppableId).data,
          source.index,
          destination.index
        )
        let { status } = this.state
        status[source.droppableId].data = items
        this.setState({ status })
      } else {
        const result = this.move(
          this.getList(source.droppableId).data,
          this.getList(destination.droppableId).data,
          source,
          destination
        )
        let { status } = this.state
        Object.entries(result).map(([key, value], index) => {
          status[key].data = value
        })

        status[source.droppableId].skip++
        status[destination.droppableId].skip--

        this.setState({ status });
      }
    })
  }


  /**
   * 
   * @method getList
   * @description Retorna los elementos de la lista
   * @param {*} id Id de la lista 
   * @returns Lista en cuestión 
   */
  getList = id => this.state.status[id];

  columns = [
    {
      title: "En Espera",
      color: "#2c59ff",
      key: "1"
    },
    {
      title: "En Proceso",
      color: "#faad14",
      key: "2"
    },
    {
      title: "En Confirmación",
      color: "#7b868e",
      key: "3"
    },
    {
      title: "Pausadas",
      color: "#4e2e8f",
      key: "4"
    },
    {
      title: "Completadas",
      color: "#52c41a",
      key: "5"
    },
  ]

  columnsDictionary = {}

  render() {
    const { columns } = this
    return (
      <>
        <DragDropContext
          sensors={[
            api => this.apiRef = api
          ]}
          onDragEnd={this.onDragEnd}>
          <Row className='kanban p-1' >
            {(columns).map((value, indexColumn) => {
              return <Col
                span={5}
                className='kanban-column'
              >
                <SectionTitleCard color={value.color}>{value.title}</SectionTitleCard>
                <Droppable droppableId={value.key}  >
                  {(provided, snapshot) => (
                    <div
                      className='kanban-column-draggable'
                      ref={provided.innerRef}
                    >
                      {this.state.status[value.key].data.map((item, index) => {
                        this.columnsDictionary[item._id] = {
                          index,
                          indexColumn,
                          key: value.key
                        }
                        return (
                          <Draggable
                            key={"drag-" + item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <CardTicket
                                key={"card" + item._id}
                                provided={provided}
                                snapshot={snapshot}
                                color={value.color}
                                item={item}
                                draggableId={this.state.draggableId}
                                onButtonClick={({ ticket_id, tipo }) => {
                                  this.setState({ modalTicketVisible: true, ticket_id, tipo })
                                }}

                                onDeleteClick={() => {
                                  Modal.confirm({
                                    title: <>¿Deseas eliminar el ticket {item.folio}?</>,
                                    content: "Al eliminarlo, el cliente tampoco podrá visualizarlo.",
                                    okButtonProps: {
                                      danger: true
                                    },
                                    okTex:"Eliminar",
                                    onOk: () => {
                                      try {
                                        this.props.socket.emit("/admin/tickets/delete", item?._id)
                                      } catch (error) {

                                      }
                                    }
                                  })

                                }}
                              />
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
            })}
          </Row>
          <ModalTicket
            visible={this.state.modalTicketVisible}
            ticket_id={this.state.ticket_id}
            onCancel={() => {
              this.setState({ modalTicketVisible: false })
            }}
          />
        </DragDropContext>
        <FloatingButton onClick={() => this.setState({ modalTicketVisible: true, ticket_id: undefined })} />
      </>
    );
  }
}

export default function (props) {
  return <Kanban socket={React.useContext(SocketContext)} />
};
