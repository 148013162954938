
import React from 'react';


import { Drawer, Form, Select, Cascader, message, Badge, Button, DatePicker } from 'antd';
import axios from 'axios';


import CAvatar from '../Avatar/Avatar';
import { DownloadOutlined } from '@ant-design/icons';

const { Option } = Select
const { RangePicker } = DatePicker

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
export default class FiltrosDrawer extends React.Component {

    /**
     *
     *
     * @export
     * @class FiltrosDrawer
     * @extends {React.Component}
     * 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state ordenes Objeto para paginar y buscar en el select de ordenes
     * @state ordenes_compras Objeto para paginar y buscar en el select de ordenes de compra
     * @state areas Objeto para paginar y buscar en el select de areas  
     * @state areas_dictionary Diccionario de Areas. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state rubros_dictionary Diccionario de Rubros. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state empresas Objeto para paginar y buscar en el select de Empresas
     * @state clasificadores Objeto para paginar y buscar en el select de Clasificadores 
     * 
     * @state paginator.data Lista de elementos para paginar
     * @state paginator.dictionary Ya que los selects funcionan solo con ID´s, utilizamos el diccionario para obtener el objeto al que el ID hace referencia
     * @state paginator.page Pagina actual
     * @state paginator.limit Limit de elementos por página
     * @state paginator.pages Total de paginas
     * @state paginator.total Total de Elementos en la lista
     * @state paginator.search Para buscar
     * @state paginator.search Loading, para declarar el actualizado.
     */
    state = {


        cuentas: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        clientes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        ordenes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        ordenes_compras: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },


        areas: [],
        areas_dictionary: {},
        rubros_dictionary: {},

        empresas: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        clasificadores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        negocios: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        proveedores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        }
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
         ;

        if (this.props.cuentas === undefined || this.props.cuentas !== false)
            this.getCuentas()

        // if (this.props.clientes === undefined || this.props.clientes !== false)
        //     this.getClientes()

        // if (this.props.ordenes === undefined || this.props.ordenes !== false)
        //     this.getOrdenes()

        // if (this.props.orden_compra === undefined || this.props.orden_compra !== false)
        //     this.getOrdenesCompras()

        // if (this.props.area_rubro === undefined || this.props.area_rubro !== false)
        //     this.getAreas();


        // if (this.props.empresas === undefined || this.props.empresas !== false)
        //     this.getRazonesSociales()

        // if (this.props.clasificadores === undefined || this.props.clasificadores !== false)
        //     this.getClasificadores()

        // if (this.props.negocios === undefined || this.props.negocios !== false)
        //     this.getNegocios()


        // if (this.props.proveedores === undefined || this.props.proveedores !== false)
        //     this.getProveedores()

    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * 
     * @description Para obtener las cuentas en la lista
     */
    getCuentas = ({ page, limit, search } = this.state.cuentas, { cuentas } = this.state) => {

        cuentas.loading = true;
        cuentas.page = page;
        cuentas.limit = limit;

        this.setState({ cuentas })

        axios.get('/cuentas', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                cuentas.data = (page === 1) ? data.data : [...cuentas.data, ...data.data];

                cuentas.dictionary = {}
                cuentas.data.map(d => cuentas.dictionary[d._id] = d)

                cuentas.total = data.total
                cuentas.pages = data.pages
                cuentas.loading = false;

                this.setState({ cuentas })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.cuentas.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.clientes]
     * @param {*} [{ clientes }=this.state]
     * @memberof FiltrosDrawer
     * @method getClientes
     * 
     * @description Para obtener los clientes en la lista
     */
    getClientes = ({ page, limit, search } = this.state.clientes, { clientes } = this.state) => {

        clientes.loading = true;
        clientes.page = page;
        clientes.limit = limit;

        this.setState({ clientes })

        axios.get('/clientes', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                clientes.data = (page === 1) ? data.data : [...clientes.data, ...data.data];

                clientes.dictionary = {}
                clientes.data.map(d => clientes.dictionary[d._id] = d)

                clientes.total = data.total
                clientes.pages = data.pages
                clientes.loading = false;

                this.setState({ clientes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clientes.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.ordenes]
     * @param {*} [{ ordenes }=this.state]
     * @memberof FiltrosDrawer
     * @method getOrdenes
     * 
     * @description Para obtener las ordenes en la lista
     */
    getOrdenes = ({ page, limit, search, clientes_ids } = this.state.ordenes, { ordenes } = this.state) => {

        ordenes.loading = true;
        ordenes.page = page;
        ordenes.limit = limit;
        ordenes.clientes_ids = clientes_ids

        this.setState({ ordenes })

        axios.get('/ordenes', {
            params: {
                page, limit, search, cliente_id: clientes_ids
            }
        })
            .then(response => {

                ordenes.data = (page === 1) ? response.data.itemsList : [...ordenes.data, ...response.data.itemsList];

                ordenes.dictionary = {}
                ordenes.data.map(d => ordenes.dictionary[d._id] = d)

                ordenes.total = response.data.paginator.itemCount
                ordenes.pages = response.data.paginator.pageCount
                ordenes.loading = false;

                this.setState({ ordenes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.ordenes.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.ordenes_compras]
     * @param {*} [{ ordenes_compras }=this.state]
     * @memberof FiltrosDrawer
     * @method getOrdenesCompras
     * 
     * @description Para obtener las ordenes de compra en la lista
     */
    getOrdenesCompras = ({ page, limit, search, orden_id } = this.state.ordenes_compras, { ordenes_compras } = this.state) => {

        ordenes_compras.loading = true;
        ordenes_compras.page = page;
        ordenes_compras.limit = limit;

        this.setState({ ordenes_compras })

        axios.get('/ordenesCompra/list', {
            params: {
                page, limit, search, orden_id
            }
        })
            .then(response => {
                ordenes_compras.data = (page === 1) ? response.data.data : [...ordenes_compras.data, ...response.data.data];

                ordenes_compras.dictionary = {}
                ordenes_compras.data.map(d => ordenes_compras.dictionary[d._id] = d)

                ordenes_compras.total = response.data.total
                ordenes_compras.pages = response.data.pages
                ordenes_compras.loading = false;

                this.setState({ ordenes_compras })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.ordenes_compras.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.areas]
     * @param {*} [{ areas }=this.state]
     * @memberof FiltrosDrawer
     * @method getAreas
     * 
     * @description Para obtener las areas en la lista
     */
    getAreas = (search) => {
        return axios.get('/areas/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {

            let areas_dictionary = {}
            let areas = response.data.map(area => {

                areas_dictionary[area._id] = area
                return ({
                    value: area._id, label: area.nombre, isLeaf: false
                })
            })

            this.setState({
                areas,
                areas_dictionary
            })
        }).catch(error => {
            console.log('error al traer areas', error)
        })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.rubros]
     * @param {*} [{ rubros }=this.state]
     * @memberof FiltrosDrawer
     * @method loadRubros
     * 
     * @description Cargamos los rubros
     */
    loadRubros = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        let areas = this.state.areas

        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: targetOption.value
            }
        })
            .then(response => {
                targetOption.loading = false;

                let index = areas.findIndex(area => area.value.toString() === targetOption.value.toString())
                let rubros = response.data.data;

                if (index !== -1 && rubros.length > 0) {


                    let rubros_dictionary = {}
                    let areas_temp = rubros.map(rubro => {
                        rubros_dictionary[rubro._id] = rubro
                        return ({ label: rubro.nombre, value: rubro._id })
                    })

                    areas[index].children = areas_temp
                    this.setState({ areas: [...areas], rubros_dictionary: { ...this.state.rubros_dictionary, ...rubros_dictionary } });

                } else {
                    message.error('Rubros no encontrados')
                }


            })
            .catch(error => {
                console.log(error)
                message.error('Error al cargar los rubros')
            })

    };

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.empresas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getRazonesSociales
     * 
     * @description Obtenemos las Empresas
     */
    getRazonesSociales = ({ page, limit, search } = this.state.empresas, { empresas } = this.state) => {

        empresas.loading = true;
        empresas.page = page;
        empresas.limit = limit;

        this.setState({ empresas })

        axios.get('/empresas', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                // console.log('response', response.data.data)
                empresas.data = (page === 1) ? response.data.itemsList : [...empresas.data, ...response.data.itemsList];

                empresas.dictionary = {}
                empresas.data.map(d => empresas.dictionary[d._id] = d)

                empresas.total = response.data.paginator.itemCount
                empresas.pages = response.data.paginator.pageCount
                empresas.loading = false;

                this.setState({ empresas })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.empresas.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.clasificadores]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getClasificadores
     * 
     * @description Obtenemos los clasificadores
     */
    getClasificadores = ({ page, limit, search } = this.state.clasificadores, { clasificadores } = this.state) => {

        clasificadores.loading = true;
        clasificadores.page = page;
        clasificadores.limit = limit;
        this.setState({ clasificadores })

        axios.get('/clasificadores/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                clasificadores.data = (page === 1) ? response.data.itemsList : [...clasificadores.data, ...response.data.itemsList];


                clasificadores.dictionary = {}
                clasificadores.data.map(d => clasificadores.dictionary[d._id] = d)

                clasificadores.total = response.paginator.itemCount
                clasificadores.pages = response.paginator.pageCount
                clasificadores.loading = false;

                this.setState({ clasificadores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clasificadores.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.negocios]
     * @param {*} [{ negocios }=this.state]
     * @memberof FiltrosDrawer
     * @method getNegocios
     * 
     * @description Obtenemos los Negocios
     */
    getNegocios = ({ page, limit, search } = this.state.negocios, { negocios } = this.state) => {

        negocios.loading = true;
        negocios.page = page;
        negocios.limit = limit;
        this.setState({ negocios })

        axios.get('/negocios/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                negocios.data = (page === 1) ? response.data.data.data : [...negocios.data, ...response.data.data.data];


                negocios.dictionary = {}
                negocios.data.map(d => negocios.dictionary[d._id] = d)

                negocios.total = response.data.data.total
                negocios.pages = response.data.data.pages
                negocios.loading = false;

                this.setState({ negocios })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.negocios.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getProveedores
    * 
    * @description Obtenemos los proveedores
    */
    getProveedores = ({ page, limit, search } = this.state.proveedores, { proveedores } = this.state) => {

        proveedores.loading = true;
        proveedores.page = page;
        proveedores.limit = limit;
        this.setState({ proveedores })

        axios.get('/proveedores/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                proveedores.data = (page === 1) ? response.data.itemsList : [...proveedores.data, ...response.data.itemsList];


                proveedores.dictionary = {}
                proveedores.data.map(d => proveedores.dictionary[d._id] = d)

                proveedores.total = response.data.paginator.itemCount
                proveedores.pages = response.data.paginator.pageCount
                proveedores.loading = false;

                this.setState({ proveedores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.proveedores.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = ({ area_rubro, clasificadores, clientes, cuentas, ordenes, ordenes_compras, empresas }) => {

        let filtros = []
        // clasificadores, clientes, cuentas, ordenes, ordenes_compras, empresas

        console.log('trans submit filter', clientes, cuentas,)
        //Metodo para añadir al areglo de filtros.
        let addToFilter = (name, objectName, array) => array.map(element => filtros.push({
            _id: element,//clasificador,
            name: name,
            objectName,
            filter: this.state[objectName].dictionary[element]
        }))

        if (clasificadores) addToFilter("clasificadores", "clasificadores", clasificadores)
        if (clientes) addToFilter("cliente_id", "clientes", clientes)
        if (cuentas) addToFilter("cuenta_id", "cuentas", cuentas)
        if (ordenes) addToFilter(["ordenes.orden_id", "ordenes.orden_id"], "ordenes", ordenes)
        if (ordenes_compras) addToFilter("ordenes_compra.orden_compra_id", "ordenes_compras", ordenes_compras)
        if (empresas) addToFilter("empresa_id", "empresas", empresas)

        if (area_rubro)
            for (const ar of area_rubro) {

                if (ar.length === 1) {
                    filtros.push({
                        _id: ar[0],//clasificador,
                        name: "area_id",
                        objectName: "areas",
                        filter: this.state.areas_dictionary[ar[0]]
                    })
                } else {
                    for (let index = 0; index < ar.length; index++) {
                        if (index === 0) continue;
                        const element = ar[index];
                        filtros.push({
                            _id: element,//clasificador,
                            name: "rubro_id",
                            objectName: "rubros",
                            filter: this.state.rubros_dictionary[element]
                        })
                    }
                }
            }


        if (this.props.updateFilters)
            this.props.updateFilters(filtros)
    }


    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method downloadCSV
     */
    downloadCSV = () => {
        console.log('kk csv', this.props.csv)
        let url = new URL(axios.defaults.baseURL + '/' + this.props.csv)
        url.searchParams.set('csv', true)
        url.searchParams.set('Authorization', sessionStorage.getItem('token'))
        window.open(url, '_blank');
    }

    render() {

        const { onClose, visible } = this.props
        const { clientes, cuentas, empresas, clasificadores, ordenes, ordenes_compras, negocios, proveedores } = this.state

        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            visible={visible}
            extra={
                (this.props.csv !== undefined) ?
                    <Button
                        onClick={this.downloadCSV}
                        icon={<DownloadOutlined />}
                    >CSV</Button>
                    : null
            }>


            <Form
                ref={ref => this.formFiltros = ref}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >
                {/* RangePicker */}

                <Form.Item label="Rango de Fechas" name="fechas" >
                    <RangePicker placeholder={["Fecha Inicial","Fecha Final"]} />
                </Form.Item>
                {(this.props.cuentas === undefined || this.props.cuentas !== false) ?
                    <Form.Item label="Cuentas" name="cuentas">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !cuentas?.loading && cuentas.page < clientes.pages) ? this.getCuentas({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getCuentas({ search, page: 1 })}
                        >
                            {cuentas.data.map(cuenta => <Option key={cuenta._id} value={cuenta._id}>
                                <CAvatar
                                    size='small'
                                    image={cuenta.logo}
                                    name={cuenta.nombre}
                                    color={cuenta.color}
                                /> &nbsp;{cuenta.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.clientes === undefined || this.props.clientes !== false) ?
                    <Form.Item label="Clientes" name="clientes">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}

                            onSelect={() => this.getOrdenes({ clientes_ids: this.formFiltros.getFieldValue('clientes'), page: 1 })}
                        >
                            {clientes.data.map(cliente => <Option key={cliente._id} value={cliente._id}>{cliente.razon_social || cliente.nombre || cliente.email}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.ordenes === undefined || this.props.ordenes !== false) ?
                    <Form.Item label="Ventas / Ordenes" name="ordenes">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !ordenes?.loading && ordenes.page < ordenes.pages) ? this.getOrdenes({ page: ordenes.page + 1 }) : null}
                            onSearch={search => this.getOrdenes({ search, page: 1 })}

                            onSelect={() => this.getOrdenesCompras({ orden_id: this.formFiltros.getFieldValue('ordenes'), page: 1 })}
                        >
                            {ordenes.data.map(orden => <Option key={orden._id} value={orden._id}>{orden.folio}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.ordenes_compras === undefined || this.props.ordenes_compras !== false) ?
                    <Form.Item label="Ordenes de Compra" name="ordenes_compras">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !ordenes_compras?.loading && ordenes_compras.page < ordenes_compras.pages) ? this.getOrdenesCompras({ page: ordenes_compras.page + 1 }) : null}
                            onSearch={search => this.getOrdenesCompras({ search, page: 1 })}
                        >
                            {ordenes_compras.data.map(orden_compra => <Option key={orden_compra._id} value={orden_compra._id}>{orden_compra.folio}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.area_rubro === undefined || this.props.area_rubro !== false) ?
                    <Form.Item label="Áreas / Rubros" name="area_rubro">
                        <Cascader
                            multiple={true}
                            placeholder="Seleccione el Área y Rubro"
                            options={this.state.areas}
                            loadData={this.loadRubros}
                            changeOnSelect
                        />
                    </Form.Item> : null}
                {(this.props.empresas === undefined || this.props.empresas !== false) ?
                    <Form.Item label="Empresas" name="empresas">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !empresas?.loading && empresas.page < empresas.pages) ? this.getRazonesSociales({ page: empresas.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}
                        >
                            {empresas.data.map(razon_social => <Option key={razon_social._id} value={razon_social._id}>        <CAvatar
                                size='small'
                                image={razon_social.logo}
                                name={razon_social.razon_social}
                                color={razon_social.color}
                            /> &nbsp; {razon_social.razon_social}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.clasificadores === undefined || this.props.clasificadores !== false) ?
                    <Form.Item label="Clasificadores" name="clasificadores">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}
                        >
                            {clasificadores.data.map(clasificador => <Option key={clasificador._id} value={clasificador._id}><Badge color={clasificador.color} />{clasificador.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.negocios === undefined || this.props.negocios !== false) ?
                    <Form.Item label="Negocios" name="negocios">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !negocios?.loading && negocios.page < negocios.pages) ? this.getNegocios({ page: negocios.page + 1 }) : null}
                            onSearch={search => this.getNegocios({ search, page: 1 })}
                        >
                            {negocios?.data?.map(negocio => <Option key={negocio._id} value={negocio._id}><Badge color={negocio.color} />{negocio.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.proveedores === undefined || this.props.proveedores !== false) ?
                    <Form.Item label="Proveedores" name="proveedores">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !proveedores?.loading && proveedores.page < proveedores.pages) ? this.getProveedores({ page: proveedores.page + 1 }) : null}
                            onSearch={search => this.getProveedores({ search, page: 1 })}
                        >
                            {proveedores.data.map(proveedor => <Option key={proveedor._id} value={proveedor._id}><Badge color={proveedor.color} />{proveedor.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
            </Form>
        </Drawer >)
    }
};
