import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, InputNumber, Select, DatePicker, Typography, Checkbox } from 'antd';
import axios from "axios"

//Componentes
import { SelectModulo } from "../../../Widgets/Inputs/Selects";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;
const moment = require("moment")

class ModalProveedores extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modulo_id: undefined,
            actividades: []
        }
    }

    ModalModulo = React.createRef();

    componentDidMount() {
        if(this.props.modulo_id){
            this.getModulo()
        }
    }

    onFinish = (values) => {

        if(this.props.modulo_id){
            this.updateModulo(values)
        }else{
            this.addModulo(values)
        }
    }

    /**
     * @memberof ModalCuentas
     * @method getModulo
     * @description Obtiene informacion del modulo
     */
    getModulo = () => {
        this.setState({loading: true})
        axios.get('/modulo-proyecto/'+this.props.modulo_id)
        .then(({ data }) => {
            console.log("data", data);
            this.ModalModulo.current.setFieldsValue({
                ...data,
                fechas: [moment(data.fecha_inicio),moment(data.fecha_final)]
            })
           
        }).catch(error => {
            message.error('Error al obtener el modulo')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalCuentas
     * @method addModulo
     * @description Añade un nuevo modulo al sistema relacionado al proyecto
     */
    addModulo = (values) => {
        this.setState({loading: true})
        axios.post('/modulo-proyecto',{
            ...values,
            proyecto_id: this.props.proyecto_id
        })
        .then(({ data }) => {
            message.success("Modulo registrado")
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al crear el modulo')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalCuentas
     * @method updateModulo
     * @description Actualiza el modulo
     */
    updateModulo = (values) => {
        this.setState({loading: true})
        axios.put('/modulo-proyecto',{
            ...values,
            id: this.props.modulo_id
        })
        .then(({ data }) => {
            message.success("Modulo Actualizado")
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al actualizar el modulo')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalCuentas
     * @method onSelectModulo
     * @description Se ejecuta al dar enter al formulario
     */
    onSelectModulo = (modulo_id) => {
        this.setState({loading: true})
        axios.get('/modulo/' + modulo_id,{
            params:{
                actividades: true
            }
        })
        .then(({ data }) => {
            this.ModalModulo.current.setFieldsValue({
                nombre: data.nombre,
                descripcion: data.descripcion,
                horas_estimadas: data.horas_aproximadas,
            })

            this.setState({
                actividades: data.actividades,
                modulo_id
            })
        }).catch(error => {
            message.error('Error al traer el modulo')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalCuentas
     * @method calcularHorasEntreFechas
     * @description Se calculan las horas que hay entre dos fechas, quitando fines de semana y solo toma las horas entre 8 am y 6pm
     */
    calcularHorasEntreFechas = (fechaInicio, fechaFinal) => {

        fechaInicio = new Date(fechaInicio)
        fechaFinal = new Date(fechaFinal)

        let horasHabiles = 0;
        let fechaActual = moment(fechaInicio).clone();

        while (fechaActual.isBefore(fechaFinal)) {
            if (fechaActual.day() !== 0 && fechaActual.day() !== 6) { // Si no es fin de semana
                const horaActual = fechaActual.hour();
                if (horaActual >= 8 && horaActual < 18) { // Si está entre las 8am y las 6pm
                    horasHabiles++;
                }
            }

            fechaActual.add(1, 'hour');
        }

        return horasHabiles;
    }

    render() {

        const disabledDateTime = () => ({
          disabledHours: () => [0,1,2,3,4,5,6,7,19,20,21,22,23]
        });

        return (
            <Form
                layout="vertical"
                name="form-proveedor"
                id="form-proveedor"
                ref={this.ModalModulo}
                onFinish={this.onFinish}
                initialValues={{
                    estatus: 1,
                    horas_consumidas: 0,
                    porcentaje_avance: 0,
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center">
                        <Col span={22}>
                            <Row gutter={[16,0]}>
                                {this.props.modulo_id ? null : <Col xs={24} lg={24}>
                                    <Form.Item
                                        label="Modulo"
                                        name="modulo_id"
                                    >
                                        <SelectModulo
                                            onSelect={(modulo_id)=>this.onSelectModulo(modulo_id)}
                                        />
                                    </Form.Item>
                                </Col>}
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Nombre"
                                        name="nombre"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese el nombre"
                                        }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Estatus"
                                        name="estatus"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el estatus"
                                        }]}
                                    >
                                        <Select>
                                            <Option value={1}>Planeado</Option>
                                            <Option value={2}>En proceso</Option>
                                            <Option value={3}>To commit</Option>
                                            <Option value={4}>En testeo</Option>
                                            <Option value={5}>En correción</Option>
                                            <Option value={6}>En pausa</Option>
                                            <Option value={7}>Completado</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        label="Fecha Inicio y Fin"
                                        name="fechas"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese las fechas"
                                        }]}
                                    >
                                        <RangePicker 
                                            showTime
                                            format="YYYY-MM-DD HH"
                                            className="width-100"
                                            disabledTime={disabledDateTime}
                                            onChange={(fechas)=>{
                                                let horas = this.calcularHorasEntreFechas(fechas[0], fechas[1])
                                                this.ModalModulo.current.setFieldsValue({
                                                    horas_estimadas: horas
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        label="Descripción"
                                        name="descripcion"
                                    >
                                        <Input.TextArea/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Horas Estimadas"
                                        name="horas_estimadas"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese las horas"
                                        }]}
                                    >
                                        <InputNumber
                                            className="width-100"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Horas Consumidas"
                                        name="horas_consumidas"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese las horas"
                                        }]}
                                    >
                                        <InputNumber
                                            className="width-100"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Porcentaje Avance"
                                        name="porcentaje_avance"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese los avances"
                                        }]}
                                    >
                                        <InputNumber
                                            className="width-100"
                                        />
                                    </Form.Item>
                                </Col>
                                {this.state.modulo_id ? <Col xs={24} lg={24}>
                                    <Form.Item
                                        name="crear_actividades"
                                        valuePropName="checked"
                                    >
                                        <Checkbox>¿Crear Actividades?</Checkbox>
                                    </Form.Item>
                                </Col> : null }
                                <Col xs={24}>
                                    {
                                        this.state.actividades?.map(e => {
                                            return <div className="flex-between">
                                                <Text>{e.nombre}</Text>
                                                <Text>{e.horas_aproximadas} <small>hrs</small></Text>
                                            </div>
                                        })
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, modulo_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={modulo_id ? "Editar Módulo" : "Crear Módulo"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-proveedor', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProveedores {...props} />
    </Modal>

}