
import React, { Component } from 'react';
import { Layout, Button, Col, Row, message, Card, PageHeader, List, Spin, Typography, Space, Modal, Checkbox } from 'antd'
import { User } from "../../../../Hooks/Logged";
import { EyeOutlined, CheckOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { ReloadOutlined } from "@ant-design/icons";

import SyncWidget from "@paybook/sync-widget";




//componentes
import axios from 'axios'
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton';
//modales
import ModalXML from '../../Syncfy/ModalXML';
import ModalClasificarFacturas from '../../Syncfy/ModalClasificarFacturas';

window.SyncWidget = SyncWidget
const moment = require('moment');
const { Text } = Typography;
const { Content } = Layout;


/**
 *
 *
 * @export
 * @class FacturasSyncfy
 * @extends {Component}
 */
class FacturasSyncfy extends Component {

    constructor(props) {
        super(props)
        this.state = {

            facturas: {
                data: [],
                page: 1,
                limit: 20,
                total: 0,
                pages: 0,

                filter: {}
            },
            selectedFacturas: {},

            empresa_id: this.props.params.empresa_id,

            cuenta: {},
            vista_guardadas: false,
            update: true,
            id_site_organization_type: "56cf4f5b784806cf028b4569"
        }
    }
    static contextType = User;

    /**
     *
     *
     * @memberof FacturasSyncfy
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de los Razones Sociales
     */
    componentDidMount() {
        this.getEmpresa()
    }

    componentWillUnmount(){
        window.syncWidget = undefined
    }


    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof FacturasSyncfy
     * 
     * @method getAccount
     * @description Obtenemos la información de la cuenta, espcificamente dos campos, los de syncfy
     */
    getEmpresa = (id = this.props.params.empresa_id) => {
        this.setState({ loading: true })
        return axios.get("/empresa/"+id, { params: { id } })
            .then(({ data }) => {
                this.setState({ empresa: data },() => {
                    this.getToken()
                        // this.setState({ loading: true })
                        // this.props.setUserId(this.state.cuenta.syncfy_usuario_id)
                        //     .finally(() => {
                        //         this.getFacturasSyncfy()
                        //     })
                    }
                )
            })
            .catch(res => {
                this.setState({ loading: false })
                message.error('Error al obtener la información de la cuenta.')
            })
    }

    /**
    *
    *
    * @memberof FacturasSyncfy
    * @description Obtiene todas todas las facturas
    * @param values pagina
    */
    getToken = () => {
        axios.get('/syncfy/session', {
            params: {
                id_user_syncfy: this.state.empresa?.syncfy_usuario_id,
            }
        }).then(response => {
            sessionStorage.setItem('syncfy', response.data.token)
            axios.defaults.headers.common['syncfy'] = response.data.token
            this.setState({ token: response.data.token }, () => {
                this.getFacturasSyncfy();
            })
        }).catch(error => {
            console.log("error", error.response.data);
            message.error('Error al obtener el token')
            this.setState({ loading: false })
        })

    }


    /**
    * @memberof FacturasSC

    * @description  Lista las facturas de una empresa, en un rango de fechas
    **/
    getFacturasSyncfy = () => {
        this.setState({ loading: true })
        axios.get('/syncfy/facturas', {
            params: {
                empresa_id: this.state.empresa._id,
                page: this.state.facturas.page,
                limit: this.state.facturas.limit,
                fechas: (Array.isArray(this.state.facturas.filter.fechas)) ? this.state.facturas.filter.fechas.map(e => e.toDate()) : null
            }
        })
            .then(response => {
                console.log(response.data)
                this.setState(state => {
                    state.facturas.data = response.data.data.data
                    state.facturas.total = response.data.data.total
                    state.facturas.pages = response.data.data.pages
                    return state;
                })
            })
            .catch(error => {
                message.error("No es posible traer las facturas. ")

                if (error?.response?.data?.data?.nombre) {
                    Modal.warning({
                        title: error.response?.data?.data?.nombre,
                        content: error.response?.data?.data?.description,
                        onOk: (error.response?.data?.data?.nombre === "Invalidtoken") ? this.renderWidget() : undefined
                    })
                }


            })
            .finally(() => this.setState({ loading: false }))
    }




    /**
    * @memberof FacturasSC
    * @description añade las facturas a clasificar
    **/
    facturaChecked = (factura) => {
        let { selectedFacturas } = this.state

        // item.id_transaction
        // let id = (factura.id_transaction) ?  : factura._id

        if (selectedFacturas[factura.id_transaction])
            delete selectedFacturas[factura.id_transaction]
        else
            selectedFacturas[factura.id_transaction] = factura


        this.setState({ selectedFacturas: { ...selectedFacturas } })
    }


    /**
 * @memberof FacturasSC
 * @description  Abre el modal para clasificar las facturas
 **/
    addFacturas = () => {
        let { selectedFacturas } = this.state
        selectedFacturas = Object.values(selectedFacturas)
        if (selectedFacturas.length === 0) {
            message.error('No hay Facturas Seleccionadas')
        } else this.setState({ modalClasificarVisible: true })
    }


    renderTipo = (value) => {
        value = Math.sign(value)
        switch (value) {
            case 1:
                return "Ingreso"
            case 0:
                return "Sin cambios"
            case -1:
                return "Egreso"
            default:
                return "Sin Tipo"
        }
    }


    /**
     *
     *
     * @param {*} item
     * @memberof FacturasSC
     * 
     * @description 
     */
    renderViews = (item) => {

        if (item.xml)
            return <Button
                type="primary"
                icon={<EyeOutlined style={{ color: "white" }} />}
                // className="button-survey btn-eye button-eye"
                onClick={(e) => this.setState({ xml: item.xml, ModalXMLVisible: true })}
                title="Ver XML" >
            </Button>



        let attachments = []
            //xml = false
            //pdf = false

        if (Array.isArray(item.attachments)) {
            for (const attachment of item.attachments) {

                // console.log(attachment)
                if (/xml/.test(attachment.mime)) {
                    attachments.push(<Button
                        type="primary"
                        icon={<EyeOutlined style={{ color: "white" }} />}
                        className="button-eye"
                        onClick={(e) => this.setState({ factura_xml: attachment, ModalXMLVisible: true })}
                        title="Ver XML">
                    </Button>)
                    //xml = true
                }



            }
            return <Space>{attachments}</Space>
        }
    }


     /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof EmpresaForm
     * 
     * @method renderWidget
     * @description Renderizamos el widget para declara las solicitudes.
     */
    renderWidget = (credential = this.state.empresa?.syncfy_credenciales_id) => {
        if (sessionStorage.getItem('syncfy') && sessionStorage.getItem('syncfy') !== null) {
            if (!window.syncWidget)
                window.syncWidget = new SyncWidget({
                    // Set up the token you created in the Quickstart:  
                    token: this.state.token,
                    config: {
                        // Set up the language to use:  
                        locale: 'es',
                        navigation: {
                            displayStatusInToast: true,
                            displaySiteOrganizationTypes: [this.state.id_site_organization_type]
                        },
                        entrypoint: {
                            credential
                        }
                    }
                })
            else {
                window.syncWidget.setConfig({
                    navigation: {
                        displayStatusInToast: true,
                        displaySiteOrganizationTypes: [this.state.id_site_organization_type]
                    },
                    entrypoint: {
                        credential: credential ? credential : undefined
                    }
                })
                window.syncWidget.setToken(this.state.token)
            }

            window.syncWidget.$on("error", () => {
                message.error("Hubo un error al actualizar")
            });
            window.syncWidget.$on("success", () => this.getFacturas());

            if (!credential)
                window.syncWidget.open()
            return
        }
        return Modal.error({
            title: "Debe seleccionar un Usuario de Syncfy para poder generar las credenciales."
        })
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Facturas de Syncfy"
                        extra={[
                            <Space>
                                <Button
                                    type="ghost"
                                    icon={<ReloadOutlined />}
                                    style={{ color: "white" }}
                                    onClick={() => this.renderWidget()}
                                >Sincronizar Credenciales</Button>
                            </Space>
                        ]}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Razones Sociales " }}
                            dataSource={this.state.facturas.data}
                            pagination={{
                                current: this.state.facturas.page,
                                pageSize: this.state.facturas.limit,
                                total: this.state.facturas.total,
                                showSizeChanger: true,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page, limit) => this.setState(state => {
                                    // getFacturasSyncfy
                                    state.facturas.page = page;
                                    state.facturas.limit = limit;
                                    return state;
                                }, () => this.getFacturasSyncfy())
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col span={1} className="center"></Col>

                                <Col span={4} className="center">
                                    <Text strong>Tipo</Text>
                                </Col>
                                <Col span={8} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Cantidad</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            <Col span={1} className="center">
                                                <Checkbox
                                                    checked={this.state.selectedFacturas[item.id_transaction]}
                                                    onChange={() => this.facturaChecked(item)}
                                                    disabled={item.factura}
                                                ></Checkbox>
                                            </Col>

                                            <Col span={4} className="center">
                                                <Text>{this.renderTipo(item.amount)}</Text>
                                            </Col>
                                            <Col span={8} className="center">
                                                <p style={{ width: '100%' }}>
                                                    <strong>{item.description}</strong><br />
                                                    <small>{item?.extra?.tax_id}</small>
                                                </p>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text strong>{moment.unix(item.dt_transaction).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col span={4} className="center">

                                                <Text>$ {item.amount ? item.amount.toMoney(true) : '0.00'} <small>{item.currency}</small></Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                {this.renderViews(item)}
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                        <FloatingButton
                            background="linear-gradient(126.63deg,#61f5c0 11.98%,#00ff38 83.35%)"
                            icon={<CheckOutlined style={{ fontSize: 50, color: "white" }} />}
                            title="Nuevo registro"
                            onClick={() => this.addFacturas()} />
                    </Content>

                    <ModalXML
                        visible={this.state.ModalXMLVisible}
                        onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml: undefined, xml: undefined })}
                        factura_xml={this.state.factura_xml}
                        xml={this.state.xml}
                    />

                    <ModalClasificarFacturas
                        visible={this.state.modalClasificarVisible}
                        seleccionadas={this.state.selectedFacturas}
                        onClose={() => {
                            this.setState({ modalClasificarVisible: false })
                            this.getFacturasSyncfy()
                        }}
                        onClear={()=>this.setState({selectedFacturas: {}})}
                        empresa_id={this.state.empresa_id}
                    />

                </Spin>
            </>

        )
    }
}

export default function Vista(props) {
    return <FacturasSyncfy params={useParams()} navigate={useNavigate()} {...props}/>
}