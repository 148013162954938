import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Select, DatePicker, InputNumber} from 'antd';
import { ControlFilled, UploadOutlined } from "@ant-design/icons";

//componentes
import ColorPicker from '../../../../Widgets/ColorPicker';
import { Uploader } from "../../../../Widgets/Uploaders";

import axios from "axios"

const { Title } = Typography;
const { Option } = Select;
const moment = require('moment')

class ModalProyectos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            proyectos: [],
            usuarios: {
                data: [],
                page: 1,
                limit: 20,

                total: 0,
                pages: 0
            },
            loading: false,
        }
    }

    ModalProyectos = React.createRef();

    componentDidMount() {
         ;
        this.getAsesores()
        if (this.props.proyecto_id !== undefined) {
            this.getProyecto()
        }
    }


    /**
     * @memberof ModalProyectos
     * @method getAsesores
     * @description Obtiene el listado de usuarios que puedan ser sesores del proyecto
     * 
     */
    getAsesores = (search) => {
        axios.get('/usuarios',{
            params:{
                asesores: true,
                search,
            }
        }).then(({ data }) => {
            this.setState({usuarios: data})
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los usuarios')
        })
    }

     /**
     * @memberof ModalProyectos
     * @method getProyecto
     * @description Obtiene un proyecto de la DB
     */
    getProyecto = () => {

        this.setState({ loading: true })

        axios.get('/proyecto', {
            params: {
                id: this.props.proyecto_id
            }
        }).then(({data}) => {
            let proyecto = data

            this.ModalProyectos.current.setFieldsValue({
                ...proyecto,
                nombre: proyecto.nombre,
                descripcion: proyecto.descripcion,
                color: proyecto.color,
                fecha_venta: moment(proyecto.fecha_venta),
                fecha_final: moment(proyecto.fecha_final),
                asesor_id: proyecto?.asesor_id?._id ? {
                    value: proyecto?.asesor_id?._id,
                    label: `${proyecto?.asesor_id?.nombre} ${proyecto?.asesor_id?.apellidos}`
                } : null,
                documentos: proyecto?.documentos?.map((file, index) => ({
                    uid: file.file,
                    name: file.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/proyecto/?id=" + this.props.proyecto_id + "&documento=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                })),

            })

        }).catch(error => {
            message.error('Error al traer proyecto')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalProyectos
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        this.setState({loading: true})

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            asesor_id: (values?.asesor_id?.value) ? values.asesor_id.value : null,
            cliente_id: this.props.cliente_id,
            id: this.props.proyecto_id,
            documentos: undefined
        })

        values.documentos?.forEach(e => {
            if (!e.fromDB) formData.append("documentos", e, e.name)
        })

        if(this.props.proyecto_id){
            this.editProyecto(formData)
        }else{
            this.addProyecto(formData)
        }
        
    }


    /**
     * @memberof ModalProyectos
     * @method addProyecto
     * @description Crea un nuevo proyecto
     */
    addProyecto = (values) => {
        axios.post('/crm/add/proyectos',values).then(response =>{
            message.success('Proyecto enlazado')
            this.props.onClose(true)
        }).catch(error=>{
            console.log(error)
            message.error('Error al crear proyecto')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof ModalProyectos
     * @method editProyecto
     * @description se edita el proyecto
     */
    editProyecto = (values) => {
        axios.post('/crm/update/proyectos',values).then(response =>{
            message.success('Proyecto editado')
            this.props.onClose(true)
        }).catch(error=>{
            console.log(error)
            message.error('Error al editar proyecto')
        }).finally(()=>this.setState({loading: false}))
    }





    render() {


        console.log("this", this.state)

        return (
            <Form
                layout="vertical"
                name="form-proyectos"
                ref={this.ModalProyectos}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row justify="center" gutter={[16,16]}>
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Dominio"
                                name="dominio"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="dominio" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción" ></Input.TextArea>

                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Color"
                                name="color"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un color"
                                }]}
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={10}>
                            <Form.Item
                                label="Fecha de Venta"
                                name="fecha_venta"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={10}>
                            <Form.Item
                                label="Fecha de Termino"
                                name="fecha_final"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Asesor"
                                name="asesor_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el asesor"
                                }]}
                            >
                                <Select 
                                    placeholder="Proyecto"
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getAsesores(search)}
                                    labelInValue
                                >
                                    {this.state.usuarios.data.map(usuario => <Option value={usuario._id}>{usuario.nombre} {usuario.apellidos}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={10}>
                            <Form.Item
                                label="Monto de Venta"
                                name="monto_venta"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber 
                                    min={0.01} 
                                    defaultValue={0} 
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} 
                                    className="width-100 " 
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={10} >
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.acreedor_id ? {
                                        method: "PUT",
                                        name: "documentos",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        //action: axios.defaults.baseURL + '/transacciones',
                                        onRemove: e => {
                                            axios.put('/crm/update/proyectos', {
                                                documento: e.uid,
                                                id: this.props.proyecto_id
                                            })
                                        }
                                    } : {}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={props.proyecto_id ? 'Editar Proyecto' : 'Añadir Proyecto'}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-proyectos', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProyectos {...props} />
    </Modal>

}