import React from 'react'
import { Route, Routes, } from "react-router-dom";

import Cuentas from '../components/Admin/Cuentas/Cuentas';


/**
 * 
 * @export
 * @function RouterCuentas
 * @description Router for Cuentas routes 
 */
function RouterCuentas(props) {
  return (
    <Routes>
      <Route path="" element={<Cuentas {...props} />} />>
    </Routes>
  )
}

export default RouterCuentas