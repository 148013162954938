import React from "react";
import {
    Row,
    Col,
    Button,
    Popconfirm,
    Spin,
    Divider,
    Table,
    PageHeader,
    Avatar,
    Layout,
    Input,
    Form,
    Tag,
    message,
    Space,
    List,
    Card,
    Typography,
    Image, 
    Tooltip, 
    Collapse
} from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";

import {
    CloseOutlined,
    EditOutlined,
    EyeOutlined,
    HighlightOutlined,
    PlusOutlined,
} from "@ant-design/icons";

// Componentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import {
    IconDelete,
    IconEdit,
    IconDetails,
    IconEye,
} from "../../../Widgets/Iconos";

// Modales
import ModalClientes from "./ModalClientes";
import DetallesClientesCard from "./DetallesClientesCard";

//css
import "../../../../Styles/Modules/CRM/Clientes.css";


const { Title, Text, Paragraph } = Typography;
const moment = require("moment");

/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Componente del listado de transacciones
 */
export default class Clientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientes: {
                data: [],
                loading: false,
                page: 1,
                limit: 20,
                total: 0,
                pages: 0,
            },

            clienteActive: false,
        };
    }

    componentDidMount() {


        this.getClientes();
    }

    /**
     *
     *
     * @memberof Clientes
     *
     * @method getClientes
     * @description Obtiene los Clientes
     */
    getClientes = (
        {
            page = this.state.clientes.page,
            limit = this.state.clientes.limit,
            search = this.props.search,
        } = this.state.clientes
    ) => {
        axios
            .get("/clientes", {
                params: { page, limit, search },
            })
            .then(({ data }) => {
                this.setState({
                    clientes: data,
                });
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al traer la información");
            })
            .finally(() => this.setState({ loading: false }));
    };

    render() {
        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Clientes"
                />
                <Content className="admin-content content-bg pd-1">
                    <Row className="parent" gutter={20}>
                        <Col
                            className="custom-colum"
                            // span={1}
                            // span={5}
                            span={this.state.clienteActive ? 18 : 24}
                        >
                            <Row >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.clientes.data}
                                    key={(item) => item._id}
                                    className="component-list"
                                    locale={{ emptyText: "Sin Cliente" }}
                                    pagination={{
                                        current: this.state.clientes.page,
                                        pageSize: this.state.clientes.limit,
                                        total: this.state.clientes.total,
                                        
                                        className: "flex-left",
                                        onChange: (page, limit) =>
                                            this.getClientes({ page, limit }),
                                    }}
                                    header={
                                        <Row className="header-list width-100">
                                            <Col lg={5}>
                                                <Text strong>
                                                    Alías
                                                </Text>
                                            </Col>
                                            <Col span={7} >
                                                <Text strong>Nombre</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text strong>RFC</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text strong>Proyectos</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text strong> Cliente desde </Text>
                                            </Col>
                                            <Col
                                                span={2}
                                                className="center"
                                            ></Col>
                                        </Row>
                                    }
                                    renderItem={(item) => (
                                        <List.Item className="component-list-item">
                                            <Card className="ant-card-list width-100">
                                                <Row className="width-100">
                                                    <Col lg={5}  className="flex-left">
                                                       

                                                        <CustomAvatar
                                                            height={42}
                                                            width={107}
                                                            placeholder={true}
                                                            url={{
                                                                url:  axios.defaults.baseURL + "/cliente/" + item._id,
                                                                logo: item?.logo?.file
                                                            }}
                                                            image={item.logo}
                                                            name={item.alias}
                                                        />
                                                        <Title level={4} className="m-0">{item.alias}</Title>
                                                    </Col>
                                                    <Col span={7} className="">
                                                        <Text>
                                                            {((item.nombre || item.apellido_paterno || item.apellido_materno) && item.empresa) ? <>
                                                                <Title style={{ marginBottom: 0 }} level={5}>{item.empresa}</Title>
                                                                <Paragraph style={{ marginBottom: 0 }}>{item.nombre} {item.apellido_paterno} {item.apellido_materno}</Paragraph>
                                                            </> : (
                                                                (item.nombre || item.apellido_paterno || item.apellido_materno) ?
                                                                    <>{item.nombre} {item.apellido_paterno} {item.apellido_materno}</>
                                                                    :
                                                                    item.empresa
                                                            )}
                                                        </Text>
                                                    </Col>
                                                    <Col span={3} className="center " >
                                                        <Text>{item.rfc}</Text>
                                                    </Col>
                                                    <Col span={4} className="center " >
                                                        <Avatar.Group maxCount={4}>
                                                            {item.proyectos.map(p => <Tooltip title={p.nombre} placement="top">
                                                                <CustomAvatar
                                                                    color={p.color}
                                                                    name={`${p.nombre}`}
                                                                />
                                                            </Tooltip>)}
                                                        </Avatar.Group>
                                                    </Col>
                                                    <Col lg={3} className="center " >
                                                        <Text className="text-date-format"> {moment(item.createdAt).fromNow()} </Text>
                                                    </Col>
                                                   

                                                    <Col
                                                        span={2}
                                                        className="center "
                                                    >
                                                        <Space>
                                                            <Link
                                                                to={`/admin/customer-relationship-management/clientes/${item._id}`}
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    icon={<EyeOutlined style={{ color: "currentcolor" }} />}
                                                                />
                                                            </Link>
                                                            <Button
                                                                style={{ paddingTop: "3px" }}
                                                                type="primary"
                                                                icon={<EditOutlined  style={{ color: "currentcolor" }}  />}
                                                                title="Editar"
                                                                onClick={() => {
                                                                    this.setState(
                                                                        !this
                                                                            .state
                                                                            .clienteActive
                                                                            ? {
                                                                                clienteActive: true,
                                                                                cliente_id:
                                                                                    item._id,
                                                                            }
                                                                            : {
                                                                                clienteActive: false,
                                                                                cliente_id:
                                                                                    undefined,
                                                                            }
                                                                    );
                                                                }}
                                                            />
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar este cliente?"
                                                                onConfirm={() =>
                                                                    axios
                                                                        .delete(
                                                                            `/cliente/${item._id}`,
                                                                            {
                                                                                params: {
                                                                                    cliente_id: item._id,
                                                                                },
                                                                            }
                                                                        )
                                                                        .then(
                                                                            () => {
                                                                                this.getClientes();
                                                                                message.success(
                                                                                    "Cliente eliminado"
                                                                                );
                                                                            }
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                error
                                                                            ) =>
                                                                                console.log(
                                                                                    error
                                                                                )
                                                                        )
                                                                }
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    style={{
                                                                        paddingTop:
                                                                            "5px",
                                                                    }}
                                                                    type="danger"
                                                                    icon={
                                                                        <IconDelete />
                                                                    }
                                                                    title="Eliminar"
                                                                    danger
                                                                />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </Row>
                        </Col>
                        <Col
                            className={
                                "custom-colum w-100 " +
                                (this.state.clienteActive
                                    ? "drawer-open"
                                    : "drawer-close")
                            }
                            span={6}
                        >
                            <DetallesClientesCard
                                cliente_id={this.state.cliente_id}
                                onClose={() => {
                                    this.setState({
                                        clienteActive: false,
                                        cliente_id: undefined,
                                    });
                                    this.getClientes();
                                }}

                            />
                        </Col>
                    </Row>
                    <FloatingButton
                        title="Nuevo registro"
                        onClick={() => this.setState({ clienteActive: true })}
                    />
                </Content>
            </>
        );
    }
}
