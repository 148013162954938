import React, { Component } from "react";
import { Button, Col, Row, Typography, Card, List, Space, message, Avatar, Tag } from "antd";
import { IconDelete, IconEdit, IconEye, IconHyperLink } from "../../../Widgets/Iconos";
import axios from 'axios'
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
// import "../../../../Styles/Modules/CRM/crm.css"
// import "../../../../Styles/Modules/CRM/Clientes/clientes.css"

const { Text } = Typography;


/**
 * @export
 * @class FacturasCliente
 * @extends {Component}
 * @description Lista de facturas del cliente
 */
export default class FacturasCliente extends Component {

	constructor(props) {
        super(props)
        this.state = {
            facturas: [{},{}],
        }
    }

    componentDidMount(){
         
        if(this.props.cliente_id){
    		/* this.getFacturas() */
    	}
    }


    /**
     * @memberof FacturasCliente
     * @method getFacturas
     * @description Obtiene los facturas del cliente
     *
     */
    getFacturas = (page = 1) => {
        this.setState({ loading: true })
        axios.get('/facturas', {
        	params:{
            	cliente_id: this.props.cliente_id,
            	page: page
        	}
        }).then(response => {
      		this.setState({
      			facturas: response.data.itemsList,
      			page: response.data.currentPage,
      			total: response.data.itemCount
      		})  	
      
        }).catch(error => {
            message.error('Error al traer los facturas')
            console.log(error.response)
        }).finally(() => this.setState({ loading: false }))
    }


	render() {


		return (
			<>
				<Row>
					<List
						itemLayout="horizontal"
						dataSource={this.state.facturas}
						key={(item => item._id)}
						className="component-list w-100"
						renderItem={item => (
							<List.Item className="component-list-item">
								<Card className="ant-card-list width-100">
									<Row className="width-100">
										<Col lg={3} className="center">
											<Text className="text-date-format transacciones-cliente-fecha">{/* {item.fecha} */}{'{{Fecha}}'}</Text>
										</Col>
										<Col lg={4} className="center">
											<Text>{/* {item.nombre} */}{'{{Servicio}}'}</Text>
										</Col>
										<Col lg={4} className="center">
											<Text>{/* {item.proyecto} */}{'{{Proyecto}}'}</Text>
										</Col>
										<Col lg={2} className="center">
											<Avatar />
										</Col>
										<Col lg={3} className="center">
											{/* <Text className="text-amount-format transacciones-cliente-monto">{item.monto}</Text> */}{'$ {{Monto}} MXN'}
										</Col>
										<Col lg={3} className="center">
											{/* <Text className="text-amount-format transacciones-cliente-monto">{item.monto}</Text> */}{'$ {{Monto}} MXN'}
										</Col>
										<Col lg={2} className='center'>
											<Tag>Pagado</Tag>
										</Col>
										<Col lg={3} className="center">
											<Space direction="horizontal">
												<Button type="text" style={{ backgroundColor: "#FF03D7" }} icon={<IconEye color="white" />} />
												<Button type="text" style={{ backgroundColor: "#456EFF" }} icon={<IconEdit />} />
												<Button type="text" style={{ backgroundColor: "#FF547D" }} icon={<IconDelete color="white" />} />
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>
			</>
		)
	}
}