import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select } from 'antd';

//componentes
import PhoneInput from "../../Widgets/Inputs/PhoneInput";
import { SelectRol } from "../../Widgets/Inputs/Selects";

  import axios from "axios"
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
         

        if (this.props.usuario_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/usuario/' + this.props.usuario_id)
            .then(({data}) => {
                this.formRef.current.setFieldsValue(data)
            }).catch(error => {
                message.error('Error al obtener el usuario')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {
        console.log(values)
        if (this.props.usuario_id) {
            this.update(values)
        } else {
            this.add(values)
        }
    }




    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/usuario', {
            ...values
        }).then(response => {
            message.success('¡Usuario Creado!')
            this.props.onClose()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al crear el Usuario')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Añade un nuevo registro de usuario
    */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/usuario', {
            ...values,
            id: this.props.usuario_id
        }).then(response => {
            message.success('¡Usuario Actualizado!')
            this.props.onClose()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al actualizar el Usuario')
        }).finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-usuario"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">
                        <Col span={18}>
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="apellidos"
                                label="Apellidos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los apellidos',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingrese el Emil',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el Teléfono',
                                    },
                                ]}
                            >
                                <PhoneInput />
                            </Form.Item>
                            <Form.Item
                                name="rol_id"
                                label="Rol de usuario"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el Rol de Usuario',
                                    },
                                ]}
                            >
                                <SelectRol/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, usuario } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={usuario ? "Editar Usuario" : "Crear Usuario"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-usuario', key: 'submit', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}