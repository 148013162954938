import React, { useState, Component, useEffect } from 'react';
import { Card, Typography, Row, Col, List, Tag, Space, Badge } from "antd"
import { DragDropContext, Droppable, Draggable, } from 'react-beautiful-dnd';
import { MdNewReleases} from 'react-icons/md';

import Color from "color"
import Avatar from "../../Widgets/Avatar/Avatar"
import moment from "moment"

import { BellOutlined, MessageOutlined, DeleteOutlined } from "@ant-design/icons"



const { Text, Title, Paragraph } = Typography

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    boxShadow: isDragging ? "0px 0px 20px 7px rgba(0, 0, 0, 0.12)" : undefined,
    ...draggableStyle
})

export default function (props) {
    const { provided, snapshot, color, onButtonClick, onDeleteClick, item = {}, draggableId } = props
    const { proyecto_id, modulo, responsable_id, _id, folio, order, estatus, email_contacto, nombre_contacto } = item

    let [showTools, setShowTools] = useState(draggableId == _id)
    let [update, setUpdate] = useState(false)

    useEffect(() => {
        let interval = setInterval(function () {
            setUpdate(!update)
        }, 60000)

        return () => {
            clearInterval(interval)
        }

    }, [])

    let haveRibbon = moment().subtract(10, "minute").isBefore(moment(item.createdAt))

    let content = <Card
        key={_id}
        className={'kanban-element-draggable' + ((showTools || snapshot.isDragging || snapshot.draggingOver || snapshot.mode || snapshot.dropAnimation) ? " show-tools " : "")}
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
        )}
        onMouseEnter={() => setShowTools(true)}
        onMouseLeave={() => setShowTools(false)}
    >
        {haveRibbon && <MdNewReleases style={{ position: "absolute", fontSize: 30, right: -10, top: -10, zIndex: 1, color: "red" }} />}
        <Space
            {...provided.dragHandleProps}
            size={1}>
            <Avatar className="kanban-element-draggable-avatar" image={proyecto_id?.logo} name={proyecto_id?.nombre} size={25} />
            <Typography>
                <Title level={5} className="kanban-element-draggable-title" style={{ fontSize: 14 }}>{proyecto_id?.nombre.substring(0, 30)}</Title>
                <Paragraph className="kanban-element-draggable-subtitle">{folio} {modulo}</Paragraph>
            </Typography>
        </Space>
        {/* MdNewReleases */}
        <Tag style={{ position: "absolute", right: 0, top: 8, display: "flex", gap: 3, flexDirection: "row" }} color={Color(color).hex()}>{moment(item.createdAt).fromNow()}</Tag>
        <Title className="kanban-element-draggable-content-title" level={5}>{item.asunto}</Title>
        <Paragraph className="kanban-element-draggable-content-description" style={{ textAlign: "justify" }}>{item.descripcion}</Paragraph>
        <Text>{nombre_contacto || ""}</Text>
        <Text style={{ fontStyle: "italic", fontSize: 12, color: "rgba(0,0,0,0.5)" }}> {email_contacto || ""} </Text>
        <div className={'kanban-element-draggable-tools show-tools'} >
            {/* <div className={'kanban-element-draggable-tools' + ((showTools || snapshot.isDragging || snapshot.draggingOver || snapshot.mode || snapshot.dropAnimation) ? " show-tools " : "")} > */}
            <Space style={{ width: "100%", justifyContent: 'space-between' }}>
                <Space size={1} style={{ position: "relative", top: 10 }}>
                    {responsable_id ? <>
                        <Avatar
                            size="small"
                            placeholder={true}
                            image={responsable_id.logo}
                            name={responsable_id.nombre}
                        />
                        <Title level={5} className="kanban-element-draggable-title" style={{ fontWeight: "lighter" }}>{responsable_id?.nombre} {responsable_id?.apellidos}</Title>
                    </> : <Text>N/A</Text>}
                </Space>
                <Space size={12} style={{ position: "relative", top: 10, left: '-10px' }}>
                    <DeleteOutlined style={{ fontSize: 20 }} onClick={(...args) => onDeleteClick({ ticket_id: _id, estatus })} />
                    <Badge count={item.actualizaciones} onClick={(...args) => onButtonClick({ ticket_id: _id, tipo: 2 })}>
                        <BellOutlined style={{ fontSize: 20 }} />
                    </Badge>
                    <Badge count={item.mensajes} onClick={(...args) => onButtonClick({ ticket_id: _id, tipo: 1 })}>
                        <MessageOutlined style={{ fontSize: 20 }} />
                    </Badge>
                </Space>
            </Space>
        </div>
    </Card>

    // if (haveRibbon)
    //     return <Badge.Ribbon text={<></>} color={color}>
    //         {content}
    //     </Badge.Ribbon>
    return content
}


