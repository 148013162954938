import React, { Component } from "react";
import { PlusOutlined } from "@ant-design/icons"
import { Button, Col, Row, Typography, Card, List, Space, message, Avatar, Modal } from "antd";
import { IconDelete, IconEdit, IconEye, IconHyperLink } from "../../../../Widgets/Iconos";
import axios from 'axios'
import CustomAvatar from "../../../../Widgets/Avatar/Avatar";

//Modal
import ModalFacturas from '../../../Finanzas/Facturas/ModalFacturas'
import ModalXML from '../../../Finanzas/Facturas/ModalXML'

const { Text } = Typography;
const moment = require('moment')

/**
 * @export
 * @class FacturasCliente
 * @extends {Component}
 * @description Lista de facturas del cliente
 */
export default class FacturasCliente extends Component {

	constructor(props) {
        super(props)
        this.state = {

            facturas: {
            	data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
            },
        }
    }

    componentDidMount(){
         
        if(this.props.cliente_id){
    		 this.getFacturas() 
    	}
    }


    /**
     * @memberof FacturasCliente
     * @method getFacturas
     * @description Obtiene los facturas del cliente
     *
     */
    getFacturas = (page = this.state.facturas.page) => {
        this.setState({ loading: true })
        axios.get('/facturas', {
        	params:{
            	cliente_id: this.props.cliente_id,
            	page: page
        	}
        }).then(({data}) => {
        	console.log("data", data);
        	this.setState({ facturas: { ...this.state.facturas, ...data } });
      
        }).catch(error => {
            message.error('Error al traer los facturas')
            console.log(error.response)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof FacturasCliente
     * @method desvincularFactura
     * @description Obtiene los transacciones del cliente
     *
     */
    desvincularFactura = (factura_id) => {
        Modal.confirm({
        	title: '¿Seguro de desvincular la factura?',
        	okText: 'Si',
        	cancelText: 'Cancelar',
        	onOk: () => axios.post('/crm/desvicular/factura',{
        		factura_id
        	}).then(response => {
        		message.success('Factura desvinculada')
        		this.getFacturas()
        		this.props.refreshInfo()
        	}).catch(error => {
        		console.log("error", error);
        		message.error('Error al desvincular')
        	})
        })
    }


	render() {


		return (
			<>
				<Row gutter={[8, 8]}>
					<Col push={20} span={4}>
							<Button type="primary" icon={<PlusOutlined />} onClick={()=>this.setState({modal_visible: true})}>
								Agregar Factura
							</Button>
					</Col>
						<List
							itemLayout="horizontal"
							dataSource={this.state.facturas.data}
							key={(item => item._id)}
							className="component-list w-100"
							renderItem={item => (
								<List.Item className="component-list-item">
									<Card className="ant-card-list width-100">
										<Row className="width-100">
											<Col span={5} className="center">
												<Text className="text-date-format transacciones-cliente-fecha">{moment(item.fecha).format('DD-MM-YYYY')}</Text>
											</Col>
											<Col span={5} className="center">
												<Text>{item.concepto}</Text>
											</Col>
											<Col span={5} className="center">
												<Text>{item.proyecto_id?.nombre}</Text>
											</Col>
											<Col span={5} className="center">
												<Text className="text-amount-format transacciones-cliente-monto">$ {item.monto.toMoney(true)} MXN</Text>
											</Col>
											<Col span={4} className="center">
												<Space direction="horizontal">
													<Button
										                type="primary"
										                icon={<IconEye/>}
										                onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
										                title="Ver XML"
										                disabled={!!item.factura_xml}
										            ></Button>
													<Button 
														type="primary" 
														icon={<IconHyperLink />}
														title="Quitar enlace"
														onClick={()=>this.desvincularFactura(item._id)}
													/>
													<Button
														onClick={()=>this.setState({modal_visible: true, factura_id: item._id})}
														type="primary" 
														icon={<IconEdit />}
														title="Editar"
													/>
													<Button
														type="primary"
														icon={<IconDelete />}
														title="Eliminar"
														danger
													/>
												</Space>
											</Col>
										</Row>
									</Card>
								</List.Item>
							)}
						/>
				</Row>
				<ModalFacturas
					visible={this.state.modal_visible}
					onClose={()=>{
						this.setState({modal_visible: false})
						this.getFacturas()
					}}
					factura_id={this.state.factura_id}
				/>
				<ModalXML
                    visible={this.state.ModalXMLVisible}
                    onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml_storage: undefined, xml: undefined })}
                    factura_xml_storage={this.state.factura_xml_storage}
                />
			</>
		)
	}
}