import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin } from 'antd';
import { PlusOutlined,  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import ColorPicker from '../../../Widgets/ColorPicker';

import axios from "axios"

const { Title } = Typography;
const { Option } = Select;


class ModalClientes extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    refModalClientes = React.createRef();

    componentDidMount() {
         ;

        if (this.props.cliente_id !== undefined) {
            this.getCliente()
        }
    }

    /**
     * @memberof ModalClientes
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.cliente_id) {
            this.updateCliente(values)
        } else {
            this.addCliente(values)
        }

    }

    /**
     * @memberof ModalClientes
     * @method addCliente
     * @description Añade una cliente a la BD
     *
     */
    addCliente = (values) => {
        axios.post('/clientes/add', {
            ...values,
            logo: this.state.image?.name,
        }).then(response => {
            message.success('Cliente creado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al crear el cliente')
            console.log(error)
        })
    }

    /**
     * @memberof ModalClientes
     * @method getCliente
     * @description Obtiene una Cliente de la DB
     *
     */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/cliente', {
            params: {
                id: this.props.cliente_id
            }
        }).then(response => {
            let cliente = response.data;
            this.setState({
                cliente: response.data.data,
                tipo: cliente.tipo,
                empresa: cliente.empresa,
                telefono: cliente.telefono,
                image: cliente.logo ? {
                    url: axios.defaults.baseURL + '/upload/' + cliente.logo,
                    name: cliente.logo
                } : undefined,
            })

            this.refModalClientes.current.setFieldsValue({
                nombre: cliente.nombre,
                empresa: cliente.empresa,
                rfc: cliente.rfc,
            })

        }).catch(error => {
            message.error('Error al traer la cliente')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalClientes
     * @method updateCliente
     * @description Actualiza la información de una Cliente
     * 
     */
    updateCliente = (values) => {
        this.setState({ loading: true })
        axios.put('/clientes/update', {
            ...values,
            logo: this.state.image?.name,
            id: this.state.cliente._id,
        }).then(response => {
            message.success('Cliente actualizado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar la cliente')
            console.log(error.response)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof ModalProyectos
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        if (this.props.cliente_id !== undefined)
            axios.put('/clientes/update', { logo: null, id: this.props.cliente_id })
                .catch(error => console.log(error)).finally(() => {
                    this.setState({ loadingImage: false, image: null })
                })
        else {
            this.setState({ loadingImage: false })
        }

    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })

        }


        return e && e.fileList;
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito");
                this.setState({ loadingImage: false, image: null })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    };




    render() {

        const uploadButton = (
            <div>
                {this.state.loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Subir Imagen</div>
            </div>
        );


        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.refModalClientes}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row align="center">
                        <Form.Item
                            name="logo"
                            align="center"
                            getValueFromEvent={this.normFile}
                            valuePropName="fileList"
                            help={this.state.image ? <Button
                                className="btn-upload-delete"
                                shape="circle"
                                danger
                                onClick={this.deleteImage}
                            ><DeleteOutlined /></Button> : null}
                        >
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={axios.defaults.baseURL + "/upload/add"}
                                accept="image/*"
                            >

                                {this.state.image ? <img src={this.state.image?.url} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el titular"
                                }]}
                            >
                                <Input placeholder="Titular" ></Input>

                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Empresa"
                                name="razon_social"
                                rules={[{
                                    required: false,
                                    message: "Por favor, ingrese el número de teléfono"
                                }]}
                            >
                                <Input placeholder="Empresa SA de CV" ></Input>

                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Empresa"
                                name="empresa"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre de la empresa"
                                }]}
                            >
                                <Input placeholder="I SEE YOU TECHNOLOGIES" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Teléfono"
                                name="telefono"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el número de teléfono"
                                }]}
                            >
                                <Input placeholder="(55)-555-5555" ></Input>

                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11}}>
                            <Form.Item
                                label="Correo Electronico"
                                name="correo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el correo"
                                }]}
                            >
                                <Input placeholder="test@email.com" ></Input>

                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Sitio"
                                name="url"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el sitio de la empresa"
                                }]}
                            >
                                <Input placeholder="www.algo.com" ></Input>

                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                label="Dirección"
                                name="direccion"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el número de teléfono"
                                }]}
                            >
                                <Input placeholder="(55)-555-5555" ></Input>

                            </Form.Item>
                        </Col>

                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Cliente</Title>
        </div>
        <ModalClientes {...props} />
    </Modal>

}