import React, { Component, useContext } from 'react';
import { Row, Col, Button, Popconfirm, Space, Spin, PageHeader, Layout, message, Typography, Tooltip, Card, List } from 'antd'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BankOutlined } from '@ant-design/icons'


//Componentes
import User from "../../../Hooks/Logged";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import usePermissions from "../../../Hooks/usePermissions";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconBankError, IconMoneyCircle } from '../../Widgets/Iconos';

import ModalCuentas from './ModalCuentas.js'
import ModalConfiguraciónSyncfy from '../Syncfy/ModalConfiguraciónSyncfy'

const { Content } = Layout;
const { Text } = Typography;
/**
 *
 *
 * @export
 * @class Cuentas
 * @extends {Component}
 * @description Vista de Project Manager
 */
class Cuentas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            cuenta_id: undefined,


            cuentas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            }
        }
    }

    componentDidMount() {
         ;
        this.getCuentas();
    }


    /**
    * @memberof Cuentas
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    getCuentas = ({
        page = this.state.cuentas.page,
        limit = this.state.cuentas.limit,

        search = this.props.search

    } = this.state.cuentas) => {
        this.setState({ loading: true, cuentas: { ...this.state.cuentas, page, limit } })
        axios.get('/cuentas', {
            params: { page, limit, search}
        }).then(({ data }) => {
            this.setState({ cuentas: data })
        }).catch(error => {
            message.error(error.response.data.message)
        }).finally(() => this.setState({ loading: false }))
    }

    render() {

        const { cuentas } = this.state

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Cuentas"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Cuentas" }}
                            dataSource={cuentas.data}
                            pagination={{
                                current: cuentas.page,
                                pageSize: cuentas.limit,
                                total: cuentas.total,
                                showSizeChanger: true,
                                position: 'bottom',
                                onChange: (page, limit) => {
                                    this.getCuentas({ page, limit })
                                }
                            }}
                            header={<Row className="width-100 pr-1 pl-1 component-list-header" >
                                <Col span={2} className="center">
                                    <Text strong>Logo</Text>
                                </Col>

                                <Col span={5} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Saldo</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Titular</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Número Cuenta</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[6,8]}>

                                            <Col xs={12} lg={2} className="center">
                                                <Text strong><CustomAvatar name={[item.nombre]} image={item.logo} color={item.color} /></Text>
                                            </Col>

                                            <Col xs={12} lg={5} className="center">
                                                <Text strong>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={12} lg={4} className="center">
                                                <Text className="text-gray">$ {item.saldo.toMoney(true)} MXN</Text>
                                            </Col>
                                            <Col xs={12} lg={5} className="center">
                                                <Text className="text-gray">{item.titular}</Text>
                                            </Col>
                                            <Col xs={12} lg={5} className="center">
                                                <Text className="text-gray">{item.cuenta}</Text>
                                            </Col>
                                            <Col xs={12} lg={3} className="center">
                                                <Space>

                                                    <Tooltip title={(item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "Cuenta con enlace bancario" : "Cuenta sin enlace bancario"}>
                                                        <Button
                                                            onClick={() => this.setState({ syncfy_id: item._id, modalSyncfy: true })}
                                                            danger={!(item.syncfy_usuario_id && item.syncfy_credenciales_id)}
                                                            type="primary"
                                                            icon={
                                                                (item.syncfy_usuario_id && item.syncfy_credenciales_id)
                                                                    ?
                                                                    <BankOutlined style={{ fontSize: 22, color: "white", position: "relative", bottom: 2 }} />
                                                                    :
                                                                    <IconBankError color="white" style={{ position: 'relative', bottom: 2 }} />
                                                            }
                                                            title="Eliminar"
                                                            style={{ backgroundColor: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "#1890ff" : undefined, border: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "none" : undefined }}
                                                        />
                                                    </Tooltip>

                                                    <Tooltip title={(item.syncfy_credenciales_id && item.syncfy_cuenta_bancaria_id) ? "Ir a las transacciones bancarias" : "No hay un enlace bancario, no podemos ir a las transacciones bancarias."}>
                                                        <Button
                                                            disabled={!(item.syncfy_credenciales_id && item.syncfy_cuenta_bancaria_id)}
                                                            icon={<IconMoneyCircle width={20} height={20} style={{ marginTop: 2 }} color="currentColor" />}
                                                            onClick={() => this.props.navigate(`/admin/finanzas/transacciones/sin-clasificar/${item._id}`)}
                                                        />
                                                    </Tooltip>

                                                    <Button
                                                        style={{ paddingTop: '3px' }}
                                                        type="primary"
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, cuenta_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta Cuenta?"
                                                        onConfirm={() => axios.delete('/cuenta/' + item._id).then(() => {
                                                                this.getCuentas()
                                                                message.success('Cuenta eliminada')
                                                            }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            style={{ paddingTop: '5px' }}
                                                            danger
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                        />
                                                    </Popconfirm>


                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />


                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />


                </Spin>
                <ModalCuentas
                    visible={this.state.modalVisible}
                    onClose={() => {
                        this.setState({ modalVisible: false, cuenta_id: undefined })
                        this.getCuentas()
                    }}
                    cuenta_id={this.state.cuenta_id}
                />
                <ModalConfiguraciónSyncfy
                    visible={this.state.modalSyncfy}
                    id={this.state.syncfy_id}
                    onCancel={() => {
                        this.setState({ modalSyncfy: false, id: null, cuenta_id: undefined })
                        this.getCuentas();
                    }}
                    tipo={1}
                />
            </>
        )

    }
}

export default function Vista(props) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarCuentas: ["cuentas", "edit"],
        deleteCuentas: ["cuentas", "delete"],
        createCuentas: ["cuentas", "create"],
        enlaceCuenta: ["cuentas", "syncfy_cuenta"]

    });

    return <Cuentas {...props} navigate={useNavigate()} {...permisos}/>

}