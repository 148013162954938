import React, { Component } from 'react';
import { Row, Col, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Card, Typography } from 'antd'
import axios from 'axios';  
import { Link } from "react-router-dom"
import Carousel from 'react-multi-carousel';
//Componentes
import { CardEmpresa } from "../../Widgets/Cards";
import FacturasList from './Facturas/FacturasList';
import TransaccionesList from './Transacciones/TransaccionesList';

const responsive = {
    desktop_fhq: {
        breakpoint: { max: 4000, min: 1200 },
        items: 2
    },
    desktop_hq: {
        breakpoint: { max: 1500, min: 1200 },
        items: 1
    },
    desktop_xxl: {
        breakpoint: { max: 1200, min: 992 },
        items: 1
    },
    desktop_xl: {
        breakpoint: { max: 992, min: 768 },
        items: 1
    },
    desktop_lg: {
        breakpoint: { max: 768, min: 576 },
        items: 1
    },
    desktop_md: {
        breakpoint: { max: 576, min: 464 },
        items: 1
    },
    desktop_sm: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    },

    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const { Content } = Layout;
const { Text, Title } = Typography
/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista de Areas
 */
export default class Areas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            empresas: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },
        }
    }

    refFacturas = React.createRef()
    refTransacciones = React.createRef()

    componentDidMount() {
        this.getEmpresas()
    }

    /**
     *
     * @memberof Empresas
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
    getEmpresas = (
        {
            page = this.state.empresas.page,
            limit = this.state.empresas.limit,
            search = this.props.search,
        } = this.state.empresas
    ) => {
        this.setState({ loading: true,});
        axios
            .get("/empresas", {
                params: { 
                    contabilidad: true
                },
            })
            .then(({ data }) => {
                this.setState({ empresas: data });
            })
            .catch((res) => {
                message.error(res.response.data.message);
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     *
     * @memberof Empresas
     * @method updateInfo
     * @description Actualiza la informacion de los listados
     */
    updateInfo = () => {
        this.refFacturas.current.getFacturas()
        this.refTransacciones.current.getTransacciones()
    }

    render() {

        const { empresas } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Contabilidad"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row>
                            <Col span={24}><Title className="subtitle-dashboard" level={2}>Empresas</Title></Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Carousel
                                    responsive={responsive}
                                    className='carousel-cards'
                                >
                                    {empresas.data.map(empresa => {
                                        return <Link to={`/admin/contabilidad/empresa/${empresa._id}`}>
                                            <CardEmpresa
                                                className="mr-1"
                                                empresa={empresa} 
                                                transacciones={empresa.transacciones}
                                                facturas={empresa.facturas}
                                            />
                                        </Link>
                                    })}
                                </Carousel>
                            </Col>
                        </Row>

                        <FacturasList 
                            ref={this.refFacturas}
                            updateInfo={this.updateInfo}
                        />

                        <TransaccionesList
                            ref={this.refTransacciones}
                            updateInfo={this.updateInfo}
                        />

                    </Content>
                    
                </Spin>
            </>
        )
    }
}