import React, { Component } from 'react';
import { Row, Col, Form, Input, Typography, DatePicker, Button, InputNumber, Collapse, Space, Modal } from 'antd'

import { EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';

//Modal
import ModalServicioAdquirido from "./ModalServicioAdquirido";

const { Panel } = Collapse;
const { Title, Text } = Typography;
const moment = require("moment");

/**
 * @export
 * @class Servicios
 * @extends {Component}
 * @description Vista de Servicios, crea los servicios adquiridos de los proyectos
 */
class Servicios extends Component {


	constructor(props){
		super(props);
		this.state = {
			servicios_adquiridos: [],
			servicio: undefined,
			index: undefined,
		}
	}

	componentDidMount(){
		if(this.props.servicios_adquiridos){
			this.setState({servicios_adquiridos: this.props.servicios_adquiridos.servicios_adquiridos})
		}
	}


	/**
     * @memberof Servicios
     * @method addServicio
     * @description Añade un nuevo servicio
     */
	addServicio = (values) => {
		let servicios_adquiridos = [...this.state.servicios_adquiridos]
		servicios_adquiridos.push(values)
		this.setState({
			servicios_adquiridos,
			modal_visible: false
		})	
	}

	/**
     * @memberof Servicios
     * @method updateServicio
     * @description Actualiza un servicio del array
     */
	updateServicio = (values) => {
		let {index} = this.state
		if(typeof index === "number" && index >= 0){
			let servicios_adquiridos = [...this.state.servicios_adquiridos]
			servicios_adquiridos.splice(index, 1, values)
			this.setState({
				servicios_adquiridos,
				modal_visible: false,
				index: undefined,
				servicio: undefined
			})
		}
	}

	/**
     * @memberof Servicios
     * @method deleteServicio
     * @description Elimina un servicio del array
     */
	deleteServicio = (index) => {
		if(typeof index === "number" && index >= 0){
			Modal.confirm({
				title: '¿Quieres eliminar este servicio?',
    			icon: <ExclamationCircleFilled />,
			    onOk: () => {
			    	let servicios_adquiridos = [...this.state.servicios_adquiridos]
					servicios_adquiridos.splice(index, 1)
					this.setState({
						servicios_adquiridos,
					})
			    },
			    onCancel() {},
			    okText: "Si",
			    cancelText: "Cancelar"
			})
			
		}
	}

	render(){

		let { servicios_adquiridos } = this.state;

		return(
			<>
				<Row>
					<Col span={12} className="flex-left">
						<Title level={5} className="m-0">Servicios</Title>
					</Col>
					<Col span={12} className="flex-right">
						<Button type="primary" onClick={()=>this.setState({modal_visible: true})}>
							Agregar
						</Button>
					</Col>
				</Row>
				<div style={{minHeight: "500px"}} className="mt-1">
					{servicios_adquiridos?.length === 0 ? <Title level={4} className="text-center text-gray">Sin servicios</Title> : null}
					<Collapse>
						{servicios_adquiridos?.map((servicio, index) => {
							return <Panel header={servicio.nombre} key={index} extra={<Space>
									<EditOutlined onClick={(e)=>{
											e.stopPropagation()
											this.setState({modal_visible: true, servicio, index})
										}}
									/>
									<DeleteOutlined onClick={(e)=>{
											e.stopPropagation()
											this.deleteServicio(index)
										}}
									/>
								</Space>
							}>
								{
									servicio.tipo === 1 ? <Row className="text-right">
										<Col span={5}>
											<Text strong className="text-purple">Costo Por Hora</Text>
										</Col>
										<Col span={4}>
											<Text strong className="text-purple">Horas Totales</Text>
										</Col>
										<Col span={5}>
											<Text strong className="text-purple">Costo</Text>
										</Col>
										<Col span={5}>
											<Text strong className="text-purple">Utilidad</Text>
										</Col>
										<Col span={5}>
											<Text strong className="text-purple">Total</Text>
										</Col>
										<Col span={5}>
											<Text>$ {servicio?.costo_hora?.toMoney(true)}</Text>
										</Col>
										<Col span={4}>
											<Text>$ {servicio?.horas_totales?.toMoney(true)}</Text>
										</Col>
										<Col span={5}>
											<Text>$ {servicio?.costo?.toMoney(true)}</Text>
										</Col>
										<Col span={5}>
											<Text>$ {servicio?.utilidad?.toMoney(true)}</Text>
										</Col>
										<Col span={5}>
											<Text>$ {servicio?.monto?.toMoney(true)}</Text>
										</Col>
									</Row> : <Row className="text-right">
										<Col span={12}>
											<Text strong className="text-purple">Costo</Text>
										</Col>
										<Col span={6}>
											<Text strong className="text-purple">Utilidad</Text>
										</Col>
										<Col span={6}>
											<Text strong className="text-purple">Total</Text>
										</Col>
										<Col span={12}>
											<Text>$ {servicio?.costo?.toMoney(true)}</Text>
										</Col>
										<Col span={6}>
											<Text>$ {servicio?.utilidad?.toMoney(true)}</Text>
										</Col>
										<Col span={6}>
											<Text>$ {servicio?.monto?.toMoney(true)}</Text>
										</Col>
									</Row>
								}
							</Panel>
						})}
					</Collapse>
				</div>
				<Row>
					<Col xs={12}>
						<Button type="secondary" onClick={()=>this.props.back(this.state.servicios_adquiridos)}>
							Anterior
						</Button>
					</Col>
					<Col xs={12} className="flex-right">
						
						<Button type="primary" onClick={()=>this.props.onSubmitServicios(this.state.servicios_adquiridos)}>
							Continuar
						</Button>
						
					</Col>
				</Row>
				<ModalServicioAdquirido
					visible={this.state.modal_visible}
					onClose={()=>{
						this.setState({modal_visible: false, servicio: undefined, index: undefined})
					}}
					addServicio={(values)=>{
						this.addServicio(values)}
					}
					updateServicio={(values) => this.updateServicio(values)}
					servicio={this.state.servicio}
				/>
			</>
		)
	}

}


export default Servicios