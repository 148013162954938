import React from 'react';
import { Row, Card, List, Tag, Typography, Col, Progress } from 'antd';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { AiOutlineClockCircle, AiOutlineCheckCircle } from 'react-icons/ai'

import '../../../../Styles/Modules/ProjectManager/cards.scss'

const moment = require('moment')
const { Text } = Typography



const CardProgressClient = (props) => {

    let { 
        id, 
        modulos, 
        porcentaje, 
        complete = false, 
        cliente = {}, 
        tipo, 
        className,
        date = new Date(), 
        description, 
        proyecto = {}, 
        onClick = () => { }, 
        onCancel = () => { } 
    } = props




    let dates = (now = moment(), date) => {

        let time_ago = 'min'
        let diff_ago = now.diff(moment(date), 'minutes')

        if (diff_ago > 120) {
            diff_ago = now.diff(moment(date), 'hours')
            time_ago = 'hrs';
            if (diff_ago > 23) {
                diff_ago = now.diff(moment(date), 'days');
                time_ago = 'days';
            }
        }


        


        return <>
            {diff_ago} {time_ago}
        </>
    }

    let color='#DAC401'
    

    return <Card
        title={proyecto.nombre}
        hoverable
        extra={<Tag className="card-task-tag" color={color} >En Proceso</Tag>}
        bordered={false}
        // complete
        className={"card-shadow card-task pm " + (complete ? " card-task-complete" : "") + (className)}
        style={{ position: "relative", }}
        loading={(proyecto.loading === true)}
    >

        <div className='card-container-pm'>
            <div>
                <Row align='space-between'>
                    <Col className='fecha-proyecto'>
                        {moment(proyecto?.fecha_final).format('ll')}
                    </Col>
                    <Col className='flex porcentaje'>
                        <Text className='flex-left'>{proyecto.porcentaje_avance?.toMoney() ?? 0}%</Text>
                    </Col>
                    <Col span={24}>
                        <Progress percent={proyecto.porcentaje_avance?.toMoney() ?? 0} showInfo={false} strokeColor={proyecto.porcentaje_avance.toMoney() > 99 ? "#52c41a" : "red"} />
                    </Col>
                </Row>
                <List
                    itemLayout="horizontal"
                    dataSource={modulos}
                    bordered={false}
                    className="list-border-none"
                    style={{height: "250px", overflowY: "auto"}}
                    renderItem={item => (
                        <List.Item className='center mb-1'>
                            <Row className='width-95' align='space-between'>
                                <Col className='modulo'>
                                    <Text>{item.nombre}</Text>
                                </Col>
                                <Col>
                                    <Text className='fecha-proyecto'>
                                        {moment(item?.updatedAt).format('D [de] MMMM')}
                                    </Text>
                                    <Text className='ml-1 fecha-proyecto'>
                                        {item.horas_consumidas} / {item.horas_estimadas} h
                                    </Text>
                                </Col>
                                <Col span={22}>
                                    <Progress 
                                        size="small"
                                        percent={item.porcentaje_avance} 
                                        showInfo={true} 
                                    />
                                </Col>
                               
                            </Row>
                        </List.Item>
                    )}

                />
                <Row className='mt-1'>
                    <Col span={12} className="horas-utilizadas">
                        <Row>
                            Horas utilizadas
                        </Row>
                        <Row align='middle' className='principal'>
                            <AiOutlineClockCircle className='mr-1' /> {proyecto.horas_consumidas ? proyecto.horas_consumidas : 0} h
                        </Row>
                    </Col>
                    <Col span={12} className="horas-estimadas">
                        <Row>
                            Horas estimadas
                        </Row>
                        <Row align='middle' className='principal'>
                            <AiOutlineCheckCircle className='mr-1' /> {proyecto.horas_estimadas ? proyecto.horas_estimadas : 0} h
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
        <Row className='card-task-footer' justify={"space-between"}>
            <Col span={24} className='mt-1'>
				<CustomAvatar
					image={cliente?.nombre}
            		name={[cliente?.nombre, cliente?.apellido_paterno]}
                    className="mr-1" /> 
				{cliente.nombre} {cliente.apellido_paterno ?? ""} {cliente.apellido_materno ?? ""}
            </Col>

        </Row>
    </Card>

}

export default CardProgressClient