import React, { Component } from 'react';
import { Row, Col, Form, Input, Typography, DatePicker, Button, InputNumber, Modal, message, Statistic, Space, Switch } from 'antd'
import axios from "axios";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

//componentes
import { ImagesUploader } from "../../../Widgets/Uploaders";
import { SelectPlantilla, SelectServicio } from "../../../Widgets/Inputs/Selects";

const { Title } = Typography;
const moment = require("moment");

/**
 * @export
 * @class ModalServicioAdquirido
 * @extends {Component}
 * @description Vista de Servicios, crea los servicios adquiridos de los proyectos
 */
class ModalServicioAdquirido extends Component {


	constructor(props){
		super(props);
		this.state = {
			servicio: {},
			utilidad: 0,
			porcentaje: 0,
			usar_plantilla: false
		}
	}

	refModalServicio = React.createRef()

	componentDidMount(){
		if(this.props.servicio){
			const { monto, costo, tipo, servicio_id, usar_plantilla } = this.props.servicio;

			const servicio = {
				tipo,
				_id: servicio_id
			};

			const fieldsValue = {
				...this.props.servicio
			};

			this.setState({
				servicio: tipo === 1 ? { ...servicio } : { ...servicio, usar_plantilla }
			});

			this.refModalServicio.current.setFieldsValue(fieldsValue);

			this.calcularUtilidad(costo, monto);

		}
	}


	/**
     * @memberof ModalServicioAdquirido
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario, pasa al sig step
     */
	onFinish = (values) => {

			console.log("this.props.cotizacion_id", this.props.cotizacion_id)
		if(this.props.cotizacion_id){
			this.addServicio(values)
			return
		}

		if(this.props.servicio){
			this.props.updateServicio(values)
		}else{
			this.props.addServicio(values)
		}
	}

	/**
     * @memberof ModalServicioAdquirido
     * @method onSelectServicio
     * @description Obtiene informacion del servicio seleccionado, y hace los caluculos de costos
     */
	onSelectServicio = (servicio_id) => {

		if(!servicio_id) return

		this.setState({ loading: true });
        axios.get("/servicio/" + servicio_id)
        .then((response) => {
            let servicio = response.data;
            this.setState({
            	servicio: servicio,
            	usar_plantilla: false
            })

            let costo = servicio.costo
            let monto = servicio.costo + 100
            let costo_hora = servicio.tipo === 1 ? servicio.costo_hora ?? 200 : undefined
            let horas_totales = servicio.tipo === 1 ? 50 : undefined

            if(servicio.tipo === 1){
            	monto = (horas_totales * costo_hora) + 100
            	costo = (horas_totales * costo_hora)
            }
            
            this.refModalServicio.current.setFieldsValue({
            	nombre: servicio.nombre,
            	tipo: servicio.tipo,
            	costo: costo,
            	monto: monto,
            	descripcion: servicio.descripcion,
            	costo_hora: costo_hora,
            	horas_totales: horas_totales,
            	usar_plantilla: false,
            	plantilla_id: null
            })

            this.calcularUtilidad(costo, monto)
        })
        .catch((error) => {
            message.error("Error al obtener el servicio");
            console.log(error);
        }).finally(()=>this.setState({loading: false}))
	}

	/**
     * @memberof ModalServicioAdquirido
     * @method onSelectPlantilla
     * @description Obtiene informacion de la plantilla seleccionada, y hace los caluculos de costos
     */
	onSelectPlantilla = (plantilla_id) => {
		this.setState({ loading: true });
        axios.get('/plantilla/' + plantilla_id)
        .then((response) => {
            let plantilla = response.data;
            
            let { monto, costo_hora} = this.refModalServicio.current.getFieldsValue()
            
            let costo = (costo_hora * plantilla.horas_aproximadas)

            this.calcularUtilidad(costo, monto)
            this.refModalServicio.current.setFieldsValue({
            	horas_totales: plantilla.horas_aproximadas
            })
        })
        .catch((error) => {
            message.error("Error al obtener el servicio");
            console.log(error);
        }).finally(()=>this.setState({loading: false}))
	}

	/**
     * @memberof ModalServicioAdquirido
     * @method calcularUtilidad
     * @description calcula la utilidad y porcentaje del servicio seleccionado
     */
	calcularUtilidad = (costo, monto) => {
		const utilidad = monto - costo;
  		const porcentaje = (utilidad / costo) * 100;

		this.setState({
			utilidad,
			porcentaje,
		})

		this.refModalServicio.current.setFieldsValue({
        	costo: costo,
        	utilidad: utilidad
        })
	
	}

	/**
     * @memberof ModalServicioAdquirido
     * @method calcularUtilidad
     * @description calcula la utilidad y porcentaje del servicio seleccionado
     */
	addServicio = (values) => {
		this.setState({loading: true})
		axios.post('/project-manager/add/servicios',{
			...values,
			cotizacion_id: this.props.cotizacion_id
		}).then(()=>{
			message.success('servicio agregado')
			this.props.onClose(true)
		}).catch(error => {
			message.error(error?.response?.data?.message ?? 'Error al crear el servicio')
		}).finally(()=>this.setState({loading: false}))
	}


	render(){

		const { servicio, usar_plantilla } = this.state;

		return(
			<>
				<Form
					name="form-servicio"
					layout="vertical"
					onFinish={this.onFinish}
					ref={this.refModalServicio}
					initialValues={{
						fecha: moment()
					}}
					onValuesChange={(value, allvalues)=>{
						if(value.usar_plantilla !== undefined){
							this.setState({usar_plantilla: value.usar_plantilla})
							if(usar_plantilla === false) this.refModalServicio.current.setFieldsValue({ plantilla_id: undefined })
						}
						if(servicio.tipo === 1 && typeof allvalues.horas_totales === "number" && typeof allvalues.costo_hora === "number" && typeof allvalues.monto === "number"){
							this.calcularUtilidad(allvalues.horas_totales * allvalues.costo_hora, allvalues.monto)
						}else if(typeof allvalues.costo === "number" && typeof allvalues.monto === "number"){
							this.calcularUtilidad(allvalues.costo, allvalues.monto)
						}
					}}
				>
					<Row gutter={[16,0]}>
						<Form.Item
							name="nombre"
							type="hidden"
						/>
						<Form.Item
							name="tipo"
							type="hidden"
						/>
						<Form.Item
							name="utilidad"
							type="hidden"
						/>
						
						<Col xs={24} md={12}>
							<Form.Item
								label="Servicio"
								name="servicio_id"
								rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el servicio"
                                }]}
							>
								<SelectServicio
									onSelect={(servicio_id)=>this.onSelectServicio(servicio_id)}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							{this.state.servicio?._id ? <Form.Item
								label="Utilidad"
							>	
								<Space>
									<Statistic
										value={this.state.utilidad}
										precision={2}
										prefix={"$"}
										valueStyle={{fontSize: "20px"}}
							        />
							        <Statistic
										value={this.state.porcentaje}
										precision={2}
										prefix={this.state.porcentaje > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
										valueStyle={{fontSize: "14px", color: this.state.porcentaje > 0 ? '#3f8600' : '#cf1322'}}
										suffix="%"
							        />
							        
							    </Space>
							</Form.Item> : null }
						</Col>
						<Col xs={24} md={12}>
							<Form.Item
								label="Costo"
								name="costo"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el costo"
                                }]}
							>
								<InputNumber
									disabled={servicio.tipo === 1}
									className="width-100"
									formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							<Form.Item
								label="Monto"
								name="monto"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el costo"
                                }]}
							>
								<InputNumber
									className="width-100"
									formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							{servicio.tipo === 1 ? <Form.Item
								label="Costo por Hora"
								name="costo_hora"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el costo"
                                }]}
							>
								<InputNumber 
									className="width-100"
									formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
								/>
							</Form.Item> : null}
						</Col>
						<Col xs={24} md={12}>
							{servicio.tipo === 1 ? <Form.Item
								label="Horas totales"
								name="horas_totales"
								rules={[{
                                    required: true,
                                    message: "Por favor, ingrese las horas_totales"
                                }]}
							>
								<InputNumber
									className="width-100"
									formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
								/>
							</Form.Item> : null}
						</Col>
						<Col xs={24} md={12}>
							{servicio.tipo === 1 ? <Form.Item
								label="¿Basar en plantilla?"
								name="usar_plantilla"
								valuePropName="checked"
							>
								<Switch />
							</Form.Item> : null}
						</Col>

						<Col xs={24} md={12}>
							{servicio.tipo === 1 && usar_plantilla === true ? <Form.Item
								label="Plantilla"
								name="plantilla_id"
								rules={[{
                                    required: true,
                                    message: "Por favor, seleccione una plantilla"
                                }]}
							>
								<SelectPlantilla 
									onSelect={(plantilla_id) =>this.onSelectPlantilla(plantilla_id) }
								/>
							</Form.Item> : null}
						</Col>

						<Col xs={24}>
							<Form.Item
								label="Descripción"
								name="descripcion"
							>
								<Input.TextArea disabled={true}/>
							</Form.Item>
						</Col>
						
					</Row>
				</Form>
			</>
		)
	}

}


export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={props.plantilla_id ? "Editar Servicio" : "Añadir Servicio"}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-servicio', key: 'submit', htmlType: 'submit' }}
        width={700}
    >
        <ModalServicioAdquirido {...props} />
    </Modal>

}