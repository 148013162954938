import React from 'react';
import { Row, Card, List, Tag, Checkbox, Typography, Col } from 'antd';

//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';

const moment = require('moment')
const { Meta } = Card
const { Title, Text } = Typography

const ProjectTracker = (props) => {


    //datos del proyecto
    let { horas_estimadas, costo_total } = props;



    const cotizacion_task = [
        {
            title: 'Lista de Requerimientos',
            value: 1,
            checked: true,
        },
        {
            title: 'Estimación de Tiempos',
            value: 2,
            checked: horas_estimadas && horas_estimadas > 0,
            extra: <small className="text-gray">{horas_estimadas ? horas_estimadas.toMoney(true) : 0 } hrs. </small>
        },
        {
            title: 'Estimación de Costos',
            value: 3,
            checked: costo_total && costo_total > 0,
            extra: <small className="text-gray">$ {costo_total ? costo_total.toMoney(true) : 0} mxn</small>
        },
        {
            title: 'Envío de Cotización Final',
            value: 4,
            checked: false,
            extra: <small className="text-gray"></small>
        },
        {
            title: 'Firma de Contrato',
            value: 5,
            checked: false,
            extra: <small className="text-gray"></small>
        }
    ]

    const desarrollo_task = [
        
    ]

    const entrega_task = [
        {
            title: 'Lista de Requerimientos',
            value: 1,
            checked: true,
        },
        {
            title: 'Estimación de Tiempos',
            value: 2,
            checked: false,
            extra: <Text>text</Text>
        }
    ]

    return <Card>
        <Row>
            <Col span={24}>
                <Title level={4}>Project Tracker</Title>
            </Col>
        </Row>
        <List
            itemLayout="horizontal"
            dataSource={cotizacion_task}
            bordered={false}
            className="list-border-none mt-1"
            header={<Row>
                <Col span={3} className="center">
                    <div className="">1</div>
                </Col>
                <Col span={20}>
                    <Title level={5} className="m-0">Cotización</Title>
                </Col>
            </Row>}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        className="card-task-list-meta"
                        avatar={<Checkbox
                            className="custom-checkbox-circle"
                            checked={item.checked}
                        />}
                        title={item.title}
                    />
                    <Row align='middle'>{item.extra ? item.extra : null}</Row>
                </List.Item>
            )}
        />
        <List
            itemLayout="horizontal"
            dataSource={desarrollo_task}
            bordered={false}
            className="list-border-none mt-1"
            header={<Row>
                <Col span={3} className="center">
                    <div>2</div>
                </Col>
                <Col span={20}>
                    <Title level={5} className="m-0">Desarrollo</Title>
                </Col>
            </Row>}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        className="card-task-list-meta"
                        avatar={<Checkbox
                            className="custom-checkbox-circle"
                            checked={item.checked}
                        />}
                        title={item.title}
                    />
                </List.Item>
            )}
        />
        <List
            itemLayout="horizontal"
            dataSource={entrega_task}
            bordered={false}
            className="list-border-none mt-1"
            header={<Row>
                <Col span={3} className="center">
                    <div>3</div>
                </Col>
                <Col span={20}>
                    <Title level={5} className="m-0">Entrega</Title>
                </Col>
            </Row>}
            renderItem={item => (
                <List.Item >
                    <List.Item.Meta
                        className="card-task-list-meta"
                        avatar={<Checkbox
                            className="custom-checkbox-circle"
                            checked={item.checked}
                        />}
                        title={item.title}
                    />
                </List.Item>
            )}
        />

    </Card>

}

export default ProjectTracker