import React, { Component } from 'react';
import { Row, Col, Form, Input, Typography, DatePicker, Button, InputNumber, Collapse, Space, Modal, message } from 'antd'
import axios from "axios"
import { EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';

//Modal
import ModalServicioAdquirido from "./ModalServicioAdquirido";

const { Panel } = Collapse;
const { Title, Text } = Typography;
const moment = require("moment");

/**
 * @export
 * @class Revision
 * @extends {Component}
 * @description Vista de Revision, Se observa todo el detalle de la cotizacion
 */
class Revision extends Component {


	constructor(props){
		super(props);
		this.state = {
			values: {},
			horas_aproximadas: 0,
			cliente: {}
		}
	}

	componentDidMount(){
			console.log("this.props.values", this.props.values);
		if(this.props.values && Object.keys(this.props.values).length > 0){
			
			let horas_aproximadas = 0

			for(let modulo of this.props.values.modulos){
				horas_aproximadas += modulo.horas_aproximadas
			}

			this.setState({horas_aproximadas})

			if(this.props.values.cliente_id)
				this.getCliente()

			if(this.props.values.asesor_id)
				this.getUsuario()

		}
	}


	/**
     * @memberof Revision
     * @method getCliente
     * @description Obtiene un Cliente de la DB
     */
    getCliente = () => {
        axios.get(`/cliente/${this.props.values.cliente_id}`)
            .then(({ data }) => {
                let cliente = data;
                console.log("cliente", cliente);
                this.setState({ cliente });
            })
            .catch((error) => {
                message.error("Error al traer la cliente");
                console.log(error);
            })
    }

    /**
    * @method getUsuario
    * @description Obtiene la informacion de un usuario
    */
    getUsuario = () => {
        axios.get('/usuario/' + this.props.values.asesor_id)
            .then(({data}) => {
               	let asesor = data
               	console.log("asesor", asesor);
               	this.setState({ asesor });
            }).catch(error => {
                message.error('Error al obtener el usuario')
            })
    }

    /**
     * @memberof Revision
     * @method saveProyecto
     * @description Crea en la BD los registros del proyecto, modulos seleccionados,(la informacion restante se guarda en el primer step)
     */
    saveProyecto = () => {
    	this.setState({loading: true})
    	axios.post('/project-manager/proyecto',{
    		...this.props.values,
    	}).then(response => {
    		console.log("response", response.data);
    		message.success("Proyecto creado con exito")
    		this.props.onClose(true)
    	}).catch(error => {
    		console.log("error", error);
    		message.error(error?.response?.data?.message ?? "Error al crear el proyecto")
    	})
    }


	render(){

		let { cliente, asesor } = this.state
		let { modulos } = this.props.values;
		let { values } = this.props;

		return(
			<>
				<Row>
					<Col span={12} className="flex-left">
						<Title level={5} className="m-0">Revisión</Title>
					</Col>
				</Row>
				<div style={{minHeight: "500px"}} className="mt-1">

					<Row className="mb-1">
						<Col xs={24} md={4} className="col-black">
							<Text>Nombre</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>{values.nombre}</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Asesor</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>{asesor?.nombre ?? "-"} {asesor?.apellidos ?? ""}</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Fecha Venta</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>{moment(values.fecha_venta).format("DD/MM/YYYY")}</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Horas Aproximadas</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>{this.state.horas_aproximadas?.toMoney(true)} hrs</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Documentos</Text>
						</Col>
						<Col xs={24} md={4} className="flex-column text-center">
							{this.props.values.documentos?.length > 0 ? null : "-"}
							{this.props.values.documentos?.map(e => {
								
								if(e instanceof File){
									return <a target="_blank" href={URL.createObjectURL(e)} className="link">{e.name}</a>
								}


							})}
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Cliente</Text>
						</Col>
						<Col xs={24} md={4} className="center text-center">
							<Text>{cliente?.nombre ?? "-"} {cliente?.apellido_paterno ?? ""}</Text>
						</Col>
						<Col xs={24} md={4} className="col-black">
							<Text>Descripción</Text>
						</Col>
						<Col xs={24} md={20} className="flex-left p-1">
							<Text>{values.descripcion}</Text>
						</Col>
					</Row>

					<Collapse>
						{modulos?.map((modulo, index) => {
							return <Panel header={modulo.nombre} key={index}>
								<Row className="text-right">
									<Col span={14}>
										<Text strong className="text-purple">Actividad</Text>
									</Col>
									<Col span={10}>
										<Text strong className="text-purple">Horas Aproximadas</Text>
									</Col>
								</Row>
								{
									modulo.actividades.map(actividad => {
										return <Row className="text-right">
											<Col span={14}>
												<Text>{actividad.nombre}</Text>
											</Col>
											<Col span={10}>
												<Text>{modulo?.horas_aproximadas?.toMoney(true)}</Text>
											</Col>
										</Row>
									}) 
								}
							</Panel>
						})}
					</Collapse>
				</div>
				<Row>
					<Col xs={12}>
						<Button type="secondary" onClick={()=>this.props.back()}>
							Anterior
						</Button>
					</Col>
					<Col xs={12} className="flex-right">
						
						<Button type="primary" onClick={()=>this.saveProyecto()}>
							Continuar
						</Button>
						
					</Col>
				</Row>
				<ModalServicioAdquirido
					visible={this.state.modal_visible}
					onClose={()=>{
						this.setState({modal_visible: false, servicio: undefined, index: undefined})
					}}
					addServicio={(values)=>{
						this.addServicio(values)}
					}
					updateServicio={(values) => this.updateServicio(values)}
					servicio={this.state.servicio}
				/>
			</>
		)
	}

}


export default Revision