
import React, { Component, useEffect } from "react";

import { Upload, Button } from "antd";
import _ from "lodash";

export default function Uploader(props) {
    const {
        method,
        children,
        value = [],
        onChange = () => { }
    } = props;


    
    let [fileList, setFileList] = React.useState(value);
    let [temp, setTemp] = React.useState(value);
    
    if  (method) return <Upload {...props} />



    const triggerChange = (changedValue) => {
        
        let currentFileList = [...fileList, changedValue]
        if (onChange)
            onChange(currentFileList)
        setFileList(currentFileList);
    }
    
    if (value && value!= null && !_.isEqual(temp, value)) {
        setFileList(value)
        setTemp(value)
    }
        
    return <Upload 
        {...props}
        fileList={fileList}
        customRequest={(custom) => {
            let { file } = custom
            file.status = "done"
            triggerChange(file);
        }}
    >
        {children}
    </Upload>
}
