import React, { Component } from "react";
import { Row, Col, Modal, Form, Checkbox, Input, message, Spin, Select, Space, List, DatePicker, Divider, InputNumber, Radio } from 'antd';
import axios from "axios";
import moment from "moment";

//componentes
import Avatar from "../../Widgets/Avatar/Avatar";
import { UploadOutlined } from "@ant-design/icons";
import PhoneInput from "../../Widgets/Inputs/PhoneInput";
import { SimpleUploader, Uploader, AvatarLoader } from "../../Widgets/Uploaders";

const { Option } = Select;

/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            empresas: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,

            },
            loading: false,
            mostrarProyectos: false,
            baja: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getEmpresas()
        if (this.props.empleado_id) {
            this.get()
        }
    }




    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/empleados/' + this.props.empleado_id)
            .then(({ data }) => {
                let datos = data
                datos.fecha_nacimiento = datos.fecha_nacimiento ? moment(datos.fecha_nacimiento) : null
                datos.fecha_ingreso = datos.fecha_ingreso ? moment(datos.fecha_ingreso) : null
                datos.estatus == 2 ? this.setState({ baja: true }) : this.setState({ baja: false })
                datos.fecha_baja = datos.fecha_baja ? moment(datos.fecha_baja) : null


                console.log("data modal empleado", data)
                this.formRef.current.setFieldsValue({
                    ...datos,
                    avatar: datos.avatar ? {
                        uid: datos.avatar.file,
                        name: datos.avatar.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.empleado_id + "?avatar=" + datos.avatar.file + "&Authorization=" + sessionStorage.getItem("token")
                    } : null
                })
            }).catch(error => {
                message.error('Error al obtener el empleado')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {
        console.clear()
        console.log("values", values.vacaciones_dias_tomados);

        console.log("enviando")

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            avatar: undefined,
            id: this.props.empleado_id,
            vacaciones_dias_tomados: values.vacaciones_dias_tomados ?? 'sss'
        })



        if (values.avatar) {
            if (!values.avatar.fromDB) {
                formData.append("avatar", values.avatar, values.avatar.name)
            }
        }


        if (this.props.empleado_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }

    };

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/empleados', values)
            .then(response => {

                message.success('Empleado Creado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al crear el Usuario')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Actualiza la informacion de un usuario
    */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/empleados', values)
            .then(response => {
                message.success('Empleado Actualizado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al actualizar el Empleado')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
     *
     * @memberof ModalFacturas
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
    getEmpresas = (search) => {
        axios.get('/empresas', {
            params: { limit: 10, page: 1, search }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ empresas: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }));
    }

    /**
    * @memberof ModalEmpleados
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElement) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, apellido, razon_social, folio }, index) {
                arr.push(<Option value={_id}>{nombre}{apellido || ""}{razon_social} {folio}</Option>)
            })
        }

        if (array && addElement) {
            if (!array.some(element => element._id === addElement._id)) {
                arr.push(<Option value={addElement._id}> {addElement.nombre}{addElement.apellido || ""}{addElement.razon_social} {addElement.folio}</Option>)
            }
        }
        return arr
    }

    // Habilita fecha de salida y establece el estatus en baja
    handleBaja = (e) => {
        console.log(e)
        if(e.target.value == 2) {
            this.setState({ baja: true })
        }
        else {
            this.setState({ baja: false })
        }
    }

    render() {

        window.a = this.formRef

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-empleado"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    defaultValue={{estatus: 1}}
                    scrollToFirstError={true}
                >
                    <Row justify="center" className="center" gutter={[16, 16]}>
                        <Col span={24} className="center">
                            <Form.Item
                                name="avatar"
                            >
                                <AvatarLoader
                                    imageCrop={true}
                                    onRemove={() => {
                                        axios.put('/empleados', {
                                            id: this.props.empleado_id,
                                            delete_avatar: true
                                        })
                                    }}

                                />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="segundo_nombre"
                                label="Segundo Nombre"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="apellido_paterno"
                                label="Apellido Paterno"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="apellido_materno"
                                label="Apellido Paterno"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                            >
                                <PhoneInput />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="email"
                                label="Correo Electrónico"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="fecha_ingreso"
                                label="Fecha de Ingreso"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la fecha de ingreso',
                                    },
                                ]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="estatus"
                                label="Estatus del empleado"
                                rules={[{
                                    required: true,
                                    message: 'Por favor, seleccione el estatus del empleado'
                                }]}
                            >
                                <Radio.Group onChange={this.handleBaja}>
                                    <Radio value={1}>Alta</Radio>
                                    <Radio value={2}>Baja</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                label="Tipo"
                                name="tipo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo de empleado"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione el tipo de empleado"
                                    filterOption={false}
                                    defaultValue={1}
                                >

                                    <Select.Option value={1}>Normal</Select.Option>
                                    <Select.Option value={2}>Maestro</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="fecha_baja"
                                label="Fecha de Baja"
                                noStyle={!this.state.baja}
                                hidden={!this.state.baja}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>



                        <Col md={24} xl={12}>
                            <Form.Item
                                label="Empresa"
                                name="empresa_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la razón social"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione la razon social"
                                    showSearch
                                    onSearch={this.getEmpresas}
                                    filterOption={false}

                                >

                                    {this.selectOptions(this.state.empresas.data)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                        </Col>

                        <Divider orientation="left">Datos Personales</Divider>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="fecha_nacimiento"
                                label="Fecha de Nacimiento"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la fecha de nacimiento',
                                    },
                                ]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="genero"
                                label="Género"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="curp"
                                label="CURP"
                                rules={
                                    [
                                        {
                                            pattern: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
                                            message: 'Curp invalido'
                                        }
                                    ]
                                }
                            >
                                <Input
                                    placeholder="XXXX000000XXXXXX0X"
                                />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el RFC"
                                }, {
                                    pattern: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/,
                                    message: "El RFC es inválido"
                                }]}
                            >
                                <Input placeholder="VECJ880326XXX" ></Input>
                            </Form.Item>
                        </Col>




                        <Col md={24} xl={12}>
                            <Form.Item
                                name="pais_nacimiento"
                                label="País de Nacimiento"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el País de Nacimiento',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="estado_nacimiento"
                                label="Estado de Nacimiento"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el Estado de Nacimiento',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Divider orientation="left">Domicilio</Divider>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="calle"
                                label="Calle"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="numero_exterior"
                                label="Número"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="numero_interior"
                                label="Número Interior"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="colonia"
                                label="Colonia"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="municipio"
                                label="Municipio"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="estado_residencia"
                                label="Estado de Residencia"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="pais_residencia"
                                label="País de Residencia"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24} xl={12}>
                            <Form.Item
                                name="codigo_postal"
                                label="Código Postal"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Divider orientation="left">Contacto de Emergencia</Divider>

                        <Col md={24} xl={24}>
                            <Form.Item
                                name="contacto_emergencia_nombre"
                                label="Nombre"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="contacto_emergencia_telefono"
                                label="Teléfono"
                            >
                                <PhoneInput />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="contacto_emergencia_parentesco"
                                label="Parentesco"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Divider orientation="left">Vacaciones</Divider>

                        <Col md={24} xl={24}>
                            <Form.Item
                                name="vacaciones_dias_permitidos"
                                label="Dias Permitidos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los días permitidos',
                                    },
                                ]}
                            >
                                <InputNumber className="width-100" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="vacaciones_dias_disponibles"
                                label="Dias Disponibles"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los días disponibles',
                                    },
                                ]}
                            >
                                <InputNumber className="width-100" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="vacaciones_dias_tomados"
                                label="Días Tomados"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los días tomados',
                                    },
                                ]}
                            >
                                <InputNumber className="width-100" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Vista(props) {

    const { visible = false, onClose = () => { }, empleado_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={empleado_id ? "Editar Empleado" : "Crear Empleado"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-empleado', key: 'form-empleado', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}