import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button,  Typography, Image, Alert, Modal, Checkbox } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { User, SetUser } from '../../Hooks/Logged';
import Socket, { SetSocketContext } from '../../Context/Socket';
import axios from 'axios'

//css
import '../../Styles/Global/auth.css';

const { Title } = Typography

/**
 * 
 * @class Login
 * @extends {Component}
 * @description Componente Login para inicio de sesion
 */
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	/**
	 * @memberof Login
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 *
	 * @param values (array)
	 * Contiene los campos del formulario para registrar al usuario
	 *
	 * @returns response (array)
	 **/
	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.post('/login', values)
			.then(({ data, headers }) => {
				const { setUser, setSocketContext } = this.props;

				axios.defaults.headers.post["Authorization"] = data.token;
				sessionStorage.setItem('token', data.token);
				axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

				setUser(data.user);
				setSocketContext()

				let isAdmin = data.user?.rol_id?.tipo === 1
				let hasOverviewAccess = !!data.user?.rol_id?.permisos?.overview

				this.props.navigate(isAdmin ? "/admin/dashboard" : hasOverviewAccess ? "/admin/overview" : "/admin/dashboard");
			})
			.catch((error) => {
				Modal.error({ title: error.response.data })
				this.setState({ loading: false });
			})
	};

	/**
	* @const cerrarSesion
	* @description Cierra la sesion
	*/
	cerrarSesion = () => {

		axios.get("/logout")
			.then(e => {
				this.props.setUser(undefined);
				sessionStorage.clear();
				this.props.navigate("/");
			})
			.catch(e => {
				console.log("ERROR");
			})

	};

	render() {
		return (
			<Row className='layout-login'>
				<Col span={10} className="col-welcome">
					<Row>
						<img src="/img/ISY-Logo.png" alt="Logo I See You Technologies" />
					</Row>
					<Row>
						<div>
							<Title level={2} className='title-isyt-erp'>
								I See You Tech <br />
								Enterprise Resource <br />
								Planning <br /><br />
							</Title>
							<p className='subtitle-isyt-erp'>Bienvenido a ISYT Digital Ecosystem</p>
						</div>
					</Row>
				</Col>
				{this.props.user?._id ?
					<Col span={14}>
						<div className='login-form-container'>
							<div className='login-form'>
								<Title level={3} className="h3-title" style={{ marginBottom: '3em' }}>¡Bienvenido de vuelta!</Title>
								<Title align="center" style={{ marginTop: '1em' }}>{this.props?.user?.nombre} {this.props?.user?.apellidos}</Title>
								<Title level={4} align="center" style={{ marginBottom: '1em' }}>{this.props?.user?.email}</Title>
								<Button type="primary" htmlType="submit" block size="large" onClick={() => this.props.navigate(this.props?.user?.rol_id?.tipo === 1 ? "/admin/dashboard" : this.props.overview ? "/admin/overview" : "/admin")} >Continuar</Button>
								<Button type="link" htmlType="submit" block size="large" onClick={() => this.cerrarSesion()}>Cerrar Sesión</Button>
							</div>
						</div>
					</Col> :
					<Col span={14}>
						<div className='login-form-container'>
							<Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form' initialValues={{ keep_session: true }}>
								<Title level={3} className="h3-title">¡Bienvenido de vuelta!</Title>
								<Title level={5} className="h5-title login-subtitle">Inicia sesión para continuar</Title>
								<Form.Item className='input-email' name="email" label="Correo Electrónico" rules={[{ required: true, message: 'Debe de indicar su correo electrónico' }]}>
									<Input placeholder="Escriba su correo electrónico" size="large" className="input-login" />
								</Form.Item>
								<Form.Item label="Password" name="password" rules={[{ required: true, message: 'Debe ingresar su contraseña' }]}

									extra={<Form.Item valuePropName='checked' name="keep_session">
										<Checkbox >Mantener sesión iniciada</Checkbox>
									</Form.Item>}
								>
									<Input type="password" placeholder="********" size="large" />
								</Form.Item>
								<Form.Item>
									<Button type="primary" htmlType="submit" block size="large" >Sign in</Button>
								</Form.Item>
							</Form>
						</div>
					</Col>}
				<div className='link-password-recovery'>
					¿Olvidaste tus credenciales? <Link style={{ color: '#5452F6' }} to='/password/recovery'>Restauralas aquí</Link>
				</div>
			</Row>
		)
	}
}

export default function (props) {
	let user = useContext(User)
	const setUser = useContext(SetUser)
	const navigate = useNavigate()


	return <Login
		{...props}
		user={user}
		navigate={navigate}

		setUser={setUser}
		socket={useContext(Socket)}
		setSocketContext={useContext(SetSocketContext)}
	/>
}