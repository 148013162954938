import React, { Component } from "react";
import { Button, Col, Row, Typography, Card, List, Space, message, Popconfirm, Modal, Avatar, Statistic } from "antd";
import { PlusOutlined } from "@ant-design/icons"
import axios from 'axios'

//componentes
import CustomAvatar from "../../../../Widgets/Avatar/Avatar";
import { SelectProyecto } from "../../../../Widgets/Inputs/Selects";
import { IconDelete, IconEdit, IconEye, IconHyperLink } from "../../../../Widgets/Iconos";

//modal
import ModalTransaccion from '../../../Finanzas/Transacciones/ModalTransaccion'
import ModalTransaccionDetalle from '../../../Finanzas/Transacciones/ModalTransaccionDetalle'

const { Text } = Typography;
const moment = require('moment');

/**
 * @export
 * @class TransaccionesTab
 * @extends {Component}
 * @description Lista de transacciones del cliente
 */
export default class TransaccionesTab extends Component {

	constructor(props) {
        super(props)
        this.state = {
            proyecto: undefined,
            transacciones: {
            	data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
            },
        }
    }

    componentDidMount(){
         
        if(this.props.cliente_id){
    		this.getTransacciones()
    	}
    }


    /**
     * @memberof Proyectos
     * @method getTransacciones
     * @description Obtiene los transacciones del cliente
     *
     */
    getTransacciones = ({
    	page = this.state.transacciones.page,
    	limit = this.state.transacciones.limit,
    	proyecto_id = this.state.transacciones.proyecto_id,
    } = this.state.transacciones) => {
    	console.log("proyecto_id", proyecto_id);

        this.setState({ loading: true })
        axios.post('/transacciones', {
        	cliente_id: this.props.cliente_id,
        	tipo: this.props.tipo,
        	proyecto_id,
        	
        }).then(({ data }) => {
        	console.log("data", data);
			this.setState({ transacciones: { ...this.state.transacciones,proyecto_id, ...data } });
		}).catch(error => {
            message.error(error.response?.data ?? 'Error al traer los transacciones')
            // console.log()
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof Proyectos
     * @method desvincularTran
     * @description Obtiene los transacciones del cliente
     *
     */
    desvincularTran = (transaccion_id) => {
        Modal.confirm({
        	title: '¿Seguro de desvincular la transaccion?',
        	okText: 'Si',
        	cancelText: 'Cancelar',
        	onOk: () => axios.post('/crm/desvicular',{
        		transaccion_id
        	}).then(response => {
        		message.success('Transacción desvinculada')
        		this.getTransacciones()
        		this.props.refreshInfo()
        	}).catch(error => {
        		console.log("error", error);
        		message.error('Error al desvincular')
        	})
        })
    }


	render() {

		const { data } = this.state.transacciones

		return (
			<>
				<Row gutter={[8, 8]}>
					<Col xs={24} lg={12}>
						<SelectProyecto
							cliente_id={this.props.cliente_id}
							style={{
								minWidth: "300px",
								backgroundColor: "white"
							}}
							onSelect={(value)=>this.getTransacciones({proyecto_id: value ?? null})}

						/>
					</Col>
					<Col xs={24} lg={12} className="flex-right">
						<Button type="primary" icon={<PlusOutlined />} onClick={()=>this.setState({modalTransacVisible: true})}>
							Agregar Transacción
						</Button>
					</Col>
					<List
						itemLayout="horizontal"
						dataSource={data}
						key={(item => item._id)}
						className="component-list w-100"
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.getTransacciones({page,limit})
						}}
						header={
                            <Row className="header-list width-100">
                                <Col lg={3} className="center">
                                    <Text strong> Fecha </Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>Concepto</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Área / Proyecto</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>Cuenta</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong> Monto </Text>
                                </Col>
                                <Col
                                    span={4}
                                    className="center"
                                ></Col>
                            </Row>
                        }
						renderItem={item => (
							<List.Item className="component-list-item transacciones">
								<Card className="ant-card-list width-100">
									<Row className="width-100">
										<Col lg={3} className="center">
											<Text className="text-date-format fecha">{moment(item.fecha).format('DD-MM-YYYY')}</Text>
										</Col>
										<Col lg={6} className="center">
											<Text className="concepto">{item.concepto}</Text>
										</Col>
										<Col lg={4} className="flex-column">
											<Text className="concepto">{item.area_id?.nombre}</Text>
											<small className="concepto">{item.proyecto_id?.nombre}</small>
										</Col>
										<Col lg={3} className="center">
											<Text className="concepto">{item.cuenta_id?.nombre}</Text>
										</Col>
										<Col lg={4} className="center">
											{(item.tipo == 2) ?
			                                    <Statistic
			                                        value={item.monto}
			                                        valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}
			                                        prefix={'$'}
			                                        suffix={'MXN'}
			                                    /> :
			                                    <Statistic
			                                        value={item.monto}
			                                        valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF19' }}
			                                        prefix={'$'}
			                                        suffix={'MXN'}
			                                    />
			                                }
										</Col>
										<Col lg={4} className="center">
											<Space direction="horizontal">
												
												<Button
													type="primary"
													icon={<IconEye/>} 
													onClick={()=>this.setState({modalTransaccionDetalleVisible: true, transaccion_id: item._id})}
												/>
												
												<Button 
													type="primary"
													icon={<IconHyperLink />}
													onClick={()=>this.desvincularTran(item._id)}
												/>
												
												<Button
													type="primary"
													icon={<IconEdit />} 
													onClick={()=>this.setState({modalTransacVisible: true, transaccion_id: item._id})}
												/>
												
												<Popconfirm
	                                                placement="topRight"
	                                                title="¿Deseas eliminar esta transaccion?"
	                                                onConfirm={() => axios.delete(`/transaccion/${item._id}`,
	                                                    { params: {id: item._id} }).then(() => {
	                                                        this.getTransacciones()
	                                                        this.props.refreshInfo()
	                                                        message.success('Transaccion eliminada')
	                                                    }).catch(error => console.log(error))}
	                                                okText="Si"
	                                                cancelText="No"
	                                            >
	                                                <Button
	                                                    type="primary"
	                                                    icon={<IconDelete />}
	                                                    title="Eliminar"
	                                                    danger
	                                                />
	                                            </Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>
				<ModalTransaccion 
					visible={this.state.modalTransacVisible}
					onClose={(update)=>{
						this.setState({modalTransacVisible: false, transaccion_id: undefined})
						this.getTransacciones(this.state.page)
						if(update){
							this.props.refreshInfo()
						}
					}}
					transaccion_id={this.state.transaccion_id}
					cliente_id={this.props.cliente_id}
					transacciones_programadas={true}
					initialValues={{
						cliente_id: {
							value: this.props.cliente?._id,
							label: this.props.cliente?.alias
						}
					}}
				/>
				<ModalTransaccionDetalle
					visible={this.state.modalTransaccionDetalleVisible}
					onClose={() => this.setState({
						modalTransaccionDetalleVisible: false,
						transaccion_id: undefined
					})}
					id={this.state.transaccion_id}
				/>
			</>
		)
	}
}