import React, { Component, useContext } from 'react';
import { Row, Col, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Card, Typography } from 'antd'

import axios from 'axios';
import { Link } from "react-router-dom";

//Componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import { IconDelete, IconEdit, IconDetails } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalPlantilla from "./ModalPlantilla"

const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class Plantillas
 * @extends {Component}
 * @description Vista de Plantillas
 */
class Plantillas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            area_id: undefined,
            filtroSearch: '',
            searching: true,

            plantillas: {
                data: [],

                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
            }
        }
    }

    componentDidMount() {
        this.getPlantillas();
    }


    /**
     * @memberof Plantillas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.getPlantillas();
            this.setState({searching: false})
        }
    }
    /**
     *
     *
     * @memberof Plantillas
     * 
     * @method getPlantillas
     * @description Obitiene las Plantillas
     */
    getPlantillas = ({
        page = this.state.plantillas.page,
        limit = this.state.plantillas.limit,
        search = this.props.search
    } = this.state.plantillas) => {

        this.setState({ loading: true })

        axios.get('/plantillas', {
            params: { 
            	limit, 
            	page, 
            	search 
            }
        })
            .then(({ data }) => {
                this.setState({ plantillas: data })
            })
            .catch(res => {
                message.error(res.response.data ?? "No fue posible obtener las plantillas")
            })
            .finally(() => this.setState({ loading: false }))
    }
    

    render() {

        const { plantillas } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Plantillas"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Plantillas" }}
                            dataSource={plantillas.data}
                            pagination={{
                                current: plantillas.page,
                                pageSize: plantillas.limit,
                                total: plantillas.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getPlantillas({ page,  limit })
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={6} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>Horas Aproximadas</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={6} className="center">
                                                <Text strong>{item.nombre}</Text>
                                            </Col>

                                            <Col span={6} className="center">
                                                <Text className="text-gray">{item.descripcion}</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text className="text-gray">{item.horas_aproximadas}</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Space>
                                                    <Button
                                                        style={{ paddingTop: '3px' }}
                                                        type="primary"
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modal_visible: true, plantilla_id: item._id }) }}

                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta plantilla?"
                                                        onConfirm={() => axios.delete('/plantilla/' + item._id).then(() => {
                                                                this.getPlantillas()
                                                                message.success('Plantilla eliminada')
                                                            }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            style={{ paddingTop: '5px' }}
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

                    <ModalPlantilla
                    	visible={this.state.modal_visible}
                    	onClose={(flag)=>{
                    		this.setState({modal_visible: false, plantilla_id: undefined})
                    		if(flag === true){
                    			this.getPlantillas()
                    		}
                    	}}
                    	plantilla_id={this.state.plantilla_id}
                    />

                </Spin>
            </>
        )
    }
}

export default function Vista(props) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarAreas: ["areas", "edit"],
        deleteAreas: ["areas", "delete"],
        createAreas: ["areas", "create"],
        accessRubros: ["rubros", "access"],
    });

    return <Plantillas {...props} {...permisos}/>

}