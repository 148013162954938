import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin, Space } from 'antd';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";

import axios from "axios"

const { Title, Paragraph } = Typography;
const { Option } = Select;
const moment = require('moment');



/**
 *
 *
 * @class ModalFacturas
 * @extends {Component}
 */
class ModalFacturas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedores: [],
            razones_sociales: [],
            ordenes: [],
            proveedor: undefined,
            razon_social: undefined,
            orden: undefined,
            tipo_cambio: 0,
            disabled: false,

            clientes: {
                data: [],
                page: 1,
                limit: 15,
                pages: 0,
                total: 0,
                search: null
            },
            proyectos: {
                data: [],
                page: 1,
                limit: 15,
                pages: 0,
                total: 0,
                search: null
            },
            empresas: {
                data: [],
                page: 1,
                limit: 15,
                pages: 0,
                total: 0,
                search: null
            },

        }
    }

    ModalFacturas = React.createRef();

    componentDidMount() {
         ;

        this.getTipoCambio();
        this.getClientes();
        this.getEmpresas();

        if (this.props.factura_id !== undefined || this.props.factura_id != null) {
            this.getFactura();
        }
    }

    /**
     * @memberof ModalFacturas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        values.cliente_id = (values?.cliente_id?.value) ? values.cliente_id.value : null
        values.proyecto_id = (values?.proyecto_id?.value) ? values.proyecto_id.value : null


        if (this.props.factura_id) {
            this.updateFactura(values)
        } else {
            this.addFactura(values)
        }
    }


    /**
     * @memberof ModalFacturas
     * @method getFactura
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    getFactura = () => {
        this.setState({ loading: true })

        axios.get(`/factura/${this.props.factura_id}`)
            .then(async response => {
                let factura = response.data
                this.ModalFacturas.current?.setFieldsValue({
                    concepto: factura.concepto,
                    fecha: moment(factura.fecha),
                    uuid: factura.uuid,
                    cliente_id: factura?.cliente_id?._id ? {
                        value: factura?.cliente_id?._id,
                        label: factura?.cliente_id?.nombre
                    } : null,
                    proyecto_id: factura?.proyecto_id?._id ? {
                        value: factura?.proyecto_id?._id,
                        label: factura?.proyecto_id?.nombre
                    } : null,
                    empresa_id: factura?.empresa_id?._id ? {
                        value: factura?.empresa_id?._id,
                        label: factura?.empresa_id?.nombre
                    } : null,
                    tipo: factura.tipo,
                    tipo_cambio: factura.tipo_cambio,
                    monto: factura.monto,
                    monto_dolar: factura.monto_dolar,
                })

                if (factura.cliente_id?._id) {
                    this.setState({
                        cliente_id: factura.cliente_id._id
                    }, () => this.getProyectos())
                }

            }).catch(error => {
                console.log(error)
                message.error('Error al traer la transaccion')
            }).finally(() => {

                this.setState({ loading: false })
            })
    }

    /**
    * @memberof ModalFacturas
    * @method getClientes
    * @description Trae los clientes
    * 
    */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,
    } = this.state.clientes
    ) => {

        this.setState(state => {
            state.clientes.page = page;
            state.clientes.limit = limit;
            state.clientes.search = search;
        })

        return axios.get('/clientes', {
            params: {
                page,
                search,
                limit
            }
        })
            .then(({ data }) => {
                this.setState({ clientes: { ...this.state.clientes, ...data, loading: false } })
            }).catch(error => {
                console.log(error)
                message.error('Error al traer los clientes')
            })
    }

    /**
    * @memberof ModalTransacciones
    * @method getEmpresas
    * @description 
    * 
    */
    getEmpresas = ({

        page = this.state.empresas.page,
        limit = this.state.empresas.limit,
        search = this.state.empresas.search

    } = this.state.empresas) => {

        this.setState({ empresas: { ...this.state.empresas, loading: true, page, limit, search } })
        return axios.get('/empresas', {
            params: {
                page,
                limit,
                search

            }
        }).then(({ data }) => {
            this.setState({ empresas: { ...this.state.empresas, ...data, loading: true } })
        }).catch(error => {
            message.error('Error al traer las Empresas')
            console.log(error)
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method getProyectos
     * @description Trae los proyectos del cliente 
     * 
     */
    getProyectos = (search) => {

        if (this.state.cliente_id) {
            axios.get('/proyectos', {
                params: {
                    cliente_id: this.state.cliente_id,
                    search
                }
            }).then(response => {
                this.setState({
                    proyectos: response.data
                })

            }).catch(error => {
                message.error('Error al obetner los proyectos')
            })
        }
    }

    /**
    * @memberof ModalFacturas
    * @method getTipoCambio
    * @description Trae el ulitmo tipo de cambio utilizado 
    * 
    */
    getTipoCambio = () => {
        this.ModalFacturas.current?.setFieldsValue({
            tipo_cambio: 19.5
        })

    }


    /**
     * @memberof ModalFacturas
     * @method addFactura
     * @description Trae las cuentas 
     */
    addFactura = (values) => {
        console.log('values', values)

        if (this.state.loading) return

        this.setState({ loading: true }, () => {

            axios.post('/facturas/add', {
                ...values,
                monto_pesos: parseFloat(values.monto_pesos).toMoney(),
                monto_dolar: parseFloat(values.monto_dolar).toMoney(),
                tipo_cambio: parseFloat(values.tipo_cambio).toMoney(),
            }).then(response => {
                message.success('Factura creada')
                this.props.onClose()
            }).catch(error => {
                console.log(error)
                message.error('Error al guardar')
            }).finally(() => this.setState({ loading: false }))
        })

    }

    /**
    * @memberof ModalFacturas
    * @method updateFactura
    * @description Actualiza la informacion de una factura
    */
    updateFactura = (values) => {
        this.setState({ loading: true })
        axios.put('/facturas/update', {
            ...values,
            monto: values.monto.toMoney(),
            monto_dolar: values.monto_dolar.toMoney(),
            tipo_cambio: values.tipo_cambio.toMoney(),
            id: this.props.factura_id
        }).then(response => {
            message.success('Factura Actualizada')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al guardar')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalFacturas
     * @method onChangePesos
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares 
     * 
     */
    onChangePesos = (value) => {
        let tipo_cambio = this.ModalFacturas.current?.getFieldValue('tipo_cambio')
        this.ModalFacturas.current?.setFieldsValue({
            monto_dolar: parseFloat(value / tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalFacturas
     * @method onChangeDolar
     * @description Hace la conversion de dolares a pesos para actualizar el campo de pesos 
     * 
     */
    onChangeDolar = (value) => {
        let tipo_cambio = this.ModalFacturas.current?.getFieldValue('tipo_cambio')
        this.ModalFacturas.current?.setFieldsValue({
            monto: parseFloat(value * tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalFacturas
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        let monto_pesos = this.ModalFacturas.current?.getFieldValue('monto')
        this.ModalFacturas.current?.setFieldsValue({
            monto_dolar: parseFloat(monto_pesos / value).toMoney()
        })
    }

    /**
    * @memberof ModalFacturas
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, razon_social, folio }, index) {
                arr.push(<Option value={_id}>{nombre}{razon_social} {folio}</Option>)
            })
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}{addElemnt.razon_social} {addElemnt.folio}</Option>)
            }
        }
        return arr
    }

    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-facturas"
                ref={this.ModalFacturas}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio,
                    tipo: -1,

                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input
                                    disabled={disabled}
                                    placeholder="Concepto"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="UUID"
                                name="uuid"
                            >
                                <Input disabled={this.state.disabled} placeholder="UUID"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                            >
                                <Select
                                    className="width-100"
                                    disabled={disabled}
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getClientes({ search })}
                                    onSelect={cliente_id => {
                                        this.setState({ cliente_id: cliente_id.value }, () => this.getProyectos())
                                        this.ModalFacturas.current.setFieldsValue({
                                            proyecto_id: null
                                        })
                                    }}
                                    labelInValue
                                >
                                    {this.state.clientes?.data?.map((cliente) => {
                                        return <Option value={cliente._id} >
                                            <Space size={3}>
                                                <CustomAvatar
                                                    size="small"
                                                    placeholder={true}
                                                    image={cliente.logo}
                                                    name={cliente.alias}
                                                />
                                                {cliente.alias}
                                            </Space>
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id"
                            >
                                <Select
                                    className="width-100"
                                    disabled={disabled}
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getProyectos(search)}
                                    labelInValue
                                >
                                    {this.state.proyectos?.data?.map((item) => {
                                        return <Option value={item._id} >{item.nombre}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item
                                label="Tipo"
                                name="tipo"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]}
                            >
                                <Select
                                    disabled={this.state.disabled}
                                    placeholder="Seleccione tipo">
                                    <Option value={0}>Sin Cambios</Option>
                                    <Option value={1}>Ingreso</Option>
                                    <Option value={-1}>Egreso</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Empresa"
                                name="empresa_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la empresa"
                                }]}

                            >
                                <Select
                                    placeholder="Seleccione la empresa"
                                    onSearch={(value) => this.getRazones({ search: value })}
                                    showSearch
                                    disabled={disabled}
                                >
                                    {
                                        this.state.empresas.data.map(empresa => {
                                            return <Option value={empresa._id}>
                                                <Space>
                                                    <CustomAvatar
                                                        size="small"
                                                        image={empresa.logo}
                                                        name={empresa.alias}
                                                        color={empresa.color}
                                                    /> {empresa.alias}
                                                </Space>
                                            </Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} lg={6} >
                            <Form.Item
                                label="Tipo Cambio"
                                name="tipo_cambio"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={1} defaultValue={0} className="width-100" onChange={this.onChangeTipo} disabled={this.props.factura_id} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8, push: 1 }} >
                            <Form.Item
                                label="Monto (Pesos)"
                                name="monto"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber
                                    disabled={this.props.factura_id}
                                    min={0.01}
                                    defaultValue={0}
                                    decimalSeparator=","
                                    className="width-100"
                                    onChange={this.onChangePesos}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8, push: 2 }} >
                            <Form.Item
                                label="Monto (Dolar)"
                                name="monto_dolar"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber
                                    disabled={this.props.factura_id}
                                    min={0.01}
                                    defaultValue={0}
                                    decimalSeparator=","
                                    className="width-100"
                                    onChange={this.onChangeDolar}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        title={factura_id ? "Editar Factura" : "Nueva Factura"}
        okButtonProps={{ form: 'form-facturas', key: 'submit', htmlType: 'submit' }}
    >
        <ModalFacturas {...props} />
    </Modal>

}