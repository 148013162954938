import React, { Component } from "react";
import { Button, Col, Row, Typography, Menu, Card, message, Popconfirm, Avatar, Tag, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import axios from 'axios';

//componentes
import { IconLink, IconEdit, IconDelete, IconEye } from "../../../../Widgets/Iconos";
import CustomAvatar from "../../../../Widgets/Avatar/Avatar";

//css
import "../../../../../Styles/Modules/CRM/Clientes/clientes.css"

//modales
import ModalProyecto from '../Modales/ModalProyecto';

const { Title, Paragraph, Text } = Typography;
const moment = require('moment')
/**
 * @export
 * @class ProyectosTab
 * @extends {Component}
 * @description Lista de proyectos
 */
export default class ProyectosTab extends Component {

	constructor(props) {
        super(props)
        this.state = {
            proyecto: undefined,
            proyecto_id: undefined,
            proyectos: {
            	data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
            },
        }
    }

    componentDidMount(){
         
        if(this.props.cliente_id){
    		this.getProyectos()
    	}
    }


    /**
     * @memberof ProyectosTab
     * @method getProyectos
     * @description Obtiene los proyectos del cliente
     *
     */
    getProyectos = (page = this.state.proyectos.page) => {
        this.setState({ loading: true })
        axios.get('/crm/proyectos', {
        	params:{
            	cliente_id: this.props.cliente_id
        	}
        }).then(response => {
			console.log("re", response)
        	this.setState({
        		proyectos: response.data
        	})
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los proyectos')
        }).finally(() => this.setState({ loading: false }))
    }


	
	render() {

		const { proyecto, proyectos } = this.state

		return (
			<>
				<Row gutter={[8, 8]} className="proyecto">
					<Col push={20} span={4}>
						<Button type="primary" icon={<PlusOutlined />} onClick={()=>this.setState({modalVisible: true})}>
							Nuevo Proyecto
						</Button>
					</Col>
					<Col span={24}>
						<Row gutter={[8,8]}>
							{proyectos.data.map(p => 
								<Col sm={24} md={12} lg={8}>
									<Card className="card-proyecto">
										<Row className="">
											<Col span={18} className="">
												<Text ellipsis className="proyecto-title">{p.nombre}</Text>
											</Col>
											<Col span={6} className="flex-right ">
												<Tag className="proyecto-tag">Cotización</Tag>
											</Col>
										</Row>
										<Row>
											<Paragraph className="proyecto-fecha">{moment(p.fecha_venta).fromNow()}</Paragraph>											
										</Row>
										<Row>
											<Paragraph className="proyecto-descripcion">{p.descripcion}</Paragraph>
										</Row>
										<Row>
											<Col span={12}>
												<Paragraph className="costo-title">Costo</Paragraph>
												<Paragraph className="costo-monto">{"$" + p.monto_venta.toMoney(true) + " MXN"}</Paragraph>
											</Col>
											<Col span={12} align='right'>
												<Paragraph className="pago-title">Pago Pendiente</Paragraph>
												<Paragraph className="pago-monto">{"$" +p.monto_por_pagar.toMoney(true)+ " MXN"}</Paragraph>
											</Col>
										</Row>
										<Row>
											<Col span={15} className="flex-left">
												<CustomAvatar name={[p.asesor_id?.nombre, p.asesor_id?.apellidos]}  />
												<Text ellipsis>{p.asesor_id?.nombre} {p.asesor_id?.apellidos}</Text>
											</Col>
											<Col align="right" span={9}>
												<Space>
													<Button 
														type="primary" 
														title="Editar"
														onClick={()=>this.setState({ modalVisible: true, proyecto_id: p._id})}
														icon={<IconEdit/>}
													/>
													{/*<Link to={`/admin/crm/proyecto/${p._id}`}>
														<Button type="primary" className="" title="Editar"
															icon={<IconEye color="white" />}
															/>
													</Link>*/}
													<Popconfirm
														placement="topRight"
														title="¿Deseas eliminar esta proyecto?"
														onConfirm={() => axios.delete('/proyectos/delete',
															{ params: {id: p._id} }).then(() => {
																this.getProyectos()
																this.props.refreshInfo()
																message.success('Proyecto eliminado')
															}).catch(error => console.log(error))}
														okText="Si"
														cancelText="No"
													>
														<Button
															type="primary"
															icon={<IconDelete />}
															title="Eliminar"
															danger
														/>
													</Popconfirm>
													
												</Space>
											</Col>
										</Row>
									</Card>
								</Col>
								)}
						</Row>
					</Col>
				</Row>
				<ModalProyecto 
					visible={this.state.modalVisible}
					onClose={(flag)=>{
						this.setState({modalVisible: false, proyecto_id: undefined})
						if(flag === true){
							this.getProyectos()
							this.props.refreshInfo()
						}
					}}
					cliente_id={this.props.cliente_id}
					proyecto_id={this.state.proyecto_id}
				/>
			</>
		)
	}
}