import React, { Component } from 'react';
import { Card, Row, Col, Typography, Progress, Button, InputNumber, Form, Collapse, Popconfirm, message } from 'antd'
import axios from "axios";
import { FlagFilled, DeleteOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const moment = require("moment");

const DesarrolloForm = (props) => {

	let {
		_id,
		costo = 0,
    	monto = 0,
        utilidad = 0,
        servicio_id = {
            nombre: "-"
        },
        horas_estimadas = null,
        modulos = [],
        updateAll = () => {},
    } = props;

    return <Collapse defaultActiveKey={['1']} className="collapse-servicios">
		<Panel className="row-servicio-collapse" header={
			<Row gutter={[8,0]} className="width-100 row-servicio-collapse-header">
	            <Col xs={7} className="center">
	                <Text>{servicio_id.nombre}</Text>
	            </Col>
	            <Col xs={3} className="center">
	                <Text>{horas_estimadas ? `${horas_estimadas.toMoney(true)} hrs` : "N/A"}</Text>
	            </Col>
	            <Col xs={4} className="center">
	                <Text>$ {costo.toMoney(true)} mxn</Text>
	            </Col>
	            <Col xs={4} className="center">
	                <InputNumber
	                	value={monto}
                        size="small"
                        className="width-100"
                        onChange={(value)=>{
                        	console.log("value", value);
                        	props.onChangeMonto(value)
                        }}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
	            </Col>
	            <Col xs={5} className="center">
	                <Text>$ {utilidad.toMoney(true)} mxn</Text>
	            </Col>
	            <Col xs={1} className="center">
	                <Popconfirm
	                    placement="topRight"
	                    title="¿Deseas eliminar este servicio?"
	                    onConfirm={() => axios.delete('/project-manager/delete/servicios/'+_id)
	                        .then((response) => {
	                            message.success("Servicio eliminado")
	                            updateAll()
	                        }).catch(error => console.log(error))}
	                    okText="Si"
	                    cancelText="No"
	                    onCancel={(e)=>e.stopPropagation()}
	                >
	                    <Button
	                    	onClick={(e)=>{e.stopPropagation()}}
	                        type="primary"
	                        icon={<DeleteOutlined />}
	                        title="Eliminar"
	                        danger
	                        size="small"
	                    />
	                </Popconfirm>
	            </Col>
	        </Row>
		} key="1">
			<Row className="width-100">
	            <Col xs={8} className="center">
	                <Text strong>Modulo</Text>
	            </Col>
	            <Col xs={4} className="center">
	                <Text strong>Horas</Text>
	            </Col>
	            <Col xs={4} className="center">
	                <Text strong>Costo</Text>
	            </Col>
	            <Col xs={4} className="center">
	                 <Text strong>Precio</Text>
	            </Col>
	            <Col xs={4} className="center">
	                <Text strong>Utilidad</Text>
	            </Col>
	        </Row>
			{modulos.map( modulo => <Form
		        initialValues={{
		            costo: costo,
		            monto: monto,
		            horas_estimadas: modulo.horas_estimadas,
		            modulo_proyecto_id: modulo._id
		        }}
		        onValuesChange={(value, allValues) => {
		            props.onChangeValues(allValues)
		        }}
		    > 
		    	<Row gutter={[16,16]} style={{paddingTop: "0.5rem", paddingBottom: "0.5rem",borderBottom: "solid 1px lightgray"}}>
		    		<Form.Item
		                name="modulo_proyecto_id"
		                hidden 
		           	></Form.Item>
					<Col xs={8} className="center">
						<Text>{modulo.nombre}</Text>
					</Col>
					<Col xs={4} className="center">
						<Form.Item
		                    name="horas_estimadas"
		                    className="m-0"
		                >
		                    <InputNumber
		                        size="small"
		                        className="width-100"
		                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
		                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
		                    />
		                </Form.Item>
					</Col>
					<Col xs={4} className="center">
						<Text>$ {(modulo.horas_estimadas * props.costo_hora).toMoney(true)} mxn</Text>
					</Col>
					<Col xs={4} className="center">
						<Text>-</Text>
					</Col>
					<Col xs={4} className="center">
						<Text>-</Text>
					</Col>
				</Row>
			</Form>)}
		</Panel>
	</Collapse>
}

export default DesarrolloForm