import React, { Component, useContext } from 'react';
import {Row, Col, Spin, PageHeader, Layout, message, Card, Typography, Tabs } from 'antd'
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";

//Componentes
import User from "../../../Hooks/Logged";
import { IconArrowBack } from '../../Widgets/Iconos';
import usePermissions from "../../../Hooks/usePermissions";
import { CardCustomerProjectInfo, CardTimeProjectInfo, CardTimeConsumedProjectInfo } from "../../Widgets/Cards";

//tabs
import Inicio from "./Tabs/Inicio"
import Egresos from "./Tabs/Costos"
import Modulos from "./Tabs/Modulos"
import Ingresos from "./Tabs/Ingreso"
import Facturas from "./Tabs/Facturas"
import Servicios from "./Tabs/Servicios"

const { Content } = Layout;
const { Text } = Typography 
const { TabPane } = Tabs;

/**
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista de Proyectos
 */
class Proyectos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            proyecto_id: this.props.params.proyecto_id,
            proyecto:{
                monto_venta: 0
            },

            page: 1,
            total: 0,
            filtroSearch:'',
            searching: true,
        }
    }

    back = () => {
        this.props.navigate(-1);
    }

    componentDidMount() {
        this.getProyecto();
    }


    /**
     *
     *
     * @memberof Proyectos
     * 
     * @method getProyecto
     * @description Obitiene la informacion del proyecto
     */
    getProyecto = () => {

        this.setState({loading: true})
        axios.get('/project-manager/proyecto', {
            params: {
                proyecto_id: this.props.params.proyecto_id,
            }
        }).then(response => {
            console.clear()
            console.log("proyecto", response.data);
            this.setState({
                proyecto: response.data
            })
            
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


    render() {

        const { proyecto } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Project Manager"
                        onBack={this.back}
                        backIcon={<IconArrowBack />}
                    />
                    <Content className="admin-content content-bg pd-1">
                    	<Row gutter={[16,16]}>
                            <Col xs={24} md={12} className="grid">
                                <CardCustomerProjectInfo {...proyecto}/>
                            </Col>
                            <Col xs={24} md={12} className="grid">
                                <Row gutter={[0,12]}>
                                    <Col span={24}>
                                        <CardTimeProjectInfo/>
                                    </Col>
                                    <Col span={24} className="grid">
                                        <CardTimeConsumedProjectInfo {...proyecto}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Row gutter={[12,12]}>
                                        <Col xs={12} sm={6} xxl={3} className="flex-column text-center">
                                            <Text strong className="text-purple">Venta Actual</Text>
                                            <Text>$ {proyecto?.monto_venta?.toMoney(true)} mxn</Text>
                                        </Col>
                                        <Col xs={12} sm={6} xxl={3} className="flex-column text-center">
                                            <Text strong className="text-purple">Costos</Text>
                                            <Text style={{color: "#e15462"}}>$ {proyecto?.transacciones?.egreso?.toMoney(true)} mxn</Text>
                                        </Col>
                                        <Col xs={12} sm={6} xxl={3} className="flex-column text-center">
                                            <Text strong className="text-purple">Ingresos</Text>
                                            <Text style={{color: "#28a745"}}>$ {proyecto?.transacciones?.ingreso?.toMoney(true)} mxn</Text>
                                        </Col>
                                        <Col xs={12} sm={6} xxl={3} className="flex-column text-center">
                                            <Text strong className="text-purple">Utilidades</Text>
                                            <Text>$ { (proyecto?.transacciones?.ingreso - proyecto?.transacciones?.egreso).toMoney(true) } mxn</Text>
                                        </Col>
                                        <Col xs={12} sm={6} xxl={3} className="flex-column text-center">
                                            <Text strong className="text-purple">Saldos</Text>
                                            <Text>$ 0 mxn</Text>
                                        </Col>
                                        <Col xs={12} sm={6} xxl={3} className="flex-column text-center">
                                            <Text strong className="text-purple">Facturas Emitidas</Text>
                                            <Text>$ {proyecto?.facturas?.emitidas?.toMoney(true)} mxn</Text>
                                        </Col>
                                        <Col xs={12} sm={6} xxl={3} className="flex-column text-center">
                                            <Text strong className="text-purple">Facturas Recibidas</Text>
                                            <Text>$ {proyecto?.facturas?.recibidas?.toMoney(true)} mxn</Text>
                                        </Col>
                                        <Col xs={12} sm={6} xxl={3} className="flex-column text-center">
                                            <Text strong className="text-purple">Saldo Pendiente</Text>
                                            <Text>$ {(proyecto?.monto_venta - proyecto?.transacciones?.ingreso)?.toMoney(true)} mxn</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Tabs defaultActiveKey="administracion" className="tabs-crm-client" destroyInactiveTabPane={true}>
                                    <TabPane tab="Inicio" key="inicio">
                                        <Inicio 
                                            proyecto_id={this.props.params.proyecto_id}
                                        />
                                    </TabPane>
                                    <TabPane tab="Ingresos" key="ingresos">
                                        <Ingresos 
                                            proyecto_id={this.props.params.proyecto_id}
                                            getProyecto={this.getProyecto}
                                        /> 
                                    </TabPane>
                                    <TabPane tab="Egresos" key="egresos">
                                        <Egresos 
                                            proyecto_id={this.props.params.proyecto_id}
                                            getProyecto={this.getProyecto}
                                        /> 
                                    </TabPane>
                                    <TabPane tab="Facturas" key="facturas">
                                        <Facturas 
                                            proyecto_id={this.props.params.proyecto_id}
                                        /> 
                                    </TabPane>
                                    <TabPane tab="Servicios" key="servicios">
                                        <Servicios proyecto_id={this.props.params.proyecto_id}/> 
                                    </TabPane>
                                    <TabPane tab="Administración" key="administracion">
                                        <Modulos 
                                            proyecto_id={this.props.params.proyecto_id}
                                            getProyecto={this.getProyecto}
                                        /> 
                                    </TabPane>
                                    <TabPane tab="Soporte" key="soporte">
                                        
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}

export default function Vista(props) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarProyectos: ["proyectos", "edit"],
        deleteProyectos: ["proyectos", "delete"],
        createProyectos: ["proyectos", "create"],
    });

    return <Proyectos {...props} {...permisos} params={useParams()} navigate={useNavigate()}/>

}