import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, InputNumber, Select, message, Spin } from "antd";

import axios from "axios"
const { Option } = Select;

class ModalServicio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    refModalServicio = React.createRef();

    componentDidMount() {
        if (this.props.servicio_id !== undefined) {
            this.getServicio();
        }
    }

    /**
     * @memberof ModalServicio
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     *
     */
    onFinish = (values) => {
        if (this.props.servicio_id) {
            this.updateServicio(values);
        } else {
            this.addServicio(values);
        }
    };

    /**
     * @memberof ModalServicio
     * @method addServicio
     * @description Añade un servicio a la BD, relacionado a una empresa o proveedor
     *
     */
    addServicio = (values) => {
        axios
            .post("/servicio", {
                ...values,
                empresa_id: this.props.empresa_id,
                proveedor_id: this.props.proveedor_id,
            })
            .then((response) => {
                message.success("Servicio creada");
                this.props.onClose(true);
            })
            .catch((error) => {
                message.error("Error al crear el servicio");
                console.log(error);
            });
    };

    /**
     * @memberof ModalServicio
     * @method getServicio
     * @description Obtiene eñ servicio de la DB
     *
     */
    getServicio = () => {
        this.setState({ loading: true });
        axios
            .get("/servicio/" + this.props.servicio_id)
            .then((response) => {
                let cuenta = response.data;

                this.refModalServicio.current.setFieldsValue({
                    nombre: cuenta.nombre,
                    descripcion: cuenta.descripcion,
                    costo: cuenta.costo,
                    tipo: cuenta.tipo,
                });
            })
            .catch((error) => {
                message.error("Error al obtener el servicio");
                console.log(error);
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @memberof ModalServicio
     * @method updateServicio
     * @description Actualiza la información de un servicio
     *
     */
    updateServicio = (values) => {
        this.setState({ loading: true });
        axios
            .put("/servicio", {
                ...values,
                servicio_id: this.props.servicio_id,
            })
            .then((response) => {
                message.success("Servicio Actualizado");
                this.props.onClose(true);
            })
            .catch((error) => {
                message.error("Error al actualizar el servicio");
                console.log(error);
            })
            .finally(() => this.setState({ loading: false }));
    };

    render() {
        return (
            <Form
                layout="vertical"
                name="form-servicio"
                id="form-servicio"
                ref={this.refModalServicio}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center">
                        <Col xs={22}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, ingrese el nombre",
                                    },
                                ]}
                            >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>

                        <Col xs={22}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Por favor, ingrese la descripción",
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder="Descripción"></Input.TextArea>
                            </Form.Item>
                        </Col>
                        <Col xs={22}>
                            <Form.Item
                                label="Costo"
                                name="costo"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, ingrese el costo",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={(value) =>
                                        `$ ${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                        )
                                    }
                                    parser={(value) =>
                                        value?.replace(/\$\s?|(,*)/g, "")
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={22}>
                            <Form.Item
                                label="Tipo"
                                name="tipo"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Por favor, seleccione el tipo de servicio",
                                    },
                                ]}
                            >
                                <Select>
                                    <Option value={0}>Periódico</Option>
                                    <Option value={1}>Desarrollo</Option>
                                    <Option value={2}>Único</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        );
    }
}

export default function Modals(props) {
    const { visible = false, onClose = () => {}, servicio_id } = props;

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={servicio_id ? "Editar Servicio" : "Nuevo Servicio"}
            destroyOnClose={true}
            cancelText="Cancelar"
            okText="Guardar"
            okButtonProps={{
                form: "form-servicio",
                key: "submit",
                htmlType: "submit",
            }}
        >
            <ModalServicio {...props} />
        </Modal>
    );
}
