import React from 'react';
import ReactEcharts from "echarts-for-react";

import { Card, Col, Row, Typography, List, Button, Image, Progress, Tooltip, Tag, Checkbox, Dropdown, Popconfirm, Space } from 'antd';


import { ArrowUpOutlined, ArrowDownOutlined, DollarOutlined, WarningOutlined, CloseOutlined, FlagFilled, DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { AiOutlineClockCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import { IconCheckMedal, IconFinanzas, IconLoss, IconMaster, IconMenuDots, IconProfit, IconVisa, IconError, IconWarning, IconSuccess, IconFacturaElectronica, IconView } from './Iconos'
import { LightenDarkenColor } from 'lighten-darken-color';
import CustomAvatar from './Avatar/Avatar';
import Avatar from 'antd/lib/avatar/avatar';
import { Link } from 'react-router-dom';
import { User, SetUser } from '../../Hooks/Logged'
import '../../Styles/Global/cards.css';
import './cards.css'

import crm_datasource from "./crm_card_datasource.json"

import { estatus_modulo } from "../Utils"
 
const { Text, Title} = Typography;
const { Meta } = Card



const moment = require('moment');
moment.locale('es');

/**
 * CardSaldosGraph
 * 
 * @param {*}
 * @description Tarjeta de Saldos con Grafica para Dashboard
 */
const CardSaldosGraph = ({ title = "Saldos", data = [] }) => {
	let dataSaldos = [
		{ value: 1000, name: 'cuenta 1' },
		{ value: 0, name: 'cuenta 2' },
		{ value: 20, name: 'cuenta 3' },
		{ value: 100, name: 'cuenta 4' }
	];

	let series = []

	dataSaldos.map(item => {
		series.push({
			name: item.name,
			type: 'bar',
			stack: '1',
			label: {
				show: false,
			},
			barWidth: 15,
			data: [item.value],
		});
	});

	return <Card className="card-shadow card-saldos-dashboard" bordered={false} title={title}>
		<ReactEcharts
			style={{ height: '260px', maxWidth: '400px' }}
			theme="light"
			className="pd-1"
			option={{
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					}
				},
				title: {
					subtext: (new Intl.NumberFormat().format(4000000)),
					subtextStyle: {
						align: 'center',
						fontSize: 30,
						color: '#000',
						fontWeight: 'bold'
					}
				},
				legend: {
					data: dataSaldos,
					left: 'left',
					bottom: 0,
					itemWidth: 10,
					itemHeight: 10,
					orient: 'vertical',
					textStyle: {
						align: 'right',
						fontSize: 14,
						fontWeight: "normal",
						lineHeight: 21,
						color: '#858997',

					},
					lineStyle: {
						cap: 'round'
					},
					formatter: function (name) {
						let info = ""
						for (let index = 0; index < dataSaldos.length; index++) {
							const element = dataSaldos[index]
							if (element.name == name) {
								const val = new Intl.NumberFormat().format(element.value);
								info = name + ' $ ' + val
								break
							}
						}
						return info
					}
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '0px',
					top: '80px',
					height: '20px',
					innerWidth: '100px',
					containLabel: false,

				},
				xAxis: {
					type: 'value',
					show: false,
					splitLine: {
						show: false
					}
				},
				yAxis: {
					type: 'category',
					show: false,
					splitLine: {
						show: false
					},
				},
				series: series
			}}
		/>
	</Card>
}

/**
 * CardMontoVentaMensual
 * 
 * @param {*}
 * @description Tarjeta de Venta Mensual con Lista Top 5 Clientes para Dashboard
 */
const CardMontoVentaMensual = ({ title = '', icon = 'down', color = '#006BFF' }) => {
	let data = [
		{ color: '#4807EA', nombre: 'Rubro', monto: 0 },
		{ color: '#FF9C54', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 }
	];

	return <Card bordered={false} className='card-venta-mensual-dashboard' title="Monto Venta Mensual">
		<Card.Meta
			title={<> $32,134 <span className={icon == 'up' ? 'ventas-up' : 'ventas-down'}>{icon == 'up' ? <ArrowUpOutlined /> : <ArrowDownOutlined />} 2.5 %</span></>}
			description='Comparado con $20,500 presupuestado'
		/>
		<List
			size='small'
			className='list-border-none list-top-dashboard'
			header={<div className='top-list-header'>Top 5 Clientes</div>}
			bordered={false}
			dataSource={data}
			renderItem={item => <List.Item>
				<List.Item.Meta
					avatar={<Avatar shape="square" size="small" style={{ backgroundColor: item.color }} />}
					title={item.nombre}
				/>

				<div className="list-top-dashboard-monto">${item.monto}</div>
			</List.Item>}
		/>
	</Card>
}

/**
 * CardMontoVentaAnual
 * 
 * @param {*}
 * @description Tarjeta de Venta Anual con Lista Top 5 Clientes para Dashboard
 */
const CardMontoVentaAnual = ({ title = '', icon = 'down', color = '#006BFF' }) => {
	let data = [
		{ color: '#4807EA', nombre: 'Rubro', monto: 0 },
		{ color: '#FF9C54', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 }
	];

	return <Card bordered={false} className='card-venta-mensual-dashboard' title="Monto Venta Anual">
		<Card.Meta
			title={<> $32,134 <span className={icon == 'up' ? 'ventas-up' : 'ventas-down'}>{icon == 'up' ? <ArrowUpOutlined /> : <ArrowDownOutlined />} 2.5 %</span></>}
			description='Comparado con $1,000,000 presupuestado'
		/>
		<List
			size='small'
			className='list-border-none list-top-dashboard'
			header={<div className='top-list-header'>Top 5 Clientes</div>}
			bordered={false}
			dataSource={data}
			renderItem={item => <List.Item>
				<List.Item.Meta
					avatar={<Avatar shape="square" size="small" style={{ backgroundColor: item.color }} />}
					title={item.nombre}
				/>

				<div className="list-top-dashboard-monto">${item.monto}</div>
			</List.Item>}
		/>
	</Card>
}

/**
 * CardDashboardGraph
 * 
 * @param {*}
 * @description Grafica 'Multiple X Axes' para Dashboard
 */
const CardDashboardGraph = ({ }) => {
	const colors = ['#5470C6', '#EE6666'];

	return <Card className='card-shadow card-graph-dashboard' bordered={false}>
		<ReactEcharts
			style={{ height: '472px', maxHeight: '472px' }}
			option={{
				color: colors,
				tooltip: {
					trigger: 'none',
					axisPointer: {
						type: 'cross'
					}
				},
				legend: {},
				grid: {
					top: 70,
					bottom: 50
				},
				xAxis: [
					{
						type: 'category',
						axisTick: {
							alignWithLabel: true
						},
						axisLine: {
							onZero: false,
							lineStyle: {
								color: colors[1]
							}
						},
						axisPointer: {
							label: {
								formatter: function (params) {
									return (
										'Precipitation  ' +
										params.value +
										(params.seriesData.length ? '：' + params.seriesData[0].data : '')
									);
								}
							}
						},
						// prettier-ignore
						data: ['2016-1', '2016-2', '2016-3', '2016-4', '2016-5', '2016-6', '2016-7', '2016-8', '2016-9', '2016-10', '2016-11', '2016-12']
					},
					{
						type: 'category',
						axisTick: {
							alignWithLabel: true
						},
						axisLine: {
							onZero: false,
							lineStyle: {
								color: colors[0]
							}
						},
						axisPointer: {
							label: {
								formatter: function (params) {
									return (
										'Precipitation  ' +
										params.value +
										(params.seriesData.length ? '：' + params.seriesData[0].data : '')
									);
								}
							}
						},
						// prettier-ignore
						data: ['2015-1', '2015-2', '2015-3', '2015-4', '2015-5', '2015-6', '2015-7', '2015-8', '2015-9', '2015-10', '2015-11', '2015-12']
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: 'Precipitation(2015)',
						type: 'line',
						xAxisIndex: 1,
						smooth: true,
						emphasis: {
							focus: 'series'
						},
						data: [
							2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
						]
					},
					{
						name: 'Precipitation(2016)',
						type: 'line',
						smooth: true,
						emphasis: {
							focus: 'series'
						},
						data: [
							3.9, 5.9, 11.1, 18.7, 48.3, 69.2, 231.6, 46.6, 55.4, 18.4, 10.3, 0.7
						]
					}
				]
			}}
		/>
	</Card>
}

/**
 * @const CardCuenta
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
 const CardCuenta = ({ cuenta = { saldo: 0 } }) => {

    let logos = {
        1: <IconMaster />,
        2: <IconVisa />,
        3: <img src={'/images/AE.png'} alt="AE" height="30px" />
    }
                console.log("cuenta.color", cuenta.color);
        console.log("DD", {
            background: `linear-gradient(244.64deg,${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", -20)} 10%,${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", 20)} 49.92%,${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", -10)}  85.62%)`
        });

    return <Card bordered={false} className="card-cuenta"
        style={{
            background: `linear-gradient(244.64deg, 
                ${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", -10)} 10%, 
                ${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", 20)} 49.92%, 
                ${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", -10)}  85.62%)`
        }}
    >
        <Row justify='end' align='top'>
            <Col span={12} className="center">
                {cuenta.syncfy_credenciales_id && cuenta.syncfy_cuenta_bancaria_id ?
                    <Link to={`/admin/finanzas/transacciones/sin-clasificar/${cuenta._id}`} style={{ position: "absolute", right: 0, top: -8 }}>
                        <Tooltip title="Transacciones Bancarias">
                            <Button type='primary' icon={<DollarOutlined style={{ color: "currentColor" }} />} style={{ fontWeight: 300 }} ></Button>
                        </Tooltip>
                    </Link>
                    : null}
            </Col>
        </Row>
        <Row>
            <Col span={4} className="center">
                <CustomAvatar name={[cuenta.nombre, cuenta.apellido]} image={cuenta.logo} color={cuenta.color} />
            </Col>
            <Col span={14} className="flex-left ">
                <Text className="cuenta-nombre">{cuenta.nombre} <IconCheckMedal /></Text>
            </Col>

        </Row>
        <Card.Meta
            className="card-saldo-sub-meta"
            title={<Row justify="space-between">
                <Col><Text style={{ marginRight: '0.5rem' }} className="text-white">$ {cuenta.saldo?.toMoney(true)}</Text><small>MXN</small></Col>
                <Col> <Image preview={false} src={"/img/Chip.png"} /></Col>
            </Row>}
            description={cuenta.syncfy_saldo ? <><Text style={{ marginRight: '0.5rem' }} className="text-white">$ {cuenta.syncfy_saldo?.toMoney()}</Text><small>MXN</small></> : <><Text style={{ marginRight: '0.5rem' }} className="text-white"></Text></>} />
        <Row>
            <Col span={8} className="flex-left">
                <small className="text-white">ACCOUNT NUMBER</small>
            </Col>
            <Col span={12} className="flex-left">
                <Text strong style={{ paddingLeft: '7px' }} className="text-white">{cuenta.cuenta}</Text>

            </Col>
            <Col span={4} className="center">
                {logos[cuenta.marca]}
            </Col>
        </Row>
    </Card>
}


/**
 * @const CardSaldo
 * @description Card que muetra el total de Saldo diponible entre todas las cuentas
 * @param num saldo suma de los saldos disponibles
 * @param num cuentas cantidad de cuentas registradas
 */
const CardSaldo = ({ saldo = 0, cuentas = 0 }) => {
	return <Card className="card-shadow card-saldo">
		<Card.Meta
			className="card-saldo-main-meta"
			title="Saldo al Momento"
			description={<><Text style={{ marginRight: '0.5rem' }}>$ {saldo?.toMoney(true)}</Text><small>MXN</small></>} />
		<Card.Meta
			className="card-saldo-sub-meta"
			title="Cuentas Incluidas"
			description={<>{cuentas}</>} />
	</Card>
}


/**
 * @const CardProfit
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardGains = ({ title = "Profit", status = 'success' }) => {
	return <Card className="card-shadow  card-gains card-border-16">
		<Row align='middle' justify='center'>
			<Col span={4} lg={4}>
				<div className={`${title === 'Profit' ? 'green-box' : 'red-box'}`}>
					{title === 'Profit' ? <IconProfit /> : <IconLoss />}
				</div>
			</Col>
			<Col span={16} lg={16} >
				<Text className="card-title center">{title}</Text>
			</Col>
			<Col span={4} lg={4} className=" dots">
				<Button ghost icon={<IconMenuDots />}></Button>
			</Col>
		</Row>
		<Row gutter={[8, 8]}>
			<Col span={24} className="center">
				<Text>{moment().format('DD/MM/YYYY')}</Text>
			</Col>
			<Col span={16} className="flex-left">
				<Text className="text-paid">Monto Pagado</Text>
			</Col>
			<Col span={8} className="flex-right">
				<Text className="text-paid">Total</Text>
			</Col>
			<Col span={24} className="center">
				<Progress percent={90} status={status} showInfo={false} />
			</Col>
		</Row>
		<Row>
			<Col span={24} className="center">
				<Text>{moment().format('DD/MM/YYYY')}</Text>
			</Col>
			<Col span={16} className="flex-left">
				<Text className="text-paid">Monto Pagado</Text>
			</Col>
			<Col span={8} className="flex-right">
				<Text className="text-paid">Total</Text>
			</Col>
			<Col span={24} className="center">
				<Progress percent={100} status={status} showInfo={false} />
			</Col>

		</Row>

	</Card>
}

/**
 * 
 * 
 * @export
 * @const VentasAnuales
 * @description Card de ventas por año del dashboard de CRM
 */
const CardVentasAnuales = (props) => {

	return <Card title={"Ventas por año"} bordered={false} className="card-shadow echart-card card-crm-ventas-anuales">
		<ReactEcharts
			style={{ height: '240px', width: "100%" }}
			option={{
				responsive: true,
				maintainAspectRatio: false,
				xAxis: {
					type: 'category',
					data: props.ventas_anuales?.map(y => y.año.toString())
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						data: props.ventas_anuales?.map(y => y.monto.toString()),
						type: 'bar',
						showBackground: true,
						backgroundStyle: {
							color: 'rgba(180, 180, 180, 0.2)'
						}
					}
				]
			}}
		/>
	</Card>
}

/**
 * 
 * 
 * @export
 * @param {*}
 * @description Grafica de Ventas Reales vs Estimadas para dashboard CRM
 */
const CardCRMVentasEstimadasReales = ({ }) => {
	const colors = ['#5470C6', '#EE6666'];

	return <Card className="card-shadow card-crm-ventas-estimadas-reales" title={"Ventas Estimadas vs Reales"} bordered={false}>
		<ReactEcharts
			style={{ width: "100%", height: "470px" }}
			option={{
				dataset: [
					{
						id: 'dataset_raw',
						source: crm_datasource
					},
					{
						id: 'dataset_since_1950_of_germany',
						fromDatasetId: 'dataset_raw',
						transform: {
							type: 'filter',
							config: {
								and: [
									{ dimension: 'Year', gte: 1950 },
									{ dimension: 'Country', '=': 'Germany' }
								]
							}
						}
					},
					{
						id: 'dataset_since_1950_of_france',
						fromDatasetId: 'dataset_raw',
						transform: {
							type: 'filter',
							config: {
								and: [
									{ dimension: 'Year', gte: 1950 },
									{ dimension: 'Country', '=': 'France' }
								]
							}
						}
					}
				],
				tooltip: {
					trigger: 'axis'
				},
				xAxis: {
					type: 'category',
					nameLocation: 'middle'
				},
				yAxis: {
					name: 'Income'
				},
				series: [
					{
						type: 'line',
						datasetId: 'dataset_since_1950_of_germany',
						showSymbol: false,
						encode: {
							x: 'Year',
							y: 'Income',
							itemName: 'Year',
							tooltip: ['Income']
						}
					},
					{
						type: 'line',
						datasetId: 'dataset_since_1950_of_france',
						showSymbol: false,
						encode: {
							x: 'Year',
							y: 'Income',
							itemName: 'Year',
							tooltip: ['Income']
						}
					}
				]
			}}
		/>
	</Card>
}


/**
 * @const CardEmpresa

 * @description Card con los datos de razon social
 */
const CardEmpresa = ({ empresa = {}, transacciones = [], facturas = [], onClick = () => { }, className = "" }) => {


    // * 1 - Ingreso , 2 - Egreso ,3 - Traspaso ,4 - Nulo
    let trans_ingresos = transacciones.find(trans => trans._id == 1);
    let trans_egresos = transacciones.find(trans => trans._id == 2);



    //0 - Sin Cambios ,1 - Ingreso ,-1 - Egreso
    let fact_ingresos = facturas.find(fact => fact._id == 1);
    let fact_egresos = facturas.find(fact => fact._id == -1);

    return <Card
        onClick={onClick}
        hoverable={true}
        className={`card-project card-shadow card-medium-small ${className}`}
        title={
            <Row className=''>
                <Col span={16} className="flex-left" style={{ whiteSpace: 'normal' }}>
                    <CustomAvatar
                        color={empresa.color}
                        size="large"
                        image={empresa.logo}
                        name={empresa.razon_social}
                        style={{ marginRight: "10px", minWidth: '40px' }}
                    />
                    <Text ellipsis>{empresa.razon_social}</Text>
                </Col>
                <Col span={8} className="flex-right">
					<Link to={'/admin/contabilidad/facturas/'+empresa._id}>
						<Tag className='factura-tag flex-right'><IconFacturaElectronica  /><Text className='texto' style={{marginLeft: '8px'}}>Facturas</Text></Tag>
					</Link>
				</Col>
            </Row>
        }
    >
        <Row align="middle" justify="start" style={{ marginTop: "24px" }}>
            <Col span={24} className="m-1 flex-left">
                <Text ellipsis className="text-gray"> 
                    <span className='mt-1'>
                        Alta {moment(empresa.createdAt).format('L')}
                    </span>
                </Text>
            </Col>
        </Row>

        {/* Bancos */}
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Bancos</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_ingresos?.total) ? trans_ingresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_egresos?.total) ? trans_egresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>
        </Row>

        {/* Facturación */}
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Facturación</Col>
                </Row>

            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start"  >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((fact_ingresos?.total) ? fact_ingresos?.total : 0).toMoney(true)} MXN</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >

                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((fact_egresos?.total) ? fact_egresos?.total : 0).toMoney(true)} MXN</Col>
                </Row>

            </Col>
        </Row>

    </Card>
}

/**
 * @const CardConciliacion_1

 * @description Card con los datos de razon social
 */
const MontosFaltantes = ({ transacciones = [], facturas = [], onClick = () => { } }) => {

    // * 1 - Ingreso , 2 - Egreso ,3 - Traspaso ,4 - Nulo
    let trans_ingresos = transacciones.find(trans => trans._id == 1);
    let trans_egresos = transacciones.find(trans => trans._id == 2);


    //0 - Sin Cambios ,1 - Ingreso ,-1 - Egreso
    let fact_ingresos = facturas.find(fact => fact._id == 1);
    let fact_egresos = facturas.find(fact => fact._id == -1);



    return <Card
        onClick={onClick}
        hoverable={true}
        className="card-project card-shadow card-medium-small"

        title={
            <Row>
                <Text strong className='pt-5px'>
                    Montos Faltantes
                </Text>
            </Row>
        }
    >

        <Row align="middle" justify="start" style={{ marginTop: "3.5rem" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Bancos</Col>
                </Row>
            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start"  >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_ingresos?.total) ? trans_ingresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >

                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_egresos?.total) ? trans_egresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>
        </Row>

        {/* Facturación */}
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Facturación</Col>
                </Row>

            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start"  >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(fact_ingresos?.total) ? fact_ingresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >

                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(fact_egresos?.total) ? fact_egresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>
        </Row>

    </Card>

}

/**
 * @const CardEstatusContabilidad

 * @description Card con los datos de razon social
 */
const CardEstatusContabilidad = ({ sin_soporte = 0, incompleto = 0, completo = 0, onClick = () => { } }) => {
    return <Card
        onClick={onClick}
        hoverable={true}
        className="card-project card-shadow card-medium-small"

        title={
            <Row className='mb-1 p-1'>
                <Text strong className='pt-5px'>
                    Estatus
                </Text>
            </Row>
        }
    >
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row gutter={[16,16]}>
                    <Col span={20} className="flex-left">
                        <IconError />
                        <Text className='ml-1'>
                            Sin Soporte
                        </Text>
                    </Col>
                    <Col span={4} >
                        <Text strong >{sin_soporte}</Text>
                    </Col>
                    <Col span={20} className="flex-left">
                        <IconWarning />
                        <Text className='ml-1'>
                            Incompleto
                        </Text>

                    </Col>
                    <Col span={4} >
                        <Text strong >{incompleto}</Text>
                    </Col>
                    <Col span={20} className="flex-left">
                        <IconSuccess />
                        <Text className='ml-1'>
                            Completo
                        </Text>

                    </Col>
                    <Col span={4} >
                        <Text strong >{completo}</Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardTaskStep
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la informacion del step
 * @param String color Color del step
 */
const CardTaskStep = ({ title = "", color = "#bababa", almacen = false }) => {
    return <Card
        className="card-task-name"
        cover={<span className="card-task-color"
            style={{ backgroundColor: color }} />}
    >
        <Card.Meta
            className="card-task-name-meta"
            title={title}

        />
    </Card>
}

const CardTaskClient = ({ id, vencido = false, sinAsesor = null,complete = false, cliente = {}, tipo, date = new Date(), description, task, color = "#bababa", proyecto = {}, onClick = () => { }, onCancel = () => { } }) => {


    let user = React.useContext(User)


    let dates = (now = moment(), date) => {

        let time_ago = 'min'
        let diff_ago = now.diff(moment(date), 'minutes')

        if (diff_ago > 120) {
            diff_ago = now.diff(moment(date), 'hours')
            time_ago = 'hrs';
            if (diff_ago > 23) {
                diff_ago = now.diff(moment(date), 'days');
                time_ago = 'days';
            }
        }


        return <>
            {diff_ago} {time_ago}
        </>
    }

    return <Card
        hoverable
        bordered={false}
        
        className={"card-task pm " + (complete ? " card-task-complete" : "")}
        style={{ position: "relative", }}
        loading={(proyecto.loading === true)}
    >
		<Meta title={
			<Row justify='space-between' align='middle'>
				<Title className='card-title'>
					{'{{PROYECTO}}'}
				</Title>
				<Tag onClick={onClick} className="card-task-tag" color={color} style={{ color: vencido ? "black" : undefined }} >{tipo}</Tag>
				</Row>} 
				description={dates(moment(), proyecto.createdAt)} />
        <div className='card-container-pm'>
            <div  onClick={onClick}>
                <List
                    itemLayout="horizontal"
                    dataSource={task}
                    bordered={false}
                    className="list-border-none mt-1"
                    renderItem={item => (
                        <List.Item >
                            <List.Item.Meta
                                className="card-task-list-meta"
                                avatar={<Checkbox

                                    className="custom-checkbox-circle"
                                    checked={item.title == 'Liquidación del Cliente' ? proyecto.liquidado : item.value <= proyecto.estatus}

                                />}
                                title={item.title}

                            />
                        </List.Item>
                    )}

                />
            </div>
        </div>
        <Row className='card-task-footer' justify={"space-between"}>
            <Col span={24} className='mt-1'>
				<CustomAvatar
					image={cliente?.nombre}
            		name={[cliente?.nombre, cliente?.apellido]} /> 
				{`{{CLIENTE}}`}
            </Col>

        </Row>
    </Card>

}

const CardProgressClient = ({ id, modulos, porcentaje = 50,complete = false, cliente = {}, tipo, date = new Date(), description, task, color = "#bababa", proyecto = {}, onClick = () => { }, onCancel = () => { } }) => {


    let user = React.useContext(User)


    let dates = (now = moment(), date) => {

        let time_ago = 'min'
        let diff_ago = now.diff(moment(date), 'minutes')

        if (diff_ago > 120) {
            diff_ago = now.diff(moment(date), 'hours')
            time_ago = 'hrs';
            if (diff_ago > 23) {
                diff_ago = now.diff(moment(date), 'days');
                time_ago = 'days';
            }
        }


        return <>
            {diff_ago} {time_ago}
        </>
    }



    return <Card
        title={`{{PROYECTO}}`}
        hoverable
        extra={<Tag className="card-task-tag" color={color} >En Proceso</Tag>}
        bordered={false}
        // complete
        className={"card-task pm " + (complete ? " card-task-complete" : "")}
        style={{ position: "relative", }}
        loading={(proyecto.loading === true)}
    >

        <div className='card-container-pm'>
            <div>
                <Row align='space-between'>
					<Col>
						{moment(proyecto?.updatedAt).format('ll')}
					</Col>
					<Col className='flex'>
						<Text className='flex-left'>{porcentaje}%</Text>
					</Col>
					<Col span={24}>
						<Progress percent={porcentaje} showInfo={false}/>
					</Col>
				</Row>
                <List
                    itemLayout="horizontal"
                    dataSource={modulos}
                    bordered={false}
                    className="list-border-none"
                    renderItem={item => (
                        <List.Item className='center mt-1'>
                            <Row className='width-95' align='space-between'>
								<Col>
									<Text>{item.nombre}</Text>
								</Col>
								<Col>
									<Text>
										{moment(item?.updatedAt).format('D [de] MMMM')}
									</Text>
									<Text className='ml-1'>
										{item.horas_utilizadas} / {item.horas_estimadas} h
									</Text>
								</Col>
								<Col span={24}>
									<Progress percent={Number(item.horas_utilizadas/item.horas_estimadas)} showInfo={false}/>
								</Col>
							</Row>
                        </List.Item>
                    )}

                />
            </div>
        </div>
        <Row className='card-task-footer' justify={"space-between"}>
            <Col>
                
            </Col>
            
        </Row>
    </Card>

}


/**
 * @const CardCustomerProjectInfo
 * @description 
 */
const CardCustomerProjectInfo = ({
    nombre = "",
    cliente_id = {},
    createdAt = null,
    modulos = {},
    proyecto= {},
    fecha_venta=null
}) => {

    return <Card title={nombre} className="card-project card-shadow">
        <Card.Meta
            description={<p><FlagFilled /> <span className='mb-1'>{moment(fecha_venta).fromNow(true)}</span> <small>({moment(fecha_venta).format("LLL")})</small></p>}
        />
        <Row className="mb-1 mt-1">
            <Col span={24} className="card-project-info">{cliente_id?.nombre ?? "-"}</Col>
            <Col span={24} className="card-project-info">{cliente_id?.razon_social ?? "-"}</Col>
            <Col span={24} className="card-project-info">{cliente_id?.telefono ?? "-"} /<span>{cliente_id?.email ?? "-"}</span></Col>
        </Row>

        <Row>
        	<Col span={24}>
                <Text>Progreso General</Text>
            </Col>
            <Col span={22}>
                <Progress percent={modulos?.avance?.toMoney()} />
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardCustomerProjectInfo
 * @description 
 */
const CardTimeProjectInfo = ({
    proyecto = {
    	nombre: "-"
    },
}) => {

    return <Card title={"Tiempo y consumo"} className="card-project card-shadow">
        <Row className="">
        	<Col xs={24} md={18}>
        		<Row>
		            <Col span={24} className="card-project-info flex-between">
		            	<Text>Envio de Cotización Final</Text>
		            	<small>{moment().format("ll")}</small>
		            </Col>
		            <Col span={24} className="card-project-info flex-between">
		            	<Text>Firma del Contrato</Text>
		            	<small>{moment().format("ll")}</small>
		            </Col>
		            <Col span={24} className="card-project-info flex-between">
		            	<Text>Último Deposito</Text>
		            	<small>{moment().format("ll")}</small>
		            </Col>
        		</Row>
        	</Col>
        	<Col xs={24} md={6} className="center">
        		<Progress type="circle" percent={70} className="progress-sm"/>
        	</Col>
        </Row>
    </Card>
}



/**
 * @const CardCustomerProjectInfo
 * @description 
 */
const CardTimeConsumedProjectInfo = ({
    proyecto = {
    	nombre: "-"
    },
    modulos = {},
}) => {
    return <Card title={"Consumo de Horas"} className="card-project card-shadow">
        <Row className="">
        	<Col span={12} className="horas-utilizadas">
                <Row>
                    Horas utilizadas
                </Row>
                <Row align='middle' className='principal'>
                    <AiOutlineClockCircle className='mr-1' /> {modulos.horas_consumidas ? modulos.horas_consumidas : 0} h
                </Row>
            </Col>
            <Col span={12} className="horas-estimadas">
                <Row>
                    Horas estimadas
                </Row>
                <Row align='middle' className='principal'>
                    <AiOutlineCheckCircle className='mr-1' /> {modulos.horas_estimadas ? modulos.horas_estimadas : 0} h
                </Row>
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardColors
 * @description 
 */
const CardColors = ({
    title,
    color = "white",
    text
}) => {
    return <Card size="small" className="card-color card-shadow" style={{backgroundColor: color}}>
        <Row>
        	<Col>
                <Text>{title}</Text>
            </Col>
        	<Col span={24} className="flex-right">
                <Text className="text-card">{text}</Text>
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardTime
 * @description 
 */
const CardTime = ({
    title,
    color = "white",
    data = []
}) => {
    return <Card size="small" className=" card-shadow" style={{backgroundColor: color}}>
        <Row>
        	<Col>
                <Text>{title}</Text>
            </Col>
        	<Col span={24}>
                <ReactEcharts
                	style={{ height: '260px', maxWidth: '400px' }}
					theme="light"
                	option = {{
						tooltip: {
							trigger: 'item'
					  	},
						series: [{
					    	//name: 'Access From',
					    	type: 'pie',
							radius: ['40%', '70%'],
							avoidLabelOverlap: false,
							label: {
								show: false,
								position: 'center'
							},
							emphasis: {
								label: {
									show: false,
									fontSize: 40,
									fontWeight: 'bold'
								}
							},
							labelLine: {
								show: false
							},
							data: data
					    }]
					}}
                />
            </Col>
        </Row>
    </Card>
}


/**
 * @const CardModulos
 * @description 
 */
const CardModulos = ({
    actividades = [],
    estatus,
    horas_estimadas,
    horas_consumidas,
    fecha_inicio,
    fecha_final,
    porcentaje_avance = 2,
    onEdit = () => {},
    onDelete = () => {},
    onClick = () => { },
    onView = () => { },
	nombre,
    color = "white",
    text
}) => {
    return <Card size="small" className="card-shadow hoverable" style={{backgroundColor: color}} onClick={onClick}>
        <Row gutter={[16,16]}>
        	<Col span={24} className="center">
                <Title level={4}>{nombre}</Title>
            </Col>
            <Col span={24} className="center">
                <Text>{actividades?.length} Actividades</Text>
            </Col>
            <Col span={24} className="center">
                {estatus_modulo(estatus)}
            </Col>
        	<Col span={12} className="flex-column">
                <Text>Horas estimadas</Text>
                <Text>{horas_estimadas}</Text>
            </Col>
            <Col span={12} className="flex-column">
                <Text>Horas consumidas</Text>
                <Text style={{color: horas_consumidas > horas_estimadas ? "#e15462" : "black"}} >{horas_consumidas}</Text>
            </Col>
            <Col span={12} className="flex-column">
                <Text>Fecha inicio</Text>
                <Text>{moment(fecha_inicio).format("DD/MM/YYYY")}</Text>
            </Col>
            <Col span={12} className="flex-column">
                <Text>Fecha termino</Text>
                <Text>{moment(fecha_final).format("DD/MM/YYYY")}</Text>
            </Col>
            <Col span={24} className="center">
            	<Progress type="circle" percent={porcentaje_avance}/>
            </Col>
            <Col span={24} className="center">
            	<Space>
            		
            		<Button
						icon={<EyeOutlined style={{ color: "currentcolor" }} />}
						type="primary"
						title="Editar"
						onClick={(e)=>{
							e.stopPropagation()
							onView()
						}}
					/>
					<Button
						icon={<EditOutlined style={{ color: "currentcolor" }} />}
						type="primary"
						title="Editar"
						onClick={(e)=>{
							e.stopPropagation()
							onEdit()
						}}
					/>
					
					<Popconfirm
						placement="topRight"
						title="¿Deseas eliminar esta Transacción?"
						okText="Si"
						cancelText="No"
						onConfirm={(e)=>{
							e.stopPropagation()
							onDelete()
						}}
						onCancel={(e)=>{
							e.stopPropagation()
						}}
					>
						<Button 
							type="primary" 
							danger 
							icon={<DeleteOutlined style={{ color: "currentcolor" }} />} 
							title="Eliminar" 
							onClick={(e)=>{
								e.stopPropagation()
							}}
						/>
					</Popconfirm>
            	</Space>
            </Col>
        </Row>
    </Card>
}

export {
	CardSaldosGraph,
	CardMontoVentaMensual,
	CardMontoVentaAnual,
	CardDashboardGraph,
	CardCuenta,
	CardSaldo,
	CardGains,
	CardVentasAnuales,
	CardCRMVentasEstimadasReales,
	CardEmpresa,
	MontosFaltantes,
	CardEstatusContabilidad,
	CardTaskStep,
	CardTaskClient,
	CardProgressClient,
	CardCustomerProjectInfo,
	CardTimeProjectInfo,
	CardTimeConsumedProjectInfo,
	CardColors,
	CardTime,
	CardModulos
}