import React, { Component, useContext } from "react";
import {
    Layout,
    Button,
    Col,
    Popconfirm,
    Row,
    message,
    PageHeader,
    List,
    Spin,
    Typography,
    Space,
    Tooltip,
    Collapse,
    Divider,
} from "antd";
import axios from "axios";
import { BankOutlined, PlusOutlined } from "@ant-design/icons";

//componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import {
    IconDelete,
    IconEdit,
    IconBankError,
    IconSATSCR,
} from "../../Widgets/Iconos";
import { tipo_servicio } from "../../Utils";

//modales
import ModalEmpresas from "./ModalEmpresas";
import ModalServicio from "../Servicios/ModalServicios";
import ModalConfiguraciónSyncfy from "../Syncfy/ModalConfiguraciónSyncfy";
import ModalConfiguraciónTaxpayer from "../Syncfy/ModalConfiguraciónTaxpayer";

const { Text } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;

/**
 *
 *
 * @export
 * @class Empresas
 * @extends {Component}
 */
class Empresas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            id: null,
            empresas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
        };
    }

    /**
     *
     *
     * @memberof Empresas
     *
     * @method componentDidMount
     * @description Cargamos la informacion de los Empresas
     */

    componentDidMount() {
            sessionStorage.getItem("token");
        this.getEmpresas();
    }

    /**
     *
     * @memberof Empresas
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
    getEmpresas = (
        {
            page = this.state.empresas.page,
            limit = this.state.empresas.limit,
            search = this.props.search,
        } = this.state.empresas
    ) => {
        this.setState({
            loading: true,
            empresas: { ...this.state.empresas, page, limit },
        });
        axios
            .get("/empresas", {
                params: { limit, page, search },
            })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ empresas: data });
            })
            .catch((res) => {
                message.error(res.response.data.message);
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @methodOf  Empresas
     * @function showModal
     *
     * @description Muestra el modal de la Empresa
     *
     * */
    showModal = (id) => {
        this.setState({
            visible: true,
            id: id,
        });
    };

    /**
     * @methodOf  Empresas
     * @function hideModal
     *
     * @description Oculta el modal de la Empresa
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined,
        });
    };

    render() {
        const { getEmpresas, showModal, hideModal } = this;
        const { visible, empresas, id } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Empresas"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Empresas " }}
                            loading={this.state.loading}
                            dataSource={empresas.data}
                            pagination={{
                                current: empresas.page,
                                pageSize: empresas.limit,
                                total: empresas.total,
                                onChange: (page, limit) => {
                                    this.getEmpresas({ page, limit });
                                },
                            }}
                            header={
                                <Row className="header-list width-100 pr-1 pl-1">
                                    <Col lg={5} className="center">
                                        <Text strong>Alias</Text>
                                    </Col>
                                    <Col lg={5} className="center">
                                        <Text strong>Razón Social</Text>
                                    </Col>
                                    <Col lg={5} className="center">
                                        <Text strong>Descripción</Text>
                                    </Col>
                                    <Col lg={5} className="center">
                                        <Text strong>RFC</Text>
                                    </Col>
                                </Row>
                            }
                            renderItem={(item) => {
                                return (
                                    <Collapse
                                        bordered={false}
                                        className="collapse-item"
                                    >
                                        <Panel
                                            header={
                                                <Row className="width-100 ">
                                                    <Col span={5} className="">
                                                        <CustomAvatar
                                                            image={item.logo}
                                                            name={item.alias}
                                                            color={item.color}
                                                        />{" "}
                                                        {item.alias}
                                                    </Col>
                                                    <Col
                                                        span={5}
                                                        className="center"
                                                    >
                                                        {item.razon_social}
                                                    </Col>
                                                    <Col
                                                        span={5}
                                                        className="center"
                                                    >
                                                        <Text>
                                                            {item.descripcion}
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={5}
                                                        className="center"
                                                    >
                                                        <Text>{item.rfc}</Text>
                                                    </Col>

                                                    <Col
                                                        span={4}
                                                        className="center"
                                                    >
                                                        <Space>
                                                            <Button
                                                                type="primary"
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                                onClick={() =>
                                                                    this.setState(
                                                                        {
                                                                            modal_visible: true,
                                                                            empresa_id:
                                                                                item._id,
                                                                        }
                                                                    )
                                                                }
                                                                title="Añadir Servicio"
                                                            />
                                                            <Tooltip
                                                                title={
                                                                    item.razon_social &&
                                                                    item.rfc
                                                                        ? !item.syncfy_taxpayer
                                                                            ? "Asignar el Certifcado y la Llave para Timbrar"
                                                                            : "Actualizar el Certifcado y la Llave para Timbrar"
                                                                        : "Es necesario tener la razón social y el RFC para poder realizar el enlace"
                                                                }
                                                            >
                                                                <Button
                                                                    disabled={
                                                                        !(
                                                                            item.razon_social &&
                                                                            item.rfc
                                                                        )
                                                                    }
                                                                    type={
                                                                        !!item.syncfy_taxpayer
                                                                            ? "primary"
                                                                            : undefined
                                                                    }
                                                                    icon={
                                                                        <IconSATSCR
                                                                            width={
                                                                                18
                                                                            }
                                                                            color="currentColor"
                                                                            ballsColor={
                                                                                !!item.syncfy_taxpayer
                                                                                    ? "currentColor"
                                                                                    : undefined
                                                                            }
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                top: 1,
                                                                            }}
                                                                        />
                                                                    }
                                                                    onClick={() =>
                                                                        this.setState(
                                                                            {
                                                                                empresa_id:
                                                                                    item._id,
                                                                                modalKeys: true,
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={
                                                                    item.razon_social &&
                                                                    item.rfc
                                                                        ? item.syncfy_usuario_id &&
                                                                          item.syncfy_credenciales_id
                                                                            ? "Realizar el Enlace"
                                                                            : "Modificar el enlace"
                                                                        : "Es necesario tener la razón social y el RFC para poder realizar el enlace"
                                                                }
                                                            >
                                                                <Button
                                                                    disabled={
                                                                        !(
                                                                            item.razon_social &&
                                                                            item.rfc
                                                                        )
                                                                    }
                                                                    onClick={() =>
                                                                        this.setState(
                                                                            {
                                                                                syncfy_id:
                                                                                    item._id,
                                                                                modalSyncfy: true,
                                                                            }
                                                                        )
                                                                    }
                                                                    danger={
                                                                        !(
                                                                            item.syncfy_usuario_id &&
                                                                            item.syncfy_credenciales_id
                                                                        )
                                                                    }
                                                                    type="primary"
                                                                    icon={
                                                                        item.syncfy_usuario_id &&
                                                                        item.syncfy_credenciales_id ? (
                                                                            <BankOutlined
                                                                                style={{
                                                                                    fontSize: 22,
                                                                                    color: "white",
                                                                                    position:
                                                                                        "relative",
                                                                                    bottom: 2,
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <IconBankError
                                                                                color="white"
                                                                                style={{
                                                                                    position:
                                                                                        "relative",
                                                                                    bottom: 2,
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                    title="Eliminar"
                                                                    style={{
                                                                        backgroundColor:
                                                                            item.syncfy_usuario_id &&
                                                                            item.syncfy_credenciales_id
                                                                                ? "#1890ff"
                                                                                : undefined,
                                                                        border:
                                                                            item.syncfy_usuario_id &&
                                                                            item.syncfy_credenciales_id
                                                                                ? "none"
                                                                                : undefined,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                            <Button
                                                                type="primary"
                                                                icon={
                                                                    <IconEdit />
                                                                }
                                                                onClick={() =>
                                                                    showModal(
                                                                        item._id
                                                                    )
                                                                }
                                                            />
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar esta Empresa?"
                                                                onConfirm={() =>
                                                                    axios
                                                                        .delete(
                                                                            "/empresa/" +
                                                                                item._id
                                                                        )
                                                                        .then(
                                                                            (
                                                                                response
                                                                            ) => {
                                                                                message.success(
                                                                                    response
                                                                                        .data
                                                                                        .message
                                                                                );
                                                                                this.getEmpresas();
                                                                            }
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                error
                                                                            ) => {
                                                                                message.error(
                                                                                    error
                                                                                        .response
                                                                                        .data
                                                                                        .message
                                                                                );
                                                                                this.getEmpresas();
                                                                            }
                                                                        )
                                                                }
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    danger
                                                                    icon={
                                                                        <IconDelete />
                                                                    }
                                                                    title="Eliminar"
                                                                />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            }
                                        >
                                            <Divider />
                                            <Row className="mt-1 mb-1">
                                                <Col
                                                    span={5}
                                                    className="center"
                                                >
                                                    <Text className="text-gray">
                                                        Nombre
                                                    </Text>
                                                </Col>
                                                <Col
                                                    span={5}
                                                    className="center"
                                                >
                                                    <Text className="text-gray">
                                                        Descripción
                                                    </Text>
                                                </Col>
                                                <Col
                                                    span={5}
                                                    className="center"
                                                >
                                                    <Text className="text-gray">
                                                        Costo
                                                    </Text>
                                                </Col>
                                                <Col
                                                    span={5}
                                                    className="center"
                                                >
                                                    <Text className="text-gray">
                                                        Tipo
                                                    </Text>
                                                </Col>
                                                <Col span={4}></Col>
                                            </Row>
                                            {item.servicios?.length === 0 ? (
                                                <Row className="">
                                                    <Col
                                                        span={24}
                                                        className="center"
                                                    >
                                                        <Text strong>
                                                            Sin servicios
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                            {item.servicios?.map((servicio) => {
                                                return (
                                                    <Row>
                                                        <Col
                                                            span={5}
                                                            className="center"
                                                        >
                                                            <Text>
                                                                {
                                                                    servicio.nombre
                                                                }
                                                            </Text>
                                                        </Col>
                                                        <Col
                                                            span={5}
                                                            className="center"
                                                        >
                                                            <Text>
                                                                {
                                                                    servicio.descripcion
                                                                }
                                                            </Text>
                                                        </Col>
                                                        <Col
                                                            span={5}
                                                            className="center"
                                                        >
                                                            <Text>
                                                                ${" "}
                                                                {servicio.costo.toMoney(
                                                                    true
                                                                )}{" "}
                                                                MXN
                                                            </Text>
                                                        </Col>
                                                        <Col
                                                            span={5}
                                                            className="center"
                                                        >
                                                            <Text>
                                                                {tipo_servicio(
                                                                    servicio.tipo
                                                                )}
                                                            </Text>
                                                        </Col>
                                                        <Col
                                                            span={4}
                                                            className="center"
                                                        >
                                                            <Space>
                                                                <Button
                                                                    style={{
                                                                        paddingTop:
                                                                            "3px",
                                                                    }}
                                                                    type="primary"
                                                                    icon={
                                                                        <IconEdit />
                                                                    }
                                                                    title="Editar"
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            {
                                                                                modal_visible: true,
                                                                                servicio_id:
                                                                                    servicio._id,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    title="¿Deseas eliminar este Servicio?"
                                                                    onConfirm={() =>
                                                                        axios
                                                                            .delete(
                                                                                "/servicio/" +
                                                                                    servicio._id
                                                                            )
                                                                            .then(
                                                                                () => {
                                                                                    this.getEmpresas();
                                                                                    message.success(
                                                                                        "Servicio eliminado"
                                                                                    );
                                                                                }
                                                                            )
                                                                            .catch(
                                                                                (
                                                                                    error
                                                                                ) =>
                                                                                    console.log(
                                                                                        error
                                                                                    )
                                                                            )
                                                                    }
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        style={{
                                                                            paddingTop:
                                                                                "5px",
                                                                        }}
                                                                        danger
                                                                        type="primary"
                                                                        icon={
                                                                            <IconDelete />
                                                                        }
                                                                        title="Eliminar"
                                                                    />
                                                                </Popconfirm>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </Panel>
                                    </Collapse>
                                );
                            }}
                        />
                        <FloatingButton
                            title="Nuevo registro"
                            onClick={() => showModal()}
                        />
                    </Content>
                </Spin>
                <ModalEmpresas
                    visible={visible}
                    hideModal={hideModal}
                    accept={() => {
                        hideModal();
                        getEmpresas();
                    }}
                    update={() => getEmpresas()}
                    id={id}
                />
                <ModalConfiguraciónSyncfy
                    visible={this.state.modalSyncfy}
                    id={this.state.syncfy_id}
                    onCancel={() => {
                        this.setState({
                            modalSyncfy: false,
                            id: null,
                            cuenta_id: undefined,
                        });
                        this.getEmpresas();
                    }}
                    tipo={2}
                />
                <ModalConfiguraciónTaxpayer
                    visible={this.state.modalKeys}
                    empresa_id={this.state.empresa_id}
                    onCancel={() => {
                        this.setState({
                            modalKeys: false,
                            empresa_id: null,
                            cuenta_id: undefined,
                        });
                        this.getEmpresas();
                    }}
                />
                <ModalServicio
                    visible={this.state.modal_visible}
                    onCancel={() => {
                        this.setState({
                            modal_visible: false,
                            empresa_id: undefined,
                            servicio_id: undefined,
                        });
                        this.getEmpresas();
                    }}
                    empresa_id={this.state.empresa_id}
                    servicio_id={this.state.servicio_id}
                />
            </>
        );
    }
}


export default function Vista(props) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarEmpresas: ["empresas", "edit"],
        deleteEmpresas: ["empresas", "delete"],
        createEmpresas: ["empresas", "create"],
    });

    return <Empresas {...props} {...permisos}/>

}