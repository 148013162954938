import React, { Component } from 'react';
import { Row, Col, Layout, Space, Typography, Avatar, Breadcrumb, Divider, Tag } from 'antd'
import axios from 'axios';

import '../../../Styles/Modules/ProjectManager/cards.scss'

import { FaSquare } from 'react-icons/fa';
import { UserOutlined } from '@ant-design/icons';
//Componentes


//modelos
const moment = require('moment');

const { Content } = Layout;
const { Text, Paragraph, Title } = Typography
/**
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista de Proyectos
 */
export default class Feed extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filtroSearch: '',
            searching: true,
            actividades: [
                { nombre: 'Maquetación', modulo_id: { nombre: 'Empleados Nomina' }, estatus: 1, horas_estimadas: 30, horas_utilizadas: 14, asignados: [{ foto: "https://joeschmoe.io/api/v1/random" }, { foto: "https://joeschmoe.io/api/v1/random" }, { foto: "https://joeschmoe.io/api/v1/random" }], proyecto_id: { nombre: 'Proyecto 1', imagen: "https://joeschmoe.io/api/v1/random" } },
                { nombre: 'Maquetación', modulo_id: { nombre: 'Empleados Nomina' }, estatus: 1, horas_estimadas: 30, horas_utilizadas: 14, asignados: [{ foto: "https://joeschmoe.io/api/v1/random" }], proyecto_id: { nombre: 'Proyecto 1', imagen: "https://joeschmoe.io/api/v1/random" } },
                { nombre: 'Maquetación', modulo_id: { nombre: 'Empleados Nomina' }, estatus: 1, horas_estimadas: 30, horas_utilizadas: 14, asignados: [{ foto: "https://joeschmoe.io/api/v1/random" }], proyecto_id: { nombre: 'Proyecto 1', imagen: "https://joeschmoe.io/api/v1/random" } }
            ],
            actualizaciones: [
                { nombre: 'Maquetación', modulo_id: { nombre: 'Empleados Nomina' }, estatus: 1, horas_estimadas: 30, horas_utilizadas: 14, usuario: { nombre: "Fulanito", foto: "https://joeschmoe.io/api/v1/random" }, action: { tipo: 1 } },
                { nombre: 'Maquetación', modulo_id: { nombre: 'Empleados Nomina' }, estatus: 1, horas_estimadas: 30, horas_utilizadas: 14, usuario: { nombre: "Fulanito", foto: "https://joeschmoe.io/api/v1/random" }, action: { tipo: 2, comment: "¿Como es el tema del guardado de la matriz?" } },
                { nombre: 'Maquetación', modulo_id: { nombre: 'Empleados Nomina' }, estatus: 1, horas_estimadas: 30, horas_utilizadas: 14, usuario: { nombre: "Fulanito", foto: "https://joeschmoe.io/api/v1/random" }, action: { tipo: 1 } },
            ],
        }
    }

    componentDidMount() {
         ;

    }

    render() {



        return (
            <>
                <Content className='feed'>
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <Row>
                            <Col xs={24}>
                                <Title level={4}>
                                    Actividades Actuales
                                </Title>
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.actividades.map((actividad, index) => (
                                        <>
                                            <Row className="actividad" gutter={[5, 5]}>
                                                <Col xs={24}>
                                                    <Row wrap={false}>
                                                        <Col flex={"auto"}>
                                                            <Space size={7}>
                                                                <FaSquare size={7} color={"#0089ED"} />
                                                                <Breadcrumb className="actividad-breadcrumb" separator={">"}>
                                                                    <Breadcrumb.Item>Empleados Nomina</Breadcrumb.Item>
                                                                    <Breadcrumb.Item>Maquetación</Breadcrumb.Item>
                                                                </Breadcrumb>
                                                            </Space>
                                                        </Col>
                                                        <Col flex={"none"}>
                                                            <Avatar.Group>
                                                                <Avatar size="small" icon={<UserOutlined />} />
                                                                <Avatar size="small" icon={<UserOutlined />} />
                                                                <Avatar size="small" icon={<UserOutlined />} />
                                                            </Avatar.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24}>
                                                    <Row wrap={false}>
                                                        <Col flex={"auto"}>
                                                            <Space size={5}>
                                                                <Text strong className="actividad-tiempo">
                                                                    14 de 30 horas
                                                                </Text>
                                                                <Text italic className="actividad-limite">
                                                                    Limite el jueves
                                                                </Text>
                                                            </Space>
                                                        </Col>
                                                        <Col flex={"none"}>
                                                            <Space className='actividad-tag' style={{backgroundColor: "#59B200"}}>
                                                                <Avatar icon={<UserOutlined />} size={18} />
                                                                <Text className='actividad-tag-name' strong style={{color: "white"}}>
                                                                    Parque Esmeralda
                                                                </Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            { this.state.actividades.length - 1 >= index && <Divider/> }
                                        </>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <Row>
                            <Col xs={24}>
                                <Title level={4}>
                                    Ultimas Actualizaciones
                                </Title>
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.actualizaciones.map((actualizacion, index) => (
                                        <>
                                            <Row className="actualizacion" gutter={[5, 5]}>
                                                <Col xs={24}>
                                                    <Row wrap={false}>
                                                        <Col flex={"auto"}>
                                                            <Space direction='vertical' size={8}>
                                                                <Space size={7}>
                                                                    <FaSquare size={7} color={"#0089ED"} />
                                                                    <Breadcrumb className="actualizacion-breadcrumb" separator={">"}>
                                                                        <Breadcrumb.Item>Empleados Nomina</Breadcrumb.Item>
                                                                        <Breadcrumb.Item>Maquetación</Breadcrumb.Item>
                                                                    </Breadcrumb>
                                                                </Space>
                                                                <Space size={5}>
                                                                    <Space className='actualizacion-tag' style={{backgroundColor: "#59B200"}}>
                                                                        <Avatar icon={<UserOutlined />} size={18} />
                                                                        <Text className='actualizacion-tag-name' strong style={{color: "white"}}>
                                                                            Jose Luis
                                                                        </Text>
                                                                    </Space>
                                                                    <Text italic className="actualizacion-accion">
                                                                        completó la tarea
                                                                    </Text>
                                                                </Space>
                                                            </Space>
                                                        </Col>
                                                        <Col flex={"none"}>
                                                            <Space direction="vertical" size={4}>
                                                                <Tag className="actualizacion-estatus" color="#46BD84">
                                                                    Completada
                                                                </Tag>
                                                                <Text className="actualizacion-tiempo">
                                                                    Hace dos minutos
                                                                </Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                               
                                            </Row>
                                            { this.state.actualizaciones.length - 1 >= index && <Divider/> }
                                        </>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                  
                </Content>
            </>
        )
    }
}