import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import '../../Styles/Global/sider.css';
import { useNavigate } from "react-router-dom"
import { FaBuilding, FaClipboardList, FaUserCircle, FaLock } from "react-icons/fa"
import {
    IconCuentas,
    IconFinanzas,
    IconDashboard,
    IconUsuarios,
    IconRubros,
    IconProveedores,
    IconPM,
    IconVentas,
    IconCRM,
    IconProjectManager
} from '../Widgets/Iconos.js'

import { DollarOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;
const { Sider } = Layout;

/**
 *
 *
 * @export
 * @class Admin_Sidebar
 * @extends {Component}
 */
class AdminSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof AdminSidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        return (
            <Sider 
                theme="light"
                width={250}
                collapsedWidth={0}
                breakpoint="lg"
                collapsedWidth="0"
                className="custom-sider sider-width" 
                //collapsed={this.state.collapsed}
                //collapsible
            >
                
                <Menu
                    mode="inline"
                    theme="light"
                    className="heigth-100 "
                    style={{ borderRight: 0 }}
                >
                    <Button ghost onClick={this.toogle} className="no-border">
                        {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>


                    <Menu.ItemGroup key="navegacion" title="NAVEGACIÓN">

                        <Menu.Item key="dashboard" icon={<IconDashboard />} onClick={() => this.props.navigate("/admin/dashboard", { replace: true })}>
                            Dashboard
                        </Menu.Item>
                        {/* <Menu.Item key="finanzas" icon={<IconFinanzas />} onClick={() => this.props.navigate("/admin/finanzas", { replace: true })}>
                            Finanzas
                        </Menu.Item> */}

                        <SubMenu key="finanzas" title={<span onClick={() => this.props.navigate("/admin/finanzas", { replace: true })}>Finanzas</span>} icon={<IconFinanzas />}>
                            <Menu.Item key="transacciones" icon={<DollarOutlined />} onClick={() => this.props.navigate("/admin/finanzas/transacciones", { replace: true })}>
                                Transacciones
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="crm" title={<span onClick={() => this.props.navigate("/admin/customer-relationship-management", { replace: true })}>CRM</span>} icon={<IconCRM />}>
                            <Menu.Item key="clientes" icon={<IconVentas />} onClick={() => this.props.navigate("/admin/customer-relationship-management/clientes", { replace: true })}>
                                Clientes
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item key="contabilidad" icon={<IconDashboard />} onClick={() => this.props.navigate("/admin/contabilidad", { replace: true })}>
                            Contabilidad
                        </Menu.Item>
                        <Menu.Item key="project-manager" icon={<IconProjectManager />} onClick={() => this.props.navigate("/admin/project-manager", { replace: true })}>
                            Project Manager
                        </Menu.Item>
                        <Menu.Item key="tickets" icon={<FaClipboardList />} onClick={() => this.props.navigate("/admin/tickets", { replace: true })}>
                            Ticket Manager
                        </Menu.Item>
                        <Menu.Item key="empleados" icon={<FaUserCircle />} onClick={() => this.props.navigate("/admin/empleados", { replace: true })}>
                            Recursos Humanos
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.Divider className="group-sider-divider " />
                    <Menu.ItemGroup key="g2" title="AJUSTES">

                        <Menu.Item key="areas" icon={<IconRubros />} onClick={() => this.props.navigate("/admin/areas", { replace: true })}>
                            Areas
                        </Menu.Item>

                        <Menu.Item key="cuentas" icon={<IconCuentas />} onClick={() => this.props.navigate("/admin/cuentas", { replace: true })}>
                            Cuentas
                        </Menu.Item>
                        
                        <Menu.Item key="empresas" icon={<FaBuilding />} onClick={() => this.props.navigate("/admin/empresas", { replace: true })}>
                            Empresas
                        </Menu.Item>

                        <Menu.Item key="modulos" icon={<FaBuilding />} onClick={() => this.props.navigate("/admin/modulos", { replace: true })}>
                            Modulos
                        </Menu.Item>

                        <Menu.Item key="usuarios" icon={<IconUsuarios />} onClick={() => this.props.navigate("/admin/usuarios", { replace: true })}>
                            Usuarios
                        </Menu.Item>

                        <Menu.Item key="plantillas" icon={<IconPM />} onClick={() => this.props.navigate("/admin/plantillas", { replace: true })}>
                            Plantillas
                        </Menu.Item>

                        <Menu.Item key="proyectos" icon={<IconPM />} onClick={() => this.props.navigate("/admin/proyectos", { replace: true })}>
                            Proyectos
                        </Menu.Item>

                        <Menu.Item key="proveedores" icon={<IconProveedores />} onClick={() => this.props.navigate("/admin/proveedores", { replace: true })}>
                            Proveedores
                        </Menu.Item>

                        <Menu.Item key="servicios" icon={<FaBuilding />} onClick={() => this.props.navigate("/admin/servicios", { replace: true })}>
                            Servicios
                        </Menu.Item>
 
                        <Menu.Item key="roles" icon={<FaLock />} onClick={() => this.props.navigate("/admin/roles", { replace: true })}>
                            Roles
                        </Menu.Item>

                    </Menu.ItemGroup>
                </Menu>

            </Sider>
        )
    }
}

export default function View (props){ 
    return <AdminSidebar navigate={useNavigate()} {...props} />
}
