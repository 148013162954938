import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Image, Alert, Modal } from 'antd';
import { Navigate, Link, useParams, useNavigate } from 'react-router-dom';
import { User, SetUser } from '../../Hooks/Logged';
import axios from 'axios'

//css
import '../../Styles/Global/auth.css';

const { Title } = Typography

/**
 * 
 * @class Login
 * @extends {Component}
 * @description Componente Login para inicio de sesion
 */
class UpdatePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	/**
	 * @memberof Login
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 *
	 * @param values (array)
	 * Contiene los campos del formulario para registrar al usuario
	 *
	 * @returns response (array)
	 **/
	onFinish = async values => {
		axios.put('/password/recovery/update', values)
			.then(() => {
				this.props.navigate("/login")
			})
			.catch((error) => {
				console.log(error.response)
				Modal.error({
					title: "No fue posible actualizar.",
					content: "El token es invalido o el correo no existe."
				})
			})
	};


	render() {
		console.log("t", this.props)
		return (
			<Row className='layout-login'>
				<Col span={10} className="col-welcome">
					<Row>
						<img src="/img/ISY-Logo.png" alt="Logo I See You Technologies" />
					</Row>
					<Row>
						<div>
							<Title level={2} className='title-isyt-erp'>
								I See You Tech <br />
								Enterprise Resource <br />
								Planning <br /><br />
							</Title>
							<p className='subtitle-isyt-erp'>Welcome to the ISYT Digital Ecosystem</p>
						</div>
					</Row>
				</Col>
				<Col span={14}>
					<div className='login-form-container'>
						<Form initialValues={{ token: this.props.params.token }} onFinish={this.onFinish} layout={"vertical"} requiredMark={false} className='login-form'>
							<Title level={3} className="h3-title">¡Recupera tu Contraseña!</Title>
							<Title level={5} className="h5-title login-subtitle">{ }</Title>

							<Form.Item name="token" noStyle >
								<Input type="hidden" />
							</Form.Item>

							<Form.Item className='input-email' name="email" label="Email or phone number"
								rules={[{ required: true, message: 'Debe ingresar el correo electróncio de la cuenta' }]}>
								<Input placeholder='Ingrese el correo electróncio de la cuenta' size="large" className="input-login" />
							</Form.Item>
							<Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Please enter your password' }]}>
								<Input type="password" placeholder="••••••••••" size="large" />
							</Form.Item>
							<Form.Item label="Confirmar Contraseña" name="confirm" rules={[
								{
									required: true,
									message: 'Por favor, confirme su contraseña!',
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject('Las contraseñas no coinciden.');
									},
								}),
							]}>
								<Input type="password" placeholder="••••••••••" size="large" />
							</Form.Item>

							<Button type="primary" htmlType="submit" block size="large" >Recuperar Contraseña</Button>
						</Form>
					</div>
				</Col>
				<div className='link-password-recovery'>
					¿Ya tienes tus credenciales? <Link style={{ color: '#5452F6' }} to='/login'>Inicia Sesión</Link>
				</div>
			</Row>
		)
	}
}

export default function (props) {
	
	return <UpdatePassword {...props} params={useParams()} navigate={useNavigate()} />

}