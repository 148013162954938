import React, { useContext, useState } from 'react';
import { Space, Dropdown, Menu, Col, Row, Badge, Layout, Input, Typography, Button, Select, Popover, message, Form, InputNumber, Divider } from 'antd';
import { BsBellFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { Link, Navigate } from "react-router-dom";


//componentes
import { User, SetUser } from '../../Hooks/Logged'
import Notifications from '../Widgets/Notifications'

import '../../Styles/Global/header.scss'

const { Header } = Layout;
const { Search } = Input;
const { Title, Paragraph } = Typography;

/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} {  showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */
const Navbar = ({ showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal, admin }) => {


	let user = useContext(User)
	let setUser = React.useContext(SetUser)
	const [redirect, setRedirect] = useState(false)
	const navigate = useNavigate()

	/**
	 * @const cerrarSesion
	 * @description Cierra la sesion
	 */
	const cerrarSesion = () => {
		setUser(undefined);
		sessionStorage.clear();
		navigate('/login')

	};

	/**
	* @const UserMenu
	* @description Muestra el Menu del dashboard
	*/
	const UserMenu = () => {
		return (
			<Col flex="auto" className="header-content" style={{ display: "flex", flex: 1, columnGap: '1em', padding: "0 1em" }} >
				<Search
					placeholder="Buscar"
					onSearch={(e) => { setFilterSearch(e) }}
					id="inputFilterSearch"
					enterButton
					size='large'
					className="input-global-search"
				/>
				<Space size={"large"}>
					<Notifications />
					<Dropdown
						arrow={true}
						overlay={
							<Menu>
								<Menu.Item key="0" onClick={cerrarSesion}>
									Cerrar Sesión
								</Menu.Item>
							</Menu>
						} trigger={['click']}>
						<Space>
							{user?.nombre} <DownOutlined />
						</Space>
					</Dropdown>
				</Space>
			</Col>
		)
	}

	return (
		<Header className="header" >
			<Row type="flex" className="row-header-content" >
				<Col className="header-logo" sm={24} xs={24}>
					<div className="logo">
						<img src={'/img/ISY-Logo.png'} alt="logo" />
					</div>
				</Col>
				{UserMenu()}
			</Row>
		</Header>
	);

}

export default Navbar;