import React from 'react'
import { Route, Routes } from "react-router-dom";

import Proyectos from '../components/Admin/Proyectos/Proyectos';
import ProyectoDetalle from '../components/Admin/Proyectos/ProyectoDetalle';

/**
 * 
 * @export
 * @function RouterProyectos
 * @description Router for Proyectos routes 
 */
function RouterProyectos(props) {
  return (
    <Routes>
      <Route path="" element={<Proyectos {...props} />} />
      <Route path="/detalle/:proyecto_id" element={<ProyectoDetalle {...props} />} />
    </Routes>
  )
}

export default RouterProyectos