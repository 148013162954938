import React, { Component } from 'react';
import { Button, Popconfirm, Spin, Table, PageHeader, Layout, message, Image, Row, Col, Card, Typography, Space, Tag, Tabs } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";

//Componentes
import { IconDelete, IconEdit, IconArrowBack, IconEye } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import ServiciosCliente from './ServiciosCliente';




const { Content } = Layout;
const { Text, Title } = Typography;
const { TabPane } = Tabs;


const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class DetallesProyecto
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
export default class DetallesProyecto extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            proyecto: {},
            transacciones: [],

            page: 1,
            total: 0,
        }
    }

    proyectosRef = React.createRef();

    componentDidMount(){
         
        this.getProyecto()
    }


    /**
     * @memberof ModalClientes
     * @method getCliente
     * @description Obtiene una Cliente de la DB
     *
     */
    getProyecto = () => {
        this.setState({ loading: true })
        axios.get('/proyecto', {
            params: {
                id: this.state.proyecto_id
            }
        }).then(({ data  }) => {
            this.setState({proyecto: data})

        }).catch(error => {
            message.error('Error al traer el proyecto')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalClientes
     * @method refreshInfo
     * @description Actualiza la informacion del cliente
     *
     */
    refreshInfo = () => {
        this.getCliente()
        this.proyectosRef?.current?.getProyectos()
    }


    render() {
        const { proyecto } = this.state
        const { cliente } = {}
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <div>
                                <Link className="text-gray" title="Ir a clientes" to={`/admin/crm/clientes`}>
                                    <IconArrowBack />
                                </Link>

                                <Text level={4} className="text-white ant-page-header-heading-title ml-1" > Información de {proyecto.nombre} </Text>
                            </div>
                        }
                    />

                    <Content className="admin-content content-bg pd-1 crm-font-family">

                        <Row gutter={[8, 8]}>
                            <Col span={24} className="mb-1">
                                <Card bordered >
                                    <Row style={{marginBottom: "1.5rem"}}>
                                        <Space align='center'size={16}>
                                        <Image
                                            height = {42}
                                            width = {107}
                                            placeholder = {true}
                                            /* src={`${axios.defaults.baseURL}/upload/${cliente.logo}`} */
                                        />
                                        <Title level={2} className="title-client-name">{proyecto.nombre}</Title>
                                        </Space>
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={12} xl={5}>
                                            <label className="lbl-titulo">Creado el</label>
                                            <p className="p-info">{moment(proyecto.createdAt).fromNow()}</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={5}>
                                            <label className="lbl-titulo">Monto Contratado</label>
                                            <p className="p-info text-contract-format">$ {proyecto.monto_venta ? proyecto.monto_venta.toMoney(true) : 0} MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={5}>
                                            <label className="lbl-titulo">Monto Pagado</label>
                                            <p className="p-info text-amount-format">$ {proyecto.monto_pagado ? proyecto.monto_pagado.toMoney(true) : 0} MXN</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Tabs defaultActiveKey="proyectos" className="tabs-crm-client">
                                    <TabPane tab="Transacciones" key="transacciones">
                                        
                                    </TabPane>
                                    <TabPane tab="Facturas" key="facturas">
                                       
                                    </TabPane>
                                    <TabPane tab="Servicios" key="servicios">
                                        <ServiciosCliente cliente_id={this.state.proyecto._id}/>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>

                        <Row>
                        </Row>
                    

                    </Content>

                    {/* <FloatingButton title="Nuevo registro" />
                    <ModalTransaccionesDetalle
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, transaccion_id: undefined });
                            this.getTransacciones()
                        }}
                        id={this.state.transaccion_id}
                    /> */}
                </Spin>
            </>
        )
    }
}