import React from 'react'
import { Route, Routes } from "react-router-dom";

// Components
import Transacciones from '../components/Admin/Finanzas/Transacciones/Transacciones';
import TransaccionesSyncfy from '../components/Admin/Finanzas/Transacciones/TransaccionesSyncfy'
import DashboardFinanzas from '../components/Admin/Finanzas/Dashboard';

/**
 * 
 * @export
 * @function RouterFinance
 * @description Router para dashboard routes 
 */
 function RouterFinance(props) {
  return (
    <Routes>
      <Route path="/" element={<DashboardFinanzas />} />
      <Route path="transacciones" element={<Transacciones {...props}/>} />
      <Route path="transacciones/sin-clasificar/:cuenta_id" element={<TransaccionesSyncfy {...props} />} /> 
    </Routes>
  )
}

export default RouterFinance