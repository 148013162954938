import React, { Component } from 'react';
import { Row, Col, Button, PageHeader, Layout, message, Typography, Divider } from 'antd'


import Kanban from './Kanban';


const { Content } = Layout;


/**
 * @export
 * @class Tickets
 * @extends {Component}
 * @description Vista de Tickets
 */
export default class Tickets extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            feed: false,

            page: 1,
            total: 0,
            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
    }


    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Ticket Manager"
                    extra={[<Button className='ph-extra' onClick={this.toggleFeed}>Feed</Button>]}
                />
                <Content className="content-bg ">
                    <Kanban />
                </Content>
            </>
        )
    }
}