import React from 'react'
import { Layout, Col, Row, PageHeader, Typography, Card, message } from "antd";
import axios from 'axios'

import { CardVentasAnuales, CardCRMVentasEstimadasReales } from '../../Widgets/Cards';

import '../../../Styles/Modules/CRM/Dashboard.css';

const { Content } = Layout;
const { Text } = Typography;

export default class DashboardCRM extends React.Component {

	constructor(props) {
        super(props)
        this.state = {
            ventas_anules: [],
            ventas : {
                ventas_totales: 0,
                ventas_cobradas: 0,
                ventas_por_cobrar: 0,
                porcentaje: 0,
            }
        }
    }

    componentDidMount(){
         
    	this.getInfo()
    	
    }


    /**
     * @memberof Proyectos
     * @method getInfo
     * @description Obtiene Informacion general del CRM
     *
     */
    getInfo = () => {
        this.setState({ loading: true })
        axios.get('/crm/dashboard', {
        	
        }).then(response => {
        	console.log("response", response.data);
      		this.setState({
      			ventas: response.data.data.ventas,
      			ventas_anuales: response.data.data.ventas_anuales
      		})	 	
      
        }).catch(error => {
            message.error('Error al traer la información')
            console.log(error.response)
        }).finally(() => this.setState({ loading: false }))
    }
	
	render() {

		const { ventas, ventas_anuales } = this.state 

		return (
			<>
				<PageHeader
					className="site-page-header custom-page-header"
					title={
							<Row className="width-100" justify="space-between" align="middle">
									<Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
											<span className="ant-page-header-heading-title">
													Dashboard
											</span>
									</Col>
							</Row>
					}
				/>
				<Content className="isy-layout-content content-bg">
					<Row className="mb-1" gutter={[16,16]}>
						<Col xs={24} lg={16}>
							<Row gutter={[16,16]}>
								<Col xs={24} lg={12}>
									<Card bordered={false} 
										className="card-dashboard-crm card-ventas-totales" 
									>	
										<div>
												<Text className="card-title">Ventas Totales</Text>
										</div>
										<div>
												<Text className="card-amount">$ {ventas.ventas_totales.toMoney(true)} <span className="currency-type-font">MXN</span></Text>
										</div>
										
									</Card>
								</Col>
								<Col xs={24} lg={12}>
									<Card bordered={false} 
										className="card-dashboard-crm card-ventas-cobradas" 
									>	
										<div>
												<Text className="card-title">Ventas Cobradas</Text>
										</div>
										<div>
												<Text className="card-amount">$ {ventas.ventas_cobradas.toMoney(true)} <span className="currency-type-font">MXN</span></Text>
										</div>
										
									</Card>
								</Col>
								<Col xs={24} lg={12}>
									<Card 
										bordered={false} 
										className="card-dashboard-crm card-ventas-por-cobrar" 
									>
										<div>
												<Text className="card-title">Ventas por Cobrar</Text>
										</div>
										<div>
												<Text className="card-amount">$ {ventas.ventas_por_cobrar.toMoney(true)} <span className="currency-type-font">MXN</span></Text>
										</div>
										
										
									</Card>
								</Col>
								<Col xs={24} lg={12}>
									<Card 
										bordered={false} 
										className="card-dashboard-crm card-porcentaje-cobrado" 
									>
										<div>
												<Text className="card-title">Porcentaje Cobrado</Text>
										</div>
										<div>
												<Text className="card-amount">{ventas.porcentaje.toMoney()} <span className="currency-type-font">%</span></Text>
										</div>
										
										
									</Card>
								</Col>
							</Row>
						</Col>
						<Col xs={24} lg={8}>
							<CardVentasAnuales ventas_anuales={ventas_anuales}/>
						</Col>
					</Row>
					<Row gutter={[16, 16]} justify="center">
						<Col span={24}>
							<CardCRMVentasEstimadasReales />
						</Col>
					</Row>
				</Content>
			</>
		)
	}
}