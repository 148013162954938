import React, { Component, useContext } from 'react';
import { Row, Col, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Card, Typography } from 'antd'

import axios from 'axios';
import { Link } from "react-router-dom";

//Componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import { IconDelete, IconEdit, IconDetails } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalAreas from './ModalAreas'

const { Content } = Layout;
const { Text } = Typography
/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista de Areas
 */
class Areas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            area_id: undefined,
            filtroSearch: '',
            searching: true,

            areas: {
                data: [],

                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
            }
        }
    }

    componentDidMount() {
        this.getAreas();
    }


    /**
     *
     *
     * @memberof Areas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate(prevProps) {
        if(prevProps.search !== this.props.search){
            this.getAreas({page: 1,  search: this.props.search})
        }
    }
    /**
     *
     *
     * @memberof Areas
     * 
     * @method getAreas
     * @description Obitiene las Areas
     */
    getAreas = ({
        page = this.state.areas.page,
        limit = this.state.areas.limit,
        search = this.props.search
    } = this.state.areas) => {
        this.setState({ loading: true, areas: { ...this.state.areas, page, limit } })
        axios.get('/areas', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({ areas: data })
            })
            .catch(res => {
                message.error(res.response.data ?? "No fue posible obtener las areas")
            })
            .finally(() => this.setState({ loading: false }))
    }
    

    render() {

        const { areas } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Areas"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Areas" }}
                            dataSource={areas.data}
                            pagination={{
                                current: areas.page,
                                pageSize: areas.limit,
                                total: areas.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getAreas({ page,  limit })
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={9} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>

                                <Col span={9} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={9} className="center">
                                                <Text strong>{item.nombre}</Text>
                                            </Col>

                                            <Col span={9} className="center">
                                                <Text className="text-gray">{item.descripcion}</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Space>
                                                    <Link title="Editar" to={`/admin/areas/${item._id}/rubros`}>
                                                        <Button
                                                            style={{ paddingTop: '5px' }}
                                                            type="" className="detTable-icon" name={item.nombre} id={item._id}
                                                            title="Ver rubros"
                                                            icon={<IconDetails />} />
                                                    </Link>


                                                    <Button
                                                        style={{ paddingTop: '3px' }}
                                                        type="primary"
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, area_id: item._id }) }}

                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta area?"
                                                        onConfirm={() => axios.delete('/area/' + item._id).then(() => {
                                                                this.getAreas()
                                                                message.success('Area eliminada')
                                                            }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            style={{ paddingTop: '5px' }}
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />


                    <ModalAreas
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, area_id: undefined })
                            this.getAreas()
                        }}
                        area_id={this.state.area_id}
                    />
                </Spin>
            </>
        )
    }
}

export default function Vista(props) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarAreas: ["areas", "edit"],
        deleteAreas: ["areas", "delete"],
        createAreas: ["areas", "create"],
        accessRubros: ["rubros", "access"],
    });

    return <Areas {...props} {...permisos}/>

}