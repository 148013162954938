import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal ,Spin, PageHeader, Layout, Space, message, Card, List } from 'antd'
import axios from 'axios';

//componentes
import Logged from "../../../Hooks/Logged";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconMail } from '../../Widgets/Iconos';

//modal
import ModalUsuarios from './ModalUsuarios'


const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
export default class Usuarios extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            usuarios: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
            usuario_id: null
        }
    }

    componentDidMount() {
         
        this.getUsuarios()
    }

    /**
     * @methodOf  Usuarios
     * @method sendEmail
     *
     * @description Envia un correo para recuperar la contraseña
     *
     * */
     sendEmail = (email) => {
        this.setState({loading: true})
        axios.put("/password/recovery", { email: email })
            .then(res => {
                Modal.success({
                    title: 'Restablecer contraseña.',
                    content: '¡El correo ha sido enviado!'
                })
            })
            .catch(res => {
                console.log(res)
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    }

    /**
    *
    * @memberof RazonesSociales
    * @method getRS
    * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
    */
    getUsuarios = async ({
        page = this.state.usuarios.page,
        limit = this.state.usuarios.limit,
        search = this.props.search
    } = this.state.usuarios) => {

        this.setState({ loading: true, usuarios: { ...this.state.usuarios, page, limit } })

        axios.get('/usuarios', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({ usuarios: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    


    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={"Usuarios"}
                        extra={<span className="page-header-extra"> {this.state.usuarios.total} Usuarios</span>}
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Usuarios" }}
                            dataSource={this.state.usuarios.data}
                            pagination={{
                                current: this.state.usuarios.page,
                                pageSize: this.state.usuarios.limit,
                                total: this.state.usuarios.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getUsuarios({ page, limit })

                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={6} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>

                                <Col span={6} className="center">
                                    <Text strong>Email</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>Telefono</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>Inversion</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={6} className="center">
                                                <Text strong>{item.nombre} {item.apellidos}</Text>
                                            </Col>

                                            <Col span={6} className="center">
                                                <Text className="text-gray">{item.email}</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text className="text-gray">{item.telefono}</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Space>
                                                    <Popconfirm 
                                                        placement="topRight"
                                                        title="¿Deseas enviar un correo de recuperación de contraseña?" okText="Si" cancelText="No"
                                                        onConfirm={() => this.sendEmail(item.email)}
                                                    >
                                                        <Button type="primary" title="Recuperar Contraseña" icon={<IconMail />} />
                                                    </Popconfirm>
                                                    <Button type="primary" icon={<IconEdit />} onClick={() => this.setState({ modalUsuarios: true, usuario_id: item._id })} />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este usuario?"
                                                        onConfirm={() => axios.delete('/usuario/' + item._id ).then((response) => {
                                                            message.success(response?.data?.message)
                                                            this.getUsuarios()
                                                        })
                                                            .catch((error) => {
                                                                message.error(error?.response?.data?.message);
                                                                this.getUsuarios();
                                                            })
                                                        }
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button type="normal" danger title="Eliminar" icon={<IconDelete />} />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalUsuarios: true })} />

                    <ModalUsuarios
                        visible={this.state.modalUsuarios}
                        usuario_id={this.state.usuario_id}
                        onClose={() => {
                            this.setState({ modalUsuarios: false, usuario_id: undefined })
                            this.getUsuarios()
                        }}
                    />
                </Spin>
            </>
        )
    }
}
