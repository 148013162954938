import React, { Component } from "react";
import { Row, Col, Space, Modal, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin, Divider, Button, List, Badge, Tag } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from "axios"

//componentes
import { Uploader } from "../../../Widgets/Uploaders";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { SelectProyecto, SelectCuenta, SelectArea, SelectRubro } from "../../../Widgets/Inputs/Selects"
import Decimal from "decimal.js";

const { Title, Text } = Typography
const { Option } = Select

const { RangePicker } = DatePicker


const moment = require('moment');

class ModalTransaccionProgramada extends Component {


    static defaultProps = {
        area: false,
        propiedad: false
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            area_id: undefined,

            transacciones: {
                data: [],

                skip: 0,
                limit: 15,
                total: 0,

                search: null
            },
            has_pagos: false,
            filters: {},
            cuentaSeleccionada: '',
            disabled: false,
            cliente_id: undefined,
            dias_limite: 7,
            recurrencia: 1,
            abonos: []
        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.transaccion_programada_id){
            this.getTransaccionProgramada()
        }
    }


    /**
     * @memberof ModalTransaccionProgramada
     * @method getTransaccionProgramada
     * @description obtiene la informacion de la transaccion
     */
    getTransaccionProgramada = () => {

        this.setState({ loading: true })
        axios.get(`/transaccion-programada/${this.props.transaccion_programada_id}`)
            .then(({ data }) => {
                console.log("data", data.abonos);
                let tProgramada = {
                    ...data,
                    fecha_inicio_limite: undefined,
                    fecha_limite: undefined,
                    fecha_inicio: undefined,
                    cuenta_id: data?.cuenta_id?._id ? {
                        value: data?.cuenta_id?._id,
                        label: data?.cuenta_id?.nombre
                    } : null,
                    area_id: data?.area_id?._id ? {
                        value: data?.area_id?._id,
                        label: data?.area_id?.nombre
                    } : null,
                    rubro_id: data?.rubro_id?._id ? {
                        value: data?.rubro_id?._id,
                        label: data?.rubro_id?.nombre
                    } : null,
                    proyecto_id: data?.proyecto_id?._id ? {
                        value: data?.proyecto_id?._id,
                        label: data?.proyecto_id?.nombre
                    } : null,
                    comprobantes: data.comprobantes.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/transaccion-programada/" + this.props.transaccion_programada_id + "?comprobante=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })),
                }

                if (data.periodo != 1)
                    tProgramada.fecha_inicio_limite = [moment(data.fecha_inicio), moment(data.fecha_limite)]
                else
                    tProgramada.fecha_limite = moment(data.fecha_limite)

                this.modalRef.current?.setFieldsValue(tProgramada)

                this.setState({
                    periodo: tProgramada.periodo,
                    recurrencia: data.tipo_recurrencia,
                })

                this.getAbonos({}, tProgramada)
            })
            .catch(error => {
                console.log('error al traer transaccon', error)
                message.error(error?.response?.data?.message ?? "Error al traer la transacción")
            }).finally(() => {
                this.setState({ loading: false, disabled: true })
            })
    }

    /**
    * @memberof ModalTransaccionProgramada
    * @method add
    * @description Programa una nueva transaccion
    * 
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/transaccion-programada', values)
            .then(response => {
                message.success('Transacción programada')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error('Error al programar la transaccion')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransaccionProgramada
    * @method update
    * @description Actualiza la transaccion programada
    */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/transaccion-programada', values)
            .then(response => {
                message.success('Transacción Programada Actualizada')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error('Error al actualizar la transaccion programada')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransaccionProgramada
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {


        if (this.state.abonos?.length < 1 && this.state.recurrencia === 1)
            return Modal.error({
                title: "No se generaron pagos pendientes",
                content: <>
                    Para poder crear la transaccion programada mediante una temporalidad, debe de indicar un rango de fechas que genere al menos un pago pendiente. <br />
                    Si este es un pago unico, debe de indicar "No aplica, para generar el pago único</>

            })

        if (values.periodo !== 1 && this.state.recurrencia === 1) {
            values.fecha_inicio = values.fecha_inicio_limite[0]
            values.fecha_limite = values.fecha_inicio_limite[1]
        }

        for (const key in values)
            if (values[key]?.value)
                values[key] = values[key]?.value

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            id: this.props.transaccion_programada_id,
            cliente_id: this.props.cliente_id ?? undefined,
            comprobantes: undefined
        })

        values.comprobantes?.forEach(e => {
            if (!e.fromDB) formData.append("comprobantes", e, e.name)
        })

        formData.append("abonos", JSON.stringify(this.state.abonos))

        if (this.props.transaccion_programada_id)
            this.update(formData)
        else
            this.add(formData)
    }


    getAbonos = (values, { periodo, tipo, fecha_inicio, fecha_limite, fecha_inicio_limite, monto, dias_limite, comprobantes }) => {
        if(values.comprobantes) return
        
        if (Array.isArray(fecha_inicio_limite) && fecha_inicio_limite.length > 1) {
            fecha_inicio = fecha_inicio_limite[0]
            fecha_limite = fecha_inicio_limite[1]
        }

        if (Boolean(
            fecha_limite &&
            // (fecha_inicio && periodo !== 1) &&
            periodo &&
            tipo &&
            monto &&

            dias_limite &&
            (monto > 0)
        )
        ) {
            if (periodo !== 1) {
                let periodos = {
                    2: { value: 1, period: "week" },
                    3: { value: 15, period: "days" },
                    4: { value: 1, period: "months" },
                }
                let abonos = []
                for (fecha_inicio = moment(fecha_inicio); fecha_inicio.isBefore(moment(fecha_limite)); fecha_inicio = fecha_inicio.add(periodos[periodo].value, periodos[periodo].period)) {
                    let estatus
                    if (fecha_inicio.isBefore(moment()))
                        estatus = 0
                    else if (moment().isBetween(moment(fecha_inicio).subtract(dias_limite, "days"), moment(fecha_inicio)))
                        estatus = 1
                    else
                        estatus = 2

                    abonos.push({
                        fecha_corte: moment(fecha_inicio),
                        estatus,
                    })
                }

                let abono = Decimal(monto)
                    .div(abonos.length)
                    .toDecimalPlaces(2)
                    .toNumber()

                this.setState({
                    abonos: abonos.map(e => ({
                        ...e,
                        monto: abono,
                        monto_pendiente: abono,
                        monto_pagado: 0,
                        transaccion_programada_id: this.props.transaccion_programada_id,
                        dias_limite

                    }))
                }, () => console.log(this.state.abonos))
            } else {
                let estatus
                if (fecha_limite.isBefore(moment()))
                    estatus = 0
                else if (moment().isBetween(moment(fecha_limite).subtract(dias_limite, "days"), moment(fecha_limite)))
                    estatus = 1
                else
                    estatus = 2
                this.setState({
                    abonos: [
                        {
                            fecha_corte: moment(fecha_limite),
                            estatus,
                            monto,
                            monto_pendiente: monto,
                            monto_pagado: 0,
                            transaccion_programada_id: this.props.transaccion_programada_id,
                            dias_limite
                        }
                    ]
                })
            }
        } else {
            this.setState({ abonos: [] })
        }
    }


    renderEstatus = (estatus) => {
        switch (estatus) {
            case 0:
                return <Tag color="#F50">Vencida</Tag>
            case 1:
                return <Tag color="#FBBC30">Proxima</Tag>
            case 2:
                return <Tag color="#87d068">Pendiente</Tag>
        }
    }

    /**
     * @memberof ModalTransaccionProgramada
     * @method onSelectRecurrecnia
     * @description muestra o esconde los campos de fechas y la opcion de "No Aplica" en periodo
     */
    onSelectRecurrecnia = (tipo) => {
        this.setState({
            recurrencia: tipo,
            abonos: []
        })

        this.modalRef.current.setFieldsValue({
            periodo: null,
            dias_limite: undefined
        })
    } 

    render() {

        const { has_pagos } = this.state

        return (
            <Form
                layout="vertical"
                name="form-transaccion"
                id="form-transaccion"
                ref={this.modalRef}
                onFinish={this.onFinish}
                onValuesChange={this.getAbonos}
                initialValues={{
                    tipo_recurrencia: 1
                }}
            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[16, 0]}>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Monto"
                                name="monto"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, ingrese monto"
                                    }
                                ]}
                            >
                                <InputNumber
                                    min={0.01}
                                    disabled={has_pagos}
                                    defaultValue={0} className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    onChange={monto => this.setState({ monto })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Área"
                                name="area_id"
                            >
                                <SelectArea
                                    onSelect={(area_id)=>{
                                        this.setState({area_id})
                                        this.modalRef.current.setFieldsValue({rubro_id: undefined})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Rubro"
                                name="rubro_id"
                            >
                                <SelectRubro
                                    disabled={!this.state.area_id}
                                    area_id={this.state.area_id}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proyectos"
                                name="proyecto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el proyecto"
                                }]}
                            >
                                <SelectProyecto
                                    params={{
                                        cliente_id: this.props.cliente_id
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cuenta"
                                name="cuenta_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                
                                <SelectCuenta/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Tipo Recurrencia"
                                name="tipo_recurrencia"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione el tipo Recurrencia"
                                    onSelect={this.onSelectRecurrecnia}
                                >
                                    <Option value={1}>No aplica</Option>
                                    <Option value={2}>Suscripción</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Periodo"
                                name="periodo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el perido"
                                }]}
                            >
                                <Select
                                    disabled={has_pagos}
                                    placeholder="Seleccione periodo"
                                    onChange={periodo => {
                                        const values = this.modalRef.current.getFieldsValue()
                                        this.setState({ periodo })
                                        if (values.fecha_limite && moment(values.fecha_limite).isBefore(moment())) {
                                            this.modalRef.current.setFieldsValue({
                                                fecha_inicio_limite: [moment(values.fecha_limite), moment()]
                                            })
                                        }
                                        if (values.fecha_inicio_limite && values.fecha_inicio_limite.length > 1) {
                                            this.modalRef.current.setFieldsValue({
                                                fecha_limite: values.fecha_inicio_limite[0]
                                            })
                                        }

                                    }}
                                >
                                    { this.state.recurrencia === 1 ?  <Option value={1}>No aplica</Option> : null}
                                    <Option value={2}>Semanal</Option>
                                    <Option value={3}>Quincenal</Option>
                                    <Option value={4}>Mensual</Option>
                                    { this.state.recurrencia === 2 ?  <Option value={1}>Anual</Option> : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Tipo"
                                name="tipo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo de transacción"
                                }]}
                            >
                                <Select
                                    disabled={has_pagos}
                                    placeholder="Seleccione el tipo"
                                >
                                    <Option value={1}>Ingreso</Option>
                                    <Option value={2}>Egreso</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        { this.state.recurrencia === 1 ? <Col xs={24} lg={12} >
                            <Form.Item
                                label="Dias limite"
                                name="dias_limite"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la cantidad de días"
                                }]}
                            >
                                <InputNumber
                                    disabled={has_pagos}
                                    className="width-100"
                                />
                            </Form.Item>
                        </Col> : null}
                        {  this.state.recurrencia === 1 ?  ((!this.state.periodo || this.state.periodo == 1)) ? (
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Fecha Limite de Pago"
                                    name="fecha_limite"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la fecha"
                                    }]}
                                >
                                    <DatePicker
                                        className="width-100"
                                        disabled={has_pagos}
                                    />
                                </Form.Item>
                            </Col>
                        ) : (
                            <Col span={24}>
                                <Form.Item
                                    label="Fecha de Inicio y Fecha Limite"
                                    name="fecha_inicio_limite"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el tipo de transacción"
                                    }]}
                                >
                                    <RangePicker
                                        format={'YYYY/MM/DD'}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                        ) : null}

                        {(this.state.abonos.length > 0) && <List
                            className="abonos-list"
                            size="small"
                            bordered={false}
                            dataSource={this.state.abonos}
                            locale={{ emptyText: null, }}
                            style={{ width: "100%", maxHidth: 300 }}
                            renderItem={(abono) => (
                                <List.Item style={{ flex: 1, justifyContent: "space-between" }}>
                                    <div>
                                        {abono.fecha_corte.format("YYYY-MM-DD")} <br />
                                        {this.renderEstatus(abono.estatus)}
                                    </div>
                                    <Typography.Text>{abono.monto?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Typography.Text>
                                </List.Item>
                            )}
                        />}
                        <Divider className="modal-divider">Comprobantes</Divider>
                        <Col xs={24}>
                            <Form.Item
                                label="Archivos e imagenes"
                                name="comprobantes"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.transaccion_programada_id ? {
                                        method: "PUT",
                                        name: "comprobante",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        onRemove: e => {
                                            axios.put('/transaccion-programada', {
                                                comprobante: e.uid,
                                                id: this.props.transaccion_programada_id
                                            })
                                        }
                                    } : {}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>

                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_programada_id, clasificacion } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={transaccion_programada_id ? "Editar Transacción Programada" : "Nueva Transacción Programada"}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalTransaccionProgramada {...props} />
    </Modal>

}