import React, { Component } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import { PageHeader, Layout, Row, Col, Typography, Button, Collapse, message, Space, Spin } from 'antd'

//componentes
import ServicioForm from "./Form/ServicioForm";
import DesarrolloForm from "./Form/DesarrolloForm";
import ProjectTracker from "./Cards/ProjectTracker";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { CardProyecto, CardTiempos, CardUtilidades, CardEstimacion, CardEstatus } from "./Cards/Cards";

import ModalServicioAdquirido from "./Secciones/ModalServicioAdquirido"

//css
import '../../../Styles/Modules/ProjectManager/projectManager.css'

const { Content } = Layout;
const { Panel } = Collapse;
const { Title, Text } = Typography;

/**
 * @export
 * @class MatrizCostos
 * @extends {Component}
 * @description Vista de MatrizCostos
 */
class MatrizCostos extends Component {


	constructor(props){
		super(props);
		this.state = {
			proyecto: {
				horas_estimadas: 0
			},
			cotizacion: {},
			servicios: [],
			costo_hora: 0,
			editado: false
		}
	}

	componentDidMount(){
		if(this.props.params.cotizacion_id){
			this.getCotizacion()
		}
	}

	/**
		 * @memberof MatrizCostos
		 * @method getCotizacion
		 * @description Obtiene la cotizacion con toda la informacion general
		 */
	getCotizacion = () => {
		this.setState({loading: true})
		axios.get('/project-manager/cotizacion/info',{
			params:{
				cotizacion_id: this.props.params.cotizacion_id
			}
		}).then(({data}) => {
			
			this.setState({
				servicios: data.servicios,
				costo_hora: data.proyecto.costo_hora,
				editado: false
			})

		}).catch(error => {
			message.error(error?.response?.data?.message ?? "Error al obtener la información")
		}).finally(()=>this.setState({loading: false}))
	}

	/**
		 * @memberof MatrizCostos
		 * @method onChangeValues
		 * @description Actualiza las horas estimadas de un módulo dentro de un servicio y recalcula el costo y la utilidad del servicio.
		 */
	onChangeValues = (allValues,index) => {

		let servicios = this.state.servicios.slice();

		let index_modulo = servicios[index].modulos.findIndex(e => e._id === allValues.modulo_proyecto_id )

		if(index_modulo !== -1){
			servicios[index].modulos[index_modulo].horas_estimadas = allValues.horas_estimadas

			let costo = 0, horas_estimadas = 0
			for(let modulo of servicios[index].modulos){
				costo += (modulo.horas_estimadas * this.state.costo_hora)
				horas_estimadas += modulo.horas_estimadas
			}
			
			servicios[index].costo = costo
			servicios[index].horas_estimadas = horas_estimadas
			servicios[index].utilidad =  servicios[index].monto - servicios[index].costo

			this.setState({servicios, editado: true})
		}
	}

	/**
		 * @memberof MatrizCostos
		 * @method onChangeMonto
		 * @description Maneja los cambios en los valores de un servicio de desarrollo.
		 */
	onChangeMonto = (monto,index) => {

		const { servicios, costo_hora } = this.state;
		const servicio = servicios[index];

		const costo = servicio.modulos.reduce((total, modulo) => total + (modulo.horas_estimadas * costo_hora), 0);

		servicios[index] = {
				...servicio,
				costo,
				utilidad: monto - costo,
				monto
		};
		this.setState({servicios, editado: true})
	}


	/**
		 * @memberof MatrizCostos
		 * @method onChangeServicio
		 * @description Maneja los cambios en los valores de un servicio específico dentro del array de servicios.
		 */
	onChangeServicio = (values,index) => {
		
		let servicios = this.state.servicios.slice()
		let servicio = servicios[index];

		servicio.monto = values.monto
		servicio.costo = values.costo
		servicio.utilidad = servicio.monto - servicio.costo

		servicios[index] = servicio;

		this.setState({servicios, editado: true})
	}

	/**
		 * @memberof MatrizCostos
		 * @method updateMatriz
		 * @description Actualiza la informacion de los servicios usando la matriz
		 */
	updateMatriz = () => {
		this.setState({loading: true})
		axios.put('/project-manager/matriz',{
			servicios:this.state.servicios,
			cotizacion_id: this.props.params.cotizacion_id
		}).then((response) => {
			message.success("Datos actualizados")
			this.getCotizacion()
			this.props.getCotizacion()
		}).catch(error => {
			console.log("error", error);
			message.error(error?.response?.data?.message ?? "Error al actualizar los datos")
		}).finally(()=>this.setState({loading: false}))
	}

	updateAll = () => {
		this.getCotizacion()
		this.props.getCotizacion()
	}

	render(){

		const { servicios, costo_hora } = this.state

		return(
			<Spin spinning={this.state.loading}>
				<Row className="width-100" style={{ marginBottom: '10rem'}}>
					<Col xs={24} md={12}>
						<Title className="m-0" level={2}>Matriz de Costos</Title>
					</Col>
					<Col xs={24} md={12} className="flex-right">
						<Space>
							<Button
								type="primary"
							>Envíar Cotización</Button>
							<Button
								onClick={this.updateMatriz}
								type="secondary"
								disabled={!this.state.editado}
							>Guardar Cambios</Button>
						</Space>
					</Col>
					<Col xs={24}>
						<Row className="mb-1 mt-1">
							<Col xs={8} className="center">
								<Text strong>Servicios</Text>
							</Col>
							<Col xs={3} className="center">
								<Text strong>Horas</Text>
							</Col>
							<Col xs={4} className="center">
								<Text strong>Costo</Text>
							</Col>
							<Col xs={4} className="center">
								<Text strong>Precio</Text>
							</Col>
							<Col xs={5} className="center">
								<Text strong>Utilidad</Text>
							</Col>
						</Row>
						{
							servicios.map((servicio, index) =>{
								if(servicio.tipo !== 1)
									return <ServicioForm  
										{...servicio}
										updateAll={this.updateAll}
										onChangeServicio={(values)=>this.onChangeServicio(values, index)}
									/>
								else
									return <DesarrolloForm 
										{...servicio} 
										costo_hora={costo_hora} 
										updateAll={this.updateAll}
										onChangeValues={(aV) => this.onChangeValues(aV, index)} 
										onChangeMonto={(monto)=>this.onChangeMonto(monto, index)}
									/>
							})
						}
						
					</Col>
				</Row>

				<FloatingButton title="Nuevo Servicio" onClick={() => this.setState({ modal_visible: true })} />

				<ModalServicioAdquirido
					visible={this.state.modal_visible}
					onClose={(flag)=>{
						this.setState({modal_visible: false})
						if(flag === true){
							this.getCotizacion();
							this.props.getCotizacion();
						}
					}}
					cotizacion_id={this.props.params.cotizacion_id}
				/>

			</Spin>
		)
	}

}


export default React.forwardRef((props, ref) => {
	return <MatrizCostos params={useParams()} {...props} ref={ref} />;
});

