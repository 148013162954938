import React, { Component, useEffect, useState } from "react";
import { Row, Drawer, Col, Tabs, Button, Modal, Dropdown, Typography, Form, Input as InputF, InputNumber, Select, List, message, Tag, Spin, Divider, DatePicker, Upload, Space, Badge, Cascader } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined, MessageOutlined, BellOutlined, AndroidOutlined, AppleOutlined, DesktopOutlined, CodeOutlined, LoadingOutlined } from '@ant-design/icons';


import { Menu } from 'antd';

import ChatCliente from './ChatCliente'
import BitacoraHistorial from './ActualizacionesCliente'
import axios from 'axios'
import moment from 'moment'
import Socket from '../../../Context/Socket'
import Avatar from '../../Widgets/Avatar/Avatar'
import Uploader from '../../Widgets/Uploader'

import "../../../Styles/Modules/Tickets/ModalTicket.scss"

const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const { TextArea } = InputF;




function StatusSelector({ value, onChange }) {
    let [cValue, setCValue] = useState(value ?? 1)
    useEffect(() => {
        if (cValue != value)
            setCValue(value)
    })

    let columns = [
        {
            title: "Cancelar",
            color: "#ff7875",
        },
        {
            title: "En Espera",
            color: "#2c59ff",
        },
        {
            title: "En Proceso",
            color: "#faad14",
        },
        {
            title: "En Confirmación",
            color: "#7b868e",
        },
        {
            title: "Pausadas",
            color: "#4e2e8f",
        },
        {
            title: "Completadas",
            color: "#52c41a",
        },
    ]
    const renderTagStatus = ({ color, title, index, style = {} }) => <Tag color={color} style={{ width: "100%", textAlign: "center", paddingTop: 6, paddingBottom: 6, fontSize: 14, fontWeight: "bold", cursor: "pointer", ...style }}>{title}</Tag>
    return <Dropdown

        placement="bottomCenter"
        overlay={(
            <Menu>
                {columns.map((e, index) => <Menu.Item onClick={() => {
                    onChange(index)
                    setCValue(index)
                }}>{renderTagStatus({ ...e, index })}</Menu.Item>)}
            </Menu>
        )}>
        {renderTagStatus({ color: columns[cValue].color, title: columns[cValue].title, index: cValue, style: { boxShadow: '-0.5px -0.5px 1px 1px rgb(50 50 71 / 2%), 1px 1px 3px 0px rgb(50 50 71 / 8%)' } })}
    </Dropdown>
}

function Input(props) {
    const { value, onChange } = props
    let [cValue, setCValue] = useState(value ?? "")
    let [dValue, setDValue] = useState(value ?? "")
    
    useEffect(() => {

        if (dValue != value){
            setDValue(cValue)
            setCValue(value)

        }

        /* if (cValue != value && value != undefined)
            setCValue(value) */
    })

    let triggerChange = (changedValue) => {
        if (onChange && cValue != dValue) {
            onChange(cValue);
        }
    }

    return <InputF {...props} value={cValue} onChange={e => setCValue(e.target.value)} onBlur={triggerChange}>
    
    </InputF>

       
    
}

/**
 * @class DrawerTicketDetalle
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class DrawerTicketDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            folio: null,
            ticket_id: this.props.ticket_id,


            clientes_proyectos: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            usuarios: {
                data: [],

                page: 1,
                limit: 10,

                pages: 0,
                total: 0,
                search: null
            },
            modulos: {
                data: [],

                page: 1,
                limit: 10,

                pages: 0,
                total: 0,
                search: null
            },
            loading: false,
        }
    }

    formRef = React.createRef()

    componentDidMount() {
        // this.props.socket.emit("admin/chat", 65)
        // this.props.socket.on("admin/chat", this.chat)

        this.getClientesProyectos()
        this.getUsuarios()
        // this.getProyectos()


        if (this.props.ticket_id)
            this.get()
        else
            this.folio()

    }


    componentWillUnmount() {
    }

    folio = () => axios.get('/tickets/folio')
        .then(({ data }) => this.props.setFolio(data))
        .catch(({ response }) => message.error("No fue posible obtener el folio"))

    chat = () => {
        console.log("A")
    }

    get = (ticket_id = this.state.ticket_id) => {
        console.log('getting ticket ', ticket_id)
        this.setState({ loading: true })
        axios.get("/tickets/" + ticket_id)
            .then(({ data }) => {
                const { folio, folio_number } = data

                this.formRef.current.setFieldsValue({
                    ...data,
                    proyecto_id: {
                        label: this.renderClienteProyectoOption({
                            ...data.proyecto_id.cliente_id,
                            proyectos: data.proyecto_id,
                            option: false
                        }, false),
                        key: data.proyecto_id._id,
                        value: data.proyecto_id._id,
                    },
                    responsable_id: data?.responsable_id ? {
                        label: this.renderUsuarioOption({ ...data.responsable_id }, false),
                        key: data.responsable_id._id,
                        value: data.responsable_id._id,
                    } : undefined,

                    evidencias: data.evidencias.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        url: axios.defaults.baseURL + "/tickets/" + ticket_id + "?evidencia=" + file.file +"&Authorization=" + sessionStorage.getItem("token")
                    })),
                    fecha_incidencia: moment(data.fecha_incidencia)
                })
                this.setState({ folio: { folio, folio_number }, loading: false })
            })
            .catch(error => {
                console.log(error)
                message.error(error?.response?.data ?? "No fue posible obtener el ticket")
                this.setState({ loading: false })
            })
    }

    update = (values) => {
        axios.put("/tickets/" + this.props.ticket_id, {
            ...values
        })
    }

    save = (values) => {
        this.setState({ loading: true })

        const formData = new FormData()
        formData.appendMultiple({
            folio: this.props.folio.folio,
            folio_number: this.props.folio.folio_number,
            ...values,
            evidencias: undefined
        })

        values.evidencias?.forEach(e => formData.append("evidencias", e, e.name))

        axios.post("/tickets", formData,{
            headers: {
                 'Content-Type': 'multipart/form-data'
            }
        })
            .then(({ data }) => {
                message.success("Guardado correctamente")
                this.setState({ loading: false })
                this.props.onCancel()

            })
            .catch((error) => {

                const { response } = error
                console.log(error)
                this.setState({ loading: false })
                message.error(response?.data ?? "No fue posible obtener el ticket")
            })
    }

    finish = (values) => {
        const { proyecto_id, responsable_id } = values

        values.proyecto_id = (proyecto_id?.value) ? proyecto_id?.value : proyecto_id
        values.responsable_id = (responsable_id?.value) ? responsable_id?.value : responsable_id

        if (this.props.ticket_id)
            this.update(values)
        else
            this.save(values)
    }

    getUsuarios = (
        {

            page = this.state.usuarios.page,
            limit = this.state.usuarios.limit,
            search = this.state.usuarios.search,
        } = this.state.usuarios
    ) => {
        this.setState(state => {
            state.usuarios.page = page;
            state.usuarios.limit = limit;
            state.usuarios.search = search;
        })
        return axios.get('/usuarios', {
            params: {
                page,
                search,
                limit
            }
        })
            .then(({ data }) => {
                this.setState({ usuarios: { ...this.state.usuarios, ...data, loading: false } })
            }).catch(error => {
                console.log(error)
                message.error('Error al traer los clientes')
            })
    }

    /**
     * @memberof ModalTransacciones
     * @method getProyectos
     * @description Trae los proyectos del cliente 
     * 
     */
    getClientesProyectos = ({
        page = this.state.clientes_proyectos.page,
        limit = this.state.clientes_proyectos.limit,
        search = this.state.clientes_proyectos.search,
    } = this.state.clientes_proyectos) => {
        axios.get('/proyectos-clientes', {
            params: {
                page, limit, search
            }
        }).then(({ data }) => {

            this.setState({ clientes_proyectos: { ...this.state.clientes_proyectos, ...data } })

        }).catch(error => {
            message.error('Error al obetner los proyectos')
        })
    }

    renderUsuarioOption = ({ _id, logo, nombre, apellidos, }, option = true) => {

        let content = <Space size={3}>
            <Avatar
                size="small"
                placeholder={true}
                image={logo}
                name={nombre + apellidos}
            />
            {nombre} {apellidos}
        </Space>

        if (option) return <Option value={_id} title={<Avatar
            size="small"
            placeholder={true}
            image={logo}
            name={nombre + apellidos}
        />}>
            {content}
        </Option>
        else
            return content
    }

    renderClienteProyectoOption = ({ _id, alias, logo, proyectos, proyecto_id }, option = true) => {
        let content = <>
            <Space size={3}>
                <Avatar
                    size="small"
                    placeholder={true}
                    image={proyectos.logo}
                    name={proyectos.nombre}
                />{proyectos.nombre}  / <small>{alias}</small>
            </Space>
        </>

        console.log("proyecto_id", proyecto_id)
        if (option)
            return <Option value={proyecto_id} label={content}>
                {content}
            </Option>
        else return content
    }

    render() {

        const { cliente, ticket_id } = this.state;

        return (
            <Row >
                <Col className={"pb-2" + (ticket_id ? " modal-ticket-container " : null)} span={(ticket_id ? 12 : 24)} >
                    <Spin spinning={this.state.loading} indicator={<LoadingOutlined />}>
                        <Form
                            layout="vertical"
                            ref={this.formRef}
                            
                            onValuesChange={(values, allvalues) => {
                                if (this.props.ticket_id) {
                                    this.finish(values);
                                }
                            }}
                            onFinish={this.finish}
                            name="form-ticket"
                            initialValues={{
                                estatus: 1,
                                fecha_incidencia: moment()
                            }}
                            disabled={this.state.loading}
                        >
                            <Row justify="center" gutter={[12, 0]}>
                                <Col span={18}>
                                    <Form.Item
                                        name="responsable_id"
                                        label="Responsable"
                                        >
                                        <Select
                                            className="width-100"
                                            showSearch
                                            filterOption={false}
                                            showArrow
                                            labelInValue
                                            placeholder="Nadie ha atendido el ticket"
                                            style={{ width: "100%" }}
                                            onSearch={search => this.getUsuarios({ search })}>
                                            {this.state.usuarios.data.map(item => this.renderUsuarioOption(item))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name="estatus" label="Estatus">
                                        <StatusSelector />
                                    </Form.Item>
                                </Col>
                                <Col span={24}><Divider style={{ margin: '8px auto', borderTopColor: 'rgba(28, 28, 28, 0.3)', color: 'rgba(28, 28, 28, 0.7)' }}>Información del Ticket</Divider></Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Asunto / Problema"
                                        name="asunto"
                                        rules={[
                                            {
                                                required: true,
                                                message: "El Ticket debe de tener un asunto"
                                            }
                                        ]}
                                        getValueFromEvent={e => {

                                            console.log("e", e)
                                            return e?.target?.value || e
                                        }}

                                    >
                                        <Input placeholder="Asunto del Ticket" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Cliente / Proyecto"
                                        name="proyecto_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "El Ticket debe de tener un proyecto"
                                            }
                                        ]}
                                    >
                                        <Select
                                            className="width-100"
                                            showSearch
                                            filterOption={false}
                                            showArrow
                                            labelInValue
                                            placeholder="Nadie ha tomado el ticket"
                                            style={{ width: "100%" }}
                                            onSearch={search => this.getClientesProyectos({ search })}>
                                            {this.state.clientes_proyectos.data.map((item) => this.renderClienteProyectoOption(item))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Fecha"
                                        name="fecha_incidencia"
                                    >
                                        <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD HH:mm" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Nombre del Usuario"
                                        name="nombre_contacto"
                                    >
                                        <Input placeholder="Jose Robles" size="small" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Correo Electronico del Usuario"
                                        name="email_contacto"
                                    >
                                        <Input placeholder="jr@usuario.com" size="small" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item

                                        label="Descripción"
                                        name="descripcion"
                                    >
                                        <InputF.TextArea
                                            size="small"
                                            placeholder="Indique la descripción del problema"

                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item

                                        label="Pasos o reproducción del problema"
                                        name="pasos_reproduccion"
                                    >
                                        <InputF.TextArea
                                            size="small"
                                            placeholder="Serie de pasos para resolver el problema"

                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Modulo"
                                        name="modulo_id"
                                    >
                                        <Input placeholder="Finanzas, Transacciones, Nominas..." />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Plataforma"
                                        name="plataforma"
                                    >
                                        <Select placeholder="Plataforma" style={{ width: "100%" }}>
                                            <Option value={1}>
                                                <Space>
                                                    <CodeOutlined />Web
                                                </Space>
                                            </Option>
                                            <Option value={2}>
                                                <Space>
                                                    <AppleOutlined />Apple (iOS)
                                                </Space>
                                            </Option>
                                            <Option value={3}>
                                                <Space>
                                                    <AndroidOutlined />Google (Android)
                                                </Space>
                                            </Option>
                                            <Option value={4}>
                                                <Space>
                                                    <DesktopOutlined />Escritorio
                                                </Space>
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item

                                        name="evidencias"
                                        label="Evidencias del incidente, imagenes, archivos o videos"
                                        valuePropName="fileList"
                                        getValueFromEvent={e => {
                                            console.log('Upload event:', e);
                                            if (Array.isArray(e)) {
                                                return e;
                                            }
                                            return e?.fileList;
                                        }}>
                                        <Uploader
                                            {...this.props.ticket_id ? {
                                                method: "PUT",
                                                name: "evidencia",
                                                headers: {
                                                    authorization: sessionStorage.getItem("token")
                                                },
                                                action: axios.defaults.baseURL + '/tickets/' + this.props.ticket_id,
                                                onRemove: e => axios.put('/tickets/' + this.props.ticket_id + '?evidencia=' + e.uid)
                                            }:{}}>
                                            <Button icon={<UploadOutlined />} block size="small">Evidencias </Button>
                                        </Uploader>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Col>
                {this.props.ticket_id ?
                    <Col className="modal-ticket-container" span={12} style={{ minHeight: "100%" }}>
                        <Tabs
                            style={{ margin: 0 }}
                        >
                            <Tabs.TabPane tab={<>Conversación con el Cliente &nbsp;<Badge count={22}><MessageOutlined style={{ fontSize: 22 }} /></Badge></>} key="conversación-cliente">
                                <ChatCliente ticket_id={this.props.ticket_id} style={{ maxHeight: "83vh", height: "83vh" }} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<>Bitacora e Historial &nbsp;<Badge count={22}><BellOutlined style={{ fontSize: 22 }} /></Badge></>} key="historial-actividades">
                                <BitacoraHistorial ticket_id={this.props.ticket_id} style={{ maxHeight: "83vh", height: "83vh" }} />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                    : null}
            </Row>
        )
    }
}

export default function (props) {

    const { visible, onCancel, ticket_id } = props

    const [folio, setFolio] = useState(null)
    const [fecha, setFecha] = useState(moment())

    return <Drawer
        visible={visible}
        onCancel={onCancel}
        onClose={onCancel}
        title={<>
            {ticket_id ? "Nuevo Ticket" : "Información del Ticket"}<br />
            <small style={{ opacity: 0.7 }}>{folio?.folio ?? ""}</small>
        </>}
        footer={null}
        className={ticket_id ? "modal-ticket" : null}
        destroyOnClose={true}
        zIndex={1000}
        width={ticket_id ? 1200 : 600}

        extra={
            <Space>
                {!ticket_id ? <Button type="primary"
                    form="form-ticket"
                    key="submit"
                    htmlType="submit">
                    Guardar Ticket
                </Button> : null}
            </Space>
        }

    >
        <DrawerTicketDetalle fecha={fecha} setFecha={setFecha} folio={folio} setFolio={setFolio} {...props} onClick={onCancel} socket={React.useContext(Socket)} />
    </Drawer>


}