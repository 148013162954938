import React from 'react'
import { Route, Routes } from "react-router-dom";

import Modulos from '../components/Admin/Modulos/Modulos';

/**
 * 
 * @export
 * @function RouterModulos
 * @description Router for Modulos routes 
 */
function RouterModulos(props) {
  return (
    <Routes>
      <Route path="" element={<Modulos {...props} />} />
    </Routes>
  )
}

export default RouterModulos