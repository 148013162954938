import React, { Component, useContext } from "react";
import { Row, Col, Button, Modal, Form, Input, message, Upload, Spin, Image, Select, DatePicker, Typography, Checkbox, Drawer, Space, Popconfirm, Progress, Comment, Tooltip } from 'antd';
import axios from "axios"
import { PlusOutlined,  DeleteOutlined, LoadingOutlined, FileSearchOutlined, EditOutlined } from '@ant-design/icons';


//Componentes
import User from "../../../../Hooks/Logged";
import { estatus_modulo } from "../../../Utils"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { SelectModulo } from "../../../Widgets/Inputs/Selects";

//Modal
import ModalAvance from "./ModalAvance"

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text, Title } = Typography;
const moment = require("moment")

class DrawerAvance extends Component {


    constructor(props) {
        super(props);
        this.state = {
            avance: 0,
            avances:{
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },
            loading: false,
            modal_visible: false,
            flag: false,

        }
    }

    ModalModulo = React.createRef();

    componentDidMount() {
        if(this.props.actividad_modulo_id){
            this.getAvances()
            this.getActividad()
        }
    }


    /**
     * @memberof ModalCuentas
     * @method getAvances
     * @description Obtiene informacion del modulo
     */
    getAvances = () => {
        this.setState({loading: true})
        axios.get("/avances",{
            params:{
                actividad_modulo_id: this.props.actividad_modulo_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                avances: response.data
            })
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las actividades")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalCuentas
     * @method getActividad
     * @description Obtiene informacion del modulo
     */
    getActividad = () => {
        this.setState({loading: true})
        axios.get('/actividad-modulo/'+this.props.actividad_modulo_id)
        .then(({ data }) => {
            this.setState({
                avance: data.avance
            })
           
        }).catch(error => {
            console.log("error", error.response);
            message.error(error?.response?.data?.message ?? 'Error al obtener la actividad')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Row justify="center">

                    <Col span={23}>
                        <Progress percent={this.state.avance}/>
                    </Col>
                    <Col span={23}>

                        {this.state.avances.data.length === 0 ? <Title level={5} className="text-center mt-1 mb-1">Sin avances</Title> : null}

                        { this.state.avances.data.map( avance => <Row className="pb-1 pt-1" gutter={[16,0]} style={{borderBottom: "1px #d5d5d5 solid"}}>
                            
                            <Col md={24}>
                                <Comment
                                    actions={this.props.user?._id?.toString() === avance?.responsable_id?._id ? [
                                        <Tooltip key="comment-basic-like" title="Editar">
                                            <span onClick={()=>this.setState({modal_visible: true, avance_id: avance._id })}>
                                                <EditOutlined/>
                                            </span>
                                        </Tooltip>,
                                        <Popconfirm
                                            placement="topRight"
                                            title="¿Deseas eliminar este avance?"
                                            onConfirm={() => axios.delete('/avance/' + avance._id).then((response) => {
                                                message.success('Actividad Eliminada')
                                                this.getAvances()
                                            }).catch((error) => {
                                                message.success('Actividad NO Eliminada')
                                                this.getAvances();
                                            })
                                            }
                                            okText="Si"
                                            cancelText="No"
                                        >
                                            <DeleteOutlined/>
                                        </Popconfirm>
                                    ] : []}
                                    author={<a>{avance.responsable_id.nombre} {avance.responsable_id.apellidos ?? ""}</a>}
                                    avatar={<CustomAvatar 
                                                name={`${avance.responsable_id.nombre} ${avance.responsable_id.apellidos ?? ""}`}  
                                            />}
                                    content={<><p className="mb-1"> {avance.descripcion} </p>
                                        <div>
                                            {avance.evidencias.map(image => image.file?.match(/\.(jpeg|jpg|gif|png)$/i) ? <Image
                                                width={50}
                                                src={`${axios.defaults.baseURL + "/avance/" + avance._id + "?evidencia=" + image.file +"&Authorization=" + sessionStorage.getItem("token")}`}
                                            /> :<><br/> <a target="_blank" href={`${axios.defaults.baseURL + "/avance/" + avance._id + "?evidencia=" + image.file +"&Authorization=" + sessionStorage.getItem("token")}`}>{image.name}</a></>)}
                                        </div>
                                    </>}
                                    datetime={moment(avance.createdAt).format("DD/MM/YYYY HH:mm")}
                                />
                            </Col>
                        </Row>)}
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={23} className="flex-right mt-1">
                        <Button
                            onClick={()=>this.setState({modal_visible: true})}
                        >
                            Agregar Avance
                        </Button>
                    </Col>
                </Row>

                <ModalAvance
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false, avance_id: undefined})
                        if(flag === true){
                            this.getAvances()
                            this.getActividad()
                        }
                    }}
                    actividad_modulo_id={this.props.actividad_modulo_id}
                    avance_id={this.state.avance_id}
                />
            </Spin>
        )
    }
}



export default function Modals(props) {

    const user = useContext(User);


    const { visible = false, onClose = () => { }, modulo_id } = props

    return <Drawer
        visible={visible}
        onClose={onClose}
        title={"Avances de actividad"}
        destroyOnClose={true}
        //size="large"
    >
        <DrawerAvance {...props} user={user}/>
    </Drawer>

}