import { CloseOutlined, WarningOutlined } from "@ant-design/icons"
import { Tag } from "antd"

const tipo = { "0": 'Sin Cambios', "1": ' Ingreso', "-1": 'Egreso' }

const estatus_facturas = ['Factura Cancelada', 'Pendiente', 'Liquidad']

const colors = ['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']


/** 
 * @param {Number} value Tipo de factura 
*/
const tipos_facturas = (value) => {
    switch (value) {
        case 0:
            return "Sin cambios"
        case 1:
            return "Ingreso"
        case -1:
            return "Egreso"
    }
}

/** 
 * @param {Number} value Tipo de factura 
*/
const color_tipos_facturas = (value) => {
    switch (value) {
        case 0:
            return "#007BFF"
        case 1:
            return "#DC3545"
        case -1:
            return "#DC3545"
    }
}


/** 
 * @param {Number} tipo tipo de transaccion
*/
const color_transaccion_tipo = (tipo) => {
    switch (tipo) {
        case 1:
            return "#28A745"
        case 2:
            return "#DC3545"
        case 3:
            return "#007BFF"
        case 4:
            return "#17A2B8"
    }
}

/** 
 * @param {Number} tipo tipo de transaccion
*/
const tipo_servicio = (tipo) => {
    switch (tipo) {
        case 0:
            return "Periódico"
        case 1:
            return "Desarrollo"
        case 2:
            return "Único"
    }
}


/** 
 * @param {Number} tipo tipo de transaccion
*/
const estatus_modulo = (tipo) => {

    let opciones = {
        1: <Tag color="#7c4dff">Planeado</Tag>,
        2: <Tag color="#f9d900">En proceso</Tag>,
        3: <Tag color="#3397dd">To commit</Tag>,
        4: <Tag color="#71dde3">En testeo</Tag>,
        5: <Tag color="#ff7800">En correción</Tag>,
        6: <Tag color="#f900ea">En pausa</Tag>,
        7: <Tag color="#06f515">Completado</Tag>,
    }


    return opciones[tipo] ?? "N/A"
}

export {
    tipo,
    estatus_facturas,
    colors,
    tipos_facturas,
    color_transaccion_tipo,
    tipo_servicio,
    color_tipos_facturas,
    estatus_modulo
}