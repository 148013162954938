import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from 'axios';

const { Option } = Select 


/**
 * @const SelectModulo
 * @description Select de modulos
 */
const SelectModulo = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el modulo", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},

        cliente_id,

    } = props


    const [ modulos, setModulos ] = useState({
        data: [],
        page: 1,
        limit: 100,
        total: 0,
        search: null,
    })


    /**
     * @const getModulos
     * @description Obitiene las modulos
     */
    const getModulos = ({
    	page = modulos.page,  
    	limit = modulos.limit, 
    	search
    } = modulos) => {

        axios.get('/modulos', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setModulos(data)
        }).catch(error => {
            message.error(error?.response?.data?.message + " (modulos)" ?? 'Error al obtener los modulos')
        })
    }


    //componentDidMount
    useEffect(() => { 
        getModulos()
    }, [])

    //componentDidUpdate
    useEffect(() => { 
        if(cliente_id)
            getModulos()
    }, [cliente_id])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getModulos({search})}
            onSelect={(modulos)=> {
                onChange(modulos.value)
                onSelect(modulos.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {modulos?.data?.map(({ _id, nombre, horas_aproximadas }) => <Option value={_id} key={_id}>
                {nombre} <small>{horas_aproximadas} hrs</small>
            </Option>)}
        </Select>
    );
}



export default SelectModulo;