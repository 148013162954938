import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Upload, Button, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin, Cascader, Tag, AutoComplete, Divider } from 'antd';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";

import { FaFileCsv } from "react-icons/fa";

import { IconTemplate } from "../../../Widgets/Iconos";


import axios from "axios"
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const moment = require('moment');

const Decimal = require('decimal.js');


/**
 *
 *
 * @class ModalAddMultiple
 * @extends {Component}
 * @description Agregar multiples transacciones 
 */
class ModalAddMultiple extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }


    /**
     *
     *
     * @param {*} info
     * @memberof ModalAddMultiple
     * @description Cuando el archivo se sube o hay un error, queda almacenado aquí
     */
    onChangeUpload = (info) => {
        const { status } = info.file;
        if (status === 'done' || status === 'error') {
            if (status === 'done')
                Modal.success({
                    title: "Hemos subido el archivo exitosamente.",
                    content: "El archivo ha sido subido y las transacciones han sido guardadas exitosamente",
                    onOk: (...event) => {
                        this.props.onSuccess(true)
                    }
                })
            else 
                Modal.warning({
                    title: "Hemos subido el archivo con algunos errores.",
                    content: "Algunas de las transacciones no se han podido guardar exitosamente, revise el documento",

                    okText: "Cerrar",
                    onOk: () => {
                        // const a = document.createElement('a');
                        // a.style.display = 'none';
                        // a.href = URL.createObjectURL(new Blob([info.file.xhr.response]))
                        // a.download = info.file.name;
                        // a.click()
                        this.props.onSuccess(false)
                    }
                })
        } 
    }

    render() {

        const { disabled } = this.state;
        const { clasificacion, seleccionadas } = this.props;

        return (
            <Form
                layout="vertical"

                name="form-transaccion"
                id="form-transaccion"
                ref={this.modalRef}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio
                }}

                onValuesChange={value => {
                    if (value.tipo_cambio) this.onChangeTipo(value.tipo_cambio)
                    if (value.monto_pesos) this.onChangePesos(value.monto_pesos)
                    if (value.monto_dolar) this.onChangeDolar(value.monto_dolar)
                }}

            >
                <Spin spinning={this.state.loading}>
                    <Title className="mb-2 mt-1" level={5} style={{ textAlign: "center" }}> Toma en cuenta las siguientes consideraciones.</Title>
                    <List
                        bordered={false}
                        size="small"
                        dataSource={[
                            "El formato de fecha en dd/mm/aaaa",
                            "Disminuir el uso de acentos, o evitarlo para prevenir una mala lectura",
                            "Eliminar el formato de moneda ($123)",
                            "Asegurarse que el dato esté escrito correctamente igual que en el sistema (incluyendo mayúsculas y acentos)",
                            "Descarga el template CSV para subir múltiples transacciones."
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                    <div style={{ marginTop: "2em", textAlign: "center" }}>
                        <a download href="/files/template_transacciones.csv"> 

                            <IconTemplate />
                        </a>
                    </div>

                    <Row style={{ width: "100%" }} className="mt-2 mb-2">
                        <Upload.Dragger
                            {...{
                                name: 'file',
                                multiple: false,
                                maxCount: 1,
                                action: axios.defaults.baseURL + "/transacciones/multiple",
                                headers: {
                                    Authorization: sessionStorage.getItem("token")
                                },
                                onChange: this.onChangeUpload
                                // onDrop(e) {
                                //     console.log('Dropped files', e.dataTransfer.files);
                                // },
                            }}>
                            <div style={{ margin: "0 1em" }}>
                                <p className="ant-upload-drag-icon">
                                    <FaFileCsv style={{ fontSize: "3em" }} />
                                </p>
                                <p className="ant-upload-text">Haga clic o arrastre el archivo CSV a esta área para cargarlo</p>
                                <p className="ant-upload-hint">Solo es posible subir archivos de tipo CSV</p>
                            </div>
                        </Upload.Dragger>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id, clasificacion } = props

    return <Modal
        visible={visible}
        onCancel={onClose}

        title={"Agregar Multiples Transacciones"}
        // footer={null}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        zIndex={1000}
        okText="Subir Transacciones"
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalAddMultiple {...props} />
    </Modal>

}