import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, InputNumber, Select, message, Spin } from "antd";
import axios from "axios"

//componentes
import { SelectServicio } from "../../../Widgets/Inputs/Selects";

const { Option } = Select;

class ModalServicioAdquirido extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    refModalServicio = React.createRef();

    componentDidMount() {
        if (this.props.servicio_adquirido_id !== undefined) {
            this.getServicioAdquirido();
        }
    }

    /**
     * @memberof ModalServicioAdquirido
     * @method getServicioAdquirido
     * @description Obtiene el servicio adquirido de la DB
     */
    getServicioAdquirido = () => {
        this.setState({ loading: true });
        axios.get("/servicio-adquirido/"+this.props.servicio_adquirido_id)
        .then((response) => {
            let servicio = response.data
            this.refModalServicio.current.setFieldsValue({
                servicio_id: servicio.servicio_id ? {
                    value: servicio.servicio_id._id,
                    label: servicio.servicio_id.nombre,
                } : null,
                monto: servicio.monto
            })
        })
        .catch((error) => {
            message.error("Error al obtener el servicio");
            console.log(error);
        }).finally(()=>this.setState({loading: false}))
    };


    /**
     * @memberof ModalServicioAdquirido
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     *
     */
    onFinish = (values) => {
        if (this.props.servicio_adquirido_id) {
            this.updateServicio(values);
        } else {
            this.addServicio(values);
        }
    };

    /**
     * @memberof ModalServicioAdquirido
     * @method addServicio
     * @description Añade un servicio a la BD, relacionado a una empresa o proveedor
     *
     */
    addServicio = (values) => {
        this.setState({loading: true})
        axios.post("/servicio-adquirido", {
            ...values,
            proyecto_id: this.props.proyecto_id,
        })
        .then((response) => {
            message.success("Servicio creada");
            this.props.onClose(true);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message ?? "Error al crear el servicio");
            console.log(error);
        }).finally(()=>this.setState({loading: false}))
    };

     /**
     * @memberof ModalServicioAdquirido
     * @method updateServicio
     * @description Actualiza la información de un servicio
     *
     */
    updateServicio = (values) => {
        this.setState({ loading: true });
        axios.put("/servicio-adquirido", {
            ...values,
            id: this.props.servicio_adquirido_id,
        })
        .then((response) => {
            message.success("Servicio Actualizado");
            this.props.onClose(true);
        })
        .catch((error) => {
            message.error("Error al actualizar el servicio");
            console.log(error);
        })
        .finally(() => this.setState({ loading: false }));
    };

    /**
     * @memberof ModalServicioAdquirido
     * @method getServicio
     * @description Obtiene eñ servicio de la DB
     */
    getServicio = (servicio_id) => {
        this.setState({ loading: true });
        axios.get("/servicio/" + servicio_id)
        .then((response) => {
            let servicio = response.data;
            this.refModalServicio.current.setFieldsValue({
                monto: servicio.costo,
            });
        })
        .catch((error) => {
            message.error("Error al obtener el servicio");
            console.log(error);
        }).finally(()=>this.setState({loading: false}))
    };

    render() {
        return (
            <Form
                layout="vertical"
                name="form-servicio"
                id="form-servicio"
                ref={this.refModalServicio}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center">

                        <Col xs={22}>
                            <Form.Item
                                label="Servicio"
                                name="servicio_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, seleccione el servicio",
                                    },
                                ]}
                            >
                                <SelectServicio
                                    disabled={this.props.servicio_adquirido_id}
                                    onSelect={(servicio_id)=>this.getServicio(servicio_id)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={22}>
                            <Form.Item
                                label="Monto"
                                name="monto"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, ingrese el monto",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g,",")}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        );
    }
}

export default function Modals(props) {
    const { visible = false, onClose = () => {}, servicio_id } = props;

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={servicio_id ? "Editar Servicio" : "Nuevo Servicio"}
            destroyOnClose={true}
            cancelText="Cancelar"
            okText="Guardar"
            okButtonProps={{
                form: "form-servicio",
                key: "submit",
                htmlType: "submit",
            }}
        >
            <ModalServicioAdquirido {...props} />
        </Modal>
    );
}
