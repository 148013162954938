import React, { Component } from 'react';
import { Button, Popconfirm, Spin, Table, PageHeader, Layout, message, Image, Row, Col, Card, Typography, Space, Tag, Tabs, List, Tooltip } from 'antd'
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import { FaFileInvoiceDollar } from "react-icons/fa";

//Componentes
import { IconDelete, IconEdit, IconArrowBack, IconEye } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//tabs
import FacturasTabs from './Tabs/FacturasTabs';
import ProyectosTabs from './Tabs/ProyectosTabs';
import ServiciosCliente from './ServiciosCliente';

import TransaccionesTabs from './Tabs/TransaccionesTabs';
import TransaccionesProgramadasTab from './Tabs/TransaccionesProgramadasTab';

import { PaperClipOutlined } from '@ant-design/icons';





const { Content } = Layout;
const { Text, Title } = Typography;
const { TabPane } = Tabs;


const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class DetalleClientes
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
class DetalleClientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            cliente_id: this.props.params.cliente_id,
            cliente: {},
            transacciones: [],
            page: 1,
            total: 0,
        }
    }

    proyectosRef = React.createRef();

    componentDidMount(){
         
        this.getCliente()
    }


    /**
     * @memberof ModalClientes
     * @method getCliente
     * @description Obtiene una Cliente de la DB
     *
     */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/crm/cliente', {
            params: {
                cliente_id: this.state.cliente_id
            }
        }).then(response => {
            let cliente = response.data;
            console.log("cliente", cliente);
            this.setState({cliente})

        }).catch(error => {
            message.error('Error al traer la cliente')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof DetalleClientes
     * @method refreshInfo
     * @description Actualiza la informacion del cliente
     *
     */
    refreshInfo = () => {
        this.getCliente()
        this.proyectosRef?.current?.getProyectos()
    }

    /**
     * @memberof DetalleClientes
     * @method getPDF
     * @description Obtiene el estado de cuenta del cliente en formato de pdf
     *
     */
    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/clientes/estado-cuenta');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('cliente_id', this.state.cliente_id)
        window.open(urlPDF.href, '_blank');
    }


    render() {
        const { cliente } = this.state
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <div>
                                <Link className="text-gray" title="Ir a clientes" to={`/admin/customer-relationship-management/clientes`}>
                                    <IconArrowBack />
                                </Link>

                                <Text level={4} className="text-white ant-page-header-heading-title ml-1" > Información de {cliente?.nombre} </Text>
                            </div>
                        }
                    />

                    <Content className="admin-content content-bg pd-1 crm-font-family">

                        <Row gutter={[8, 8]}>
                            <Col span={24} className="mb-1">
                                <Card bordered >
                                    <Row style={{marginBottom: "1.5rem"}}>
                                        <Col xs={24} md={20}>
                                            <Space align='center'size={16}>
                                            {cliente.logo ? <Image
                                                height = {100}
                                                width = {100}
                                                placeholder = {true}
                                               src={`${axios.defaults.baseURL + "/cliente/" + this.state.cliente_id + "?logo=" + cliente.logo.file +"&Authorization=" + sessionStorage.getItem("token")}`}
                                            /> : null }
                                                <div>
                                                    <Title className="m-0"> {cliente.alias} </Title>
                                                    <Title level={2} className="title-client-name m-0">{cliente.nombre} {cliente.apellido_paterno ?? ""} {cliente.apellido_materno ?? ""}</Title>
                                                </div>
                                            </Space>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Button
                                                title={"Estado de Cuenta"}
                                                onClick={()=>this.getPDF()}
                                                icon={<FaFileInvoiceDollar/>}
                                                style={{paddingTop: '5px'}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={12} xl={6} >
                                            <label className="lbl-titulo">Empresa</label>
                                            <p className="p-info">{cliente?.empresa}</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lbl-titulo">Cliente desde</label>
                                            <p className="p-info">{moment(cliente?.createdAt).fromNow()}</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lbl-titulo">Proyectos</label>
                                            <p className="p-info">{cliente?.proyectos?.count ? cliente.proyectos.count : 0}</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lbl-titulo">Monto Contratado</label>
                                            <p className="p-info text-contract-format">$ {cliente?.monto_contratado ? cliente?.monto_contratado?.toMoney(true) : 0} MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lbl-titulo">Monto Pagado</label>
                                            <p className="p-info text-amount-format">$ {cliente?.monto_pagado ? cliente?.monto_pagado?.toMoney(true) : 0} MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lbl-titulo">Documentos</label>
                                            <List
                                                style={{ 
                                                    maxHeight: 400,
                                                    overflowY: 'auto'
                                                }}
                                                size="small"
                                                bordere={false}
                                                dataSource={cliente?.documentos?.length > 0 ? cliente?.documentos : []}
                                                renderItem={(d) => <Tooltip placement='left' title={d.name}>
                                                    <List.Item style={{ padding: '4px 8px' }}>
                                                        <Button size="small" icon={<PaperClipOutlined />} onClick={() => window.open(axios.defaults.baseURL + "/cliente/" + this.props.params.cliente_id + "?documento=" + d.file + "&Authorization=" + sessionStorage.getItem("token"))} type="link">{d.name.length > 35 ? `${d.name.slice(0, 35)}...` + d.name.split(".")[d.name.split(".").length - 1] : d.name}</Button>
                                                    </List.Item>
                                                </Tooltip>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Tabs defaultActiveKey="proyectos" className="tabs-crm-client">
                                    <TabPane tab="Proyectos" key="proyectos">
                                        <ProyectosTabs 
                                            cliente={this.state.cliente} 
                                            cliente_id={this.state.cliente_id} 
                                            ref={this.proyectosRef}
                                            refreshInfo={this.refreshInfo}
                                        />
                                    </TabPane>
                                    <TabPane tab="Costos" key="costos">
                                        <TransaccionesTabs
                                            tipo={2}
                                            cliente_id={this.state.cliente_id} 
                                            refreshInfo={this.refreshInfo}
                                            cliente={this.state.cliente}
                                        />
                                    </TabPane>
                                    <TabPane tab="Ingresos" key="ingresos">
                                        <TransaccionesTabs
                                            tipo={1}
                                            cliente_id={this.state.cliente_id} 
                                            refreshInfo={this.refreshInfo}
                                            cliente={this.state.cliente}
                                        />
                                    </TabPane>
                                    <TabPane tab="Facturas" key="facturas">
                                        <FacturasTabs 
                                            cliente_id={this.state.cliente_id} 
                                            refreshInfo={this.refreshInfo}
                                        />
                                    </TabPane>
                                    <TabPane tab="Transacciones Programadas" key="programadas">
                                        <TransaccionesProgramadasTab 
                                            cliente_id={this.state.cliente._id}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}

export default function () {
    return <DetalleClientes params={useParams()} />
}