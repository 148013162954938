import React from 'react';
import { Row, Card, List, Tag, Checkbox, Typography, Col } from 'antd';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { AiOutlineClockCircle, AiOutlineCheckCircle } from 'react-icons/ai'
const moment = require('moment')
const { Meta } = Card
const { Title } = Typography


const CardConcluido = ({ id, vencido = false, sinAsesor = null,complete = false, cliente = {}, className ,date = new Date(), description, proyecto = {}, onClick = () => { }, onCancel = () => { } }) => {

    let dates = (now = moment(), date) => {

        let time_ago = 'min'
        let diff_ago = now.diff(moment(date), 'minutes')

        if (diff_ago > 120) {
            diff_ago = now.diff(moment(date), 'hours')
            time_ago = 'hrs';
            if (diff_ago > 23) {
                diff_ago = now.diff(moment(date), 'days');
                time_ago = 'days';
            }
        }


        return <>
            {diff_ago} {time_ago}
        </>
    }

    let color='#1DD667'
    
    let tipo='Concluida'

    let task = [
        {
            title: 'Pre entrega',
            value: 8
        },
        {
            title: 'Entrega',
            value: 9
        },
        {
            title: 'Conclusion del Contrato',
            value: 10
        },
        {
            title: 'Pago Final',
            value: 11
        },

    ]

    return <Card
        hoverable
        bordered={false}
        
        className={"card-shadow card-task pm " + (complete ? " card-task-complete" : "") + (className)}
        style={{ position: "relative", }}
        loading={(proyecto.loading === true)}
    >
		<Meta title={
			<Row justify='space-between' align='middle'>
				<Title className='card-title'>
					{'{{PROYECTO}}'}
				</Title>
				<Tag onClick={onClick} className="card-task-tag" color={color} style={{ color: vencido ? "black" : undefined }} >{tipo}</Tag>
				</Row>} 
				description={dates(moment(), proyecto.createdAt)} />
        <div className='card-container-pm'>
            <div  onClick={onClick}>
                <List
                    itemLayout="horizontal"
                    dataSource={task}
                    bordered={false}
                    className="list-border-none mt-1"
                    renderItem={item => (
                        <List.Item >
                            <List.Item.Meta
                                className="card-task-list-meta"
                                avatar={<Checkbox

                                    className="custom-checkbox-circle"
                                    checked={item.value <= proyecto.estatus}

                                />}
                                title={item.title}

                            />
                        </List.Item>
                    )}

                />
                <Row className='mt-1'>
                    <Col span={12} className="horas-utilizadas">
                        <Row>
                            Horas utilizadas
                        </Row>
                        <Row align='middle' className='principal'>
                            <AiOutlineClockCircle className='mr-1' /> {proyecto.horas_utilizadas ? proyecto.horas_utilizadas : 0} h
                        </Row>
                    </Col>
                    <Col span={12} className="horas-estimadas">
                        <Row>
                            Horas estimadas
                        </Row>
                        <Row align='middle' className='principal'>
                            <AiOutlineCheckCircle className='mr-1' /> {proyecto.horas_utilizadas ? proyecto.horas_utilizadas : 0} h
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
        <Row className='card-task-footer' justify={"space-between"}>
            <Col span={24} className='mt-1'>
				<CustomAvatar
					image={cliente?.nombre}
            		name={[cliente?.nombre, cliente?.apellido]} 
                    className="mr-1"/> 
				{`{{CLIENTE}}`}
            </Col>

        </Row>
    </Card>
}

export default CardConcluido