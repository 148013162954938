import React, { Component } from "react";
import { Row, Col, Button, message, Spin, Typography, Drawer, Space, Popconfirm, Progress } from 'antd';
import axios from "axios"
import { DeleteOutlined, FileSearchOutlined, EditOutlined } from '@ant-design/icons';


//Componentes
import { estatus_modulo } from "../../../Utils"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";

//Modal
import DrawerAvance from "./DrawerAvance"
import ModalActividadModulo from "./ModalActividadModulo"

const { Text } = Typography;

class DrawerActividades extends Component {


    constructor(props) {
        super(props);
        this.state = {

            actividades:{
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },
            loading: false,
            modal_visible: false,
            flag: false,

        }
    }

    ModalModulo = React.createRef();

    componentDidMount() {
        if(this.props.modulo_proyecto_id){
            this.getActividades()
        }
    }


    /**
     * @memberof ModalCuentas
     * @method getActividades
     * @description Obtiene informacion del modulo
     */
    getActividades = () => {
        this.setState({loading: true})
        axios.get('/actividades-modulos',{
            params:{
                modulo_proyecto_id: this.props.modulo_proyecto_id
            }
        })
        .then(({ data }) => {
            console.log("data", data);
            this.setState({
                actividades: data
            })
           
        }).catch(error => {
            message.error('Error al obtener el modulo')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Row justify="center">
                    <Col span={23} className="mb-1">
                        <Row gutter={[16,0]}>
                            <Col md={8} className=" ">
                                <Text strong>Nombre</Text>
                            </Col>
                            <Col md={4} className=" center">
                                <Text strong>Horas consumidas / estimadas</Text>
                            </Col>
                            
                            <Col md={4} className=" center">
                                <Text strong>Progreso</Text>
                            </Col>
                            <Col md={4} className=" center">
                                <Text strong>Responsable</Text>
                            </Col>
                            <Col md={2} className=" center">
                                <Text strong>Estatus</Text>
                            </Col>
                            <Col md={2} className=" center">
                                <Text strong></Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={23}>
                        { this.state.actividades.data.map( actividad => <Row key={actividad._id} className="pb-1 pt-1" gutter={[16,0]} style={{borderBottom: "1px #d5d5d5 solid"}}>
                            <Col md={8} className=" flex-left">
                                <Text>{actividad.nombre}</Text>
                            </Col>
                            <Col md={4} className=" center">
                                <Text>{actividad.horas_consumidas} / {actividad.horas_estimadas} hrs</Text>
                            </Col>
                            
                            <Col md={4} className=" center">
                                <Progress percent={actividad.porcentaje_avance} size="small" />
                            </Col>
                            <Col md={4} className=" center">
                                {actividad.responsable_id ? <>
                                    <CustomAvatar 
                                        name={[actividad.responsable_id?.nombre, actividad.responsable_id?.apellidos]}  
                                    />
                                    <Text ellipsis>{actividad.responsable_id?.nombre ?? "N/A"} {actividad.responsable_id?.apellidos}</Text>
                                </>: "n/a"}
                            </Col>
                            <Col md={2} className=" center">
                                <Text>{estatus_modulo(actividad.estatus)}</Text>
                            </Col>
                            <Col md={2} className=" center">
                                <Space>
                                    <Button
                                        icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
                                        type="primary"
                                        title="Ver Detalle"
                                        onClick={() => this.setState({ drawer_visible: true, actividad_modulo_id: actividad._id, avance: actividad.porcentaje_avance })}
                                        size="small"
                                    >
                                    </Button>
                                    <Button
                                        icon={<EditOutlined style={{ color: "currentcolor" }} />}
                                        type="primary"
                                        title="Editar"
                                        onClick={() => this.setState({ modal_visible: true, actividad_modulo_id: actividad._id })}
                                        size="small"
                                    >
                                    </Button>
                                    <Popconfirm
                                        placement="topRight"
                                        title="¿Deseas eliminar esta actividad?"
                                        onConfirm={() => axios.delete('/actividad-modulo/' + actividad._id).then((response) => {
                                            message.success('Actividad Eliminada')
                                            this.getActividades()
                                        }).catch((error) => {
                                            message.success('Actividad NO Eliminada')
                                            this.getActividades();
                                        })
                                        }
                                        okText="Si"
                                        cancelText="No"
                                    >
                                        <Button 
                                            type="primary" 
                                            danger 
                                            icon={<DeleteOutlined style={{ color: "currentcolor" }} />} 
                                            title="Eliminar"
                                            size="small"
                                        />
                                    </Popconfirm>
                                </Space>
                            </Col>
                        </Row>)}
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={23} className="flex-right mt-1">
                        <Button
                            onClick={()=>this.setState({modal_visible: true})}
                        >
                            Agregar Actividad
                        </Button>
                    </Col>
                </Row>

                <ModalActividadModulo
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false, actividad_modulo_id: undefined})
                        if(flag === true){
                            this.getActividades()
                        }
                    }}
                    modulo_proyecto_id={this.props.modulo_proyecto_id}
                    actividad_modulo_id={this.state.actividad_modulo_id}
                />

                <DrawerAvance
                    visible={this.state.drawer_visible}
                    onClose={()=>{
                        this.setState({drawer_visible: false, actividad_modulo_id: undefined, avance: 0})
                        this.getActividades()
                    }}
                    actividad_modulo_id={this.state.actividad_modulo_id}
                    avance={this.state.avance}
                />
            </Spin>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { } } = props

    return <Drawer
        visible={visible}
        placement="bottom"
        onClose={onClose}
        title={"Actividades"}
        destroyOnClose={true}
        size="large"
    >
        <DrawerActividades {...props} />
    </Drawer>

}