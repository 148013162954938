import React from 'react'
import { Route, Routes } from "react-router-dom";

//componentes
import Dashboard from '../components/Admin/ProjectManager/Dashboard'
import ProjectManager from '../components/Admin/ProjectManager/ProjectManager'

//css
import '../Styles/Modules/ProjectManager/projectManager.css'

/**
 * 
 * @export
 * @function RouterProyectos
 * @description Router for Proyectos routes 
 */
function RouterProjectManager(props) {
  return (
    <Routes>
      <Route path="" element={<Dashboard {...props} />} />
      <Route path="/cotizacion/:cotizacion_id" element={<ProjectManager {...props} />} />
    </Routes>
  )
}

export default RouterProjectManager